// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bread__SA7lM {
  margin-bottom: 24px;
}
.bread__SA7lM .ant-breadcrumb {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .bread__SA7lM {
    margin-bottom: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Breadcrumb/Bread.module.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAFA;EAKQ,aAAA;EACA,mBAAA;AAAR;AAKE;EACE;IACE,mBAAA;EAHJ;AACF","sourcesContent":[".bread {\n    margin-bottom: 24px;\n  \n    :global {\n      .ant-breadcrumb {\n        display: flex;\n        align-items: center;\n      }\n    }\n  }\n  \n  @media (max-width: 767px) {\n    .bread {\n      margin-bottom: 12px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bread": `bread__SA7lM`
};
export default ___CSS_LOADER_EXPORT___;
