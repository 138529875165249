import { Button, Result } from 'antd';
import {Link} from "react-router-dom"
import React from 'react';

const InvitationSuccefully = () => {
    return (
        <Result
        status="success"
        title="Successfully Invitation!"
        subTitle="Email inviata con successo!"
        extra={
          <Link to="/">
                <Button type="primary">Back to Home</Button>
            </Link>
        }
    />
      )
}

export default InvitationSuccefully