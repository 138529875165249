import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Page from "../../Components/Page/Page";
import List from "./Components/List";
import { ColumnsListConsumption,ColumnsListAccredit,ColumnsListTransfer,ColumnsListTransferExport } from "./Components/ColumnsList";
import { Tabs, Row, Col, Select } from "antd";
import { useTranslate } from "../../Translate";
import Filter from "./Components/Filter";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { fetchGroups,fetchBalance,fetchListApps } from "../../redux/reducers/consumption-reducer";
import moment from 'moment';
import { Excel } from "antd-table-saveas-excel";
const { Option } = Select;

const ColProps = {
  xs: 24,
  sm: 12,
  style: {
    marginBottom: 16,
  },
};


const Consumption = (props) => {
  const { type } = props;
  const { keycloak } = useKeycloak();
  const i18n = useTranslate();
  const { t } = i18n;
 
  const [filterGroups, setFilterGroups] = useState([]);
  const [optionTypeSelected,setOptionTypeSelected] = useState(2);
  const userid = keycloak?.tokenParsed?.sub?.toString();

  // set up dispatch
  const dispatch = useDispatch();
  // fetch data from our store
  const { loading, error, groups,balance,applk } = useSelector((state) => state.consumption);
  const balanceData = balance?.content;
  const onChangeTab = (key) => {
    let sel = 2;
    if (key === '1') {
      sel =2;
      dispatch(fetchBalance(keycloak.token,userid,"?type=2"))
    } else if (key === '2') {
      dispatch(fetchBalance(keycloak.token,userid,"?type=1"))
      sel=1
      
    } else {
      dispatch(fetchBalance(keycloak.token,userid,""))
      sel = undefined;
      
    }
    setOptionTypeSelected(sel);
  };
  const ColumnsConsumption = ColumnsListConsumption(applk);
  const ColumnsAccredit = ColumnsListAccredit();
  const ColumnsTransfer = ColumnsListTransfer(applk,type);
  
  const  columnsExport = ColumnsListTransferExport(applk,type);
  const items = [
    {
      label: t("consumption"),
      key: "1",
      children: (
        <>
          <List loading={loading} data={balanceData} columns={ColumnsConsumption} />
        </>
      ),
    },
    {
      label: t("accredit"),
      key: "2",
      children: (
        <>
          <List loading={loading} data={balanceData} columns={ColumnsAccredit} />
        </>
      ),
    },
    {
      label: t("accountbalance"),
      key: "3",
      children: (
        <>
          <List loading={loading} data={balanceData} columns={ColumnsTransfer} />
        </>
      ),
    },
  ];
  const onFilterChange = (filter) => {
    // setfilteredData(filter)
    console.log(filter)
    let groups = filter.groups!==undefined ? filter.groups: ""
    let typefilter = filter.type !== undefined ? filter.type : "";
    let balanceStartDate= ""
    let balanceEndDate = ""
    if (filter.dataInserimento != undefined && filter.dataInserimento.length != 0) {
      let dateVarStart = moment(filter.dataInserimento[0]);
      let newDateVarStart = dateVarStart.utc().format("DD-MM-YYYY");
      let dateVarEnd = moment(filter.dataInserimento[1]);
      let newDateVarEnd = dateVarEnd.utc().format("DD-MM-YYYY");
      balanceStartDate  = newDateVarStart;
      balanceEndDate = newDateVarEnd;
      //data["orderEndDate"] = newDateVarEnd;
    }
    let str_filter = groups === "" ? "?type="+typefilter+"&balanceStartDate="+balanceStartDate+"&balanceEndDate="+balanceEndDate : "/"+groups+"?type="+typefilter+"&balanceStartDate="+balanceStartDate+"&balanceEndDate="+balanceEndDate
    dispatch(fetchBalance(keycloak.token,userid,str_filter,type));
  };

  useEffect(() => {
    dispatch(fetchListApps());
    dispatch(fetchGroups());
    type === "admin" ? dispatch(fetchBalance(keycloak.token,userid,"",type)) :  dispatch(fetchBalance(keycloak.token,userid,"?type=2",type))

  }, [type]);

  useEffect(() => {
    /*carico la lookup degli accounts*/
    const lk_accounts = [];
    groups.map((account) => {
      if (type == "admin") {
        lk_accounts.push(
          <Option value={account[0]} key={account[0]}>
            {account[1].trim()}
          </Option>
        );
      } else if (
        keycloak.tokenParsed.accounts.indexOf(account[0]) != -1 &&
        type != "admin"
      ) {
        lk_accounts.push(
          <Option value={account[0]} key={account[0]}>
            {account[1].trim()}
          </Option>
        );
      }
      return account;
    });
    setFilterGroups(lk_accounts);
  }, [groups]);

  const handleClickExport = () => {
    const excel = new Excel();
    excel
      .addSheet("balance")
      .addColumns(columnsExport)
      .addDataSource(balanceData, {
        str2Percent: true
      })
      .saveAs("balance.xlsx");
  };


  return (
    <Page inner>
      <Row gutter={24}>
        <Col {...ColProps} xl={{ span: 24 }} md={{ span: 20 }}>
          <Filter
            onFilterChange={onFilterChange}
            groups={filterGroups}
            type={type}
            optionTypeSelected={optionTypeSelected}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col {...ColProps} xl={{ span: 24 }} md={{ span: 20 }}>
          {type === "admin" ?  <List loading={loading} data={balanceData} btnexport={true} handleClickExport={handleClickExport} columns={ColumnsTransfer} /> : <Tabs defaultActiveKey="1" onChange={onChangeTab} items={items} />}
        </Col>
      </Row>
    </Page>
  );
};

export default Consumption;
