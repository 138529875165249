import React from "react";
import { Select, Space, Tooltip } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import { useTranslate } from "../../../Translate/";

const { Option } = Select;

const FilterStore = (props) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const { category, handleChangeCategory, catsearch, handleClearFilter,handleChangeSorting,sortsearch } =
    props;

  const getCategory = () => {
    const cats = [];
    category.forEach((cat) => {
      cats.push(
        <Option value={cat.id} key={cat.id}>
          {cat.descrizione.trim()}
        </Option>
      );
    });
    return cats;
  };

  return (
    <>
      <Space>
        <Select
          bordered={false}
          showSearch
          style={{
            width: 150,
          }}
          onChange={handleChangeCategory}
          placeholder={t("category")}
          optionFilterProp="children"
          value={parseInt(catsearch) ? parseInt(catsearch) : undefined} 
          dropdownMatchSelectWidth={false}
          filterOption={(input, option) => option.children.includes(input)}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {getCategory()}
        </Select>

        <Select
          bordered={false}
          showSearch
          style={{
            width: 150,
          }}
          onChange={handleChangeSorting}
          dropdownMatchSelectWidth={false}
          placeholder={t("orderby")}
          optionFilterProp="children"
          value={parseInt(sortsearch) ? parseInt(sortsearch) : 1} 
          filterOption={(input, option) => option.children.includes(input)}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          <Option value={1} key={1}>{t("mostrecent")}</Option>
          <Option value={2} key={2}>{t("lessrecent")}</Option>
        </Select>
        <Tooltip title="Clear filter">
          <CloseCircleFilled onClick={handleClearFilter} />
        </Tooltip>
      </Space>
    </>
  );
};

export default FilterStore;
