/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { AccountsService } from "../../Service/AccountsService";
import List from "./Components/List";
import Page from "../../Components/Page/Page";
import Filter from "./Components/Filter";
import {
  handleLogError,
  ErrorPage,
  openNotificationWithIcon,
} from "../../Helpers/Helpers";
import { Select } from "antd";

const { Option } = Select;

const Accounts = (props) => {
  const { type } = props;
  const [accounts, setAccounts] = useState([]);
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState(false);
  const [dealers, setDealers] = useState([]);
  const [dealerslk, setDealerslk] = useState([]);
  const [reload, setReload] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filteredData, setfilteredData] = useState([]);
  const navigate = useNavigate();

  const getData = async (newPagination) => {
    var filteredDataSend = {};
    filteredDataSend["type"] = type;
    filteredDataSend["pagination"] =
      newPagination.pagination !== undefined
        ? newPagination.pagination
        : pagination;
    filteredDataSend["fieldSearch"] = filteredData;
    try {
      const response =
        filteredData === undefined
          ? await AccountsService.getAccounts(keycloak.token, filteredDataSend)
          : await AccountsService.search(keycloak.token, filteredDataSend);

      setAccounts(response.data.content);
      setLoading(false);
      setPagination({
        ...(newPagination.pagination !== undefined
          ? newPagination.pagination
          : pagination),
        total: response.data.totalElements,
      });
      if (response.status === 500 || response.status === 0) {
        navigate("/error/500");
      } else {
        if (response.status === 403) {
          navigate("/error/403");
        } else if (response.status === 401) {
          navigate("/error/401");
        }
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  const getDealer = async () => {
    try {
      var filteredDataSend = {};
      filteredDataSend["type"] = type;
      filteredDataSend["pagination"] = {
        current: 1,
        pageSize: 1000000,
      };
      filteredDataSend["fieldSearch"] = { dealer: true };
      const response = await AccountsService.getAccountsDealer(
        keycloak.token,
        filteredDataSend
      );
      let lk_accounts = [];
      //popolo la lookup degli accounts
      lk_accounts = response.data.content.map((account) => ({
        id: account.id,
        companyname: account.companyname.trim(),
      }));
      setDealers(lk_accounts);

      var lk_accounts_dealer = [];
      //popolo la lookup degli accounts
      lk_accounts.map((account) => {
        lk_accounts_dealer.push(
          <Option value={account.id} key={account.id}>
            {account.companyname.trim()}
          </Option>
        );
        return true;
      });
      setDealerslk(lk_accounts_dealer);
    } catch (error) {
      handleLogError(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDealer();
    getData(pagination);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, filteredData, reload]);

  const onFilterChange = (filter) => {
    setPagination({
      current: 1,
      pageSize: 10,
    });
    setfilteredData(filter);
  };
  const handleTableChange = (newPagination) => {
    getData({ pagination: newPagination });
    setPagination(newPagination);
  };

  const onEditItem = (id) => {
    type === "admin"
      ? navigate("/admin/groups/" + id)
      : navigate("/team/groups/" + id);
  };

  const onDeleteItem = async (id) => {
    setLoading(true);
    try {
      const response = await AccountsService.disabled(keycloak.token, id);
      if (response.status == 201 || response.status == 200) {
        openNotificationWithIcon(
          "success",
          "Success",
          "Disattivazione avvenuta con successo!"
        );
        setReload(Math.round(Math.random() * 100000000000));
        type === "admin"
          ? navigate("/admin/groups/")
          : navigate("/team/groups/");
      } else if (response.status == 400) {
        openNotificationWithIcon(
          "warning",
          response.data.type,
          response.data.messaggio
        );
      } else {
        ErrorPage(response.status, navigate);
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
    setReload(false);
  };

  return (
    <Page inner>
      <Filter onFilterChange={onFilterChange} type={type} dealers={dealerslk}/>
      <List
        dealers={dealers}
        onEditItem={onEditItem}
        onDeleteItem={onDeleteItem}
        loading={loading}
        accounts={accounts}
        type={type}
        pagination={pagination}
        handleTableChange={handleTableChange}
      />
    </Page>
  );
};

export default Accounts;
