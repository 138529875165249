import axios from 'axios'
import { config } from '../utils/Constants'


export const FaqsService = {
    getFaqs,
    getCatFaqs,
    search,
    getFaqById,
    deleteFaq,
    updateFaq,
    saveFaq,
    getCatFaqById,
    updateCatFaq,
    saveCatFaq,
    deleteCatFaq,
    getNews,
    getAllNews,
    getCatNews,
    getCatNewsById,
    updateCatNews,
    saveCatNews,
    deleteCatNews,
    searchNews,
    getNewsById,
    deleteNews,
    updateNews,
    saveNews,


}

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response;
}, function (error) {
    console.log(error)
    return error.response;;
});

// -- Helper functions
function bearerAuth(token) {
    return `Bearer ${token}`
}


/*
SERVIZI DEDICATO ALLE  FAQ
*/

function getFaqs() {
    return instance.get('/zf/api/public/faqs', {
        headers: {
            'Content-type': 'application/json'
        }
    })
}



function search(filter) {
    return instance.get('/zf/api/public/faqs/searchall/', {
        headers: {
            'Content-type': 'application/json'
        },
        params: filter
    })
}


function deleteFaq(token, id) {
    return instance.delete('/zf/api/faqs/' + id, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}

function updateFaq(token, data) {


    return instance.put('/zf/api/faqs', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })

}

function saveFaq(token, data) {


    return instance.post('/zf/api/faqs', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })

}

function getFaqById(id) {
    return instance.get('/zf/api/public/faqs/id/' + id, {
        headers: {
            'Content-type': 'application/json'
        }
    })
}

/*
SERVIZI DEDICATO ALLE CATEGORIA DELLE FAQ
*/


function getCatFaqs(data) {
    return instance.get('/zf/api/public/catfaq/search/', {
        headers: {
            'Content-type': 'application/json'
        },
        params: data
    })
}


function getCatFaqById(id) {
    return instance.get('/zf/api/public/catfaq/id/' + id, {
        headers: {
            'Content-type': 'application/json'
        }
    })
}


function updateCatFaq(token, data) {


    return instance.put('/zf/api/catfaq', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })

}

function saveCatFaq(token, data) {


    return instance.post('/zf/api/catfaq', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })

}


function deleteCatFaq(token, id) {
    return instance.delete('/zf/api/catfaq/' + id, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}

/*
SERVIZI DEDICATO ALLE NEWS
*/

function getNews(token, accounts, id, data) {
    if (token == null) {
        //pubblica con il controllo sulla data di attivazione
        return instance.get('/zf/api/public/news/', {
            headers: {
                'Content-type': 'application/json'
            },
            params: data

        })
    } else {
        //pubblica + privata + accounts con il controllo sulla data di attivazione
        return instance.get('/zf/api/newsaccount/' + accounts, {
            headers: {
                'Content-type': 'application/json',
                'Authorization': bearerAuth(token)
            },
            params: data


        })
    }
}


function getAllNews(token) {
    return instance.get('/zf/api/news/', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}


function deleteNews(token, id) {
    return instance.delete('/zf/api/news/' + id, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}

function updateNews(token, data) {


    return instance.put('/zf/api/news', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })

}

function saveNews(token, data) {


    return instance.post('/zf/api/news', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })

}

function getNewsById(token, id) {
    return instance.get('/zf/api/news/id/' + id, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}


/*
SERVIZI DEDICATO ALLE CAT NEWS
*/


function getCatNews() {
    return instance.get('/zf/api/public/catnews', {
        headers: {
            'Content-type': 'application/json',
        }
    })
}


function getCatNewsById(id) {
    return instance.get('/zf/api/public/catnews/id/' + id, {
        headers: {
            'Content-type': 'application/json'
        }
    })
}


function updateCatNews(token, data) {
    return instance.put('/zf/api/catnews', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })

}

function saveCatNews(token, data) {
    return instance.post('/zf/api/catnews', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}


function deleteCatNews(token, id) {
    return instance.delete('/zf/api/catnews/' + id, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}


function searchNews(token, filter) {
    return instance.get('/zf/api/news/searchall/', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        },
        params: filter
    })
}