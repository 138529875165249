/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useEffect, useState } from 'react'
import Page from '../../Components/Page/Page';
import Filter from './Components/Filter';
import CollapseCustom from './Components/CollapseCustom';
import { FaqsService } from '../../Service/FaqsService';
import { handleLogError, ErrorPage} from '../../Helpers/Helpers';
import { useTranslate,getCurrentLanguage } from '../../Translate';
import {
  useNavigate,
} from 'react-router-dom';

const Faqs = () => {
  const i18n = useTranslate();
  const { t ,getCurrentLanguage} = i18n;
  const [faqs, setFaqs] = useState([])
  const [cats, setCats] = useState([])
  const [filteredData, setfilteredData] = useState([]);
  const type="public";
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const getData = async () => {
    try {
      if (filteredData.length === 0){
        const data = {languageid: getCurrentLanguage() ==='en' ? 2:1};
        const response = await FaqsService.search(data);
        setFaqs(response.data);
        ErrorPage(response.status, navigate);
        const responseCats= await FaqsService.getCatFaqs(data)
        const options = [];
        setCats(options);
        ErrorPage(responseCats.status, navigate);
        responseCats.data.map((cat) => {
          const value = cat.id
          options.push({
            label: cat.titolo,
            value
          });
        })
      }else{
        const response = await FaqsService.search(filteredData);
        setFaqs(response.data);
        ErrorPage(response.status, navigate);
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  }

  const onFilterChange = (filter) => {

    setfilteredData(filter)
  }

  useEffect(() => {
    setLoading(true)
    getData()
  }, [filteredData,getCurrentLanguage()])

  return (
    <Page  inner loading={loading}>
    <>  <Filter type={type} onFilterChange={onFilterChange} cats={cats}  />
    <CollapseCustom faqs={faqs}/></></Page>
  )
}

export default Faqs