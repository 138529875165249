import { createSlice } from "@reduxjs/toolkit";
import {
  api,
  setHeader,
} from "../../Helpers/Helpers";

// initial state
export const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  groups: [],
  balance: [],
  applk:[]
};

const consumptionSlice = createSlice({
  name: "consumption",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },

    setGroups: (state, { payload }) => {
    //  state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.groups = payload;
    },

    setAppLk: (state, { payload }) => {
    //  state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.applk = payload;
    },
    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },
    setBalance: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.balance = payload;
    },
    cleanBalance: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.balance = [];
    },
  },
});

// export the actions
export const {
  setLoading,
  setError,
  setAppLk,
  cleanError,
  setGroups,
  setBalance,
  cleanBalance,
} = consumptionSlice.actions;

// export the default reducer
export default consumptionSlice.reducer;

export const fetchGroups = () => async (dispatch) => {
  dispatch(cleanError());
  try {
    const response = await api.get(
      "/tsbadmin/api/public/accounts-lk",
      setHeader(null, null)
    );
    dispatch(setGroups(response.data));
  } catch (err) {
    dispatch(setError());
  }
};


export const fetchListApps = () => async (dispatch) => {
  dispatch(cleanError());
  try {
    const response = await api.get(
      "/tsapps/api/public/appnames",
      setHeader(null, null)
    );
    dispatch(setAppLk(response.data));
  } catch (err) {
    dispatch(setError());
  }
};

export const fetchBalance =
  (token, userid = "", filter = "",type= "") =>
  async (dispatch) => {
    dispatch(setLoading());
    dispatch(cleanError());
    let path= "";
    if(type === "admin"){
      path = "/tspayments/api/admin/balance" + filter;
    }else{
      path = "/tspayments/api/balance" + filter;
    }
   
    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setBalance(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };
