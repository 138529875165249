import React from 'react'
import { Table,Modal} from 'antd'
import { Link } from "react-router-dom";
import DropOption from '../../../Components/DropOption/DropOption'
import styles from './List.module.less'
import {SiMicrosoftexcel} from 'react-icons/si';
import { useTranslate } from '../../../Translate';

const { confirm } = Modal

const List = (props) => {
  //gestione della lingua
  const i18n = useTranslate();
  const { t } = i18n;
  const {type,users,loading,pagination,handleTableChange,handleClickExport,onDeleteItem,onEditItem,dealers} = props;
  const pathDetails = type ==="admin" ? '/admin/accounts/' : '/team/accounts/';
  // const { onDeleteItem, onEditItem } = props
  const handleMenuClick = (record, e) => {
    if (e.key === '1') {
      onEditItem(record.id)
    } else if (e.key === '2') {
      confirm({
        title: t`Procedere con l'operazione di cancellazione dell'account?`,
        onOk() {
          onDeleteItem(record.id)
          
        },
      })
    }
  }
    const columns = [
        {
          title: t("firstName"),
          dataIndex: 'firstName',
          key: 'name',
          width: 150,
          render: (text, record) => (
              <><Link to={`${pathDetails}${record.id}`}>{record.firstName} {record.lastName}</Link></>     
          ),
        },
        {
          title: 'Username',
          dataIndex: 'username',
          key: 'username',
          width: 150,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width: 150,
        },
        {
          title: t("group"),
          dataIndex: 'attributes',
          key: 'azienda',
          width: 150,
          textWrap: 'word-break',
          render: (text, record) => 
            <>
             
               {record.attributes !==null ? record.attributes.accounts : ''}
            
            </>,
        },
        {
          title: 'Dealer',
          dataIndex: 'attributes',
          key: 'dealer',
          align:"center",
          width: 90,
          render: (text, record) => <> {
            record.attributes !=null && record.attributes?.dealer != null ? record.attributes?.dealer[0] === "true" ? t("yes"): "No" : "No"
            }</>,
        },
        {
          title: "Dealer di appartenenza",
          dataIndex: "dealerAccount",
          key: "dealerAccount",
          width: 150,
          render: (text, record) => <> {  
            record.attributes !=null && record.attributes?.dealerAccount !=null ? dealers.find(oggetto => oggetto.id === parseInt(record.attributes?.dealerAccount))?.companyname ?? null : null
            }</>,
        },
        {
          title: t("active"),
          dataIndex: 'enabled',
          key: 'enabled',
          align:"center",
          width: 90,
          render: (text, record) => <> {record.enabled ? 'Sì' : 'No'}</>,
        },
        // {
        //   title: 'Data creazione',
        //   dataIndex: 'createdTimestamp',
        //   key: 'creationeDate',
        //   render:(text,record) =>(
            
        //     <>{new Intl.DateTimeFormat('it-IT', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(record.creationeDate)}</>
        //   )
        // },
        
        {
          title: t("agreementmarketing"),
          dataIndex: 'attributes',
          key: 'agreementmarketing',
          align:"center",
          width: 90,
          render: (text, record) => <> {
            record.attributes !=null ? record.attributes.agreementmarketing[0] === "true" ? t("yes"): "No" : "No"
            }</>,
        },
        {
          title: t("agreementprofilazione"),
          dataIndex: 'attributes',
          align:"center",
          key: 'agreementprofilazione',
          width: 90,
          render: (text, record) => <> {record.attributes != null ? record.attributes.agreementprofilazione[0] ==="true" ? t("yes"): "No" : "No"}</>,
        },
        {
          title: 'Operation',
          key: 'operation',
          fixed: 'right',
          width: '8%',
          render: (text, record) => {
            return (
              <DropOption
                onMenuClick={e => handleMenuClick(record, e)}
                menuOptions={record.email ==='xxxxx' ? [] : type === "admin" ?[
                  //{ key: '1', name: <>{record.enabled ? t`Disattiva` : t`Attiva`} </>},
                  { key: '1', name: <>{t`Modifica`} </>},
                  { key: '2', name: t`Cancella` },
                ]:[ { key: '1', name: <>{t`Modifica`} </>},]}
              />
            )
          },
        },
      ]

     
  return (
    <>
    <SiMicrosoftexcel title='Export' onClick={handleClickExport} style={{fontSize:'22px',cursor:'pointer'}}/>
    <Table
        dataSource={users}
        className={styles.table}
        bordered
        pagination={pagination}
        scroll={{ x: 1200 }}
        columns={columns}
        loading={loading} 
        rowKey={record => record.id}
        onChange={handleTableChange}
      />
      </>
  )
}

export default List