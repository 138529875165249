export const convertTimestamp = (timestamp) => {
  // Creazione di un oggetto Date utilizzando il timestamp
  const date = new Date(timestamp);

  // Estrazione dei componenti della data (giorno, mese, anno)
  const day = date.getDate();
  const month = date.getMonth() + 1; // Mese inizia da 0, aggiungiamo 1
  const year = date.getFullYear();

  // Formattazione della data nel formato "dd/mm/yyyy"
  const formattedDate = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;

  return formattedDate;

};
