// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  background-color: transparent;
}
.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x {
  height: 8px;
}
.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y {
  width: 8px;
}
.ps__rail-y, .ps__rail-x {
  z-index: 9;
}
.ps__thumb-y {
  width: 4px;
  right: 4px;
}
.ps__thumb-x {
  height: 4px;
  bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ScrollBar/index.module.less"],"names":[],"mappings":"AAAA;EAGI,6BAAA;AAAJ;AAHA;EAQI,WAAA;AADJ;AAPA;EAaI,UAAA;AAFJ;AAXA;EAkBI,UAAA;AAHJ;AAfA;EAsBI,UAAA;EACA,UAAA;AAJJ;AAnBA;EA2BI,WAAA;EACA,WAAA;AALJ","sourcesContent":[":global {\n  .ps--active-x > .ps__rail-x,\n  .ps--active-y > .ps__rail-y {\n    background-color: transparent;\n  }\n\n  .ps__rail-x:hover > .ps__thumb-x,\n  .ps__rail-x:focus > .ps__thumb-x {\n    height: 8px;\n  }\n\n  .ps__rail-y:hover > .ps__thumb-y,\n  .ps__rail-y:focus > .ps__thumb-y {\n    width: 8px;\n  }\n\n  .ps__rail-y,\n  .ps__rail-x {\n    z-index: 9;\n  }\n\n  .ps__thumb-y {\n    width: 4px;\n    right: 4px;\n  }\n\n  .ps__thumb-x {\n    height: 4px;\n    bottom: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
