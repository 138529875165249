import React from 'react'
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown,Avatar } from 'antd';
import { useKeycloak } from '@react-keycloak/web'
import  {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons'
import classnames from 'classnames'
import {
  setLocalStorageItem
} from "../../Helpers/Helpers";
import styles from './Header.module.less'
import { config } from '../../utils/Constants'
import {  useTranslate,
  useTranslateDispatch,
  useTranslateState} from '../../Translate'

const { Header} = Layout;


const AdminHeader = (props) => {
  const { language  } = useTranslateState();
  const i18n = useTranslate();
  const { t, getLanguages} = i18n;
  const dispatch = useTranslateDispatch();
  const { keycloak } = useKeycloak()
  const {
    collapsed,
    onCollapseChange,
  } = props

  const handleLogInOut = () => {
    if (keycloak.authenticated) {
      keycloak.logout({
        redirectUri: config.url.CLIENT_URL
      })

    } else {
      keycloak.login()
    }
  }

  const getLogInOutText = () => {
    return keycloak.authenticated ? t("signout"): t("signin2")
  }
  const getRegisterText = () => {
    return keycloak.authenticated ? false : true
  }

  const getSettingsText = () => {
    return keycloak.authenticated ? true : false
  }

  const getUsername = () => {
    //console.log(keycloak.tokenParsed);
    let username = keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.given_name
    if(username !== false){
      const welcome= t("Header.welcome");
      username =welcome+', '+username;
    }else{
      username =  <Link key="itemRegister" to="/registration"><span style={{fontSize:14}} >{t("createaccout")}</span></Link>
    }
    return username;
  }

  const itemRegister =  {
    danger: true,
    key: 'itemRegister',
    label: (
      <Link key="itemRegister" to="/registration"><span >{t("createaccout")}</span></Link>
    )
  };


  const itemSettingCookiepolicy =  {
    key: 'itemSettingCookiepolicy ',
    label: (
      <Link key="itemSettingCookiepolicy " to="/settingcookiepolicy "><span >Modifica cookie policy</span></Link>
    )
  };


  const itemSettings =  {
    danger: true,
    key: 'itemSettings',
    label: (
      <Link key="itemSettings" to="/settings"><span >{t("settings")}</span></Link>
    )
  };

  const itemsMenuUser = [
    getSettingsText() ? itemSettings : null,
    {
      key: 'itemsMenuUser',
      label: (
        <span onClick={handleLogInOut} key="itemsMenuUser" >{getLogInOutText()}</span>
      ),
    },
    itemSettingCookiepolicy,
    getRegisterText() ? itemRegister : null,
    
  ]

 
  const itemsLanguages = getLanguages().map(key => ({
    key: 'itemsLanguages_'+key,
    label: (
      <>
      <Avatar
      key={'itemsLanguages_'+key}
      style={{ marginRight: 8 }}
      src={t(`Flag.${key}`)}
    /><span onClick={() => {
          setLocalStorageItem("language", key);
          dispatch({ type: "CHANGE_LANGUAGE", language: key });
        }}>
        {t(`LanguageSwitcher.${key}`)}
      </span>
    </>
    ),
  }));

  const menuUser = (
    <Menu
    key={itemsMenuUser.key}
      items={itemsMenuUser}
    />
  );

  const menuLanguage =(
    <Menu 
       items={itemsLanguages}
    />
    
  )

  const rightContent = [
    <>
      <span style={{ color: '#666', marginRight: 10 }}>{getUsername()}</span>
      <Dropdown  overlay={menuUser} placement="bottomLeft" arrow>
        <Avatar  style={{ backgroundColor: '#e61e1e' }} icon={<UserOutlined />} />
      </Dropdown>
    </>
  ]

  if (language) {
    const defaultFlag= t(`Flag.${language}`);
    rightContent.push(
    <Dropdown key={defaultFlag}  overlay={menuLanguage} placement="bottomLeft" arrow>
      <Avatar  style={{marginLeft:8}}  /*src={currentLanguage.flag}*/ src={defaultFlag} />
    </Dropdown>
  
    );
  }

  return (
    <Header
    id="layoutHeader"
    className={classnames(styles.header, {
          [styles.collapsed]: collapsed,
          [styles.fixed]: false
        })}
    >
      <div
         className={styles.button}
         onClick={onCollapseChange}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <div key="rightContent" className={styles.rightContainer}>{rightContent}</div>
    </Header>
  )
}

export default AdminHeader