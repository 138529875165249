import React from 'react'

import { Table,Checkbox} from 'antd'

import styles from './List.module.less'
import { useTranslate } from '../../../Translate';



const List = (props) => {
    
    //gestione della lingua
    const i18n = useTranslate();
    const { t } = i18n;
    const {permissions,loading,onChangeCk}= props;
    const columns = [
        {
            title: t("name"),
            dataIndex: 'name',
            render: (text, record) => (
                <>{record.attributes.descrizione}</>     
            ),
        
        },
        {
            title: t("active"),
            dataIndex: 'active',
            key: 'active',
            align: 'center',
            render: (text, record) => (
                
                <><Checkbox key={record.id} value={record.id} defaultChecked={record.active === true ? true : false} onChange={onChangeCk} ></Checkbox></>     
            ),
        },
        {
            title: t("description"),
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => (
                <>{record.description}</>     
            ),
        },
    ]
    return (
        <Table
            dataSource={permissions}
            className={styles.table}
            loading={loading}
            bordered
            pagination={false}
            scroll={{ x: 1200 }}
            columns={columns}
            rowKey={record => record.id}
          />
      )
}

export default List