import { createSlice } from "@reduxjs/toolkit";
import { api, setHeader } from "../../Helpers/Helpers";

// initial state
export const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  contacts: [],
  contact: {},
};

// our slice
const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setContacts: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.contacts = payload;
    },
    setContact: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.contact = payload;
    },
    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },
  },
});

// export the actions
export const { setLoading, setContacts, setContact, cleanError, setError } =
  contactsSlice.actions;

// export the default reducer
export default contactsSlice.reducer;

export const fetchContacts =
  (token, userid = "", gruppoid) =>
  async (dispatch) => {
    let path = "";
    path = "/tsapps/api/postalizzazione/rubrica/gruppo/" + gruppoid;
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setContacts(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

export const fetchContact =
  (token, userid = "", contactid) =>
  async (dispatch) => {
    let path = "";
    path = "/tsapps/api/postalizzazione/rubrica/" + contactid;
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setContact(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };
