import React from 'react'
import { Link } from "react-router-dom"
import {
    Result,
    Button
} from "antd"

const Page403 = () => {
  return (
    <Result
            status="403"
            title="403 Forbidden"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
                <Link to="/">
                    <Button type="primary">Back to Home</Button>
                </Link>
            }
        />
  )
}

export default Page403