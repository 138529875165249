import React, { useState, useEffect } from "react";
import { Layout, BackTop } from "antd";
import Sidebar from "../Components/Sidebar/Sidebar";
import AdminHeader from "../Components/Header/AdminHeader";
import AdminFooter from "../Components/Footer/AdminFooter";
import { Routes } from "../utils/ConfigRoutes";
import styles from "./PrimaryLayout.module.less";
import Bread from "../Components/Breadcrumb/Breadcrumb";

//import CustomScroll from 'react-custom-scroll';

import ScrollBar from "../Components/ScrollBar";
const { Content } = Layout;

const AppLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const onCollapseChange = () => {
    setCollapsed(!collapsed);
  };

  const handleResize = () => {
    resize();
  };

  const resize = () => {
    if (window.innerWidth < 992) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    resize();
  },[]);

  return (
    <>
      <Layout style={{ border: "0px solid", overflow: "hidden" }}>
        <Sidebar routes={Routes()} collapsed={collapsed} />
        <div className={styles.container} id="adminLayout">
          <AdminHeader
            collapsed={collapsed}
            routes={Routes()}
            onCollapseChange={onCollapseChange}
          />
          <ScrollBar
            options={{
              // Disabled horizontal scrolling, https://github.com/utatti/perfect-scrollbar#options
              suppressScrollX: true,
            }}
          >
            <Content className={styles.content} id="adminLayoutContent">
              <Bread />
              {props.children}
              <BackTop
                className={styles.backTop}
                target={() => document.querySelector("#adminLayoutContent")}
              />
            </Content>
            {/* <AdminFooter />  */}
          </ScrollBar>
        </div>
      </Layout>
    </>
  );
};

export default AppLayout;
