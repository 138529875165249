/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApps,
  fetchCategoriesAppsAll,
  deleteApp,
  fetchGroups,
  buyPreview,
  cleanPreview,
} from "../../redux/reducers/admin-app-reducer";

import Page from "../../Components/Page/Page";
import List from "./Components/List";
import Filter from "./Components/Filter";
import { ColumnsListApp, ColumnsListAppBuy } from "./Components/ColumnsList";
import { useNavigate } from "react-router-dom";
import { Result, Modal, Form, Select, Typography, DatePicker } from "antd";
import {
  ErrorPage,
  getItemFromLocalStorage,
  handleLogError,
  isAppPublic,
} from "../../Helpers/Helpers";
import { useTranslate } from "../../Translate";
import moment from "moment";
import { CreditsService } from "../../Service/CreditsService";
const { Title, Paragraph } = Typography;
const { Option } = Select;

const AppList = (props) => {
  //gestione lingua
  const i18n = useTranslate();
  const { t } = i18n;
  const [formModalRef] = Form.useForm();
  const [formModalRefExpiration] = Form.useForm();
  const { buy } = props;
  const navigate = useNavigate();
  const language = getItemFromLocalStorage("language")?.category || "it";
  const { keycloak } = useKeycloak();
  const [cats, setCats] = useState([]);
  const userid = keycloak?.tokenParsed?.sub?.toString();
  // set up dispatch
  const dispatch = useDispatch();
  // fetch data from our store
  const {
    loading,
    error,
    apps,
    appsBuy,
    categories,
    groups,
    allcategories,
    preview,
  } = useSelector((state) => state.adminApps);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenExpiration, setIsModalOpenExpiration] = useState(false);
  const [rowExpiration, setRowExpiration] = useState([]);
  const [filterGroups, setFilterGroups] = useState([]);
  const [filterGroupsModal, setFilterGroupsModal] = useState([]);
  const [isBuy, setIsBuy] = useState(false);
  const [selAppId, setSelAppId] = useState(null);
  const [selGroupId, setSelGroupId] = useState(null);
  //console.log(appsData)

  //hook to fetch items
  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchApps(keycloak.token, "", "", buy));
  }, [dispatch, buy]);

  useEffect(() => {
    dispatch(fetchCategoriesAppsAll());
  }, []);

  useEffect(() => {
    const options = [];
    setCats(options);
    allcategories.map((cat) => {
      const value = cat.id;
      options.push({
        label:
          language === "en"
            ? JSON.parse(cat.descrizione).en
            : JSON.parse(cat.descrizione).it,
        value,
      });
      return cat;
    });
  }, [allcategories]);

  const onDeleteItem = (id) => {
    dispatch(deleteApp(keycloak.token, userid, id));
  };

  const onEditItem = (id) => {
    navigate("/admin/services/" + id);
  };

  const onBuyItem = (id) => {
    showModal(id);
    // navigate('/admin/services/'+id);
  };

  const onDocumentsItem = (id) => {
    navigate("/admin/services/" + id + "/documents");
  };

  const onEditScadenza = (record) => {
    setRowExpiration(record);
    setIsModalOpenExpiration(true);
  };
  const handleCancelExpiration = () => {
    setIsModalOpenExpiration(false);
    setRowExpiration([]);
  };
  const handleOkExpiration = () => {
    formModalRefExpiration
      .validateFields()
      .then((data) => {
        //formModalRefExpiration.resetFields();;
        updateExpiration(data);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const updateExpiration = async (data) => {
    var send = {}
    var d = data["newExpireDate"];
    if (d !== undefined && d.length !== 0) {
      let tmpdata = moment(d._d);
      send["newExpireDate"] = tmpdata.format("YYYY-MM-DD");
    }
    send.appOwnedId= rowExpiration?.id;
    try {
      let response = null;
      response = await CreditsService.updateExpiration(
        keycloak.token,
        keycloak.tokenParsed.sub.toString(),
        send
      );
      ErrorPage(response.status, navigate);
      setIsModalOpenExpiration(false);
      setRowExpiration([]);
      dispatch(fetchApps(keycloak.token, "", "", buy));
    } catch (error) {
      handleLogError(error);
    }
  };

  const columns = buy
    ? ColumnsListAppBuy(null, null, onEditScadenza)
    : ColumnsListApp(onEditItem, onDeleteItem, onBuyItem, onDocumentsItem);
  const onFilterChange = (values) => {
    console.log(values);
    let search = values.search !== undefined ? values.search : "";
    let category = values.category !== undefined ? values.category : "";
    let gruppo = values.gruppo !== undefined ? values.gruppo : "";
    let filter =
      "?fieldSearch=" + search + "&categoria=" + category + "&gruppo=" + gruppo;
    dispatch(fetchApps(keycloak.token, "", filter, buy));
  };

  const ckIsBuy = (app) => {
    //verfico se è public
    if (app.length === 0) return false;
    if (app.contattoAttivazione) return false;
    if (!keycloak.authenticated) return false;
    const isPublic = isAppPublic(app.visibilitaGruppiId);
    if (isPublic) {
      //metto in questa variabile tutti i gruppi dell'utente
      var newFilterGroups = groups;
      app?.appComprataDa?.map((item) => {
        newFilterGroups = newFilterGroups.filter((i) => i[0] !== item);
      });
      setFilterGroupsModal(newFilterGroups);
    } else {
      var newFilterGroups = [];
      app?.visibilitaGruppiId.map((item) => {
        var found = filterGroups.find((element, index) => {
          if (parseInt(element.key) === item) {
            return true;
          } else {
            return false;
          }
        });
        if (found) {
          newFilterGroups.push(found);
        }
      });
      setFilterGroupsModal(newFilterGroups);
      if (newFilterGroups?.length > 0) {
        app?.appComprataDa.map((item) => {
          newFilterGroups = newFilterGroups.filter((i) => i[0] !== item);
        });
        setFilterGroupsModal(newFilterGroups);
      }
    }

    //trasformo la lk
    const lk_accounts = [];
    newFilterGroups.map((account) => {
      lk_accounts.push(
        <Option value={account[0]} key={account[0]}>
          {account[1].trim()}
        </Option>
      );

      return account;
    });
    setFilterGroupsModal(lk_accounts);
    newFilterGroups.length > 0 ? setIsBuy(true) : setIsBuy(false);
  };

  const showModal = (id) => {
    setIsBuy(false);
    setSelAppId(id);
    const result = apps.content.filter((app) => app.id === id)[0];
    ckIsBuy(result);
    formModalRef.resetFields();
    setIsModalOpen(true);
  };

  const handleOk = () => {
    formModalRef
      .validateFields()
      .then((data) => {
        formModalRef.resetFields();
        setIsModalOpen(false);
        setSelGroupId(data.groupId);
        simulate(data.groupId);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const simulate = (groupId) => {
    dispatch(buyPreview(keycloak.token, selAppId, groupId));
  };

  useEffect(() => {
    if (JSON.stringify(preview) === JSON.stringify({})) return;
    navigate("/admin/services/preview/" + selAppId + "@" + selGroupId);
  }, [preview]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dateFormat = "DD/MM/YYYY";

  return (
    <Page inner>
      <Filter
        onFilterChange={onFilterChange}
        buy={buy}
        groups={groups}
        categories={cats}
      />
      {!error.status ? (
        <List loading={loading} columns={columns} data={buy ? appsBuy : apps} />
      ) : (
        <Result status="warning" title="Items not available at this time." />
      )}
      <Modal
        title={isBuy ? t("getgrouptxt") : t("warning").toUpperCase()}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"OK"}
      >
        {isBuy ? (
          <Form
            preservare={false}
            form={formModalRef}
            name="modal-ref"
            layout="vertical"
            scrollToFirstError
          >
            <Form.Item
              name="groupId"
              label={t("group")}
              rules={[
                {
                  required: true,
                  message: t("ckGroup"),
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder={t("selectgroup")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.includes(input)
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {filterGroupsModal}
              </Select>
            </Form.Item>
          </Form>
        ) : (
          t("servicenotbuy").toUpperCase()
        )}
      </Modal>
      <Modal
        title={t("selectexpiration").toUpperCase()}
        open={isModalOpenExpiration}
        onOk={handleOkExpiration}
        onCancel={handleCancelExpiration}
        okText={t("salva")}
      >
        <Form
          preservare={false}
          form={formModalRefExpiration}
          name="modal-ref-expiration"
          layout="vertical"
          scrollToFirstError
          initialValues={{
            newExpireDate:
              rowExpiration.dataScadenza !== "" &&
              rowExpiration.dataScadenza !== null
                ? moment(rowExpiration.dataScadenza)
                : "",
          }}
        >
          <Form.Item name="newExpireDate" label={t("nuovadatascadenza")}>
            <DatePicker format={dateFormat} />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  );
};

export default AppList;
