import { createSlice } from "@reduxjs/toolkit";
import {
  getItemFromLocalStorage,
  setLocalStorageItem,
  api,
  apiZen,
  setHeader,
} from "../../Helpers/Helpers";
import { config } from "../../utils/Constants";

const category = getItemFromLocalStorage("query")?.category || "";
const query = getItemFromLocalStorage("query")?.query || "";
const path = getItemFromLocalStorage("query")?.path || "";
const sort = getItemFromLocalStorage("query")?.sort || "";
// initial state
export const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  apps: [],
  app: {},
  category: [],
  users: [],
  query: {
    category: category || "",
    query: query || "",
    path: path || "",
    sort: sort || "",
  },
};

const isQuerySaved = (action) => {
  return action.type.endsWith("/saveQuery");
};

// our slice
const appsSlice = createSlice({
  name: "apps",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setApps: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.apps = payload;
    },
    setApp: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.app = payload;
    },
    deleteData: (state, action) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.apps.content = state.apps.content.filter(
        (el) => el.id !== action.payload
      );
    },
    setCategoryApps: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.category = payload;
    },
    setUsers: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.users = payload;
    },
    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },
    saveQuery: (state, action) => {
      state.query = { ...action.payload };
    },
    setOK: (state, action) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = action.payload;
      state.error.code = 200;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isQuerySaved, (state) => {
      setLocalStorageItem("query", state.query);
    });
  },
});

// export the actions
export const {
  setLoading,
  setApps,
  setApp,
  setError,
  setCategoryApps,
  setUsers,
  saveQuery,
  cleanError,
  deleteData,
  setOK,
} = appsSlice.actions;

// export the selector (".items" being same as in slices/index.js's "items: something")
export const appsSelector = (state) => state.apps;

// export the default reducer
export default appsSlice.reducer;



export const fetchApp =
  (token, userid = "",id) =>
  async (dispatch, getState) => {

    let path = "";
    dispatch(setLoading());
    dispatch(cleanError());
    path="tsapps/api/app/owned/"+id

    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setApp(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

export const fetchApps =
  (token, userid = "", filter = "", view = "") =>
  async (dispatch, getState) => {
    const { category } = getState().apps;
    let path = "";
    dispatch(setLoading());
    dispatch(cleanError());
    if (view === "view_myapps") {
      path = "/tsapps/api/applist/owned" + filter;
    } else if (view === "view_store") {
      path = token
        ? "/tsapps/api/applist" + filter
        : "/tsapps/api/public/applist" + filter;
    } else if (view === "view_favorites") {
      filter = filter.replace("?", "&");
      path = "/tsapps/api/applist/?preferiti=true" + filter;
    }

    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setApps(response.data));
      if (category.length === 0) {
        dispatch(fetchCategoryApps());
      }
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

/*RIMUOVE L'APP DA QUELLE COMPRATE DAL GRUPPO*/
export const deleteApp =
  (token, userid, appid, groupid, idlist) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(cleanError());
    let path = "/tsapps/api/app/remove/" + appid + "/" + groupid;
    try {
      await api.delete(path, setHeader(token, userid));
      dispatch(deleteData(idlist));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

export const fetchCategoryApps = () => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  try {
    const response = await api.get(
      "/tsapps/api/public/categorieapp",
      setHeader(null, null)
    );
    dispatch(setCategoryApps(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const fetchUsers = (token, groupid) => async (dispatch) => {
  dispatch(cleanError());
  try {
    const response = await api.get(
      "/tsbadmin/api/searchUsersByAttributes/accounts:" + groupid,
      setHeader(token, null)
    );
    dispatch(setUsers(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const saveUsersApp = (token, data, dataZen) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  //salviamo utenti su app
  try {
    const response = await api.post(
      "/tsapps/api/app/adduser/",
      data,
      setHeader(token, null)
    );
    if (Object.keys(dataZen).length > 0) {
      const responseZen = await apiZen.post(
        dataZen.linkApp + "/master/ws/api.php?f=utenza&codiceazienda="+dataZen.codiceAzienda,
        dataZen.users,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            "MasterKey": config.zen.KEY,
          }
        }
      );
      console.log(responseZen);
    }
    dispatch(setOK(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

// -- Helper functions
function bearerAuth(token) {
  return `Bearer ${token}`;
}
