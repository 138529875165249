/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import Page from "../../Components/Page/Page";
import { useKeycloak } from "@react-keycloak/web";
import { Divider, Button, Form, Input, Select, notification } from "antd";

// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  getCategory,saveCategory,updateCategory,cleanCategory
} from "../../redux/reducers/admin-app-reducer";
import { useTranslate } from "../../Translate";
import { useNavigate, useParams } from "react-router-dom";

import {
  openNotificationWithIcon
} from "../../Helpers/Helpers";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};

const Category = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // fetch data from our store
  const { loading, error,category } = useSelector(
    (state) => state.adminApps
  );
  const [state, setState] = useState({
    hidden_descrizione_it: false,
    hidden_descrizione_en: true
  });
  const i18n = useTranslate();
  const { t } = i18n;
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();


  const handleBack = () => {
    navigate("/admin/services/categories/");
  };
  useEffect(() => {
    if (id) {
      dispatch(getCategory("", "", id));
    }else{
      dispatch(cleanCategory());
    }
  }, []);

  useEffect(() => {
    setFormData();
  }, [category]);

  useEffect(() => {
    getNotice(error);
  }, [error]);

  const onFinish = (values) => {
    handleSendSave(values);
  };

  const setFormData = () => {
    if (JSON.stringify(category) === JSON.stringify({})) return;
    let descrizione = category ? JSON.parse(category?.descrizione) : "";
    /*setto i campi del form*/
    form.setFieldsValue({
      codice: category.codice,
      descrizione_it:
        descrizione !== null && descrizione["it"] ? descrizione["it"] : "",
      descrizione_en:
        descrizione !== null && descrizione["en"] ? descrizione["en"] : ""
    });
  };


  const handleSendSave = (data) => {
    let descrizione = {};
    descrizione["it"] = data["descrizione_it"];
    descrizione["en"] = data["descrizione_en"];
    delete data["descrizione_it"];
    delete data["descrizione_en"];
    delete data["prefix"];
    data["descrizione"] = JSON.stringify(descrizione);
    data["inattivo"] = 0;
    console.log(data);
    if (id) {
      data["id"] = id;
      update(data);
    } else {
      save(data);
    }
  };


  const save = (data) => {
    dispatch(saveCategory(keycloak.token, "", data));
  };

  const update = (data) => {
    dispatch(updateCategory(keycloak.token, "", data));
  };


  
  const getNotice = (response) => {
    if (response.status) {
      if (response.code == 400 || response.code == 404) {
        openNotificationWithIcon(
          "warning",
          "Warning",
          <>{response.message ? response.message : ""}</>
        );
      } else if (response.code == 401) {
        openNotificationWithIcon(
          "warning",
          "Warning",
          response.message ? response.message : ""
        );
      } else {
        openNotificationWithIcon(
          "error",
          "Errore",
          t("errorapp") + "\n" + response.message ? response.message : ""
        );
      }
    } else {
      if (response.code === 200) {
        var msg = t("msgsaveapp");
        openNotificationWithIcon("success", "Success", msg);
        navigate("/admin/services/categories");
      }
    }
  };


  const handleChangeLanguage = (value, e) => {
    if (value == "IT") {
      setState((state) => ({
        ...state, // <-- shallow copy previous state
        hidden_descrizione_it: false,
        hidden_descrizione_en: true,
      }));
    } else if (value == "EN") {
      setState((state) => ({
        ...state,
        hidden_descrizione_it: true,
        hidden_descrizione_en: false,
      }));
    }
  };

  const prefixLanguageSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }} onChange={handleChangeLanguage}>
        <Option value="IT">IT</Option>
        <Option value="EN">EN</Option>
      </Select>
    </Form.Item>
  );
  return (
    <Page inner>
      <>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          {id == null ? t("newcatnews") : t("editcatnews")}
        </Divider>
        <Form
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="cat-app"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            prefix: "IT",
          }}
        >
          <Form.Item
            label={t("code")}
            name="codice"
            rules={[
              {
                required: true,
                message: t("ckcode"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            hidden={state.hidden_descrizione_it}
            label={t("description")}
            name="descrizione_it"
            rules={[
              {
                required: true,
                message: t("ckDescription"),
              },
            ]}
          >
            <Input addonBefore={prefixLanguageSelector} />
          </Form.Item>

          <Form.Item
            hidden={state.hidden_descrizione_en}
            label={t("description")}
            name="descrizione_en"
          >
            <Input addonBefore={prefixLanguageSelector} />
          </Form.Item>
          <Form.Item></Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              {t("save")}
            </Button>
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={handleBack}
            >
              {t("cancel")}
            </Button>
          </Form.Item>
        </Form>
      </>
    </Page>
  );
};

export default Category;
