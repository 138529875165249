import React from 'react'
import styles from './PageStore.module.less'
import classnames from 'classnames'
import Loader from '../Loader/Loader'

const PageStore = (props) => {
    const { children,className, loading = false, inner = false } = props
    const loadingStyle = {
        height: 'calc(100vh - 184px)',
        overflow: 'hidden',
      }
    return (
        <div
            className={classnames(className, {
                [styles.contentInner]: inner,
            })}
            
            style={loading ? loadingStyle : null}
        >
            {loading ? <Loader spinning /> : ''}
            {children}
        </div>
    )
}

export default PageStore