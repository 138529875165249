/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";
import {
  Divider,
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Upload,
  message,
  DatePicker,
  Switch,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import Page from "../../Components/Page/Page";
import { useTranslate } from "../../Translate";
import styles from "./Components/Style.module.less";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  saveApp,
  updateApp,
  getApp,
  fetchCategoriesApps,
  fetchGroups,
  cleanApp,
} from "../../redux/reducers/admin-app-reducer";
import { openNotificationWithIcon, getBase64 } from "../../Helpers/Helpers";

import { lkPricingConsumo, lkPricingAttivazione } from "./UtilsApp";
import ReactQuill from "react-quill";
import moment from "moment";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";
const optionsProfilazioneDefault = [
  { value: 0, label: "Profilo link sito esterno senza integrazioni" },
  { value: 1, label: "ZenShare" },
  { value: 2, label: "Download File" },
  { value: 5, label: "Capture&Store" },
  { value: 6, label: "NV Group" },
  { value: 7, label: "Volocom" },
];
const optionsProfilazioneNone = [{ value: 0, label: "Nessuna profilazione" }];
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

let ww = 0;
let hh = 0;

const AppForm = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // fetch data from our store
  const { loading, error, apps, app, categories, groups } = useSelector(
    (state) => state.adminApps
  );
  const i18n = useTranslate();
  const { t } = i18n;

  const [state, setState] = useState({
    hidden_titolo_it: false,
    hidden_titolo_en: true,
    hidden_descrizione_it: false,
    hidden_descrizione_en: true,
    hidden_infolist_it: false,
    hidden_infolist_en: true,
    hidden_infofaq_it: false,
    hidden_infofaq_en: true,
    hidden_infosetting_it: false,
    hidden_infosetting_en: true,
  });

  const [stateProvisioning, setStateProvisioning] = useState({
    listino: true,
    servizio: true,
  });
  const [stateProfiloCaptureStore, setStateProfiloCaptureStore] = useState(true);
  const [stateActivationPrice, setStateActivationPrice] = useState(true);
  const [stateImagePreview, setStateImagePreview] = useState([]);
  const [optionsProfilazione, setOptionsProfilazione] = useState(
    optionsProfilazioneDefault
  );
  const [isPeriodico, setIsPeriodico] = useState(false);
  const [pricingConsumo, setPricingConsumo] = useState([]);
  const [pricingAttivazione, setPricingAttivazione] = useState([]);
  const [lkCategories, setLkCategories] = useState([]);
  const [lkGroups, setLkGroups] = useState([]);
  const [stateImageDettaglio, setStateImageDettaglio] = useState([]);
  const [filePreviewList, setFilePreviewList] = useState([]);
  const [fileDettaglioList, setFileDettaglioList] = useState([]);
  const { id } = useParams();
  const [form] = Form.useForm();
  const { keycloak } = useKeycloak();
  const userid = keycloak?.tokenParsed?.sub?.toString();
  const navigate = useNavigate();

  const getDataPricingConsumo = (t) => {
    const arr = lkPricingConsumo(t);
    var response = [];
    arr.forEach((item, index) => {
      response.push(
        <Option key={index} value={index}>
          {item}
        </Option>
      );
    });
    setPricingConsumo(response);
  };

  const getDataPricingAttivazione = (t) => {
    const arr = lkPricingAttivazione(t);
    var response = [];
    arr.forEach((item, index) => {
      response.push(
        <Option key={index} value={index}>
          {item}
        </Option>
      );
    });
    setPricingAttivazione(response);
  };

  const onFinish = (values) => {
    handleSendSave(values);
  };

  const handleSendSave = (data) => {
    let titolo = {};
    let descrizione = {};
    let condizioniAcquisto = {};
    let infoListino = {};
    let infoFAQ = {};
    let infoSupporto = {};
    titolo["it"] = data["titolo_it"];
    titolo["en"] = data["titolo_en"];
    descrizione["it"] = data["descrizione_it"];
    descrizione["en"] = data["descrizione_en"];
    infoListino["it"] = data["infoListino_it"];
    infoListino["en"] = data["infoListino_en"];
    infoFAQ["it"] = data["infoFAQ_it"];
    infoFAQ["en"] = data["infoFAQ_en"];
    infoSupporto["it"] = data["infoSupporto_it"];
    infoSupporto["en"] = data["infoSupporto_en"];
    condizioniAcquisto["it"] = data["condizioniAcquisto"];
    condizioniAcquisto["en"] = data["condizioniAcquisto"];
    delete data["titolo_it"];
    delete data["titolo_en"];
    delete data["descrizione_it"];
    delete data["descrizione_en"];
    delete data["prefix_description"];
    delete data["prefix_title"];
    delete data["infoListino_it"];
    delete data["infoListino_en"];
    delete data["infoFAQ_it"];
    delete data["infoFAQ_en"];
    delete data["infoSupporto_it"];
    delete data["infoSupporto_en"];
    delete data["prefix_descrizione"];
    delete data["prefix_infofaq"];
    delete data["prefix_infolist"];
    delete data["prefix_infosetting"];
    data["titolo"] = JSON.stringify(titolo);
    data["descrizione"] = JSON.stringify(descrizione);
    data["infoListino"] = JSON.stringify(infoListino);
    data["infoFAQ"] = JSON.stringify(infoFAQ);
    data["infoSupporto"] = JSON.stringify(infoSupporto);
    data["condizioniAcquisto"] = JSON.stringify(condizioniAcquisto);
    data["iconaPreview"] = stateImagePreview.filebase64;
    data["iconaPreviewNome"] = stateImagePreview.filename;
    data["iconaDettaglio"] = stateImageDettaglio.filebase64;
    data["iconaDettaglioNome"] = stateImageDettaglio.filename;
    data["disponibileWeb"] = data.disponibileWeb ? 1 : 0;
    data["disponibileMFP"] = data.disponibileMFP ? 1 : 0;
    data["contattoAttivazione"] = data.contattoAttivazione ? 1 : 0;
    data["costoAttivazioneIniziale"] = data.costoAttivazioneIniziale ? data.costoAttivazioneIniziale : 0;
    data["costoElaborazionePerPagina"] = data.costoElaborazionePerPagina ? data.costoElaborazionePerPagina : 0;
    data["stato"] = 1;
    var d = data["dataPubblicazione"];
    if (d !== undefined && d.length !== 0) {
      let tmpdata = moment(d._d);
      data["dataPubblicazione"] = tmpdata.format("DD-MM-YYYY");
    }
    //return;
    if (id) {
      data["id"] = id;
      update(data);
    } else {
      save(data);
    }
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />}> {t("uploadimage")}</Button>
  );

  const handleChangeFilePreview = async (info) => {
    let filebase64 = "";
    let filename = "";
    let fileList = [...info.fileList];
    setFilePreviewList(fileList);
    if (info.file.status !== "removed") {
      filebase64 = await getBase64(fileList[0].originFileObj);
      filename = fileList[0].name;
      setStateImagePreview({
        filebase64,
        filename,
      });
    }
  };

  const handleChangeFileDettaglio = async (info) => {
    let filebase64 = "";
    let filename = "";
    let fileList = [...info.fileList];
    setFileDettaglioList(fileList);
    if (info.file.status !== "removed") {
      filebase64 = await getBase64(fileList[0].originFileObj);
      filename = fileList[0].name;
      setStateImageDettaglio({
        filebase64,
        filename,
      });
    }
  };

  const controllaImmagine = (file, w, h) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
    });
    return reader;
  };
  const handleBefore = (w, h, tipo) => {
    var f = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        //"You can only upload JPG/PNG file!"
        message.error(t("ckUploadFiletype"));
        return Upload.LIST_IGNORE;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        //Image must smaller than 2MB!
        message.error(t("ckUploadFilesize"));
        return Upload.LIST_IGNORE;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl;
        image.addEventListener("load", () => {
          const { width, height } = image;
          ww = width;
          hh = height;
        });
      });
      setTimeout(function () {
        if (ww !== w || hh !== h) {
          console.log(ww);
          message.error(
            t("ckUploadImageSize") +  " [" + ww + "," + hh + "]"
          );
          tipo === 1 ? setFilePreviewList([]) : setFileDettaglioList([]);
          return Upload.LIST_IGNORE;
        }
      }, 100);

      return false;
    };
    return f;
  };

  const save = (data) => {
    dispatch(saveApp(keycloak.token, userid, data));
  };

  const update = (data) => {
    dispatch(updateApp(keycloak.token, userid, data));
  };

  const getNotice = (response) => {
    if (response.status) {
      if (response.code === 400 || response.code === 404) {
        openNotificationWithIcon(
          "warning",
          "Warning",
          <>{response.message ? response.message : ""}</>
        );
      } else if (response.code === 401) {
        openNotificationWithIcon(
          "warning",
          "Warning",
          response.message ? response.message : ""
        );
      } else {
        openNotificationWithIcon(
          "error",
          "Errore",
          t("errorapp") + "\n" + response.message ? response.message : ""
        );
      }
    } else {
      if (response.code === 200) {
        var msg = t("msgsaveapp");
        openNotificationWithIcon("success", "Success", msg);
        navigate("/admin/services");
      }
    }
  };

  const setFormData = () => {
    if (JSON.stringify(app) === JSON.stringify({})) {
      form.resetFields();
      form.setFieldsValue({
        periodo: 0,
        maxUtenti: 0,
      });
      setFileDettaglioList([]);
      setFilePreviewList([]);
      return;
    }
    let titolo = app ? JSON.parse(app?.titolo) : "";
    let descrizione = app ? JSON.parse(app?.descrizione) : "";
    let infoListino = app ? JSON.parse(app?.infoListino) : "";
    let infoFAQ = app ? JSON.parse(app?.infoFAQ) : "";
    let infoSupporto = app ? JSON.parse(app?.infoSupporto) : "";
    let condizioniAcquisto = app ? JSON.parse(app?.condizioniAcquisto) : "";
    let sel_cat = [];
    app.categorie.forEach((cat) => {
      sel_cat.push(cat.id);
    });
    if (app.iconaPreview !== null && app.iconaPreview !== "") {
      const fileListPreviewRead = [
        {
          uid: "-2",
          name: app.iconaPreviewNome,
          status: "done",
          //url: app.iconaPreview,
        },
      ];
      setFilePreviewList(fileListPreviewRead);
      setStateImagePreview({
        filebase64: app.iconaPreview,
        filename: app.iconaPreviewNome,
      });
    }

    if (app.iconaDettaglio !== null && app.iconaDettaglio !== "") {
      const fileListDettaglioRead = [
        {
          uid: "-1",
          name: app.iconaDettaglioNome,
          status: "done",
          //url: app.iconaDettaglio,
        },
      ];
      setFileDettaglioList(fileListDettaglioRead);
      setStateImageDettaglio({
        filebase64: app.iconaDettaglio,
        filename: app.iconaDettaglioNome,
      });
    }
    app.profiloIntegrazione === 1
      ? setStateProvisioning((stateProvisioning) => ({
          listino: false,
          servizio: false,
        }))
      : setStateProvisioning((stateProvisioning) => ({
          listino: true,
          servizio: true,
        }));
    app.profiloIntegrazione === 5 ?  setStateProfiloCaptureStore(false)  : setStateProfiloCaptureStore(true);    

    
   (app.pricingAttivazione === 2) ? setStateActivationPrice(false): setStateActivationPrice(true);    
    /*setto i campi del form*/
    form.setFieldsValue({
      titolo_it: titolo !== null && titolo["it"] ? titolo["it"] : "",
      titolo_en: titolo !== null && titolo["en"] ? titolo["en"] : "",
      descrizione_it:
        descrizione !== null && descrizione["it"] ? descrizione["it"] : "",
      descrizione_en:
        descrizione !== null && descrizione["en"] ? descrizione["en"] : "",
      infoListino_it:
        infoListino !== null && infoListino["it"] ? infoListino["it"] : "",
      infoListino_en:
        infoListino !== null && infoListino["en"] ? infoListino["en"] : "",
      infoFAQ_it: infoFAQ !== null && infoFAQ["it"] ? infoFAQ["it"] : "",
      infoFAQ_en: infoFAQ !== null && infoFAQ["en"] ? infoFAQ["en"] : "",
      infoSupporto_it:
        infoSupporto !== null && infoSupporto["it"] ? infoSupporto["it"] : "",
      infoSupporto_en:
        infoSupporto !== null && infoSupporto["en"] ? infoSupporto["en"] : "",
      linkApp: app.linkApp,
      condizioniAcquisto:
        condizioniAcquisto !== null && condizioniAcquisto["it"]
          ? condizioniAcquisto["it"]
          : "",
      autore: app.autore,
      dataPubblicazione:
        app.dataPubblicazione !== "" && app.dataPubblicazione !== null
          ? moment(app.dataPubblicazione)
          : "",
      disponibileWeb: app.disponibileWeb,
      disponibileMFP: app.disponibileMFP,
      contattoAttivazione: app.contattoAttivazione,
      visibilitaGruppiId: app.visibilitaGruppiId,
      categorieId: sel_cat,
      pricingAttivazione: app.pricingAttivazione,
      profiloIntegrazione: app.profiloIntegrazione,
      pricingConsumo: app.pricingConsumo,
      maxUtenti: app.maxUtenti,
      prezzo: app.prezzo,
      periodo: app.periodo,
      zenshareProvisioningListinoId: app.zenshareProvisioningListinoId,
      zenshareProvisioningServizioId: app.zenshareProvisioningServizioId,
      costoAttivazioneIniziale: app.costoAttivazioneIniziale,
      costoElaborazionePerPagina: app.costoElaborazionePerPagina
      
    });
  };

  const setLkCategory = () => {
    const lk_category = [];
    categories.forEach((cat) => {
      lk_category.push(
        <Option value={cat.id} key={cat.id}>
          {cat.descrizione}
        </Option>
      );
    });
    setLkCategories(lk_category);
  };

  const setLkGroup = () => {
    const lk_groups = [];
    groups.forEach((group) => {
      lk_groups.push(
        <Option value={group[0]} key={group[0]}>
          {group[1].trim()}
        </Option>
      );
    });
    setLkGroups(lk_groups);
  };

  //hook to fetch items
  useEffect(() => {
    getNotice(error);
  }, [getNotice, error, apps]);

  useEffect(() => {
    getDataPricingConsumo(t);
    getDataPricingAttivazione(t);
    dispatch(fetchCategoriesApps());
    dispatch(fetchGroups());
    if (id) {
      dispatch(getApp(keycloak.token, userid, id));
    } else {
      dispatch(cleanApp());
    }
  }, []);

  useEffect(() => {
    setFormData();
  }, [app]);

  useEffect(() => {
    setLkCategory();
  }, [categories]);

  useEffect(() => {
    setLkGroup();
  }, [groups]);

  const handleBack = () => {
    navigate("/admin/services");
  };

  const handleChangeLanguageTitle = (value, e) => {
    if (value === "IT") {
      setState((state) => ({
        ...state, // <-- shallow copy previous state
        hidden_titolo_it: false,
        hidden_titolo_en: true,
      }));
    } else if (value === "EN") {
      setState((state) => ({
        ...state,
        hidden_titolo_it: true,
        hidden_titolo_en: false,
      }));
    }
  };

  const handleChangeLanguage = (tipo) => {
    var f = (value, e) => {
      if (value === "IT") {
        setState((state) => ({
          ...state,
          [`hidden_${tipo}_it`]: false,
          [`hidden_${tipo}_en`]: true,
        }));
      } else {
        setState((state) => ({
          ...state,
          [`hidden_${tipo}_it`]: true,
          [`hidden_${tipo}_en`]: false,
        }));
      }
    };
    return f;
  };

  const onChangePricingAttivazione = (value) => {
   (value === 2 || value === 3 ) ? setIsPeriodico(true) : setIsPeriodico(false);
    value === 2  ? setStateActivationPrice(false) : setStateActivationPrice(true);
    form.setFieldsValue({ periodo: 0 });
  };

  const prefixLanguageSelectorTitle = (
    <Form.Item name="prefix_title" noStyle>
      <Select style={{ width: 70 }} onChange={handleChangeLanguageTitle}>
        <Option value="IT">IT</Option>
        <Option value="EN">EN</Option>
      </Select>
    </Form.Item>
  );

  const prefixLanguageSelector = (tipo) => (
    <Form.Item name={"prefix_" + tipo} noStyle>
      <Select
        className={styles.customSelect}
        style={{ width: 70 }}
        onChange={handleChangeLanguage(tipo)}
      >
        <Option value="IT">IT</Option>
        <Option value="EN">EN</Option>
      </Select>
    </Form.Item>
  );

  const onChangeContattoAttivazione = (checked) => {
    form.setFieldValue("profiloIntegrazione", null);
    checked
      ? setOptionsProfilazione(optionsProfilazioneNone)
      : setOptionsProfilazione(optionsProfilazioneDefault);
  };

  const onChangeProfilazione = (value) => {
    value === 1
      ? setStateProvisioning((stateProvisioning) => ({
          listino: false,
          servizio: false,
        }))
      : setStateProvisioning((stateProvisioning) => ({
          listino: true,
          servizio: true,
        }));
    value === 5 ? setStateProfiloCaptureStore(false) : setStateProfiloCaptureStore(true);    

  };

  return (
    <Page loading={loading} inner>
      <>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          {id == null ? t("newsapps") : t("editapps")}
        </Divider>
        <Form
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="apps"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            // suffix_link: ".com",
            // prefix_link: "http://",
            prefix_title: "IT",
            prefix_descrizione: "IT",
            prefix_infolist: "IT",
            prefix_infofaq: "IT",
            prefix_infosetting: "IT",
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                hidden={state.hidden_titolo_it}
                label={t("title")}
                name="titolo_it"
                rules={[
                  {
                    required: true,
                    message: t("ckTitle"),
                  },
                  {
                    max: 150,
                    message: "Il titolo non può superare i 150 caratteri.",
                  },
                ]}
              >
                <Input addonBefore={prefixLanguageSelectorTitle} />
              </Form.Item>

              <Form.Item
                hidden={state.hidden_titolo_en}
                label={t("title")}
                name="titolo_en"
              >
                <Input addonBefore={prefixLanguageSelectorTitle} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row hidden={state.hidden_descrizione_it}>
                <Col
                  span={2}
                  style={{
                    border: "0px solid",
                    paddingTop: "30px",
                    marginRight: "0px",
                  }}
                >
                  {prefixLanguageSelector("descrizione")}
                </Col>
                <Col
                  span={22}
                  style={{ border: "0px solid", marginLeft: "-45px" }}
                >
                  <Form.Item
                    label={t("description")}
                    name="descrizione_it"
                    rules={[
                      {
                        required: true,
                        message: t("ckDescription"),
                      },
                    ]}
                  >
                    {/* <TextArea rows={4} /> */}
                    <ReactQuill theme="snow" />
                  </Form.Item>
                </Col>
              </Row>
              <Row hidden={state.hidden_descrizione_en}>
                <Col
                  span={2}
                  style={{
                    border: "0px solid",
                    paddingTop: "30px",
                    marginRight: "0px",
                  }}
                >
                  {prefixLanguageSelector("descrizione")}
                </Col>
                <Col
                  span={22}
                  style={{ border: "0px solid", marginLeft: "-45px" }}
                >
                  <Form.Item label={t("description")} name="descrizione_en">
                    {/* <TextArea rows={4} /> */}
                    <ReactQuill theme="snow" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row hidden={state.hidden_infolist_it}>
                <Col
                  span={2}
                  style={{
                    border: "0px solid",
                    paddingTop: "30px",
                    marginRight: "0px",
                  }}
                >
                  {prefixLanguageSelector("infolist")}
                </Col>
                <Col
                  span={22}
                  style={{ border: "0px solid", marginLeft: "-45px" }}
                >
                  <Form.Item
                    label={t("infolist")}
                    name="infoListino_it"
                    rules={[
                      {
                        required: true,
                        message: t("ckDescription"),
                      },
                    ]}
                  >
                    {/* <TextArea rows={4} /> */}
                    <ReactQuill theme="snow" />
                  </Form.Item>
                </Col>
              </Row>
              <Row hidden={state.hidden_infolist_en}>
                <Col
                  span={2}
                  style={{
                    border: "0px solid",
                    paddingTop: "30px",
                    marginRight: "0px",
                  }}
                >
                  {prefixLanguageSelector("infolist")}
                </Col>
                <Col
                  span={22}
                  style={{ border: "0px solid", marginLeft: "-45px" }}
                >
                  <Form.Item label={t("infolist")} name="infoListino_en">
                    {/* <TextArea rows={4} /> */}
                    <ReactQuill theme="snow" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row hidden={state.hidden_infofaq_it}>
                <Col
                  span={2}
                  style={{
                    border: "0px solid",
                    paddingTop: "30px",
                    marginRight: "0px",
                  }}
                >
                  {prefixLanguageSelector("infofaq")}
                </Col>
                <Col
                  span={22}
                  style={{ border: "0px solid", marginLeft: "-45px" }}
                >
                  <Form.Item
                    label={t("infofaq")}
                    name="infoFAQ_it"
                    rules={[
                      {
                        required: true,
                        message: t("ckInfoFaq"),
                      },
                    ]}
                  >
                    {/* <TextArea rows={4} /> */}
                    <ReactQuill theme="snow" />
                  </Form.Item>
                </Col>
              </Row>
              <Row hidden={state.hidden_infofaq_en}>
                <Col
                  span={2}
                  style={{
                    border: "0px solid",
                    paddingTop: "30px",
                    marginRight: "0px",
                  }}
                >
                  {prefixLanguageSelector("infofaq")}
                </Col>
                <Col
                  span={22}
                  style={{ border: "0px solid", marginLeft: "-45px" }}
                >
                  <Form.Item label={t("infofaq")} name="infoFAQ_en">
                    {/* <TextArea rows={4} /> */}
                    <ReactQuill theme="snow" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row hidden={state.hidden_infosetting_it}>
                <Col
                  span={2}
                  style={{
                    border: "0px solid",
                    paddingTop: "30px",
                    marginRight: "0px",
                  }}
                >
                  {prefixLanguageSelector("infosetting")}
                </Col>
                <Col
                  span={22}
                  style={{ border: "0px solid", marginLeft: "-45px" }}
                >
                  <Form.Item
                    label={t("infoassistance")}
                    name="infoSupporto_it"
                    rules={[
                      {
                        required: true,
                        message: t("ckInfosetting"),
                      },
                    ]}
                  >
                    {/* <TextArea rows={4} /> */}
                    <ReactQuill theme="snow" />
                  </Form.Item>
                </Col>
              </Row>
              <Row hidden={state.hidden_infosetting_en}>
                <Col
                  span={2}
                  style={{
                    border: "0px solid",
                    paddingTop: "30px",
                    marginRight: "0px",
                  }}
                >
                  {prefixLanguageSelector("infosetting")}
                </Col>
                <Col
                  span={22}
                  style={{ border: "0px solid", marginLeft: "-45px" }}
                >
                  <Form.Item label={t("infosetting")} name="infoSupporto_en">
                    {/* <TextArea rows={4} /> */}
                    <ReactQuill theme="snow" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Form.Item
                name="iconaPreview"
                label={t("imagepreview")}
                tooltip={{
                  title: "Image 350x350 (px)",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Upload
                  onChange={handleChangeFilePreview}
                  beforeUpload={handleBefore(350, 350, 1)}
                  fileList={filePreviewList}
                  maxCount={1}
                >
                  {filePreviewList.length > 0 ? "" : uploadButton}
                </Upload>
                {/* <br />
                <h3>Current State Log</h3>
                <pre>{JSON.stringify(filePreviewList, null, 2)}</pre> */}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="iconaDettaglio"
                label={t("imagedetail")}
                tooltip={{
                  title: "Image 700x350 (px)",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Upload
                  onChange={handleChangeFileDettaglio}
                  beforeUpload={handleBefore(700, 350, 2)}
                  fileList={fileDettaglioList}
                >
                  {fileDettaglioList.length >= 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="linkApp"
                label="Link Servizio"
                rules={[
                  {
                    required: true,
                    message: t("ckLinkService"),
                  },
                ]}
              >
                <Input
                  //addonBefore={selectBeforeLink}
                  //addonAfter={selectAfterLink}
                  placeholder="link app"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="condizioniAcquisto"
                label="Link Termini e condizioni"
              >
                <Input
                  //addonBefore={selectBeforeLink}
                  //addonAfter={selectAfterLink}
                  placeholder="link"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="autore"
                label={t("author")}
                rules={[
                  {
                    required: true,
                    message: t("ckAuthor"),
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  // suffix={
                  //     <Tooltip title="Extra information">
                  //     <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  //     </Tooltip>
                  // }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="dataPubblicazione"
                label={t("activationdate")}
                rules={[
                  {
                    required: true,
                    message: t("ckActivationdate"),
                  },
                ]}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <Row>
              <Col span={3}>
                <Form.Item
                  valuePropName="checked"
                  name="disponibileMFP"
                  label={t("availablemfp")}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  valuePropName="checked"
                  name="disponibileWeb"
                  label={t("availableweb")}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  valuePropName="checked"
                  name="contattoAttivazione"
                  label={t("servicescontactus")}
                >
                  <Switch onChange={onChangeContattoAttivazione} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24} style={{ border: "0px solid" }}>
                <Form.Item
                  defaultValue={0}
                  label={t("profiloIntegrazione")}
                  name="profiloIntegrazione"
                  rules={[
                    {
                      required: true,
                      message: t("ckProfiloIntegrazione"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onChange={onChangeProfilazione}
                    style={{
                      width: "100%",
                    }}
                    allowClear
                    options={optionsProfilazione}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              hidden={stateProfiloCaptureStore}
              name="costoElaborazionePerPagina"
              label={t("costoElaborazionePerPagina")}
              rules={[
                {
                  required: !stateProfiloCaptureStore,
                  message: t("ckCostoElaborazionePerPagina"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hidden={stateProvisioning.servizio}
              name="zenshareProvisioningServizioId"
              label={t("zenshareProvisioningServizio")}
              rules={[
                {
                  required: !stateProvisioning.servizio,
                  message: t("ckZenshareProvisioningServizio"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hidden={stateProvisioning.listino}
              name="zenshareProvisioningListinoId"
              label={t("zenshareProvisioningListino")}
              rules={[
                {
                  required: !stateProvisioning.listino,
                  message: t("ckZenshareProvisioningListino"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24} style={{ border: "0px solid" }}>
                <Form.Item label={t("category")} name="categorieId">
                  <Select
                    mode="multiple"
                    autoComplete="none"
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase().trim())
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase().trim())
                    }
                    allowClear
                  >
                    {lkCategories}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item label={t("group")} name="visibilitaGruppiId">
              <Select
                mode="multiple"
                autoComplete="none"
                showSearch
                style={{
                  width: "100%",
                }}
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase().trim())
                }
                optionFilterProp="children"
                //   defaultValue={sel_category}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .includes(input.toLowerCase().trim())
                }
                allowClear
              >
                {lkGroups}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
    
              label={t("pricingAttivazione")}
              name="pricingAttivazione"
              rules={[
                {
                  required: true,
                  message: t("ckPricingAttivazione"),
                },
              ]}
            >
              <Select
                autoComplete="none"
                onChange={onChangePricingAttivazione}
                showSearch
                style={{
                  width: "100%",
                }}
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {pricingAttivazione}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hidden={stateActivationPrice}
              label={t("activationprice")}
              name="costoAttivazioneIniziale"
              rules={[
                {
                  required: !stateActivationPrice,
                  message: t("ckActivationprice"),
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t("price")}
              name="prezzo"
              rules={[
                {
                  required: true,
                  message: t("ckPrice"),
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t("period")}
              name="periodo"
              defaultValue={0}
              rules={[
                {
                  required: true,
                  message: t("ckPeriod"),
                },
              ]}
            >
              <InputNumber
                disabled={!isPeriodico}
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t("pricingConsumo")}
              name="pricingConsumo"
              rules={[
                {
                  required: true,
                  message: t("ckPricingConsumo"),
                },
              ]}
            >
              <Select
                autoComplete="none"
                showSearch
                style={{
                  width: "100%",
                }}
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {pricingConsumo}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t("maxusers")}
              name="maxUtenti"
              tooltip={{
                title: t("infomaxUsers"),
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: t("ckMaxusers"),
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
          </Col>

          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col
              span={12}
              style={{
                textAlign: "right",
              }}
            >
              <Button type="primary" htmlType="submit">
                {t("save")}
              </Button>
              <Button
                onClick={handleBack}
                style={{
                  margin: "0 8px",
                }}
              >
                {t("cancel")}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    </Page>
  );
};

export default AppForm;
