/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, {useState}from "react";
import { Button, Row, Col, Form, Input, Select } from "antd";
import { useTranslate } from "../../../Translate";
import { useNavigate } from "react-router-dom";

const {Option} = Select;

const { Search } = Input;

const ColProps = {
  xs: 24,
  sm: 12,
  style: {
    marginBottom: 16,
  },
};

const TwoColProps = {
  ...ColProps,
  xl: 96,
};

const Filter = ({ categories, onFilterChange,buy,groups }) => {
  const navigate = useNavigate();
  const i18n = useTranslate();
  const { t } = i18n;
  const formRef = React.createRef();
  var lk_accounts = [];
  if(groups){

    groups.map((account) => {
      lk_accounts.push(
        <Option value={account[0]} key={account[0]}>
          {account[1].trim()}
        </Option>
      );
      return true;
    });
  
  }
  /*eventuale normalizzazione dei dati tipo la data*/
  const handleFields = (fields) => {
    return fields;
  };
  const handleSubmit = () => {
    const values = formRef.current.getFieldsValue();
    const fields = handleFields(values);
    onFilterChange(fields);
  };

  const handleNew = () => {
    navigate("/admin/services/new");
  };

  const handleCategories = () => {
    navigate("/admin/services/categories/");
  };
  /*funzione per il reset del form*/
  const handleReset = () => {
    //ottengo tutti i campi del form con i rispettivi valori
    const fields = formRef.current.getFieldsValue();
    for (let item in fields) {
      if ({}.hasOwnProperty.call(fields, item)) {
        if (fields[item] instanceof Array) {
          fields[item] = [];
        } else {
          fields[item] = undefined;
        }
      }
    }
    formRef.current.setFieldsValue(fields);
    onFilterChange(fields);
  };

  return (
    <Form ref={formRef} name="control-ref">
      <Row gutter={24}>
        <Col {...ColProps} xl={{ span: 6 }} md={{ span: 10 }}>
          <Form.Item name="search">
            <Search placeholder={t("search")} onSearch={handleSubmit} />
          </Form.Item>
        </Col>
        <Col {...ColProps} xl={{ span: 6 }} md={{ span: 12 }} id="category">
          <Form.Item name="category">
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder={t("selectcategory")}
              optionFilterProp="children"
              options={categories}
            />
          </Form.Item>
        </Col>
        {buy ? 
         <Col {...ColProps} xl={{ span: 6 }} md={{ span: 12 }} id="gruppo">
          <Form.Item name="gruppo">
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder={t("selectgroup")}
              optionFilterProp="children"
              
            >{lk_accounts}</Select>
          </Form.Item>
        </Col>
        :""}
        <Col
          {...TwoColProps}
          xl={{ span: buy ? 6 : 12}}
          md={{ span: 24 }}
          sm={{ span: 24 }}
        >
          <Row
            gutter={24}
            type="flex"
            align="middle"
            justify="space-between"
            style={{ border: "0px solid" }}
          >
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "16px" }}
                onClick={handleSubmit}
              >
                {t("search")}
              </Button>
              <Button onClick={handleReset}>Reset</Button>
            </div>
            {!buy ?
            <div>
              <Button
                type="ghost"
                onClick={handleCategories}
                style={{ marginRight: "16px" }}
              >
                {t("categories")}
              </Button>
              <Button type="ghost" onClick={handleNew}>
                {t("new")}
              </Button>{" "}
            </div> :""}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
