/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useState, useEffect } from "react";
import Page from "../../Components/Page/Page";
import { useKeycloak } from "@react-keycloak/web";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Divider,
  Button,
  Form,
  Input,
  Select,
  Upload,
  Switch,
  Checkbox,
  Modal,
  Row,
  Col,
  Badge,
  Descriptions,
  Space,
  message,
  Tooltip,
  Radio,
} from "antd";
import { useTranslate } from "../../Translate";
import { useNavigate, useParams } from "react-router-dom";
import { openNotificationWithIcon, getBase64 } from "../../Helpers/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { fetchApp } from "../../redux/reducers/store-reducer";
import Contacts from "../Contact/Contacts";
import {
  saveShipping,
  confirmShipping,
  updateShipping,
  cleanError,
} from "../../redux/reducers/shipping-reducer";
const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};
/*
ServiceId: raccomandata -- 'RAR' raccomandata con ricevuta di ritorno -- 'RAC' raccomandata semplice
ServiceId: lettera -- 'POST1' lettera prioritari posta 1 -- 'POST4' lettera massiva posta 4
ServiceId: telegramma -- TEL*/
export const lkTipoServizio = (t) => {
  const tipoServizio = [];
  tipoServizio["POST1"] = t("post1");
  tipoServizio["POST4"] = t("post4");
  tipoServizio["RAR"] = t("rar");
  tipoServizio["RAC"] = t("rac");
  tipoServizio["TEL"] = t("tel");
  return tipoServizio;
};

const Shipping = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageHidden, setPageHidden] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [tipoContacts, setTipoContacts] = useState("");
  const [telegramma, setTelegramma] = useState(false);
  const [senderID, setSenderID] = useState(null);
  const [recipientID, setRecipientID] = useState(null);
  const [msgLoading, setMsgLoading] = useState("");
  const [posta, setPosta] = useState(false);
  const [state, setState] = useState({
    loading: false,
  });
  //gestione della lingua
  const i18n = useTranslate();
  const { t } = i18n;
  var { id, bozzaid } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const userid = keycloak?.tokenParsed?.sub?.toString();
  //STATI

  const [tipoServizio, setTipoServizio] = useState([]);
  // fetch data from our store
  const { loading, error, responseSave, confirmData } = useSelector(
    (state) => state.shipments
  );
  const { app } = useSelector((state) => state.apps);
  const gruppoid = app.accountId || null;
  const getDataTipoServizio = (t) => {
    const arr = lkTipoServizio(t);
    if (bozzaid) {
      delete arr["TEL"];
    }
    const response = [];
    Object.entries(arr).forEach(([key, value], index) => {
      response.push(
        <Option key={key} value={key}>
          {value}
        </Option>
      );
    });
    setTipoServizio(response);
  };

  //torna alla lista spedizioni
  const handleBack = () => {
    navigate("/myservices/" + id + "/shipments");
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />}> {t("uploadfile")}</Button>
  );

  const onFinish = (data) => {
    handleSendSave(data);
  };

  const handleSendSave = async (data) => {
    var dataSend = {};
    var sender = {};
    var recipient = {};
    var postal = {};
    var datiRubrica = {};
    var datiRubricaSender = {};
    var datiRubricaRecipient = {};
    //sender
    sender.name = data.senderName;
    sender.surname = data.senderSurname;
    sender.addressPrefix = data.senderAddressPrefix;
    sender.addressName = data.senderAddressName;
    sender.number = data.senderNumber;
    sender.cap = data.senderCAP;
    sender.city = data.senderCity;
    sender.country = "IT";
    //recipient
    recipient.name = data.recipientName;
    recipient.surname = data.recipientSurname;
    recipient.addressPrefix = data.recipientAddressPrefix;
    recipient.addressName = data.recipientAddressName;
    recipient.number = data.recipientNumber;
    recipient.cap = data.recipientCAP;
    recipient.city = data.recipientCity;
    recipient.country = "IT";
    //postal
    postal.print = data.print == 1 ? true : false;
    postal.color = data.color == 1 ? true : false;
    postal.sender = sender;
    postal.recipient = recipient;
    var file = state?.filebase64?.split(",");
    if (file) {
      postal.file = file[1] || data.testoTelegramma;
    } else {
      postal.file = data.testoTelegramma || "";
    }

    // rubrica
    datiRubricaSender.salvaInRubrica = data.senderSalvaInRubrica || false;
    datiRubricaSender.salvaContattoPrivato =
      data.senderSalvaContattoPrivato || false;
    datiRubricaSender.usaContattoDaRubrica =
      data.senderSalvaInRubrica == true
        ? false
        : senderID == null
        ? false
        : true;
    datiRubricaSender.contattoRubricaId =
      senderID == null || data.senderSalvaInRubrica == true ? 0 : senderID;

    datiRubricaRecipient.salvaInRubrica = data.recipientSalvaInRubrica || false;
    datiRubricaRecipient.salvaContattoPrivato =
      data.recipientSalvaContattoPrivato || false;
    datiRubricaRecipient.usaContattoDaRubrica =
      data.recipientSalvaInRubrica == true
        ? false
        : recipientID == null
        ? false
        : true;
    datiRubricaRecipient.contattoRubricaId =
      recipientID == null || data.recipientSalvaInRubrica == true
        ? 0
        : recipientID;
    datiRubrica.mittente = datiRubricaSender;
    datiRubrica.destinatario = datiRubricaRecipient;

    dataSend.extUsrId = "extusr1";
    dataSend.email = data.email;
    dataSend.serviceCode = data.serviceCode;
    dataSend.postal = postal;
    dataSend.appOwnedId = id;
    dataSend.nomeFile = state?.filename || "ccc";
    dataSend.datiRubrica = datiRubrica;
    setMsgLoading("Stiamo calcolando il prezzo del servizio..");
    if (bozzaid) {
      dataSend.id = bozzaid;
      update(dataSend);
    } else {
      save(dataSend);
    }
  };

  const save = (data) => {
    dispatch(saveShipping(keycloak.token, userid, data));
  };

  const update = (data) => {
    dispatch(updateShipping(keycloak.token, userid, data));
  };

  const confirm = (data) => {
    dispatch(confirmShipping(keycloak.token, userid, data));
  };

  const handleChange = async (info) => {
    let filebase64 = "";
    let filename = "";
    let fileList = [...info.fileList];
    setFileList(fileList);
    if (info.file.status != "removed") {
      filebase64 = await getBase64(fileList[0].originFileObj);
      filename = fileList[0].name;
      setState({
        filebase64,
        filename,
        loading: false,
      });
    }
  };

  const handleBefore = (file) => {
    const isPDF = file.type === "application/pdf";
    const isSizeValid = file.size <= 6 * 1024 * 1024; // 6 MB in bytes

    if (!isPDF) {
      message.error("Puoi caricare solo file PDF!");
      return Upload.LIST_IGNORE;
    }

    if (!isSizeValid) {
      message.error("Il file deve essere inferiore a 6 MB!");
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  const handleRemoveFile = () => {
    setFileList([]);
    setState([]);
  };

  const getNotice = (response) => {
    if (response.status) {
      if (response.code !== 200) {
        openNotificationWithIcon(
          "error",
          "Errore",
          t("errorapp") + "\n" + response.message ? response.message : ""
        );
      }
    }
  };
  //hook to fetch items
  useEffect(() => {
    getNotice(error);
    if (Object.keys(responseSave).length > 0) {
      setPageHidden(true);
    }
    if (Object.keys(confirmData).length > 0) {
      dispatch(cleanError());
      navigate("/myservices/" + id + "/shipments/");
    }
  }, [getNotice, error, responseSave, confirmData]);

  useEffect(() => {
    getDataTipoServizio(t);
    dispatch(fetchApp(keycloak.token, userid, id));
  }, []);

  useEffect(() => {
    if (!loading) {
      setMsgLoading("");
    }
  }, [loading]);

  const onChangeTipoServizio = (value) => {
    const isBozza = !!bozzaid; //Usiamo l'operatore di coalescenza nullo (??) per impostare isBozza a true se bozzaid è presente, o false in caso contrario.
    setTelegramma(isBozza ? false : value === "TEL");
    setPosta(isBozza ? false : value !== "TEL");
  };

  /*HANDLE MODAL*/

  const showModal = (tipo) => {
    setTipoContacts(tipo);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleConfermaSpedizione = (ck) => {
    var data = {};
    data.ordineId = responseSave.id;
    data.status = ck ? "confirm" : "delete";
    confirm(data);
  };

  const HandleSelectContact = (contact) => {
    // Imposta il valore del campo 'senderSurname' nel form
    if (tipoContacts === "sender") {
      form.setFieldsValue({
        senderSurname: contact.surname || "",
        senderName: contact.name || "",
        senderAddressName: contact.addressName || "",
        senderAddressPrefix: contact.addressPrefix || "",
        senderCAP: contact.cap || "",
        senderCity: contact.city || "",
        senderNumber: contact.number || "",
      });
      setSenderID(contact.id);
    } else if (tipoContacts === "recipient") {
      form.setFieldsValue({
        recipientSurname: contact.surname || "",
        recipientName: contact.name || "",
        recipientAddressName: contact.addressName || "",
        recipientAddressPrefix: contact.addressPrefix || "",
        recipientCAP: contact.cap || "",
        recipientCity: contact.city || "",
        recipientNumber: contact.number || "",
      });
      setRecipientID(contact.id);
    }
    setIsModalOpen(false);
    setTipoContacts("");
  };

  const formSpedizione = (
    <Page loading={loading} inner msgLoading={msgLoading}>
      <>
        <h3>{t("newshipping")}</h3>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          {t("general")}
        </Divider>
        <Form
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="nv-order"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            email: keycloak?.tokenParsed?.email?.toString(),
          }}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                pattern: "^([-]?[1-9][0-9]*|0)$",
                message: t("patternEmail"),
              },
              {
                required: true,
                message: t("ckEmail"),
              },
            ]}
          >
            <Input disabled={true} placeholder="Email*" />
          </Form.Item>
          <Form.Item
            label={t("tipoServizio")}
            name="serviceCode"
            rules={[
              {
                required: true,
                message: t("ckTiposervizio"),
              },
            ]}
          >
            <Select
              autoComplete="none"
              onChange={onChangeTipoServizio}
              showSearch
              style={{
                width: "100%",
              }}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {tipoServizio}
            </Select>
          </Form.Item>
          <Form.Item name="color" hidden={telegramma} label={t("color")}>
            <Radio.Group>
              <Radio value={0}>Bianvo/Nero</Radio>
              <Radio value={1}>Colore</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="print" hidden={telegramma} label={t("print")}>
            <Radio.Group>
              <Radio value={0}>Fronte</Radio>
              <Radio value={1}>Fronte/Retro</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="testoTelegramma"
            hidden={!telegramma}
            label={t("telegramma")}
            rules={[
              {
                required: telegramma,
                message: t("ckTelegramma"),
              },
            ]}
          >
            <TextArea maxLength={9999} showCount />
          </Form.Item>
          <Form.Item
            name="fileData"
            label="File"
            hidden={!posta}
            rules={[
              {
                required: posta,
                message: t("ckFile"),
              },
            ]}
            extra={
              <span style={{ color: "rgba(0,0,0,.45)" }}>
                (Solo file Pdf con dimensione max 6MB)
              </span>
            }
          >
            <Upload
              onChange={handleChange}
              beforeUpload={handleBefore}
              onRemove={handleRemoveFile}
              fileList={fileList}
              accept="application/pdf"
              maxFileSize={6 * 1024 * 1024} // 6 MB in bytes
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ color: "#e61e1e" }}
          >
            {t("sender")}
          </Divider>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => showModal("sender")}
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                float: "right",
              }}
            >
              {t("searchcontacts")}
            </Button>
          </Form.Item>
          <Form.Item
            label={t("firstName")}
            name="senderName"
            rules={[
              {
                required: true,
                message: t("ckFirstName"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("lastName")}
            name="senderSurname"
            rules={[
              {
                required: true,
                message: t("ckLastName"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t("indirizzo")} style={{ marginBottom: 0 }}>
            <Form.Item
              name="senderAddressPrefix"
              rules={[{ required: true, message: t("ckPrefix") }]}
              style={{ display: "inline-block", width: "calc(25% - 8px)" }}
            >
              <Input placeholder={t("prefixindirizzo")} />
            </Form.Item>
            <Form.Item
              name="senderAddressName"
              rules={[{ required: true, message: t("ckNomeIndirizzo") }]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 8px",
              }}
            >
              <Input placeholder={t("nomeindirizzo")} />
            </Form.Item>
            <Form.Item
              name="senderNumber"
              rules={[{ required: true, message: t("ckNumeroCivico") }]}
              style={{ display: "inline-block", width: "calc(25% - 8px)" }}
            >
              <Input placeholder={t("numerocivico")} />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={t("postalcode")}
            name="senderCAP"
            rules={[{ required: true, message: t("ckPostalcode") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("city")}
            name="senderCity"
            rules={[{ required: true, message: t("ckCity") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="senderSalvaInRubrica"
            valuePropName="checked"
            label={t("savecontact")}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="senderSalvaContattoPrivato"
            valuePropName="checked"
            label={t("savecontactprivate")}
          >
            <Checkbox />
          </Form.Item>
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ color: "#e61e1e" }}
          >
            {t("recipient")}
          </Divider>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => showModal("recipient")}
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                float: "right",
              }}
            >
              {t("searchcontacts")}
            </Button>
          </Form.Item>

          <Form.Item
            label={t("firstName")}
            name="recipientName"
            rules={[
              {
                required: true,
                message: t("ckFirstName"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("lastName")}
            name="recipientSurname"
            rules={[
              {
                required: true,
                message: t("ckLastName"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t("indirizzo")} style={{ marginBottom: 0 }}>
            <Form.Item
              name="recipientAddressPrefix"
              rules={[{ required: true, message: t("ckPrefix") }]}
              style={{ display: "inline-block", width: "calc(25% - 8px)" }}
            >
              <Input placeholder={t("prefixindirizzo")} />
            </Form.Item>
            <Form.Item
              name="recipientAddressName"
              rules={[{ required: true, message: t("ckNomeIndirizzo") }]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 8px",
              }}
            >
              <Input placeholder={t("nomeindirizzo")} />
            </Form.Item>
            <Form.Item
              name="recipientNumber"
              rules={[{ required: true, message: t("ckNumeroCivico") }]}
              style={{ display: "inline-block", width: "calc(25% - 8px)" }}
            >
              <Input placeholder={t("numerocivico")} />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={t("postalcode")}
            name="recipientCAP"
            rules={[{ required: true, message: t("ckPostalcode") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("city")}
            name="recipientCity"
            rules={[{ required: true, message: t("ckCity") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="recipientSalvaInRubrica"
            label={t("savecontact")}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="recipientSalvaContattoPrivato"
            label={t("savecontactprivate")}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              {t("save")}
            </Button>
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={handleBack}
            >
              {t("cancel")}
            </Button>
          </Form.Item>
        </Form>
      </>
      <Modal
        title={t("contacts")}
        open={isModalOpen}
        onCancel={handleCancel}
        width={1000}
        footer={null} // Imposta il footer a null per rimuovere i pulsanti OK e Annulla
      >
        {gruppoid ? (
          <Contacts
            gruppoid={gruppoid}
            HandleSelectContact={HandleSelectContact}
          />
        ) : (
          ""
        )}
      </Modal>
    </Page>
  );

  const cardRiepilogo = (
    <Page loading={loading} inner>
      <Row gutter={[16, 16]} style={{ border: "0px solid" }}>
        <Col span={24}>
          <Badge.Ribbon text="Preview" color="red">
            <Descriptions title={t("shippingpreview")} bordered>
              <Descriptions.Item label={t("tariffa")}>
                {responseSave.nomeTariffa}
              </Descriptions.Item>
              <Descriptions.Item label={t("description")}>
                {responseSave.descrizioneTariffa}
              </Descriptions.Item>
              <Descriptions.Item label={t("norder")}>
                {responseSave.orderNumber}
              </Descriptions.Item>
              <Descriptions.Item label={t("nomefile")}>
                {responseSave.nomeFile}
              </Descriptions.Item>
              <Descriptions.Item label={t("parole")}>
                {responseSave.descrizionePagineParole}
              </Descriptions.Item>
              <Descriptions.Item label={t("numeropagine")}>
                {responseSave.numPagineParole}
              </Descriptions.Item>
              {responseSave.testoTelegramma != null ? (
                <Descriptions.Item label={t("testotelegramma")} span={4}>
                  {responseSave.testoTelegramma}
                </Descriptions.Item>
              ) : (
                <></>
              )}
              <Descriptions.Item label={t("importototale")} span={4}>
                <p>
                  <span>
                    <b>
                      {responseSave.importoTotale != null
                        ? new Intl.NumberFormat("it-IT", {
                            style: "currency",
                            currency: "EUR",
                          }).format(responseSave.importoTotale)
                        : new Intl.NumberFormat("it-IT", {
                            style: "currency",
                            currency: "EUR",
                          }).format(0)}
                    </b>
                  </span>
                </p>
              </Descriptions.Item>
            </Descriptions>
          </Badge.Ribbon>
        </Col>
        <Col span={24} style={{ border: "0px solid", textAlign: "right" }}>
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
            }}
          ></div>
          <Space>
            <Button onClick={() => handleConfermaSpedizione(false)}>
              {t("cancel")}
            </Button>

            <Button
              type="primary"
              onClick={() => handleConfermaSpedizione(true)}
            >
              {t("confermaspedizione")}
            </Button>
          </Space>
          {/* </div> */}
        </Col>
      </Row>
    </Page>
  );

  return <>{!pageHidden ? formSpedizione : cardRiepilogo}</>;
};

export default Shipping;
