import React, { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import { HiOutlineUserGroup, HiMinusSm } from "react-icons/hi";
import { MdOutlineNewReleases } from "react-icons/md";
import { RiFileChartLine } from "react-icons/ri";
import { FcInvite } from "react-icons/fc";
import { BsCreditCard, BsCloudCheck } from "react-icons/bs";
import {
  AiOutlineAppstore,
  AiOutlineQuestion,
  AiOutlineStar,
} from "react-icons/ai";
import { VscTools } from "react-icons/vsc";
import Faqs from "../Views/Faq/Faqs";
import Store from "../Views/Store/Store";
import News from "../Views/News/News";
import Apps from "../Views/App/Apps";
import Users from "../Views/User/Users";
import Home from "../Views/Home/Home";
import Accounts from "../Views/Account/Accounts";
import Profiles from "../Views/Profile/Profiles";
import Credits from "../Views/Credit/Credits";
import CreditsHistory from "../Views/Credit/CreditsHistory";
import Consumption from "../Views/Consumi/Consumption";
import { useKeycloak } from "@react-keycloak/web";
import { isAccess_app, isAccess_bonus, isAccess_invitation } from "../Helpers/Helpers";
import Invitation from "../Views/Invitation/Invitation";
import { ParameterService } from "../Service/ParameterService";
export const Routes = () => {
  const { keycloak } = useKeycloak();
  const [param, setParam] = useState([]);
  const getData = async () => {
    try {
      const response = await ParameterService.getBonus(keycloak.token);
      setParam(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if(keycloak?.token){
      getData();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const rotte = [
    {
      path: "/",
      name: "Home",
      icon: FaHome,
      element: Home,
      isSidemenu: true,
      key: "home_index",
    },
    {
      name: "Invita",
      path: "/invitation",
      hide: !isAccess_invitation(param?.valore ? param.valore : 0),
      icon: FcInvite,
      element: Invitation,
      isSidemenu: true,
      private: true,
      key: "invitation_index",
    },

    {
      name: "I miei servizi",
      path: "/myservices",
      hide: !isAccess_app(keycloak),
      icon: BsCloudCheck,
      element: Apps,
      isSidemenu: true,
      private: true,
      key: "myapp_index",
    },
    {
      path: "/store",
      name: "Store",
      icon: AiOutlineAppstore,
      element: Apps,
      isSidemenu: true,
      key: "store_index",
    },
    {
      path: "/news",
      name: "Novità",
      icon: MdOutlineNewReleases,
      element: News,
      isSidemenu: true,
      key: "news_index",
    },
    {
      path: "/faqs",
      name: "FAQs",
      icon: AiOutlineQuestion,
      element: Faqs,
      isSidemenu: true,
      key: "Faqs_index",
    },
    {
      path: "/favorites",
      name: "Lista dei preferiti",
      icon: AiOutlineStar,
      element: Apps,
      isSidemenu: true,
      private: true,
      key: "preferiti_index",
    },
    {
      name: "Crediti",
      path: "/credits",
      element: Credits,
      icon: BsCreditCard,
      isSidemenu: true,
      private: true,
      key: "credit_index",
    },
    {
      name: "Consumi",
      path: "/consumption",
      element: Consumption,
      icon: RiFileChartLine,
      isSidemenu: true,
      private: true,
      key: "consumi_index",
    },
    {
      name: "Il mio team",
      private: true,
      icon: HiOutlineUserGroup,
      isSidemenu: true,
      key: "team_index",
      children: [
        {
          path: "/team/groups",
          name: "Aziende",
          hide: false,
          icon: HiMinusSm,
          element: Accounts,
          isSidemenu: true,
          key: "team_groups_index",
        },
        {
          path: "/team/accounts",
          name: "Account utente",
          icon: HiMinusSm,
          element: Users,
          isSidemenu: true,
          key: "team_accounts_index",
        },
        // {
        //     path: "/team/groups",
        //     name: "Gruppi",
        //     icon: HiMinusSm,
        //     element: Groups,
        //     isSidemenu: true,
        //     key:"team_groups_index"
        // },
        // {
        //     path: "/team/subaccounts",
        //     name: "Sub-Accounts",
        //     icon: HiMinusSm,
        //     element: Accounts,
        //     isSidemenu: true,
        //     key:"team_subaccounts_index"
        // }
      ],
    },
    {
      name: "Admin",
      isadmin: true,
      icon: VscTools,
      isSidemenu: true,
      key: "index_admin",
      children: [
        {
          path: "/admin/groups",
          name: "Tutti le aziende",
          icon: HiMinusSm,
          element: Accounts,
          isSidemenu: true,
          key: "admin_groups_index",
        },
        {
          path: "/admin/accounts",
          name: "Tutti gli accounts",
          icon: HiMinusSm,
          element: Users,
          isSidemenu: true,
          key: "admin_accounts_index",
        },
        {
          path: "/admin/profiles",
          name: "Profili",
          icon: HiMinusSm,
          element: Profiles,
          isSidemenu: true,
          key: "admin_profiles_index",
        },
        {
          path: "/admin/services",
          name: "Servizi",
          icon: HiMinusSm,
          isSidemenu: true,
          key: "listapp_index",
        },
        {
          path: "/admin/services/buy",
          name: "Servizi Acquistati",
          icon: HiMinusSm,
          isSidemenu: true,
          key: "listapp_buy_index",
        },
        {
          name: "Crediti",
          path: "/admin/credits",
          element: Credits,
          icon: HiMinusSm,
          isSidemenu: true,
          key: "admin_credits_index",
        },
        {
          name: "Ordini",
          path: "/admin/credits/ordershistory",
          element: CreditsHistory,
          icon: HiMinusSm,
          isSidemenu: true,
          key: "admin_orders_index",
        },
        {
          name: "Consumi",
          path: "/admin/consumption",
          element: Consumption,
          icon: HiMinusSm,
          isSidemenu: true,
          key: "admin_consumption_index",
        },
        {
          path: "/admin/faqs",
          name: "Gestione FAQs",
          icon: HiMinusSm,
          isSidemenu: true,
          key: "managementfaqs_index",
        },
        {
          path: "/admin/news",
          name: "Gestione novità",
          icon: HiMinusSm,
          isSidemenu: true,
          key: "managementnews_index",
        },
        {
          path: "/admin/bonus",
          name: "Bonus Registr.",
          hide: !isAccess_bonus(keycloak),
          icon: HiMinusSm,
          isSidemenu: true,
          key: "bonus_index",
        },
      ],
    },
  ];
  return rotte;
};

// const Routes = [
//     // Default 404 Not Found Page

//     {
//         path: "/error/404",
//         name: "Dashboard",
//         icon: AppstoreOutlined,
//         element: Page404,
//         layout: "/admin",
//         isSidemenu: false,
//         key:"dashboard"
//     },
//     {
//         path: "/",
//         name: "Dashboard",
//         icon: AppstoreOutlined,
//         element: Dashboard,
//         layout: "/admin",
//         isSidemenu: true,
//         key:"dashboardindex"
//     },
//     {
//         path: "/pages",
//         name: "Pages",
//         icon: FileOutlined,
//         element: Dashboard,
//         layout: "/admin",
//         isSidemenu: true,
//         key:"pages",
//         children: [
//             {
//                 path: "/pages/blank",
//                 name: "Blank Page",
//                 icon: HiMinusSm,
//                 element: BlankPage,
//                 layout: "/admin",
//                 isSidemenu: true,
//                 key:"BlankPage"
//             },
//             {
//                 path: "/pages/404",
//                 name: "404",
//                 icon: HiMinusSm,
//                 element: Page404,
//                 layout: "/admin",
//                 isSidemenu: true,
//                 key:"404"
//             },
//             {
//                 path: "/pages/500",
//                 name: "500",
//                 icon: HiMinusSm,
//                 element: Page500,
//                 layout: "/admin",
//                 isSidemenu: true,
//                 key:"500"
//             },
//             {
//                 path: "/pages/403",
//                 name: "403",
//                 icon: HiMinusSm,
//                 element: Page403,
//                 layout: "/admin",
//                 isSidemenu: true,
//                 key:"403"
//             }
//         ]
//     },
//     {
//         path: "/about",
//         name: "About",
//         icon: GithubOutlined,
//         element: About,
//         isSidemenu: true,
//         key:"about"
//     }
// ];

//export default Routes;
