import { useTranslate } from "../../../Translate";
import DropOption from "../../../Components/DropOption/DropOption";
import { AiFillPicture } from "react-icons/ai";
import { Avatar, Modal } from "antd";
import { Link } from "react-router-dom";
import {
  lkPricingConsumo,
  lkPricingAttivazione,
} from "../../App/UtilsApp";
const { confirm } = Modal;

export const ColumnsListApp = (onEditItem, onDeleteItem, onBuyItem,onDocumentsItem) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const pathDetails = "/admin/services/";

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      onEditItem(record.id);
    }
    if (e.key === "2") {
      confirm({
        title: t("questiondeleteseervice"),
        onOk() {
          onDeleteItem(record.id);
        },
      });
    }
    if (e.key === "3") {
      onBuyItem(record.id);
    }
    if (e.key === "4") {
      onDocumentsItem(record.id);
    }
  };

  const columns = [
    {
      title: t("image"),
      dataIndex: "iconaPreview",
      key: "iconaPreview",
      width: "3%",
      align: "center",
      render: (text, record) => (
        <>
          {record.iconaPreview !== null ? (
            <Avatar size={40} src={record.iconaPreview} />
          ) : (
            <Avatar size={40} icon={<AiFillPicture />}></Avatar>
          )}
        </>
      ),
    },
    {
      title: t("title"),
      dataIndex: "titolo",
      key: "titolo",
      width: "7%",
      render: (text, record) => (
        <>
          <Link to={`${pathDetails}${record.id}`}>{record.titolo}</Link>
        </>
      ),
    },
    {
      title: t("availableweb"),
      dataIndex: "disponibileWeb",
      key: "disponibileWeb",
      width: "3%",
      align: "center",
      render: (text, record) => <>{record.disponibileWeb ? t("yes") : "No"}</>,
    },
    {
      title: t("availablemfp"),
      dataIndex: "disponibileMFP",
      key: "disponibileMFP",
      width: "3%",
      align: "center",
      render: (text, record) => <>{record.disponibileMFP ? t("yes") : "No"}</>,
    },
    {
      title: t("author"),
      dataIndex: "autore",
      key: "autore",
      width: "7%",
    },
    {
      title: t("activationdate"),
      dataIndex: "dataPubblicazione",
      key: "dataPubblicazione",
      width: "5%",
      render: (text, record) => (
        <>
          {record.dataPubblicazione !== null && record.dataPubblicazione != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.dataPubblicazione))
            : ""}
        </>
      ),
    },
    {
      title: t("category"),
      dataIndex: "categorie",
      key: "categorie",
      width: "5%",
      render: (text, record) => (
        <>
          {record.categorie.map((cat, index) => {
            let category = "";
            if (index == 0) category += cat.descrizione;
            else category += ", " + cat.descrizione;
            return category;
          })}
        </>
      ),
    },
    {
      title: t("price"),
      dataIndex: "prezzo",
      key: "prezzo",
      width: "5%",
      render: (text, record) => (
        <>
          {record.prezzo != null
            ? new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(record.prezzo)
            : new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(0)}
        </>
      ),
    },
    {
      title: t("activeappstore"),
      dataIndex: "attivo",
      key: "attivo",
      width: "5%",
      render: (text, record) => <>{record.attivo ? t("yes") : "No"}</>,
    },
    {
      title: t("operation"),
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: "9%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={
              record.profiloIntegrazione === 2
                ? [
                    { key: "1", name: t("edit") },

                    {
                      key: "2",
                      name: t("deactivateapp"),
                      disabled: !record.attivo,
                    },

                    { key: "3", name: t("buy") },
                    { key: "4", name: t("documents") },
                  ]
                : [
                    { key: "1", name: t("edit") },

                    {
                      key: "2",
                      name: t("deactivateapp"),
                      disabled: !record.attivo,
                    },

                    { key: "3", name: t("buy") },
                  ]
            }
          />
        );
      },
    },
  ];
  // columns= [];
  return columns;
};

export const ColumnsListAppBuy = (onEditItem, onDeleteItem,onEditScadenza) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const arrPricingAttivazione = lkPricingAttivazione(t);
  const arrPricingConsumo = lkPricingConsumo(t);
  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      onEditItem(record.id);
    }
    if (e.key === "2") {
      confirm({
        title: t("questiondeleterecord"),
        onOk() {
          onDeleteItem(record.id);
        },
      });
    }
  };

  const columns = [
    {
      title: t("image"),
      dataIndex: "iconaPreview",
      key: "iconaPreview",
      width: "3%",
      align: "center",
      render: (text, record) => (
        <>
          {record.iconaPreview !== null ? (
            <Avatar size={40} src={record.iconaPreview} />
          ) : (
            <Avatar size={40} icon={<AiFillPicture />}></Avatar>
          )}
        </>
      ),
    },
    {
      title: t("title"),
      dataIndex: "titolo",
      key: "titolo",
      width: "7%",
      render: (text, record) => <>{record.titolo}</>,
    },
    {
      title: t("group"),
      dataIndex: "companyName",
      key: "companyName",
      width: "5%",
      render: (text, record) => <>{record.companyName}</>,
    },
    {
      title: t("author"),
      dataIndex: "autore",
      key: "autore",
      width: "7%",
    },
    {
      title: t("activationdate"),
      dataIndex: "dataPubblicazione",
      key: "dataPubblicazione",
      width: "5%",
      render: (text, record) => (
        <>
          {record.dataPubblicazione !== null && record.dataPubblicazione != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.dataPubblicazione))
            : ""}
        </>
      ),
    },
    {
      title: t("category"),
      dataIndex: "categorie",
      key: "categorie",
      width: "5%",
      render: (text, record) => (
        <>
          {record.categorie.map((cat, index) => {
            let category = "";
            if (index == 0) category += cat.descrizione;
            else category += ", " + cat.descrizione;
            return category;
          })}
        </>
      ),
    },
    {
      title: t("price"),
      dataIndex: "prezzo",
      key: "prezzo",
      width: "5%",
      render: (text, record) => (
        <>
          {record.prezzo != null
            ? new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(record.prezzo)
            : new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(0)}
        </>
      ),
    },
    {
      title: t("activeappstore"),
      dataIndex: "attivo",
      key: "attivo",
      width: "5%",
      render: (text, record) => <>{record.attivo ? t("yes") : "No"}</>,
    },
    {
      title: t("state"),
      dataIndex: "abilitazione",
      key: "abilitazione",
      width: "5%",
    },
    {
      title: t("pricingAttivazione"),
      dataIndex: "pricingAttivazioneAcquisto",
      key: "pricingAttivazioneAcquisto",
      width: "5%",
      render: (text, record) => (
        <>
          {
            arrPricingAttivazione[
              record.datiAcquisto.pricingAttivazioneAcquisto
            ]
          }
        </>
      ),
    },
    {
      title: t("pricingbuy"),
      dataIndex: "prezzoAcquisto",
      key: "prezzoAcquisto",
      width: "5%",
      render: (text, record) => (
        <>
          {record.datiAcquisto.prezzoAcquisto != null
            ? new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(record.datiAcquisto.prezzoAcquisto)
            : new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(0)}
        </>
      ),
    },
    {
      title: t("period"),
      dataIndex: "periodoAcquisto",
      key: "periodoAcquisto",
      width: "5%",
      render: (text, record) => <>{record.datiAcquisto.periodoAcquisto}</>,
    },
    {
      title: t("pricingConsumo"),
      dataIndex: "pricingConsumoAcquisto",
      key: "pricingConsumoAcquisto",
      width: "5%",
      render: (text, record) => (
        <>{arrPricingConsumo[record.datiAcquisto.pricingConsumoAcquisto]}</>
      ),
    },
    {
      title: t("maxusers"),
      dataIndex: "maxUtentiAcquisto",
      key: "maxUtentiAcquisto",
      width: "5%",
      render: (text, record) => <>{record.datiAcquisto.maxUtentiAcquisto}</>,
    },
    {
      title: t("datebuy"),
      dataIndex: "dataAcquisto",
      key: "dataAcquisto",
      width: "5%",
      render: (text, record) => (
        <>
          {record.datiAcquisto.dataAcquisto !== null && record.datiAcquisto.dataAcquisto != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.datiAcquisto.dataAcquisto))
            : ""}
        </>
      ),
    },
    {
      title: t("enddate"),
      dataIndex: "dataCessazione",
      key: "dataCessazione",
      width: "5%",
      render: (text, record) => (
        <>
          {record.datiAcquisto.dataCessazione !== null && record.datiAcquisto.dataCessazione != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.datiAcquisto.dataCessazione))
            : ""}
        </>
      ),
    },
    {
      title: t("datascadenza"),
      dataIndex: "dataScadenza",
      key: "dataScadenza",
      width: "5%",
      render: (text, record) => {
        const formattedDate = record.dataScadenza !== null && record.dataScadenza !== ""
          ? new Intl.DateTimeFormat("it-IT", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(record.dataScadenza))
          : "";
        
        const handleClick = () => {
          onEditScadenza(record);
        };
    
        return (
          <>
            {record.dataScadenza !== null && record.dataScadenza !== ""
              ? record.pricingAttivazione !== 3
                ? formattedDate
                : <a href="#" onClick={handleClick}>{formattedDate}</a>
              : ""}
          </>
        );
      },
    }
  ];
  // columns= [];
  return columns;
};
