import React from 'react'
import { Table,Modal,Avatar} from 'antd'
import DropOption from '../../../Components/DropOption/DropOption'
import styles from './List.module.less'
import { Link } from "react-router-dom";
import { useTranslate } from '../../../Translate';
import { AiFillPicture } from "react-icons/ai";
import languages from '../../../utils/languages';
const { confirm } = Modal

const List = (props) => {
    const i18n = useTranslate();
    const { t } = i18n;
    const {news,pagination,handleTableChange,loading,onEditItem,onDeleteItem} = props; 
    const pathDetails ="/admin/news/"
    const columns = [
        {
          title: t("image"),
          dataIndex: 'logo',
          key: 'logo',
          width: '6%',
          align: 'center',
          render: (text, record) => (
            <>{record.logo !=null ? <Avatar size={40} src={record.logo}/> : <Avatar size={40} icon={<AiFillPicture />  }></Avatar> }</>     
        ),
        },
        {
          title: t("title"),
          dataIndex: 'titolo',
          key: 'titolo',
          render: (text, record) => (
            <><Link to={`${pathDetails}${record.id}`}>{record.titolo}</Link></>     
        ),
        },
        {
          title: t("summary"),
          dataIndex: 'riassunto',
          key: 'riassunto',
        },
        {
          title: t("category"),
          dataIndex: 'catnews',
          key: 'catnews',
          width: '12%',
          render: (text, record) => <> {record.catNews != null ? record.catNews.titolo : ""}</>,
        },
        {
          title: t("group"),
          dataIndex: 'straccount',
          key: 'straccount',
          width: '12%',
          render: (text, record) => <> {record.straccount != null ? record.straccount : ""}</>,
        },
        {
          title: t("public"),
          dataIndex: 'pubblica',
          key: 'catnpubblicaews',
          width: '5%',
          render: (text, record) => <> {record.pubblica === 1 ? t("yes") : "No"}</>,
        },
        {
          title: t("language"),
          dataIndex: 'languageid',
          key: 'languageid',
          width: '5%',
          render: (text, record) => <> {languages.filter( element => element.id === record.languageid)[0].name}</>,
        },
        {
          title: t("active"),
          dataIndex: 'abilitata',
          key: 'abilitata',
          width: '5%',
          render: (text, record) => <> {record.abilitata === 1 ? t("yes") : "No"}</>,
        },
        {
          title: t("operation"),
          key: 'operation',
          fixed: 'right',
          width: '9%',
          render: (text, record) => {
            return (
              <DropOption
                onMenuClick={e => handleMenuClick(record, e)}
                menuOptions={[
                  { key: '1', name: t("edit")},
                  { key: '2', name: t("delete")},
                ]}
              />
            )
          },
        },
      ]
      
      const handleMenuClick = (record, e) => {
        if (e.key === '1') {
          onEditItem(record.id)
        } else if (e.key === '2') {
          confirm({
            title: t`Procedere con l'operazione di cancellazione del record?`,
            onOk() {
              onDeleteItem(record.id)
            },
          })
        }
      }
    


    return (
        <Table
            dataSource={news}
            pagination={pagination}
            className={styles.table}
            bordered
            scroll={{ x: 1200 }}
            columns={columns}
            loading={loading} 
            rowKey={record => record.id}
            onChange={handleTableChange}
          />
      )
}

export default List