import React from 'react'
import {Link} from "react-router-dom"
import {
    Result,
    Button
} from "antd"

const Page400 = () => {
  return (
    <Result
    status="warning"
    title="400 Bad Request"
    subTitle="Sorry, there are some problems with your operation."
    extra={
        <Link to="/">
            <Button type="primary">Back to Home</Button>
        </Link>
    }
/>
  )
}

export default Page400