/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../Components/Page/Page";
import { useTranslate } from "../../Translate";
import {
  NumericInput,
  ErrorPage,
  handleLogError,
  openNotificationWithIcon,
} from "../../Helpers/Helpers";
import { Button, Select, Form, DatePicker, Divider } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { AccountsService } from "../../Service/AccountsService";
import { CreditsService } from "../../Service/CreditsService";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xl: {
      span: 8,
    },

    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xl: {
      span: 8,
    },
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const dateFormat = "DD/MM/YYYY";

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 10,
      offset: 10,
    },
  },
};

const Addebito = (props) => {
  const { type } = props;
  const i18n = useTranslate();
  const { t } = i18n;
  const { keycloak } = useKeycloak();
  const { path } = useParams();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [services, setServices] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const types = path.substring(0, 1);
  const id = path.substring(1);

  const handleFinish = (data) => {
    if (data["periodo"] != undefined && data["periodo"].length != 0) {
      let dateVarStart = moment(data["periodo"][0]);
      let newDateVarStart = dateVarStart.utc().format("YYYY-MM-DD");
      let dateVarEnd = moment(data["periodo"][1]);
      let newDateVarEnd = dateVarEnd.utc().format("YYYY-MM-DD");
      data["periodoDataDa"] = newDateVarStart;
      data["periodoDataA"] = newDateVarEnd;
    }
    delete data["periodo"];
    /*dati di default */
    data["pricingConsumo"] = 1;
    data["tipoOperazione"] = 2;
    data["importoAggiunto"] = 0;
    addebito(data);
  };

  const addebito = async (data) => {
    setLoading(true);
    try {
      const response = await CreditsService.addebito(keycloak.token, data);
      ErrorPage(response, navigate);
      console.log(response);
      if (response.status == 200) {
        openNotificationWithIcon(
          "success",
          "Success",
          "Addebito inserito con successo!"
        );
        navigate("/admin/credits");
      } else {
        openNotificationWithIcon(
          "error",
          "Error",
          "Errore nella creazione di un addebito!"
        );
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      /*carico la lookup degli accounts*/
      const lk_accounts = [];
      const response_accounts = await AccountsService.getLookup();
      response_accounts.data.map((account) => {
        lk_accounts.push(
          <Option value={account[0]} key={account[0]}>
            {account[1].trim()}
          </Option>
        );
        return true;
      });
      setGroups(lk_accounts);
      ErrorPage(response_accounts, navigate);
      // /*carico le app del gruppo*/
      const lk_app = [];
      const response_services = await CreditsService.getAppsByGroup(
        keycloak.token,
        id
      );
      response_services.data.content.map((service) => {
        if(service.abilitazione !=="CESSATA"){
          lk_app.push(
            <Option value={service.id} key={service.id}>
              {service.titolo.trim()}
            </Option>
          );
        }
        return true;
      });
      setServices(lk_app);
      ErrorPage(response_services, navigate);
      form.setFieldsValue({
        accountId: parseInt(id),
      });
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  const handleBack = () => {
    navigate("/admin/credits");
  };
  return (
    <Page loading={loading} inner>
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ color: "#e61e1e" }}
      >
        {t("newcharge")}
      </Divider>
      <Form
        {...formItemLayout}
        form={form}
        layout="vertical"
        name="form-addebito"
        onFinish={handleFinish}
        scrollToFirstError
      >
        <Form.Item label={t("group")} name="accountId">
          <Select
            style={{ width: "100%" }}
            optionFilterProp="children"
            disabled={true}
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {groups}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("service")}
          name="appId"
          rules={[
            {
              required: true,
              message: t("ckService"),
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder={t("selectservice")}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {services}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("amount")}
          name="importoSottratto"
          rules={[
            {
              required: true,
              message: t("ckAmount"),
            },
          ]}
        >
          <NumericInput />
        </Form.Item>

        <Form.Item
          label={t("causal")}
          name="causale"
          rules={[
            {
              required: true,
              message: t("ckCausal"),
            },
          ]}
        >
          <TextArea></TextArea>
        </Form.Item>
        <Form.Item
          name="periodo"
          label={t("period")}
          rules={[
            {
              required: true,
              message: t("ckPeriod"),
            },
          ]}
        >
          <RangePicker format={dateFormat} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item></Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={handleBack}
          >
            {t("cancel")}
          </Button>
        </Form.Item>
      </Form>
    </Page>
  );
};

export default Addebito;
