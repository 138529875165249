import React from 'react'
import { Table,Modal} from 'antd'
import { t } from "@lingui/macro"
import DropOption from '../../../Components/DropOption/DropOption'
import styles from './List.module.less'

const { confirm } = Modal


const List = (props) => {
    const {profiles,onDeleteItem,loading,onSelectItem}= props; 
    const handleMenuClick = (record, e) => {
        if (e.key === '1') {
          onSelectItem(record.id)
        } else if (e.key === '2') {
          confirm({
            title: t`Procedere con l'operazione di cancellazione del record?`,
            onOk() {
              onDeleteItem(record.id)
            },
          })
        }
      }
    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Gruppo',
            dataIndex: 'accounts',
            key: 'accounts',
        },
        {
            title: 'Members',
            dataIndex: 'members',
            key: 'members',
        },
        {
            title: 'Operation',
            key: 'operation',
            fixed: 'right',
            width: '8%',
            render: (text, record) => {
              return (
                <DropOption
                  onMenuClick={e => handleMenuClick(record, e)}
                  menuOptions={[
                  
                    { key: '1', name: t`Gestione Permessi` },
                    { key: '2', name: t`Cancella` },
                  ]}
                />
              )
            },
          },
    ]
    return (
        <Table
            dataSource={profiles}
            className={styles.table}
            bordered
            loading={loading}
            scroll={{ x: 1200 }}
            columns={columns}
            rowKey={record => record.id}
          />
      )
}

export default List