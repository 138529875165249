import { Button, Result } from 'antd';
import {Link} from "react-router-dom"
import React from 'react';
import { useTranslate } from "../../Translate";

const BuySuccefully = (props) => {
    const{type} = props;
    const i18n = useTranslate();
    const { t } = i18n;
    return (
        <Result
        status="success"
        title={t("purchasessuccessfully")}
        subTitle={t("confirmationemail")}
        extra={
          type === "admin" ? <Link to="/admin/services">
          <Button type="primary">{t("back")}</Button>
      </Link>: <Link to="/myservices">
                <Button type="primary">{t("myapps")}</Button>
            </Link>
        }
    />
      )
}

export default BuySuccefully