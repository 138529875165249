// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepscontent__IKX8B {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  background-color: #fafafa;
  border: 0px dashed #e9e9e9;
  border-radius: 2px;
}
.stepsaction__AwDBe {
  margin-top: 24px;
  border: 0px solid;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/Views/Credit/Step.module.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,0BAAA;EACA,kBAAA;AACJ;AAEE;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".stepscontent {\n    min-height: 200px;\n    margin-top: 16px;\n    padding-top: 20px;\n    padding-left: 20px;\n    padding-right: 20px;\n    text-align: center;\n    background-color: #fafafa;\n    border: 0px dashed #e9e9e9;\n    border-radius: 2px;\n  }\n  \n  .stepsaction {\n    margin-top: 24px;\n    border:0px solid;\n    text-align: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepscontent": `stepscontent__IKX8B`,
	"stepsaction": `stepsaction__AwDBe`
};
export default ___CSS_LOADER_EXPORT___;
