import React from "react";
import { Button, Row, Col, Form, Input, Select } from "antd";
import { useTranslate } from "../../../Translate";

const { Search } = Input;

const ColProps = {
  xs: 24,
  sm: 12,
  style: {
    marginBottom: 16,
  },
};

const TwoColProps = {
  ...ColProps,
  xl: 96,
};

const Filter = ({ accounts, dealers, onFilterChange, newUser, viewBtnNew ,type}) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const formRef = React.createRef();
  /*eventuale normalizzazione dei dati tipo la data*/
  const handleFields = (fields) => {
    return fields;
  };
  const handleSubmit = () => {
    const values = formRef.current.getFieldsValue();
    const fields = handleFields(values);
    onFilterChange(fields);
  };
  const handleNew = () => {
    newUser();
  };
  /*funzione per il reset del form*/
  const handleReset = () => {
    //ottengo tutti i campi del form con i rispettivi valori
    const fields = formRef.current.getFieldsValue();
    for (let item in fields) {
      if ({}.hasOwnProperty.call(fields, item)) {
        if (fields[item] instanceof Array) {
          fields[item] = [];
        } else {
          fields[item] = undefined;
        }
      }
    }
    formRef.current.setFieldsValue(fields);
    onFilterChange(fields);
  };
  let initialCreateTime = [];
  return (
    <Form
      ref={formRef}
      name="control-ref"
      initialValues={{ createTime: initialCreateTime }}
    >
      <Row gutter={24}>
        <Col {...ColProps} xl={{ span: 6 }} md={{ span: 10 }}>
          <Form.Item name="search">
            <Search placeholder={t("search")} onSearch={handleSubmit} />
          </Form.Item>
        </Col>
        <Col
          {...ColProps}
          xl={{ span: 3 }}
          md={{ span: 4 }}
          sm={{ span: 6 }}
          id="aziendaCascader"
        >
          <Form.Item name="accounts">
            <Select
              placeholder={t("selectgroup")}
              autoComplete="none"
              showSearch
              style={{
                width: "100%",
              }}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {accounts}
            </Select>
          </Form.Item>
        </Col>
        {type ==="admin" ? <>
        <Col
          {...ColProps}
          xl={{ span: 3 }}
          md={{ span: 3 }}
          sm={{ span: 3 }}
          style={{ border: "0px solid" }}
        >
          <Form.Item name="dealer">
            <Select
              placeholder={t("dealer")}
              options={[
                { value: "0", label: "No" },
                { value: "1", label: "Sì" }
              ]}
            />
          </Form.Item>
        </Col>
        <Col
          {...ColProps}
          xl={{ span: 3 }}
          md={{ span: 4 }}
          sm={{ span: 6 }}
          style={{ border: "0px solid" }}
        >
          <Form.Item name="dealersapparteneza">
            <Select
              placeholder={t("dealerappartenenza")}
              autoComplete="none"
              showSearch
              // style={{
              //     width: '100%',
              // }}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dealers}
            </Select>
          </Form.Item>
        </Col></> :"" }
        <Col
          {...TwoColProps}
          xl={{ span: type === 'admin' ? 8 :15 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
        >
          <Row
            gutter={24}
            type="flex"
            align="middle"
            justify="space-between"
            style={{ border: "0px solid" }}
          >
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "16px" }}
                onClick={handleSubmit}
              >
                {t("search")}
              </Button>
              <Button onClick={handleReset}>Reset</Button>
            </div>
            {viewBtnNew ? (
              <Button type="ghost" onClick={handleNew}>
                {t("new")}
              </Button>
            ) : (
              ""
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
