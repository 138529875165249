import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Page from "../../Components/Page/Page";
import { Link } from "react-router-dom";
import {
  Col,
  Form,
  Input,
  Button,
  Row,
  notification,
  Divider,
  Select,
  Checkbox,
} from "antd";
import countries from "../../utils/countries";
import districts from "../../utils/districts";
import { handleLogError, ErrorPage } from "../../Helpers/Helpers";
import { useNavigate } from "react-router-dom";
import { RegistrationService } from "../../Service/RegistrationService";
import { useTranslate } from "../../Translate";
const { Option } = Select;

// const layout = {
//   labelCol: {
//     span: 8,
//   },
//   wrapperCol: {
//     span: 16,
//   },
// };

/* eslint-disable no-template-curly-in-string */

// const validateMessages = {
//   required: '${label} is required!',
//   types: {
//     email: '${label} is not a valid email!',
//     number: '${label} is not a valid number!',
//   },
//   number: {
//     range: '${label} must be between ${min} and ${max}',
//   },
// };

const Registration = () => {
  const { userid, groupid } = useParams();
  //gestione della lingua
  const i18n = useTranslate();
  const { t } = i18n;
  const [countries_children, setCountries_children] = useState([]);
  const [districts_children, setDistricts_children] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dataRegistration, setDataRegistration] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type, msg, description) => {
    notification[type]({
      message: msg,
      description: description,
    });
  };

  const handleReset = (event) => {
    const fields = formRef.current.getFieldsValue();
    for (let item in fields) {
      if ({}.hasOwnProperty.call(fields, item)) {
        if (fields[item] instanceof Array) {
          fields[item] = [];
        } else {
          fields[item] = undefined;
        }
      }
    }
    formRef.current.setFieldsValue(fields);
  };

  const getDataCountries = () => {
    var response = [];
    countries.forEach((country) => {
      response.push(<Option key={country.id}>{country.name}</Option>);
    });

    setCountries_children(response);
  };

  const getDataDistricts = (countryid) => {
    var response = [];
    districts.forEach((district) => {
      if (
        district.country_id === parseInt(countryid) &&
        (district.type === "province" || district.type === "metropolitan city")
      ) {
        response.push(
          <Option key={district.id} value={district.id}>
            {district.name}
          </Option>
        );
      }
    });
    setDistricts_children(response);
  };

  useEffect(() => {
    getDataCountries();
  }, []);

  const onFinish = (values) => {
    console.log(values);
    handleSendData(values);
  };

  const handleChangeCountries = (value) => {
    console.log(`selected ${value}`);
    getDataDistricts(value);
  };

  // const handleChangeDistricts = (value) => {
  //   console.log(`selected ${value}`);
  // };
  const formRef = React.createRef();

  const handleSendData = async (dataRegistration) => {
    if (userid !== undefined && groupid !== undefined) {
      dataRegistration["userInvitation"] = userid;
      dataRegistration["groupInvitation"] = groupid;
    } else {
      dataRegistration["userInvitation"] = null;
      dataRegistration["groupInvitation"] = null;
    }
    try {
      setLoading(true);
      const response = await RegistrationService.registration(dataRegistration);
      setDataRegistration(response.data);
      if (response.status === 201) {
        navigate("/registration/success");
      } else if (response.status === 200) {
        openNotificationWithIcon(
          "warning",
          response.data.type ? response.data.type : "Warning",
          response.data.messaggio
            ? response.data.messaggio
            : response.data.error
        );
      } else if (response.status === 400) {
        openNotificationWithIcon(
          "error",
          response.data.type ? response.data.type : "Error",
          response.data.messaggio
            ? response.data.messaggio
            : response.data.error
        );
      } else {
        ErrorPage(response.status, navigate);
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Page loading={loading} inner>
      <>
        <Form ref={formRef} name="control-ref-registration" onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={24}>
              {" "}
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ color: "#e61e1e" }}
              >
                {t("personaldata")}
              </Divider>
            </Col>
            <Col span={8}>
              <Form.Item
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: t("ckFirstName"),
                  },
                ]}
              >
                <Input placeholder={t("firstName") + "*"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: t("ckLastName"),
                  },
                ]}
              >
                <Input placeholder={t("lastName") + "*"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: t("patternEmail"),
                  },
                  {
                    required: true,
                    message: t("ckEmail"),
                  },
                ]}
              >
                <Input placeholder="Email*" />
              </Form.Item>
            </Col>
            <Col span={24}>
              {" "}
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ color: "#e61e1e" }}
              >
                {t("databusinessname")}
              </Divider>
            </Col>
            <Col span={8}>
              <Form.Item
                name="account"
                rules={[
                  {
                    required: true,
                    message: t("ckBusinessname"),
                  },
                ]}
              >
                <Input placeholder={t("businessname") + "*"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="vatnumber"
                rules={[
                  {
                    required: false,
                    message: t("ckVatnumber"),
                  },
                ]}
              >
                <Input
                  maxLength={11}
                  minLength={11}
                  placeholder={t("vatnumber") + ""}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="fiscalcode"
                rules={[
                  {
                    required: true,
                    message: t("ckFiscalcode"),
                  },
                ]}
              >
                <Input placeholder={t("fiscalcode") + "*"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    // pattern: /^1[34578]\d{9}$/,
                    message: t("ckPhone"),
                  },
                ]}
              >
                <Input placeholder={t("phone") + "*"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: true,
                    message: t("ckAddress"),
                  },
                ]}
              >
                <Input placeholder={t("address") + "*"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="cap"
                rules={[
                  {
                    pattern: "^([0-9]{5})$",
                    message: t("inputinvalid"),
                  },
                  {
                    required: true,
                    message: t("ckPostalcode"),
                  },
                ]}
              >
                <Input placeholder={t("postalcode") + "*"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: t("ckCity"),
                  },
                ]}
              >
                <Input placeholder={t("city") + "*"} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="country"
                // rules={[
                //   {
                //     required: true,
                //     message: t("ckCountry"),
                //   },
                // ]}
              >
                <Select
                  autoComplete="none"
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChangeCountries}
                  placeholder={t("country") + ""}
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countries_children}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="district"
                // rules={[
                //   {
                //     required: true,
                //     message:t("ckDistrict"),
                //   },
                // ]}
              >
                <Select
                  autoComplete="none"
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  // onChange={handleChangeDistricts}
                  placeholder={t("district") + ""}
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {districts_children}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="pec"
                rules={[
                  {
                    type: "email",
                    pattern: "^([-]?[1-9][0-9]*|0)$",
                    message: t("patternEmail"),
                  },
                  {
                    required: false,
                    message: t("ckPec"),
                  },
                ]}
              >
                <Input placeholder={t("Pec")} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                // label={t("codesdi")}
                name="sdi"
                rules={[
                  {
                    required: false,
                    message: t("ckCodeSDI"),
                  },
                ]}
              >
                <Input placeholder={t("codesdi")} />
              </Form.Item>
            </Col>
            <Col span={24}>
              {" "}
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ color: "#e61e1e" }}
              >
                {t("passwordchoice")}
              </Divider>
            </Col>
            <Col span={8}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("ckPassword"),
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password*" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="confirmpassword"
                rules={[
                  {
                    required: true,
                    message: t("ckConfirmPassword"),
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder={t("confirmPw") + "*"} />
              </Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={24}>
              {" "}
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ color: "#e61e1e" }}
              >
                GDPR - {t("consentsmanagement")}
              </Divider>
            </Col>
            <Col span={24}>
              <Form.Item
                name="agreement_commerciale"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error(t("mustacceptconsent"))),
                  },
                ]}
              >
                <Checkbox>
                  {/* {t("privacytrade") + "*"} */}
                  Accetto le{" "}
                  <Link to="/generalconditions">Condizioni Generali</Link> e
                  dichiaro di aver preso visione della{" "}
                  <Link to="/privacypolicy">Privacy Policy</Link> resa dal
                  Titolare del trattamento ai sensi dell'art. 13 del GDPR *
                </Checkbox>
              </Form.Item>

              <Form.Item
                style={{ marginTop: "-5px" }}
                name="agreement_condizionigenerali"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error(t("mustacceptconsent"))),
                  },
                ]}
              >
                <Checkbox>
                  Ai sensi e per gli effetti di cui agli articoli 1341 e 1342
                  del Codice Civile, accetto espressamente il contenuto delle
                  seguenti clausole delle Condizioni Generali: art. 3 (Accesso
                  ai servizi); art. 5 (Modalità di pagamento dei Servizi; art.
                   6 (Proprietà industriale ed intellettuale – Marchi Toshiba);
                  art. 7 (Aggiornamenti, modifiche e manutenzione della
                  Piattaforma e dei Servizi); art. 9 (Responsabilità); art. 11
                  (Clausola risolutiva espressa – Sospensione del
                  Servizio); Art. 15 (Legge applicabile e Foro Competente).*
                </Checkbox>
              </Form.Item>

              <Form.Item
                style={{ marginTop: "-5px" }}
                name="agreement_marketing"
                valuePropName="checked"
              >
                <Checkbox>
                  {/* {t("privacymarketing")} */}
                  Acconsento al trattamento dei miei dati personali per l'invio
                  di informazioni promozionali e comunicazioni di marketing da
                  parte di Toshiba
                </Checkbox>
              </Form.Item>

              <Form.Item
                style={{ marginTop: "-5px" }}
                name="agreement_profilazione"
                valuePropName="checked"
              >
                <Checkbox>
                  {/* {t("privacyprofile")} */}
                  Acconsento al trattamento dei miei dati personali per finalità
                  di profilazione
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: "right",
              }}
            >
              <Button type="primary" htmlType="submit">
                {t("acceptandcontinue")}
              </Button>
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={handleReset}
              >
                {t("cancel")}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    </Page>
  );
};

export default Registration;
