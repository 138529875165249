// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow__e9G0o {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient__jIYty {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover__fc2vI {
  transition: all 0.3s ease-out;
}
.background-hover__fc2vI:hover {
  background-color: #f9f9fc;
}
.contentInner__zGEBP {
  background: #fff;
  padding: 24px;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.01);
  min-height: calc(100vh - 230px);
  position: relative;
}
@media (max-width: 767px) {
  .contentInner__zGEBP {
    padding: 12px;
    min-height: calc(100vh - 160px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Page/Page.module.less","webpack://./src/Themes/mixin.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;ACO7F;EACE,mBAAA;EACA,uBAAA;EACA,gBAAA;ADLF;ACQA;EACE,+IAAA;EAQA,6BAAA;EACA,oCAAA;ADbF;ACgBA;EACE,6BAAA;ADdF;ACeE;EACE,yBAAA;ADbJ;AAjBA;EACE,gBAAA;EACA,aAAA;EACA,8CAAA;EACA,+BAAA;EACA,kBAAA;AAmBF;AAhBA;EACE;IACE,aAAA;IACA,+BAAA;EAkBF;AACF","sourcesContent":["@import '../../Themes/vars.less';\n\n.contentInner {\n  background: #fff;\n  padding: 24px;\n  box-shadow: @shadow-1;\n  min-height: ~'calc(100vh - 230px)';\n  position: relative;\n}\n\n@media (max-width: 767px) {\n  .contentInner {\n    padding: 12px;\n    min-height: ~'calc(100vh - 160px)';\n  }\n}","@import './default.less'; \n\n@dark-half: #494949;\n@purple: #d897eb;\n@shadow-1: 4px 4px 20px 0 rgba(0, 0, 0, 0.01);\n@shadow-2: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);\n@transition-ease-in: all 0.3s ease-out;\n@transition-ease-out: all 0.3s ease-out;\n@ease-in: ease-in;\n\n.text-overflow {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.text-gradient {\n  background-image: -webkit-gradient(\n    linear,\n    37.219838% 34.532506%,\n    36.425669% 93.178216%,\n    from(#29cdff),\n    to(#0a60ff),\n    color-stop(0.37, #148eff)\n  );\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.background-hover {\n  transition: @transition-ease-in;\n  &:hover {\n    background-color: @hover-color;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-overflow": `text-overflow__e9G0o`,
	"text-gradient": `text-gradient__jIYty`,
	"background-hover": `background-hover__fc2vI`,
	"contentInner": `contentInner__zGEBP`
};
export default ___CSS_LOADER_EXPORT___;
