import React from 'react'
import { Table} from 'antd'
import styles from './List.module.less'

const List = (props) => {
    const {columns,data,loading} = props;
    return (
        <>
       
        <Table
            dataSource={data.content === undefined ?[]: data.content}
            className={styles.table}
            bordered
            scroll={{ x: 1200 }}
            columns={columns}
            loading={loading}
            rowKey={record => record.id}
        />
        </>
    )
}

export default List