import React from "react";
import { BsFiletypePdf } from "react-icons/bs";

import Page from "../../Components/Page/Page";
import {
  Col,
  Row,
  Divider
} from "antd";


const PrivacyPolicy = () => {
  return (
    <Page inner>
    <>
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ color: "#e61e1e" }}
      >
        PRIVACY POLICY
      </Divider>
      <Row>
        <Col>
         <a href="/file/TOSHIBAHUB-PrivacyPolicy.pdf" download="privacypolicy.pdf" title="Download file"><BsFiletypePdf style={{fontSize:'25px'}}/> File privacy policy</a>
        </Col>
      </Row>
    </>
  </Page>
  );
};

export default PrivacyPolicy;
