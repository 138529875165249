import React from "react";

import { Link } from "react-router-dom";
import Page from "../../Components/Page/Page";
import {
  Col,
  Row,
  Typography,
  Divider,

} from "antd";
const { Title, Paragraph } = Typography;

const GeneralConditions = () => {
  return (
    <Page inner>
      <>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          CONDIZIONI GENERALI
        </Divider>
        <Row>
          <Col>
            <Paragraph>
              <b>
                Termini e condizioni generali di accesso alla Piattaforma
                denominata ToshibaHub ed utilizzazione dei Servizi dalla stessa
                accessibili
              </b>
              <br></br>
              <br></br>I presenti termini e condizioni generali di contratto
              trovano applicazione tra l'utente (di seguito l'"<b>Utente</b>") e
              Toshiba Tec Italia Imaging Systems S.p.A., con sede legale in
              Milano (MI), Via Vittor Pisani n. 20, p. IVA 02775910165 (di
              seguito, "<b>Toshiba</b>""), stabilendo le condizioni per
              l’accesso alla piattaforma web denominata “ToshibaHub” (di seguito
              la “Piattaforma”) ai fini della fruizione da parte dell’Utente dei
              servizi digitali, delle applicazioni e dei software gestiti da
              Toshiba e/o da terze parti ed accessibili per mezzo della
              Piattaforma stessa (di seguito i "<b>Servizi</b>"). Le presenti
              condizioni generali di accesso alla piattaforma (le “Condizioni
              Generali”) sono consultabili in ogni momento presso l’apposita
              pagina della Piattaforma, raggiungibile al seguente <Link to='/generalconditions'>link </Link>.<br></br>
              <br></br><b>Articolo 1 – Oggetto del Contratto </b><br></br>
              <br></br>
1.1 Le presenti Condizioni Generali, unitamente alle diverse condizioni speciali previste per ciascun singolo Servizio (di seguito, le “Condizioni Speciali” e, insieme alle Condizioni Generali, d’ora in avanti costituenti il “Contratto”), regolano il rapporto intercorrente tra Toshiba e l’Utente e debbono pertanto essere integralmente ed espressamente accettate dell’Utente ai fini della fruizione dei Servizi da parte dello stesso.
<br></br><br></br>
1.2 L’utente è consapevole ed accetta che per ottenere i Servizi erogati da Toshiba a mezzo della Piattaforma, dovrà sottoscrivere anche le relative Condizioni Speciali previste per ciascun Servizio, che conterranno: (i) i singoli tipi di Servizi disponibili all’Utente; (ii) le tariffe di volta in volta applicabili alle forniture degli stessi; nonché (iii) le modalità di accesso ed utilizzazione dei Servizi.
<br></br><br></br>
1.3 In caso di contrasto tra le disposizioni delle Condizioni Speciali e le presenti Condizioni Generali prevarranno i termini delle prime, salvo che non sia stabilito diversamente ovvero il contesto specifico non richieda diversamente.
<br></br><br></br>
<b>Articolo 2 – Durata del Contratto </b>
<br></br><br></br>
Il Contratto concluso tra Toshiba e l’Utente ha durata indeterminata e la sua efficacia decorrerà dalla data di attivazione dell’Account dell’Utente di cui al successivo art. 3, salvo il diritto dell’Utente di recedere dal Contratto in qualsiasi momento mediante comunicazione scritta, fermo restando tuttavia quanto convenuto in ordine alla restituzione del contenuto del Wallet di cui al successivo art. 5.

<br></br><br></br>
<b>Articolo 3 – Accesso ai Servizi </b><br></br><br></br>

3.1. La Piattaforma è accessibile per mezzo dei principali <i>browser</i> disponibili sul mercato, tramite ordinaria connessione Internet, nonché tramite le stampanti multifunzione a marchio “Toshiba”.
<br></br><br></br>
3.2 Toshiba si impegna a mettere a disposizione dell’Utente attraverso la Piattaforma l’accesso ai Servizi, che tuttavia saranno erogati all’Utente secondo le modalità ed in conformità delle Condizioni Speciali di volta in volta dallo stesso sottoscritte. A tal riguardo, si precisa che alcuni Servizi resi disponibili per mezzo della Piattaforma potrebbero essere erogati direttamente da soggetti terzi (di seguito, i “<b>Servizi di Terzi</b>”); in tal caso, al fine di usufruire dei Servizi di Terzi messi a disposizione per mezzo della Piattaforma, l’Utente dovrà altresì accettare le condizioni contrattuali previste dai soggetti terzi erogatori di tali Servizi di Terzi. 
<br></br><br></br>
3.2 Al fine di poter usufruire dei Servizi, è necessario che l’Utente si registri alla Piattaforma, ovvero crei una propria utenza composta da <i>Username</i> e <i>Password</i> tramite la sezione a ciò espressamente dedicata, fornendo fedelmente i dati e le informazioni contrassegnate come obbligatorie richieste tramite il modulo di registrazione (nel seguito “<b>Account</b>”).
<br></br><br></br>
L’Utente è tenuto a inserire i dati personali e societari necessari ai fini della registrazione in modo veritiero e completo, seguendo le istruzioni fornite, assicurandosi altresì che i propri dati siano sempre aggiornati. Una volta inseriti tutti i dati necessari ai fini della creazione dell’<i>Account</i>, l’Utente deve accettare le presenti Condizioni Generali e dichiarare di aver letto e compreso la Privacy Policy, potendo altresì prestare liberamente i relativi consensi.
<br></br><br></br>
L’utilizzo di <i>Username</i> e <i>Password</i> costituisce prova unica e sufficiente dell’avvenuta identificazione dell’Utente quale legittimo utilizzatore e della Piattaforma e dei Servizi. Per tale motivo, il Cliente è tenuto a custodire con la massima diligenza l’<i>Username</i> e <i>Password</i>, a mantenerli segreti ed a non divulgarli a terzi.
<br></br><br></br>
Dell’uso indebito dell’Account da parte di terzi, anche a seguito di furto e/o smarrimento delle credenziali di accesso, risponde l’Utente, sarà pertanto onere dello stesso richiedere la sospensione dei Servizi a mezzo e-mail all’indirizzo di cui al successivo art. 10.2.
<br></br><br></br>


Una volta completata la registrazione e creato l’Account, Toshiba riceverà all’indirizzo inserito nel form di registrazione un’e-mail contenente il link di attivazione dell’Account. Tale sistema consente a Toshiba di accertare la correttezza dell’indirizzo e-mail indicato dall’Utente tramite la verifica della coincidenza tra il soggetto che ha inserito i dati nel form di registrazione e il possessore della casella di posta elettronica indicata nello stesso. Una volta confermato l’indirizzo e-mail dell’Utente, Toshiba invierà una seconda e-mail di conferma dell’avvenuta attivazione dell’Account. Le istruzioni da seguire per l’ attivazione dell’Account sono indicate nella sezione “Faqs” della Piattaforma.
<br></br><br></br>
3.3 A seconda dei casi, l’Utente potrebbe avere la facoltà di creare a sua volta ulteriori Account da assegnare a persone appartenenti alla propria organizzazione, connessi al medesimo Wallet (di seguito, “Sotto-Account”), potendo altresì modulare i diritti di accesso dei suddetti in funzione delle specifiche esigenze organizzative. A tal riguardo, si precisa che la creazione e gestione di tali Sotto-Account avverrà sotto l’esclusiva responsabilità del Cliente, che si obbliga a manlevare e tenere indenne Toshiba da ogni danno, onere o conseguenza negativa direttamente o indirettamente causato dall’errata creazione, utilizzo e gestione dei Sotto-Account.
<br></br><br></br>

<b>Articolo 4 – Obblighi dell’Utente </b>
<br></br><br></br>
4.1. L’Utente si impegna ad utilizzare la Piattaforma rispettando le presenti Condizioni Generali e le Condizioni Speciali di volta in volta accettate ai fini dell’accesso ai Servizi, collaborando con Toshiba nell’ambito di tutte le questioni relative all’uso dei Servizi.
<br></br><br></br>
4.2. L’Utente si impegna altresì a:<br></br>
(i) fornire tempestivamente tutte le informazioni e l’assistenza che Toshiba potrebbe ragionevolmente richiedere ai fini dell’erogazione dei Servizi;<br></br>
(ii) fornire informazioni complete e veritiere;<br></br>
(iii) rispettare tutte le disposizioni delle presenti Condizioni Generali e, più in generale, del Contratto.<br></br>
<br></br><br></br>
4.3 L’utilizzo permesso della Piattaforma è limitato a quanto descritto nel Contratto nonché a quanto necessario per l’erogazione dei Servizi. 
<br></br><br></br>
L’utilizzo della Piattaforma per qualsiasi diverso scopo è espressamente vietato, ivi compreso, a mero titolo esemplificativo, ogni uso o azione diretti a quanto segue: 
<br></br><br></br>
(i) copiare, riprodurre, duplicare e distribuire copie della Piattaforma;<br></br>
(ii) alterare, modificare, svolgere il c.d. reverse engineering, smontare, decompilare, trasferire o elaborare, con ogni mezzo e ad alcun fine, la Piattaforma;<br></br>
(iii) autorizzare o consentire a qualsiasi persona non autorizzata di accedere o utilizzare la Piattaforma e i Servizi;<br></br>
(iv) concedere in licenza, rivendere, trasferire, cedere, distribuire o altrimenti sfruttare economicamente la Piattaforma ovvero il diritto di accesso e di utilizzo della Piattaforma e dei Servizi;<br></br>
(v) usare la Piattaforma in qualsiasi modo che possa ritenersi illecito, fraudolento o per perseguire qualsiasi finalità o attività illecita, illegale o fraudolenta ovvero che sia diretta a, o in grado di, violare diritti di terzi;<br></br>
(vi) utilizzare la Piattaforma in qualsiasi modo idoneo a provocare danni alla stessa ovvero un deterioramento della disponibilità o accessibilità della Piattaforma medesima agli Utenti.<br></br>

<br></br><br></br>
<b>Articolo 5 - Modalità di pagamento dei Servizi</b>
<br></br><br></br>
5.1 L’Utente potrà creare l’Account e accedere alla Piattaforma senza necessità di un apposito corrispettivo.
<br></br><br></br>
Il pagamento dei Servizi da parte dell’Utente avverrà invece attraverso la costituzione di un conto per ciascun Account o gruppo di Account, costituito dall’Account principale e dai Sotto-Account ad esso legati (di seguito, il “<b>Wallet</b>”), nel quale l’Utente potrà depositare un plafond di mezzi monetari che potranno essere utilizzati per versare il corrispettivo dovuto per ciascun singolo Servizio utilizzato.

Il Wallet potrà essere alimentato dall’Utente attraverso:<br></br><br></br>

(i) un bonifico bancario sul conto corrente di Toshiba alle seguenti coordinate bancarie:
<br></br><br></br>
<b>Iban: IT 26 H 03487 01600 000015507486</b>

<br></br><br></br>
(ii) un versamento a mezzo della piattaforma di pagamento denominata “Paypal”. <br></br><br></br>

5.2 Con riferimento al Wallet, troveranno applicazione le disposizioni seguenti, con salvezza del diritto di recesso dell’Utente di cui al successivo art. 8:
<br></br><br></br>
(a) l’Utente non potrà chiedere il rimborso delle somme versate nel Wallet, che tuttavia resterà disponibile sino a quando l’Utente non avrà utilizzato quanto nello stesso contenuto ai fini del versamento del corrispettivo dovuto per l’uso dei Servizi;
<br></br><br></br>
(b) qualora l’Utente cancelli volontariamente il proprio Account, l’ammontare ancora disponibile nel Wallet abbinato a detto Account sarà restituito all’Utente, il quale, tuttavia, non potrà più accedere alla Piattaforma, nemmeno tramite l’apertura di un nuovo Account;
<br></br><br></br>
(c) fermo quanto precede, resta inteso che il contenuto del Wallet decrementerà esclusivamente a fronte della effettiva fruizione dei Servizi da parte dell’Utente, non essendo previsto alcun corrispettivo o costo per la mera creazione e gestione dell’Account in assenza di utilizzazione di uno o più Servizi.
<br></br><br></br>
<p><b>Articolo 6 &ndash; Propriet&agrave; industriale ed intellettuale &ndash; Marchi Toshiba</b></p>

<p>6.1 L&rsquo;Utente prende atto e riconosce espressamente quanto segue.</p>

<p>Tutti i diritti di utilizzazione economica (ivi compresi, a mero titolo esemplificativo, quelli relativi a programmi informatici, codici sorgente e algoritmi) relativi alla Piattaforma ed ai contenuti della stessa, nonch&eacute; ai Servizi ivi offerti, sono di titolarit&agrave; esclusiva di, ovvero concessi in licenza a, Toshiba.</p>

<p>Tutti i diritti di utilizzazione economica relativi a tutti gli elementi che compongono la Piattaforma (ivi compresi a mero titolo esemplificativo, testi, immagini, elementi grafici, loghi, icone, script, denominazioni dei Servizi e della Piattaforma) sono di titolarit&agrave; esclusiva di, ovvero concessi in licenza a, Toshiba.  Tutti i diritti di utilizzazione relativi al marchio registrato &ldquo;TOSHIBA&rdquo; nonch&eacute; agli ulteriori marchi e segni distintivi eventualmente presenti sulla Piattaforma anche con riferimento ai Servizi sono di titolarit&agrave; esclusiva di, ovvero concessi in licenza a, Toshiba.</p>

<p>6.2 L&rsquo;Utente prende atto e riconosce espressamente che i diritti di cui al precedente art. 6.1 (i &ldquo;<b>Diritti Toshiba</b>&rdquo;) non sono in alcun modo da considerarsi concessi in licenza o trasferiti a qualsiasi titolo all&rsquo;Utente in ragione della utilizzazione della Piattaforma e dei Servizi da parte dello stesso, il quale non ha diritto di utilizzarli se non in stretta conformit&agrave; a quanto necessario ai fini dell&rsquo;utilizzazione del Servizio prescelto. Pertanto, l&rsquo;Utente:</p>

<p>(i) non ha alcun diritto di copiare, riprodurre, utilizzare, distribuire, vendere, pubblicare, trasferire, dare in prestito, concedere in licenza, modificare o disporre in qualunque altro modo dei Diritti Toshiba;</p>

<p>(ii) si obbliga a manlevare e tenere indenne Toshiba da ogni danno, onere o conseguenza negativa che dovesse alla stessa derivare dalla violazione dei Diritti Toshiba. </p>
<b><p>Articolo 7 &ndash; Aggiornamenti, modifiche e manutenzione della Piattaforma e dei Servizi</p></b>

<p>7.1. L&rsquo;Utente conviene espressamente che Toshiba avr&agrave; il diritto di modificare o aggiornare la Piattaforma e/o cambiare la configurazione tecnica della stessa ovvero dei Servizi medesima in qualsiasi momento ed a propria discrezione.</p>

<p>Quanto sopra rilevato, l&rsquo;Utente prende atto e conviene che:</p>

<p>(a) Toshiba proceder&agrave; alla effettuazione delle necessarie attivit&agrave; di ordinaria o straordinaria manutenzione della Piattaforma e dei Servizi fornendo congruo preavviso all&rsquo;Utente tramite la Piattaforma stessa nell&rsquo;ipotesi in cui uno o pi&ugrave; Servizi o l&rsquo;intera Piattaforma dovessero essere temporaneamente inaccessibili;</p>

<p>(b) nell&rsquo;ipotesi un cui la accessibilit&agrave; dei Servizi fosse temporaneamente diminuita o impedita a causa di interruzioni tecniche dovute ad eventi straordinari e imprevedibili, Toshiba provveder&agrave; al ripristino della funzionalit&agrave; della Piattaforma o dei Servizi non appena tecnicamente possibile dandone comunicazione all&rsquo;Utente all&rsquo;interno della Piattaforma;</p>

<p>(c) Toshiba si riserva la facolt&agrave; di apportare modifiche alle condizioni di accesso o di utilizzazione dei Servizi in presenza di un giustificato motivo (quale, a titolo esemplificativo, la necessit&agrave; di conformarsi a novit&agrave; legislative, regolamentari o emanate da autorit&agrave; regolamentari e di vigilanza, l&rsquo;adeguamento allo sviluppo tecnologico ed informatico, l&rsquo;introduzione di nuovi servizi ovvero l&rsquo;adozione di nuove modalit&agrave; di svolgimento degli stessi) fornendo all&rsquo;Utente un preavviso non inferiore a 7 (sette) giorni.</p>

<p>Resta convenuto che Toshiba non potr&agrave; garantire all&rsquo;Utente l&rsquo;accesso alla Piattaforma ed ai Servizi nel corso delle operazioni di ordinaria e straordinaria manutenzione degli stessi e non sar&agrave; quindi responsabile del mancato uso della Piattaforma e dei Servizi da parte dell&rsquo;Utente derivante dalle interruzioni dell&rsquo;operativit&agrave; degli stessi a ci&ograve; dovute.</p>

<p>7.2 Qualora in ragione delle modifiche apportate alla Piattaforma ai sensi del precedente art. 7.1 anche uno solo dei Servizi disponibili all&rsquo;Utente al momento dell&rsquo;apertura dell&rsquo;Account dello stesso non fosse pi&ugrave; disponibile, l&rsquo;Utente potr&agrave; recedere dal Contratto ai sensi del successivo art. 8. In tal caso, la comunicazione scritta prevista dall&rsquo;art. 8 dovr&agrave; essere inviata a Toshiba entro il termine di entrata in vigore delle predette modifiche ovvero entro il giorno di accesso alla Piattaforma se successivo all&rsquo;entrata in vigore delle modifiche.</p>

<b><p> Articolo 8 &ndash; Diritto di recesso dell&rsquo;Utente e cancellazione dell&rsquo;Account</p></b>

<p>8.1 L&rsquo;Utente ha diritto di recedere dal Contratto in ogni momento, inviando una comunicazione scritta a Toshiba ai recapiti indicati all&rsquo;art. 13, fermo restando quanto previsto al precedente art. 5.2.</p>

<b><p> Articolo 9 &ndash; Responsabilit&agrave;</p></b>

<p>9.1 Toshiba non sar&agrave; responsabile degli eventuali danni sofferti dall&rsquo;Utente in occasione dell&rsquo;uso della Piattaforma e dei Servizi e derivanti da interruzioni nell&rsquo;accesso alla Piattaforma e ai Servizi dovuti a guasti o malfunzionamenti degli stessi ovvero dei server nei quali gli stessi sono contenuti, ovvero derivanti, a mero titolo esemplificativo:</p>

<p>(i) da informazioni false e/o inesatte fornite dall&rsquo;Utente;<br></br>
 (ii) dal mancato rispetto da parte dell&rsquo;Utente degli obblighi a proprio carico;<br></br> 
  (iii) dall&rsquo;accesso illecito ovvero non autorizzato o dall&rsquo;utilizzo da parte di terzi della Piattaforma e dei Servizi; <br></br> 
  (iv) dall&rsquo;interruzione o sospensione del funzionamento della Piattaforma e dei Servizi ovvero dei server nei quali gli stessi sono contenuti dovuti a cause di forza maggiore; <br></br> 
  (v) dall&rsquo;utilizzo improprio, errato, illecito o fraudolento della Piattaforma o dei Servizi ovvero dall&rsquo;uso degli stessi per perseguire qualsiasi finalit&agrave; o attivit&agrave; illegittima, illegale, fraudolenta ovvero non espressamente prevista dalla Piattaforma o dai Servizi;<br></br>  (vi) dall&rsquo;errata creazione o gestione dei Sotto-Account messi dall&rsquo;Utente a disposizione dei soggetti appartenenti alla propria organizzazione.</p>

<p>9.2 Toshiba non sar&agrave; in alcun modo responsabile dell&rsquo;erogazione, gestione e/o manutenzione dei Servizi di Terzi, forniti all&rsquo;Utente da terze parti in base ad apposite condizioni contrattuali.</p>

<b><p> Articolo 10 &ndash; Garanzie &ndash; Assistenza</p></b>

<p>10.1. Toshiba garantisce esclusivamente che la Piattaforma ed i Servizi siano in grado di eseguire le operazioni espressamente previste o necessarie per l&rsquo;erogazione dei Servizi.</p>

<p>Toshiba non garantisce pertanto l&rsquo;adeguatezza della Piattaforma rispetto alle esigenze dell&rsquo;Utente ed agli scopi dello stesso e che il risultato voluto dall&rsquo;Utente sia sempre raggiunto e/o raggiungibile.</p>

<p>10.2 Nell&rsquo;ipotesi in cui l&rsquo;Utente abbia necessit&agrave; di ricevere assistenza in ordine all&rsquo;accesso e/o all&rsquo;uso della Piattaforma o dei Servizi, ovvero nell&rsquo;ipotesi in cui voglia inoltrare comunicazioni o reclami, lo stesso potr&agrave; contattare Toshiba a mezzo e-mail al seguente recapito supporto-hub@toshibatec-tiis.com fermo restando che detto servizio di assistenza sar&agrave; disponibile tutti i giorni feriali, dal luned&igrave; al venerd&igrave;, dalle ore 8,30 alle ore 18,30.</p>

<b><p>Articolo 11 &ndash; Clausola risolutiva espressa &ndash; Sospensione del Servizio</p></b>

<p>11.1 Toshiba avr&agrave; il diritto di sospendere e/o bloccare l&rsquo;utilizzo della Piattaforma e dei Servizi da parte dell&rsquo;Utente nell&rsquo;ipotesi in cui abbia modo di constatare o abbia il ragionevole sospetto che l&rsquo;Utente sia impegnato in attivit&agrave; fraudolente, inappropriate e/o in violazione delle condizioni d&rsquo;uso della Piattaforma o dei Servizi previste dal Contratto.</p>

<p>11.2 Ai sensi e per gli effetti dell&rsquo;art. 1456 c.c., Toshiba avr&agrave; diritto di risolvere il Contratto con effetto immediato nell&rsquo;ipotesi di inadempimento, totale o parziale, ad opera dell&rsquo;Utente di una o pi&ugrave; delle obbligazioni dallo stesso assunte ai sensi degli articoli 3 (Accesso ai Servizi), 4 (Obblighi dell&rsquo;Utente) e 6 (Propriet&agrave; industriale ed intellettuale &ndash; Marchi Toshiba).</p>

<b><p> Articolo 12 - Trattamento Dei Dati Personali</p></b>

<p>12.1 Toshiba tratter&agrave; i dati personali acquisiti per mezzo della Piattaforma in conformit&agrave; alla normativa applicabile in materia di tutela dei dati personali, secondo quanto meglio specificato nella <Link to="/privacypolicy">Privacy Policy</Link>.</p>

<p>12.2 L&rsquo;Utente conserver&agrave; la piena responsabilit&agrave; per i trattamenti di dati personali dallo stesso effettuati per mezzo della Piattaforma in qualit&agrave; di autonomo titolare del trattamento, ivi espressamente inclusi quelli attinenti ai Sotto-Account assegnati dall&rsquo;Utente alle persone facenti parte della propria organizzazione.</p>

<b><p> Articolo 13 - Comunicazioni</p></b>

<p>Tutte le comunicazioni relative al Contratto, se non diversamente indicato, dovranno essere indirizzate dalle Parti ai seguenti indirizzi:<br></br> - se a Toshiba all&rsquo;indirizzo PEC <Link   to='#'
            onClick={(e) => {
                window.location.href = 'mailto:amministrazione@toshibatec-tiis.com';
                e.preventDefault();
            }}>amministrazione@toshibatec-tiis.com</Link>;<br></br> - se all&rsquo;Utente all&rsquo;indirizzo PEC e/o e-mail utilizzato da quest&rsquo;ultimo al momento dell&rsquo;apertura del proprio Account.</p>

<b><p> Articolo 14 - Disposizioni Generali</p></b>

<p>Fatte salve le disposizioni di legge inderogabili, la nullit&agrave;, invalidit&agrave;, inefficacia o oggettiva inapplicabilit&agrave; di qualsiasi delle clausole del Contratto non avr&agrave; effetto sulla validit&agrave;, efficacia ed applicabilit&agrave; di ogni altra clausola. Le clausole eventualmente nulle, invalide, inefficaci o oggettivamente inapplicabili continueranno ad applicarsi con le modifiche che, interpretando in buona fede l&rsquo;intenzione delle Parti, ne permettano la sopravvivenza.</p>

<b><p> Articolo 15 - Legge applicabile e Foro Competente</p></b>

<p>15.1 Il Contratto &egrave; regolato dalla legge italiana.</p>

<p>15.2 L&rsquo;Autorit&agrave; Giudiziaria di Milano sar&agrave; competente in via esclusiva a decidere di ogni controversia relativa al Contratto.</p>

<p></p>
         
            </Paragraph>
          </Col>
        </Row>
      </>
    </Page>
  );
};

export default GeneralConditions;
