// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additional__rmmwp {
  width: 100%;
  justify-content: space-between;
  line-height: 0.8rem;
  margin-top: 1rem;
}
.quantity__jYw2W {
  font-weight: bold;
}
.additionaldetail__JmP9X {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}
.customSelect__cX3OZ .ant-select-selector {
  /*max-height: 51px;
      overflow: auto;*/
  position: relative;
  padding: 0 11px;
  /* color: #8c8b8b; */
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 3px;
  transition: all 0.3s;
}
.disabled__WtxI8 {
  cursor: not-allowed !important;
  color: #ccc !important;
  font-size: 20px !important;
}
.disabled__WtxI8:hover {
  color: #ccc !important;
  font-size: 20px !important;
}
.enabled__dHtym {
  font-size: 20px !important;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Views/App/Components/Style.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAGE;EACE,iBAAA;AADJ;AAGA;EACE,WAAA;EACA,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AADF;AAKA;EAHE;sBACoB;EAOhB,kBAAA;EACA,eAAA;EALJ,oBAAoB;EAOhB,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,oCAAA;EACA,oCAAA;EACA,kBAAA;EACA,oBAAA;AALN;AAWA;EACE,8BAAA;EACA,sBAAA;EACA,0BAAA;AATF;AAYA;EACE,sBAAA;EACA,0BAAA;AAVF;AAaA;EACE,0BAAA;EACA,eAAA;AAXF","sourcesContent":[".additional {\n    width: 100%;\n    justify-content: space-between;\n    line-height: 0.8rem;\n    margin-top: 1rem;\n\n  }\n  \n  .quantity {\n    font-weight: bold;\n  }\n.additionaldetail{\n  width: 100%;\n  justify-content: flex-start;\n  display: flex;\n  margin-top: -0.5rem;\n  margin-bottom: 1rem;\n}\n\n\n.customSelect {\n  :global {\n    .ant-select-selector {\n      /*max-height: 51px;\n      overflow: auto;*/\n      position: relative;\n      padding: 0 11px;\n      /* color: #8c8b8b; */\n      font-weight: normal;\n      font-size: 14px;\n      text-align: center;\n      background-color: #fafafa !important;\n      border: 1px solid #e5e5e5 !important;\n      border-radius: 3px;\n      transition: all 0.3s;\n    }\n  }\n}\n\n\n.disabled {\n  cursor: not-allowed !important;\n  color: #ccc !important;;\n  font-size: 20px !important;;\n}\n\n.disabled:hover {\n  color: #ccc !important;\n  font-size: 20px !important;\n}\n\n.enabled {\n  font-size: 20px !important;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additional": `additional__rmmwp`,
	"quantity": `quantity__jYw2W`,
	"additionaldetail": `additionaldetail__JmP9X`,
	"customSelect": `customSelect__cX3OZ`,
	"disabled": `disabled__WtxI8`,
	"enabled": `enabled__dHtym`
};
export default ___CSS_LOADER_EXPORT___;
