import React from "react";
import { Row, Col, Grid,Tag} from "antd";
import CardStore from "./CardStore";
const { useBreakpoint } = Grid;
const Store = (props) => {
  const {
    apps,
    handleDetail,
    tipo,
    handleDeleteApp,
    handleGoApp,
    handleSettingApp,
    isManagement_app,
    isAccess_app,
  } = props;
  const screens = useBreakpoint();
  //console.log(screens);
      /*
    xs: schermi extra piccoli (<= 576px)
    sm: schermi piccoli (>= 576px)
    md: schermi di medie dimensioni (>= 768px)
    lg: schermi larghi (>= 992px)
    xl: schermi extra larghi (>= 1200px)
    xxl: schermi molto larghi (>= 1600px)
    la proprietà gutter che imposta lo spazio tra le colonne  le proprietà justify e align che definiscono rispettivamente l'allineamento orizzontale e verticale dei componenti nella griglia.
    */ 
  return (
    <>
    {/* Current break point:{' '}
    {Object.entries(screens)
      .filter((screen) => !!screen[1])
      .map((screen) => (
        <Tag color="blue" key={screen[0]}>
          {screen[0]}
        </Tag>
      ))} */}
  
  

    <Row gutter={[16, 24]}  /*gutter={{ xs: [16,24], sm: [16,24], md: [16,24], lg: [16,24] }}*/ style={{ border: "0px solid",marginTop:'5px' }}>
      {apps?.content?.map((app) => {
        return (
          /*
          "xs" (extra small), cioè quando lo schermo è molto piccolo, come ad esempio su uno smartphone.
          Quando la larghezza dello schermo è "sm" (small), cioè quando lo schermo è più grande di un telefono ma ancora relativamente piccolo
          Quando la larghezza dello schermo è "md" (medium). Quando la larghezza dello schermo è "lg" (large) Infine, quando la larghezza dello schermo è "xl" (extra large).
          */
          <Col key={app.id}  xs={24} sm={24} md={24} lg={12} xl={12} xxl={6} style={{ border: "0px solid" }}>
            <CardStore
              app={app}
              tipo={tipo}
              handleDetail={handleDetail}
              handleDeleteApp={handleDeleteApp}
              handleGoApp={handleGoApp}
              handleSettingApp={handleSettingApp}
              isManagement_app={isManagement_app}
              isAccess_app={isAccess_app}
            />
          </Col>
        );
      })}
    </Row>
   </>
  );
};

export default Store;
