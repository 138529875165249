import React from "react";
import { Tabs, Empty } from "antd";
import {
  QuestionCircleOutlined,
  TagOutlined,
  SettingOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons";
import { useTranslate } from "../../../Translate";
import Parser from "html-react-parser";
import { lkPricingConsumo, lkPricingAttivazione } from "../../App/UtilsApp";

const { TabPane } = Tabs;

const CardTabs = (props) => {
  const { app } = props;
  const i18n = useTranslate();
  const { t } = i18n;
  const arrPricingAttivazione = lkPricingAttivazione(t);
  const arrPricingConsumo = lkPricingConsumo(t);
  return (
    <Tabs defaultActiveKey="1" style={{ border: "0px solid" }}>
      <TabPane
        tab={
          <span>
            <EuroCircleOutlined />
            {"Pricing"}
          </span>
        }
        key="1"
      >
        <p>
          <span>
            <b>{t("pricingAttivazione")}: </b>
            {arrPricingAttivazione[app.pricingAttivazione]}
          </span>
        </p>
        <p>
          <span>
            <b>{t("price")}: </b>{" "}
            {app.prezzo != null
              ? new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                }).format(app.prezzo)
              : new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                }).format(0)}
          </span>
        </p>
        <p>
          <span>
            <b>{t("activationprice")}: </b>{" "}
            {app.costoAttivazioneIniziale != null
              ? new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                }).format(app.costoAttivazioneIniziale)
              : new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                }).format(0)}
          </span>
        </p>
        <p>
          <span>
            <b>{t("period")}: </b>
            {app.periodo}
          </span>
        </p>
        <p>
          <span>
            <b>{t("pricingConsumo")}: </b>
            {arrPricingConsumo[app.pricingConsumo]}
          </span>
        </p>
        {parseInt(app.maxUtenti) > 0 ? (
          <p>
            <span>
              <b>{t("maxusers")}: </b>
              {app.maxUtenti}
            </span>
          </p>
        ) : (
          ""
        )}
      </TabPane>
      <TabPane
        tab={
          <span>
            <TagOutlined />
            {t("list")}
          </span>
        }
        key="2"
      >
        {app.infoListino ? Parser(app.infoListino) : <Empty />}
      </TabPane>
      <TabPane
        tab={
          <span>
            <QuestionCircleOutlined />
            FAQ
          </span>
        }
        key="3"
      >
        {app.infoFAQ ? Parser(app.infoFAQ) : <Empty />}
      </TabPane>
      <TabPane
        tab={
          <span>
            <SettingOutlined />
            {t("assistance")}
          </span>
        }
        key="4"
      >
        {app.infoSupporto ? Parser(app.infoSupporto) : <Empty />}
      </TabPane>
    </Tabs>
  );
};

export default CardTabs;
