/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react'
import {
  Divider,
  Button,
  Form,
  Input,
  Select,
  Tooltip
} from 'antd';
import { useTranslate } from '../../../Translate';
import { SiTeradata } from 'react-icons/si';
import { NumericInput } from '../../../Helpers/Helpers';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xl: {
      span: 8,
    },

    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },

  },
  wrapperCol: {
    xl: {
      span: 8,
    },
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },

  },
};

const Step1 = (props) => {
  //gestione della lingua

  const { groups, groupid, form, onFinish, order, type } = props

  const i18n = useTranslate();
  const { t } = i18n;

  const [value, setValue] = useState('');

  var sel_accounts;
  const setData = () => {

    sel_accounts = parseInt(groupid ? groupid : order.accountId);

    form.setFieldsValue({
      accountId: sel_accounts,
      imponibileOrdinato: order.imponibileOrdinato,
      tipoOrdine: order.tipoOrdine
    });
  };

  useEffect(() => {
    setData()
  }, [order, groupid])



  return (
    <>
      <Divider orientation="left" orientationMargin="0" style={{ color: '#e61e1e' }}>{t("newrefill")}</Divider>
      <Form
        {...formItemLayout}
        form={form}
        layout="vertical"
        name="step1"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          label={t('group')}
          name="accountId"
        >
          <Select
            style={{ width: '100%' }}
            optionFilterProp="children"
            defaultValue={sel_accounts}
            disabled={true}
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          >
            {groups}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('amount')}
          name="imponibileOrdinato"
          rules={[
            {
              required: true,
              message: t('ckAmount'),
            },
          ]}
        >
          <NumericInput
            value={value}
            onChange={setValue} />
        </Form.Item>
        <Form.Item
          label={t('typepayment')}
          name="tipoOrdine"
          rules={[
            {
              required: true,
              message: t('ckTypepayment'),
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            showSearch
            placeholder={t("selecttypepayment")}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          >
              <Option value={0}>PAYPAL</Option>
              <Option value={1}>{t("transfer")}</Option>
          </Select>
        </Form.Item>
        <Form.Item></Form.Item>


      </Form>
    </>
  )
}

export default Step1
