import React from 'react'
import { Layout, Typography } from 'antd'

const { Footer } = Layout;
const { Link } = Typography;

const AdminFooter = () => {
    return (
        <Footer style={{ textAlign: 'center', border:'0px solid',background:'transparent',position:'relative',bottom:'6%',color:'rgb(102, 102, 102)' }}>
            Toshiba Tec Italia Imaging Systems S.p.A. Sede legale in Milano (MI), Via Vittor Pisani n. 20, CF 12951200158 - P. IVA 02775910165  REA MB 1724312 - Registro delle Imprese Milano - <Link   to='#'
            onClick={(e) => {
                window.location.href = 'mailto:amministrazione@toshibatec-tiis.com';
                e.preventDefault();
            }}>amministrazione@toshibatec-tiis.com</Link>
        </Footer>
    )
}

export default AdminFooter