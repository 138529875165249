/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useEffect, useState } from "react";
import { List, Divider, Button, Modal, Descriptions } from "antd";
import Page from "../../Components/Page/Page";
import { useTranslate } from "../../Translate";
import { useNavigate, Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategoriesApps,
  deleteCategory
} from "../../redux/reducers/admin-app-reducer";
const { confirm } = Modal;

export const Categories = () => {
  const { keycloak } = useKeycloak();
  const i18n = useTranslate();
  const { t } = i18n;
  // set up dispatch
  const dispatch = useDispatch();
  // fetch data from our store
  const { loading, error, categories } = useSelector(
    (state) => state.adminApps
  );
  //console.log(categories)
  const navigate = useNavigate();

  const handleNew = () => {
    navigate("/admin/services/categories/new");
  };
  const handleBack = () => {
    navigate("/admin/services/");
  };

  const handleDelete = (id) => {
    confirm({
      title: t("questiondeleterecord"),
      onOk() {
        dispatch(deleteCategory(keycloak.token,id))
      },
    });
  };

  useEffect(() => {
    dispatch(fetchCategoriesApps("all"));
  }, []);
  return (
    <Page loading={loading} inner>
      <>
        <div>
          <Button type="gost" style={{ float: "right" }} onClick={handleNew}>
            {t("new")}
          </Button>
          <Button
            type="primary"
            style={{ float: "right", marginRight: "16px" }}
            onClick={handleBack}
          >
            {t("back")}
          </Button>
        </div>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          {t("categories")}
        </Divider>
        <List
          itemLayout="horizontal"
          dataSource={categories}
          renderItem={(item) => (
            
            <List.Item
              actions={[
                <Link to={"/admin/services/categories/" + item.id}>
                  {t("edit")}
                </Link>,
                <a key="list-delete" onClick={() => handleDelete(item.id)}>
                  {t("delete")}
                </a>,
              ]}
            >
              <List.Item.Meta
                title={t("description")}
                description={item.descrizione !==null && JSON.parse(item.descrizione).it ?"it: " + JSON.parse(item.descrizione).it: ""}
              />
              {item.descrizione !==null &&  JSON.parse(item.descrizione).en ? "en: " + JSON.parse(item.descrizione).en: ""}
            </List.Item>
          )}
        />
      </>
    </Page>
  );
};
