/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import Page from "../../Components/Page/Page";
import { useTranslate } from "../../Translate";
import { Result, Modal, Divider, Avatar, List } from "antd";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { fetchContacts } from "../../redux/reducers/contact-reducer";
import { useParams } from "react-router-dom";
import { AiOutlineDownload, AiOutlineDelete } from "react-icons/ai";
import { UserOutlined } from "@ant-design/icons";
const Contacts = (props) => {
  const { gruppoid ,HandleSelectContact} = props;
  const { keycloak } = useKeycloak();
  const userid = keycloak?.tokenParsed?.sub?.toString();
  const i18n = useTranslate();
  const { t } = i18n;

  const dispatch = useDispatch();

  //   fetch data from our store
  const { loading, error, contacts } = useSelector((state) => state.contacts);

  const handleItemClick = (record) => {
    HandleSelectContact(record);
  };

  useEffect(() => {
    dispatch(fetchContacts(keycloak.token, userid, gruppoid));
  }, [dispatch]);

  

  return (
    <>
      {!error.status ? (
        <List
          itemLayout="horizontal"
          dataSource={contacts}
          style={{ maxHeight: "400px", overflowY: "auto" }} // Imposta l'altezza massima desiderata
          renderItem={(item, index) => (
            <List.Item
              onClick={() => handleItemClick(item)}
              style={{ background: item.isHighlighted ? "#e6f7ff" : "inherit" }}
              title="Seleziona contatto"           >
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{ backgroundColor: "#E61E1E" }}
                    icon={<UserOutlined />}
                  />
                }
                title={
                  <a  href="#">
                    {item.name.charAt(0).toUpperCase() + item.name.slice(1)}{" "}
                    {item.surname.charAt(0).toUpperCase() +
                      item.surname.slice(1)}
                  </a>
                }
                description={item.indirizzoCompleto || "no data"}
              />
            </List.Item>
          )}
        />
      ) : (
        <Result status="warning" title="Items not available at this time." />
      )}
    </>
  );
};

export default Contacts;
