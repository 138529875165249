/*
 Stato:
    - CANCELLATO : 0
    - ATTIVATO : 1
*/
export const lkStato = (t) => {

    const stato = [];
    stato[0] = t("deleted");
    stato[1] = t("activated");
    return stato;
}

/**
 - PricingAttivazione:
    - ATT_GR(gratuito) : 0    Vincoli: Periodo = 0; Prezzo = 0
    - ATT_UT(una tantum): 1    Vincoli: Periodo = 0; Prezzo > 0
    - ATT_PP(periodico): 2    Vincoli: Periodo > 0: Prezzo > 0
    -ATT_GR_TRIAL (gratuito trial): 3   Vincoli: Periodo > 0: Prezzo = 0
*/

export const lkPricingAttivazione = (t) => {
    
    const pricingAttivazione = [];
    pricingAttivazione[0] = t("gratis");
    pricingAttivazione[1] = t("tantum");
    pricingAttivazione[2] = t("recurrent");
    pricingAttivazione[3] = t("trial");
    return pricingAttivazione;
}

/*
- PricingConsumo:
    - CONS_GR(gratuito): 0
    - CONS_FM(consumo a fine mese): 1
*/

export const lkPricingConsumo = (t) => {
    
    const pricingConsumo = [];
    pricingConsumo[0] = t("gratis");
    pricingConsumo[1] = t("spent");
    pricingConsumo[2] = t("spentinapp");
    return pricingConsumo;
}

export const lkTypeBalance = (t) => {
    
    const type = [];
    type[0] = t("initialization");
    type[1] = t("accredit");
    type[2] = t("charge");
    type[3] = t("transfer2");
    return type;
}


