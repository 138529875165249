import { config } from "../utils/Constants";
import { Form, notification, Select, Input, Tooltip, Typography } from "antd";
import axios from "axios";

const { Option } = Select;
const { Text } = Typography;

//      // keycloak.tokenParsed.resource_access[`${config.keycloak.CLIENTID}`].roles.includes('admin')
export const isAdmin = (keycloak) => {
  return (
    keycloak &&
    keycloak.tokenParsed &&
    //keycloak.tokenParsed.resource_access[`${config.keycloak.CLIENTID}`] &&
    keycloak.tokenParsed.realm_access.roles.some((v) => v.includes("admin"))
  );
};

export const isManagement_users = (keycloak) => {
  return (
    keycloak &&
    keycloak.tokenParsed &&
    keycloak.tokenParsed.realm_access.roles.some((v) =>
      v.includes("management_users")
    )
  );
};

export const isAdmin_credits = (keycloak) => {
  return (
    keycloak &&
    keycloak.tokenParsed &&
    keycloak.tokenParsed.realm_access.roles.some((v) =>
      v.includes("admin_credits")
    )
  );
};

export const isMove_credits = (keycloak) => {
  return (
    keycloak &&
    keycloak.tokenParsed &&
    keycloak.tokenParsed.realm_access.roles.some((v) =>
      v.includes("move_credits")
    )
  );
};

export const isManagement_app = (keycloak) => {
  return (
    keycloak &&
    keycloak.tokenParsed &&
    keycloak.tokenParsed.realm_access.roles.some((v) =>
      v.includes("management_app")
    )
  );
};

export const isAccess_app = (keycloak) => {
  return (
    keycloak &&
    keycloak.tokenParsed &&
    keycloak.tokenParsed.realm_access.roles.some((v) =>
      v.includes("access_app")
    )
  );
};

export const isAccess_bonus = (keycloak) => {
  return (
    keycloak &&
    keycloak.tokenParsed &&
    keycloak.tokenParsed.realm_access.roles.some((v) =>
      v.includes("admin_profiles")
    )
  );
};

export const isAccess_invitation = (valore) => {
  return (
   parseFloat(valore)>0
  );
};

export const isAdmin_users = (keycloak) => {
  return (
    keycloak &&
    keycloak.tokenParsed &&
    keycloak.tokenParsed.realm_access.roles.some((v) =>
      v.includes("admin_users")
    )
  );
};

export const isAppPublic = (arrVisibility) => {
  return arrVisibility?.length === 0 ? true : false;
};

export const handleLogError = (error) => {
  if (error.response) {
    console.log(error.response.data);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log(error.message);
  }
};

export const ErrorPage = (status, navigate) => {
  if (status === 500 || status === 0) {
    navigate("/error/500");
  } else if (status === 403) {
    navigate("/error/403");
  } else if (status === 401) {
    navigate("/error/401");
  } else if (status === 404) {
    navigate("/error/404");
  } else if (status === 400) {
    navigate("/error/400");
  }
};

export const openNotificationWithIcon = (type, msg, description) => {
  notification[type]({
    message: msg,
    description: description,
  });
};

export const getItemFromLocalStorage =  (key)  => {
  // if (localStorage !== null) {
  //   const myValue = localStorage.getItem(key);
  //   if (myValue !== null) {
  //     return JSON.parse(myValue);
  //   }
  // }
  // return "";
  if(sessionStorage !==null){
    const myValue = sessionStorage.getItem(key);
      if (myValue !== null) {
        return JSON.parse(myValue);
      }
  }
  return null;

};

export const setLocalStorageItem = (key, item) => {
  // if (localStorage !== null) {
  //   if (item && typeof key === "string") {
  //     localStorage.setItem(key, JSON.stringify(item));
  //   }
  // }

  var cookiepolicy= getCookie("cookiepolicy");
  console.log(cookiepolicy);
  if (sessionStorage !== null && cookiepolicy ==="true") {
    if (item && typeof key === "string") {
      sessionStorage.setItem(key, JSON.stringify(item));
    }
  }


};

export const bearerAuth = (token) => {
  return `Bearer ${token}`;
};

// set up axios - simple json-server prototype config here
export const api = axios.create({
  baseURL: config.url.API_BASE_URL,
});
export const apiZen = axios.create({});

export const setHeader = (token, userid) => {
  if (token !== undefined && token !== null && token !== "") {
    if (userid !== "" && userid !== undefined && userid !== null) {
      return {
        headers: {
          "Content-type": "application/json",
          Authorization: bearerAuth(token),
          "user-id": userid,
        },
      };
    } else {
      return {
        headers: {
          "Content-type": "application/json",
          Authorization: bearerAuth(token),
        },
      };
    }
  } else {
    return {
      headers: {
        "Content-type": "application/json",
      },
    };
  }
};


export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}



export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const selectBeforeLink = (
  <Form.Item name="prefix_link" noStyle>
    <Select defaultValue="http://" className="select-before">
      <Option value="http://">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  </Form.Item>
);
export const selectAfterLink = (
  <Form.Item name="suffix_link" noStyle>
    <Select defaultValue=".com" className="select-after">
      <Option value=".com">.com</Option>
      <Option value=".jp">.jp</Option>
      <Option value=".cn">.cn</Option>
      <Option value=".org">.org</Option>
    </Select>
  </Form.Item>
);

export const formatNumber = (value) => new Intl.NumberFormat().format(value);

export const NumericInput = (props) => {
  const { value, onChange, placeholder } = props;

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;

    if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
      onChange(inputValue);
    }
  }; // '.' at the end or only '-' in the input box.

  const handleBlur = () => {
    let valueTemp = value;

    if (value.charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1);
    }

    onChange(valueTemp.replace(/0*(\d+)/, "$1"));
  };

  const title = value ? (
    <span className="numeric-input-title">
      {value !== "-" ? formatNumber(Number(value)) : "-"}
    </span>
  ) : (
    "Input a number"
  );
  return (
    <Tooltip
      trigger={["focus"]}
      title={title}
      placement="topLeft"
      overlayClassName="numeric-input"
    >
      <Input
        suffix="€"
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        maxLength={25}
      />
    </Tooltip>
  );
};
