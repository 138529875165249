import React, { useEffect, useState } from "react";
import { UsersService } from "../../Service/UsersService";
import { AccountsService } from "../../Service/AccountsService";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import Filter from "./Components/Filter";
import List from "./Components/List";
import Page from "../../Components/Page/Page";
import {
  handleLogError,
  ErrorPage,
  openNotificationWithIcon,
} from "../../Helpers/Helpers";

import {
  convertTimestamp
} from "../../utils/ConvertData";

import { Excel } from "antd-table-saveas-excel";
import { useTranslate } from "../../Translate";
import { Select } from "antd";

import { isManagement_users, isAdmin_users } from "../../Helpers/Helpers";
const { Option } = Select;
const Users = (props) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const { type } = props;
  const [users, setUsers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [dealerslk, setDealerslk] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [viewBtnNew, setViewBtnNew] = useState(false);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const userid = keycloak?.tokenParsed?.sub?.toString();

  const onFilterChange = (filter) => {
    setPagination({
      current: 1,
      pageSize: 10,
    });
    setfilteredData(filter);
  };

  const newUser = () => {
    type === "admin"
      ? navigate("/admin/accounts/new")
      : navigate("/team/accounts/new");
  };

  const getDealer = async () => {
    try {
      var filteredDataSend = {};
      filteredDataSend["type"] = type;
      filteredDataSend["pagination"] = {
        current: 1,
        pageSize: 1000000,
      };
      filteredDataSend["fieldSearch"] = { dealer: true };
      const response = await AccountsService.getAccountsDealer(
        keycloak.token,
        filteredDataSend
      );
      let lk_accounts = [];
      //popolo la lookup degli accounts
      lk_accounts = response.data.content.map((account) => ({
        id: account.id,
        companyname: account.companyname.trim(),
      }));
      setDealers(lk_accounts);

      var lk_accounts_dealer = [];
      //popolo la lookup degli accounts
      lk_accounts.map((account) => {
        lk_accounts_dealer.push(
          <Option value={account.id} key={account.id}>
            {account.companyname.trim()}
          </Option>
        );
        return true;
      });
      setDealerslk(lk_accounts_dealer);
    } catch (error) {
      handleLogError(error);
    }
  };

  const getPermissionNewUser = () => {
    var r = false;
    if (keycloak.authenticated === true) {
      if (isManagement_users(keycloak)) {
        r = true;
      } else if (isAdmin_users(keycloak)) {
        r = true;
      }
    } else {
      r = false;
    }
    setViewBtnNew(r);
    return r;
  };

  const getData = async (newPagination) => {
    var filteredDataSend = {};
    filteredDataSend["type"] = type;
    filteredDataSend["pagination"] =
      newPagination.pagination !== undefined
        ? newPagination.pagination
        : pagination;
    filteredDataSend["fieldSearch"] = filteredData;
    try {
      const response = await UsersService.getUsers(
        keycloak.token,
        filteredDataSend,
        userid
      );
      setUsers(response.data.data);
      setPagination({
        ...(newPagination.pagination !== undefined
          ? newPagination.pagination
          : pagination),
        total: response.data.total,
      });
      ErrorPage(response.status, navigate);
      var filteredDataSendAccount = {};
      filteredDataSendAccount = filteredDataSend;
      filteredDataSendAccount["pagination"] = null;
      filteredDataSendAccount["fieldSearch"] = null;
      const responseAccount = await AccountsService.getAccounts(
        keycloak.token,
        filteredDataSendAccount
      );
      ErrorPage(responseAccount.status, navigate);
      var lk_accounts = [];
      //popolo la lookup degli accounts
      responseAccount.data.content.map((account) => {
        lk_accounts.push(
          <Option value={account.id} key={account.id}>
            {account.companyname.trim()}
          </Option>
        );
        return true;
      });
      setAccounts(lk_accounts);
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  const handleTableChange = (newPagination) => {
    getData({ pagination: newPagination });
    setPagination(newPagination);
  };

  const columnsExport = [
    {
      title: t("firstName"),
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: t("lastName"),
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("group"),
      dataIndex: "attributes",
      key: "azienda",
      render: (value, row) => {
        return value !== null
          ? value.accounts !== null
            ? value.accounts[0]
            : ""
          : "";
      },
    },
    {
      title: t("dealer"),
      dataIndex: "attributes",
      key: "dealer",
      render: (value, row) => {
        return value !== null && value.dealer !== undefined
          ? value.dealer !== null
            ? value.dealer[0] === "true"
              ? t("yes")
              : "No"
            : "No"
          : "No";
      },
    },
    {
      title: t("dealerappartenenza"),
      dataIndex: "attributes",
      key: "dealerAccount",
      render: (value, row) => {
        return value !== null && value.dealerAccount !== undefined && JSON.stringify(value.dealerAccount ) !== JSON.stringify([''])
          ? value.dealerAccount !== null && value.dealerAccount !== undefined 
            ? dealers.find(
                (oggetto) => oggetto.id === parseInt(value.dealerAccount[0])
              )?.companyname ?? null
            : ""
          : "";
      },
    },
    {
      title: t("active"),
      dataIndex: "enabled",
      key: "enabled",
      render: (value, row) => {
        return value ? t("yes") : "No";
      },
    },
    {
      title: t("agreementmarketing"),
      dataIndex: "attributes",
      key: "agreementmarketing",
      render: (value, row) => {
        return value !== null
          ? value.agreementmarketing !== null
            ? value.agreementmarketing[0] === "true"
              ? t("yes")
              : "No"
            : "No"
          : "No";
      },
    },
    {
      title: t("agreementprofilazione"),
      dataIndex: "attributes",
      key: "agreementprofilazione",
      render: (value, row) => {
        return value !== null
          ? value.agreementprofilazione !== null
            ? value.agreementprofilazione[0] === "true"
              ? t("yes")
              : "No"
            : "No"
          : "No";
      },
    },
    {
      title: t("createddata"),
      dataIndex: "createdTimestamp",
      key: "createdTimestamp",
      render: (value, row) => {
        return convertTimestamp(value);
      },
    },
  ];

  const onDeleteItem = async (id) => {
    try {
      setLoading(true);
      setDeleted(true);
      const response = await UsersService.disable(keycloak.token, id);
      if (response.status === 201) {
        openNotificationWithIcon(
          "success",
          response.data.type,
          response.data.messaggio
        );
        type === "admin"
          ? navigate("/admin/accounts/")
          : navigate("/team/accounts/");
      } else if (response.status === 400) {
        openNotificationWithIcon(
          "warning",
          response.data.type,
          response.data.messaggio
        );
      } else {
        ErrorPage(response.status, navigate);
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
    setDeleted(false);
  };

  const onEditItem = (id) => {
    type === "admin"
      ? navigate("/admin/accounts/" + id)
      : navigate("/team/accounts/" + id);
  };

  const handleClickExport = () => {
    const excel = new Excel();
    excel
      .addSheet("accounts")
      .addColumns(columnsExport)
      .addDataSource(users, {
        str2Percent: true,
      })
      .saveAs("export_accounts.xlsx");
  };

  useEffect(() => {
    setLoading(true);
    getDealer();
    getData(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getPermissionNewUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, filteredData, deleted]);

  return (
    <Page inner>
      <>
        {" "}
        <Filter
          onFilterChange={onFilterChange}
          accounts={accounts}
          newUser={newUser}
          viewBtnNew={viewBtnNew}
          dealers={dealerslk}
          type={type}
        />
        <List
          users={users}
          dealers={dealers}
          type={type}
          loading={loading}
          pagination={pagination}
          handleClickExport={handleClickExport}
          handleTableChange={handleTableChange}
          onDeleteItem={onDeleteItem}
          onEditItem={onEditItem}
        />
      </>
    </Page>
  );
};

export default Users;
