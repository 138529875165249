import React, { useEffect, useState } from 'react'
import { UsersService } from '../../Service/UsersService'
import { useKeycloak } from '@react-keycloak/web'
import Page from '../../Components/Page/Page';
import { handleLogError, ErrorPage,openNotificationWithIcon } from '../../Helpers/Helpers';
import { useTranslate } from '../../Translate';
import { useNavigate } from 'react-router-dom';
import {
    Tabs,
    Button,
    Checkbox,
    Form, Row, Col, Input, Divider,
    Typography,Modal
} from 'antd';
const ColProps = {
    xs: 24,
    sm: 12,
    style: {
        marginBottom: 16,
    },
}
const { confirm } = Modal
const {Text} = Typography;
const { TabPane } = Tabs;
const Settings = () => {
    const i18n = useTranslate();
    const { t } = i18n;
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState([])
    const [loading, setLoading] = useState(false)
    const { keycloak } = useKeycloak()
    const navigate = useNavigate();

    const onChangeTab = (key) => {
        // key == 1 ? setLoadingUser(true) : setLoadingAdmin(true)
        // key == 1 ? getPermission("user") : getPermission("admin")
    };
    const onFinishPrivacy = (values) => {
        handleSendDataPrivacy(values)
    };

    const  handleDisableUser= () =>{
        confirm({
            title: t("disableuser"),
            onOk() {
              disable()
            },
          })
    }

    const disable= async()=>{
     
        try {
            setLoading(true);
            const userid = keycloak.tokenParsed.sub.toString();
            const response = await UsersService.disable(keycloak.token,userid);
            if(response.status === 201){
                openNotificationWithIcon('success',response.data.type,response.data.messaggio);
            }else if(response.status === 400){
                openNotificationWithIcon('warning',response.data.type,response.data.messaggio);
            }else{
                ErrorPage(response.status,navigate);
            }
        } catch (error) {
            handleLogError(error);
            setLoading(false);
        }
        setLoading(false);
        keycloak.logout();
    }

    const handleSendDataPrivacy= async (data) => {
        try {
            setLoading(true);
            data["username"] = keycloak.tokenParsed.preferred_username.toString()
            console.log(data);
            const response = await UsersService.updatePrivacy(keycloak.token,keycloak.tokenParsed.sub.toString(), data)
            
            if(response.status === 201){
                openNotificationWithIcon('success',response.data.type,response.data.messaggio);
            }else if(response.status === 400){
                openNotificationWithIcon('warning',response.data.type,response.data.messaggio);
            }else{
                ErrorPage(response.status,navigate);
            }
        } catch (error) {
            handleLogError(error);
            setLoading(false);
        }
        setLoading(false);
    }

    const onFinishChangepassword = (values) => {
        handleSendDataPassword(values)
    };

    const handleSendDataPassword= async (data) => {
        try {
            setLoading(true);
            const response = await UsersService.updatePassword(keycloak.token,keycloak.tokenParsed.sub.toString(), data)
            
            if(response.status === 201){
                openNotificationWithIcon('success',response.data.type,response.data.messaggio);
            }else if(response.status === 400){
                openNotificationWithIcon('warning',response.data.type,response.data.messaggio);
            }else{
                ErrorPage(response.status,navigate);
            }
        } catch (error) {
            handleLogError(error);
            setLoading(false);
        }
        setLoading(false);
    }
    //const formRefPrivacy = React.createRef()
    //const formRefChangepassword = React.createRef()
    const [formRefPrivacy,formRefChangepassword] = Form.useForm();

    const getData = async () => {
        var filteredData = {};
        filteredData["type"] = "admin"
        try {
            const response = await UsersService.getUserById(keycloak.token,keycloak.tokenParsed.sub.toString(), filteredData)
            setUser(response.data);
    
            /*setto i campidel form*/
            formRefPrivacy.setFieldsValue({
                agreement_marketing:getAgreement(response.data.attributes.agreementmarketing),
                agreement_profilazione:getAgreement(response.data.attributes.agreementprofilazione)
            });
            ErrorPage(response.status, navigate);
        } catch (error) {
            handleLogError(error); 
        }
        setLoading(false);
    }

    const getAgreement = (agreement) => {
        if(agreement === undefined) return false;
        if(agreement[0] === "true") return true;
        else return false;  
    } 

    useEffect(() => {
        setLoading(true)
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [])

    return (
        <Page inner loading={loading}>
            <Divider orientation="left" orientationMargin="0" style={{ color: '#e61e1e' }}>{t("settings")}</Divider>
            <Row gutter={24}>
                <Col {...ColProps} xl={{ span: 24 }} md={{ span: 20 }} >
                    <Tabs defaultActiveKey="1" onChange={onChangeTab}>
                        <TabPane tab={t("privacypreferences")} key="1">
                            <Form form={formRefPrivacy} name="control-ref-privacypreferences" onFinish={onFinishPrivacy} >
                                <Row>&nbsp;</Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item style={{ marginTop: '-5px' }}
                                            name="agreement_marketing"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                {t("privacymarketing")}
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item style={{ marginTop: '-5px' }}
                                            name="agreement_profilazione"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                {t("privacyprofile")}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>&nbsp;</Row>
                                <Row>
                                    <Col
                                        span={12}
                                        style={{
                                            textAlign: 'right',
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit">
                                            {t("save")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </TabPane>
                        <TabPane tab={t("changepassword")} key="2">
                            <Form form={formRefChangepassword} layout="vertical" name="control-ref-changepassword" onFinish={onFinishChangepassword} >
                          
                                <Row><Text>È richiesta una password complessa. La lunghezza deve essere compresa tra 8 e 256 caratteri. Evitare nomi e parole comuni e usare una combinazione di lettere maiuscole e minuscole, numeri e simboli.</Text></Row>
                                <Row>&nbsp;</Row>
                                <Row>

                                    <Col span={12}>
                                        <Form.Item
                                            label="Password"
                                            name="password"

                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <Input.Password placeholder="Password*" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col span={12}>
                                        <Form.Item
                                            label={t("confirmPw")}
                                            name="confirm"
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please confirm your password!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password placeholder='Confirm Password*' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>&nbsp;</Row>
                                <Row>
                                    <Col
                                        span={6}
                                        style={{
                                            textAlign: 'right',
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit">
                                            {t("save")}
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>
                        </TabPane>
                        <TabPane tab={t("deactivateuser")} key="3">
                            <Row><Text>Il sistema andrà a rimuovere tutti i dati sensibili dell’utente e storicizzare solamente le informazioni amministrative contabili dell’eventuale account</Text></Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                    <Col
                                        span={12}
                                        style={{
                                            textAlign: 'right',
                                        }}
                                    >
                                        <Button type="primary" onClick={handleDisableUser} htmlType="submit">
                                            {t("deactivateuser")}
                                        </Button>
                                    </Col>
                                </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Page>
    )
}

export default Settings