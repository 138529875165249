import axios from 'axios'
import { config } from '../utils/Constants'

import { useMemo } from 'react';

export const ParameterService = {
    getBonus,
    update,
    save
}

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response;
}, function (error) {
    console.log(error) 
    return error.response;
});

// -- Helper functions
function bearerAuth(token) {
    return `Bearer ${token}`
}

function update(token,data) {
    return instance.put('/tsbadmin/api/parameter/', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
      
  }

  function save(token,data) {
    return instance.post('/tsbadmin/api/parameter/', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
  }

function getBonus(token) {
    return instance.get('/tsbadmin/api/parameter/?nome=bonus', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}



  

