const config = {
    siteName: 'Toshiba Hub',
    logoPath: '/logo/ToshibaHUB.png',
    homeGirl: '/logo/Image-Girl-Home.jpg',
    hub:'/logo/Logo-HUB.png',
    support: '/logo/Icona-support.png',
    fixedHeader: true, // sticky primary layout header
    /* I18n configuration, `languages` and `defaultLanguage` are required currently. */
    i18n: {
        /* Countrys flags: https://www.flaticon.com/packs/countrys-flags */
        languages: [
            {
                key: 'it',
                title: 'Italiano',
                flag: '/italy.png',
            },
            {
                key: 'en',
                title: 'English',
                flag: '/united-kingdom.png',
            }
        ],
        defaultLanguage: 'it'
    }
    
}


export default config;