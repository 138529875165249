/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";

import List from "./Components/List";
import Page from "../../Components/Page/Page";
import { handleLogError, ErrorPage } from "../../Helpers/Helpers";
import { useTranslate } from "../../Translate";
import { FaHistory } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CreditsService } from "../../Service/CreditsService";
import { AccountsService } from "../../Service/AccountsService";
import DropOption from "../../Components/DropOption/DropOption";
import Filter from "./Components/FilterGroups";
import { Select } from "antd";
import { isMove_credits } from "../../Helpers/Helpers";
import { Excel } from "antd-table-saveas-excel";

const { Option } = Select;

const Credits = (props) => {
  const { type } = props;
  const i18n = useTranslate();
  const { t } = i18n;
  const [credits, setCredits] = useState([]);
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState(false);
  const pathDetails =
    type == "admin"
      ? "/admin/credits/ordershistory/"
      : "/credits/ordershistory/";
  const [groups, setGroups] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const columns = [
    {
      title: t("group"),
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: t("vatnumber"),
      dataIndex: "companyVatNumber",
      key: "companyVatNumber",
    },
    {
      title: t("balance"),
      dataIndex: "saldo",
      key: "saldo",
      render: (text, record) => (
        <>
          {record.saldo != null
            ? new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(record.saldo)
            : new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(0)}
        </>
      ),
    },
    {
      title: t("orderhistory"),
      dataIndex: "orderhistory",
      key: "orderhistory",
      align: "center",
      width: "9%",
      render: (text, record) => (
        <>
          <Link to={{ pathname: `${pathDetails}` + record.accountId }}>
            <FaHistory
              title={t("goorderhistory")}
              style={{ fontSize: "16px" }}
            />
          </Link>
        </>
      ),
    },
    {
      title: t("operation"),
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: "9%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            // menuOptions={[
            //   { key: '1', name: t("topupcredit") },
            //   { key: '2', name: t("addbonus"),disabled: type ==="admin" ? false:true },
            //   { key: '3', name:t("addcharge"), disabled:type ==="admin" ? false:true},
            //   { key: '4', name: t("movethecredit"),disabled: type ==="admin" ? false:true },

            // ]}
            menuOptions={
              type === "admin"
                ? [
                    { key: "1", name: t("topupcredit") },
                    { key: "2", name: t("addbonus") },
                    { key: "3", name: t("addcharge") },
                    { key: "4", name: t("movethecredit") },
                  ]
                : type === undefined && isMove_credits(keycloak)
                ? [
                    { key: "1", name: t("topupcredit") },
                    { key: "4", name: t("movethecredit") },
                  ]
                : [{ key: "1", name: t("topupcredit") }]
            }
          />
        );
      },
    },
  ];

  const onFilterChange = (filter) => {
    setfilteredData(filter);
  };

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      // navigate('/credits/order',{state:{accountid:record.accountId,companyname:record.companyName}});
      type === "admin"
        ? navigate("/admin/credits/order/a" + record.accountId)
        : navigate("/credits/order/a" + record.accountId);
    } else if (e.key === "2") {
      navigate("/admin/credits/bonus/a" + record.accountId);
    } else if (e.key === "3") {
      navigate("/admin/credits/charge/a" + record.accountId);
    } else if (e.key === "4") {
      type === "admin"
        ? navigate("/admin/credits/move/a" + record.accountId)
        : navigate("/credits/move/a" + record.accountId);
    }
  };

  const getData = async () => {
    try {
      /*carico la lookup degli accounts*/
      const lk_accounts = [];
      const response_accounts = await AccountsService.getLookup();
      console.log(response_accounts);
      response_accounts.data.map((account) => {
        if (type == "admin") {
          lk_accounts.push(
            <Option value={account[0]} key={account[0]}>
              {account[1].trim()}
            </Option>
          );
        } else if (
          keycloak.tokenParsed.accounts.indexOf(account[0]) != -1 &&
          type != "admin"
        ) {
          lk_accounts.push(
            <Option value={account[0]} key={account[0]}>
              {account[1].trim()}
            </Option>
          );
        }
        return true;
      });
      setGroups(lk_accounts);
      let filterAccount = "";
      //let filterAccount =keycloak.tokenParsed.accounts.toString();
      if (filteredData.groups != undefined) {
        filterAccount = filteredData.groups;
      }
      console.log(filterAccount);
      const response = await CreditsService.getCredits(
        keycloak.token,
        filterAccount,
        keycloak.tokenParsed.sub.toString(),
        type
      );
      setCredits(response.data);
      setLoading(false);
      if (response.status == 500 || response.status == 0) {
        navigate("/error/500");
      } else {
        console.log(response);
        if (response.status == 403) {
          navigate("/error/403");
        } else if (response.status == 401) {
          navigate("/error/401");
        } else if (response.status == 404) {
          navigate("/error/404");
        }
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, [filteredData, type]);

  const onHistoryItem = (id) => {
    navigate("/credits/history/" + id);
  };

  const columnsExp = [
    {
      title: t("group"),
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: t("vatnumber"),
      dataIndex: "companyVatNumber",
      key: "companyVatNumber",
    },
    {
      title: t("balance"),
      dataIndex: "saldo",
      key: "saldo",
      render: (text, record) => (
        <>
          {record.saldo != null
            ? new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(record.saldo)
            : new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
              }).format(0)}
        </>
      ),
    }
  ];

  const handleClickExport = () => {
    const excel = new Excel();
    excel
      .addSheet("credits")
      .addColumns(columnsExp)
      .addDataSource(credits, {
        str2Percent: true,
      })
      .saveAs("export_credits.xlsx");
  };

  const navigate = useNavigate();
  return (
    <Page inner>
      <Filter onFilterChange={onFilterChange} groups={groups} />
      <List
        onHistoryItem={onHistoryItem}
        loading={loading}
        columns={columns}
        data={credits}
        handleClickExport={handleClickExport}
        exportflag={type === "admin" ? true : false}
      />
    </Page>
  );
};

export default Credits;
