import React, { useEffect, useState } from "react";
import Parser from "html-react-parser";
import {
  Badge,
  Descriptions,
  Button,
  Col,
  Row,
  Space,
  Modal,
  Checkbox,
} from "antd";
import Page from "../../Components/Page/Page";
import { useTranslate } from "../../Translate";
import { ShoppingCartOutlined } from "@ant-design/icons";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  buyPreview,
  clearPreview,
  buyApp,
} from "../../redux/reducers/store-detail-reducer";
import { useKeycloak } from "@react-keycloak/web";
import { Link, useNavigate } from "react-router-dom";
import { openNotificationWithIcon } from "../../Helpers/Helpers";
import { lkPricingConsumo, lkPricingAttivazione } from "../App/UtilsApp";
const { confirm, info } = Modal;

const BuyPreview = (props) => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  let arrPath = pathname.split("/");
  const code = arrPath[3].split("@");
  const appid = code[0];
  const groupid = code[1];
  const { keycloak } = useKeycloak();
  const userid = keycloak?.tokenParsed?.sub?.toString();
  //gestione lingua
  const i18n = useTranslate();
  const { t } = i18n;
  // set up dispatch
  const dispatch = useDispatch();
  // fetch data dal nostro store redux
  const { loading, preview, error } = useSelector((state) => state.appDetail);
  const [isBuy, setIsBuy] = useState(false);
  const arrPricingAttivazione = lkPricingAttivazione(t);
  const arrPricingConsumo = lkPricingConsumo(t);
  useEffect(() => {
    if (JSON.stringify(preview) !== JSON.stringify({})) return;
    dispatch(buyPreview(keycloak.token, userid, appid, groupid));
  }, []);

  //hook to fetch items
  useEffect(() => {
    getNotice(error);
  }, [error]);

  const handleBack = () => {
    dispatch(clearPreview());
    navigate("/store");
  };
  const handleBuy = () => {
    if (preview.condizioniAcquisto !== "" && isBuy === false) {
      info({
        title:
          "Per proseguire con l'acquisto del servizio bisogna accettare i termini e le condizioni d'utilizzo.",
      });
      return false;
    }
    confirm({
      title: t("questionbuyapp"),
      onOk() {
        dispatch(buyApp(keycloak.token, userid, appid, groupid));
      },
    });
  };

  const getNotice = (response) => {
    if (response.status) {
      if (response.code == 400 || response.code == 404) {
        openNotificationWithIcon(
          "warning",
          "Warning",
          <>{response.message ? response.message : ""}</>
        );
      } else if (response.code == 401) {
        openNotificationWithIcon(
          "warning",
          "Warning",
          response.message ? response.message : ""
        );
      } else {
        openNotificationWithIcon(
          "error",
          "Errore",
          t("errorapp") + "\n" + response.message ? response.message : ""
        );
      }
    } else {
      if (response.code === 200) {
        navigate("/store/buy-succefully");
      }
    }
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setIsBuy(e.target.checked);
  };

  const goLink = () => {
    if (
      preview.condizioniAcquisto.search("http://") == -1 &&
      preview.condizioniAcquisto.search("https://") == -1
    ) {
      window.open("https://" + preview.condizioniAcquisto, "_blank");
    } else {
      window.open(preview.condizioniAcquisto, "_blank");
    }
  };
  return (
    <Page loading={loading} inner>
      <Row gutter={[16, 16]} style={{ border: "0px solid" }}>
        <Col span={24}>
          <Badge.Ribbon text="Preview" color="red">
            <Descriptions title={t("purchasepreview")} bordered>
              <Descriptions.Item label={t("app")}>
                {preview.titolo}
              </Descriptions.Item>
              <Descriptions.Item label={t("author")}>
                {preview.autore}
              </Descriptions.Item>
              <Descriptions.Item label={t("description")}>
                {preview.descrizione !== undefined
                  ? Parser(preview.descrizione)
                  : preview.descrizione}
              </Descriptions.Item>
              <Descriptions.Item label={t("state")} span={3}>
                {preview.canBuy ? (
                  <Badge
                    status="processing"
                    text={t("purchasable")}
                    color="green"
                  />
                ) : (
                  <Badge
                    status="processing"
                    text={t("notpurchasable")}
                    color="red"
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label={t("price")}>
                {preview.prezzo != null &&
                preview.costoAttivazioneIniziale != null
                  ? new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(preview.prezzo + preview.costoAttivazioneIniziale)
                  : new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(0)}
              </Descriptions.Item>
              <Descriptions.Item label={t("currentbalance")}>
                {preview.saldoAttuale != null
                  ? new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(preview.saldoAttuale)
                  : new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(0)}
              </Descriptions.Item>
              <Descriptions.Item label={t("finalbalance")}>
                {preview.saldoAcquisto != null
                  ? new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(preview.saldoAcquisto)
                  : new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(0)}
              </Descriptions.Item>
              <Descriptions.Item label="Info Pricing" span={4}>
                <p>
                  <span>
                    <b>{t("pricingAttivazione")}: </b>
                    {arrPricingAttivazione[preview.pricingAttivazione]}
                  </span>
                </p>
                <p>
                  <span>
                    <b>{t("price")}: </b>{" "}
                    {preview.prezzo != null
                      ? new Intl.NumberFormat("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        }).format(preview.prezzo)
                      : new Intl.NumberFormat("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        }).format(0)}
                  </span>
                </p>
                <p>
                  <span>
                    <b>{t("activationprice")}: </b>{" "}
                    {preview.costoAttivazioneIniziale != null
                      ? new Intl.NumberFormat("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        }).format(preview.costoAttivazioneIniziale)
                      : new Intl.NumberFormat("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        }).format(0)}
                  </span>
                </p>
                <p>
                  <span>
                    <b>{t("period")}: </b>
                    {preview.periodo}
                  </span>
                </p>
                <p>
                  <span>
                    <b>{t("pricingConsumo")}: </b>
                    {arrPricingConsumo[preview.pricingConsumo]}
                  </span>
                </p>
                {parseInt(preview.maxUtenti) > 0 ? (
                  <p>
                    <span>
                      <b>{t("maxusers")}: </b>
                      {preview.maxUtenti}
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </Descriptions.Item>
            </Descriptions>
          </Badge.Ribbon>
        </Col>
        <Col span={24} style={{ border: "0px solid", textAlign: "right" }}>
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
            }}
          >
            {preview.condizioniAcquisto !== "" ? (
              <>
                <Checkbox onChange={onChange}></Checkbox>&nbsp;
                <a onClick={goLink}>Termini e condizioni</a>
              </>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
          <Space>
            <Button onClick={handleBack}>{t("cancel")}</Button>
            {preview.canBuy ? (
              <Button
                type="primary"
                onClick={handleBuy}
                icon={<ShoppingCartOutlined />}
              >
                {t("confirmpurchase")}
              </Button>
            ) : (
              ""
            )}
          </Space>
          {/* </div> */}
        </Col>
      </Row>
    </Page>
  );
};

export default BuyPreview;
