/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React from "react";
import { Button, Row, Col, DatePicker, Form, Input, Select } from "antd";
import { useTranslate } from "../../../Translate";
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const ColProps = {
  xs: 24,
  sm: 12,
  style: {
    marginBottom: 16,
  },
};

const TwoColProps = {
  ...ColProps,
  xl: 96,
};

const Filter = ({ onFilterChange, type, groups }) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const formRef = React.createRef();
  /*eventuale normalizzazione dei dati tipo la data*/
  const handleFields = (fields) => {
    return fields;
  };
  const handleSubmit = () => {
    const values = formRef.current.getFieldsValue();
    const fields = handleFields(values);
    onFilterChange(fields);
  };
  /*funzione per il reset del form*/
  const handleReset = () => {
    //ottengo tutti i campi del form con i rispettivi valori
    const fields = formRef.current.getFieldsValue();
    for (let item in fields) {
      if ({}.hasOwnProperty.call(fields, item)) {
        if (fields[item] instanceof Array) {
          fields[item] = [];
        } else {
          fields[item] = undefined;
        }
      }
    }
    formRef.current.setFieldsValue(fields);
    onFilterChange([]);
  };
  let initialCreateTime = [];

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };
  return (
    <Form
      ref={formRef}
      name="control-ref"
      initialValues={{ createTime: initialCreateTime }}
    >
      <Row gutter={24}>
        <Col {...ColProps} xl={{ span: 3 }} md={{ span: 6 }}>
          <Form.Item name="search">
            <Search placeholder={t("search")} onSearch={handleSubmit} />
          </Form.Item>
        </Col>
        {type == "admin" ? (
          <Col {...ColProps} xl={{ span: 3 }} md={{ span: 6 }} id="lkgroups">
            <Form.Item name="groups">
              <Select
                style={{ width: "100%" }}
                showSearch
                autoComplete="none"
                placeholder={t("selectgroup")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {groups}
              </Select>
            </Form.Item>
          </Col>
        ) : (
          ""
        )}
        <Col {...ColProps} xl={{ span: 3 }} md={{ span: 6 }} id="lkstates">
          <Form.Item name="status">
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder={t("selectstatus")}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Option value="0">{t("paid")}</Option>
              <Option value="1">{t("waitingforpayment")}</Option>
              <Option value="2">{t("draft")}</Option>
              <Option value="3">{t("canceled")}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col
          {...ColProps}
          xl={{ span: 6 }}
          md={{ span: 10 }}
          sm={{ span: 12 }}
          id="dataInserimento"
          style={{ border: "0px solid" }}
        >
          <Form.Item
            //initialValue={moment(null)}
            name="dataInserimento"
            label={t("orderdate")}
          >
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col
          {...ColProps}
          xl={{ span: 6 }}
          md={{ span: 10 }}
          sm={{ span: 12 }}
          id="dataPagamento"
          style={{ border: "0px solid" }}
        >
          <Form.Item name="dataPagamento" label={t("paymentdate")}>
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col
          {...TwoColProps}
          xl={{ span: 3 }}
          md={{ span: 6 }}
          sm={{ span: 8 }}
        >
          <Row
            gutter={24}
            type="flex"
            align="middle"
            justify="space-between"
            style={{ border: "0px solid" }}
          >
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "16px" }}
                onClick={handleSubmit}
              >
                {t("search")}
              </Button>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
