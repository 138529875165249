import React from 'react'
import styles from './Bread.module.less'
import { Breadcrumb } from 'antd'
import {Link,useLocation} from "react-router-dom";



const Bread = () => {
 const location = useLocation ();
  const generateBreadcrumbs= () =>{
    const {pathname} = location;
    const pathnames = pathname.split('/').filter((item)=>item);
    const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    return (
      <>
         {pathnames.length > 0 ? (
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          )}
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            return isLast ? (
              <Breadcrumb.Item key={index}>{capatilize(name)}</Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item key={index}> 
                {(name === "admin" || name ==="team") ? capatilize(name) : <Link to={`${routeTo}`}>{capatilize(name)}</Link>} 
              </Breadcrumb.Item>
            );
          })}
      </>
    )
  }
  return (
    <Breadcrumb className={styles.bread}>
        {generateBreadcrumbs()}
    </Breadcrumb>
  )
}

export default Bread