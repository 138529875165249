/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useState, useEffect } from "react";
import Page from "../../Components/Page/Page";
import { useKeycloak } from "@react-keycloak/web";
import { UploadOutlined } from "@ant-design/icons";
import { Divider, Button, Form, Input, Select, Upload } from "antd";
import { useTranslate } from "../../Translate";
import { useNavigate, useParams } from "react-router-dom";
import { openNotificationWithIcon, getBase64 } from "../../Helpers/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../redux/reducers/document-reducer";

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};

const Document = (props) => {
  const { type,docstype } = props;
  const [fileList, setFileList] = useState([]);
  const [state, setState] = useState({
    loading: false,
  });

  //gestione della lingua
  const i18n = useTranslate();
  const { t } = i18n;
  var { id } = useParams();
  var appId= null;
  if(docstype !==undefined){
    const arrayIds = id.split('@');
    // eslint-disable-next-line no-const-assign
    const idBuy=parseInt(arrayIds[1]);
    appId= parseInt(arrayIds[0]);
    id = idBuy;
  }
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const userid = keycloak?.tokenParsed?.sub?.toString();
  // fetch data from our store
  const { loading, error,document  } = useSelector((state) => state.documents);

  const handleBack = () => {
    if(docstype !=undefined){
      navigate("/myservices/"+ appId +"@"+id+"/cs/documents");
    }else{
      navigate("/admin/services/" + id + "/documents");
    }
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />}> {t("uploadfile")}</Button>
  );

  const onFinish = (data) => {
    handleSendSave(data);
  };

  const handleSendSave = async (data) => {
    if(docstype !==undefined){
      data["appOwnedId"] = id;
    }else{
      data["appId"] = id;
    }
    data["fileData"] = state.filebase64;
    data["nome"]=state.filename;
    dispatch(uploadFile(keycloak.token, userid, data,docstype));
  };

  const handleChange = async (info) => {
    let filebase64 = "";
    let filename = "";
    let fileList = [...info.fileList];
    setFileList(fileList);
    if (info.file.status != "removed") {
      filebase64 = await getBase64(fileList[0].originFileObj);
      filename = fileList[0].name;
      setState({
        filebase64,
        filename,
        loading: false,
      });
    }
  };

  const handleBefore = (file) => {
    return false;
  };

  const handleRemoveFile = () => {
    setFileList([]);
    setState([]);
  };

  const getNotice = (response) => {
    if (response.status) {
      if (response.code !== 200) {
      
        openNotificationWithIcon(
          "error",
          "Errore",
          t("errorapp") + "\n" + response.message ? response.message : ""
        );
      
    }
}
  };
  //hook to fetch items
  useEffect(() => {
    getNotice(error);
  }, [error]);

  useEffect(() => {
    if (JSON.stringify(document) !== JSON.stringify({})){
    var msg = t("msgsavefile");
    openNotificationWithIcon("success", "Success", msg);
    handleBack();
    }
  }, [document]);

  return (
    <Page loading={loading} inner>
      <>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          {t("uploaddocument")}
        </Divider>
        <Form
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="news"
          onFinish={onFinish}
          scrollToFirstError
        >
          {/* <Form.Item label={t("name")} name="nome">
            <Input />
          </Form.Item> */}
          <Form.Item label={t("description")} name="descrizione" rules={[
                      {
                        required: true,
                        message: t("ckDescription"),
                      },
                    ]}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="fileData" label="File" rules={[
                      {
                        required: true,
                        message: t("ckFile"),
                      },
                    ]}>
            <Upload
              onChange={handleChange}
              beforeUpload={handleBefore}
              onRemove={handleRemoveFile}
              fileList={fileList}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item></Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              {t("save")}
            </Button>
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={handleBack}
            >
              {t("cancel")}
            </Button>
          </Form.Item>
        </Form>
      </>
    </Page>
  );
};

export default Document;
