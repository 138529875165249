import React, { useEffect, useState } from "react";
import { UsersService } from "../../Service/UsersService";
import { AccountsService } from "../../Service/AccountsService";
import { ProfilesService } from "../../Service/ProfilesService";
import { useKeycloak } from "@react-keycloak/web";
import {
  Divider,
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Switch,
  notification,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import Page from "../../Components/Page/Page";
import { handleLogError, ErrorPage } from "../../Helpers/Helpers";
import { useTranslate } from "../../Translate";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};

const User = (props) => {
  //gestione della lingua
  const i18n = useTranslate();
  const { t } = i18n;
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState([]);
  const [groups_children, setGroups_children] = useState([]);
  const [groups_children_dealer, setGroups_children_dealer] = useState([]);
  const [profiles_children, setProfiles_children] = useState([]);
  const [dealerappartenenzadisabled, setDealerappartenenzadisabled] =
    useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [enabled, setEnabled] = useState(false);
  const [form] = Form.useForm();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const { type } = props;

  const onFinish = (values) => {
    handleSendData(values);
  };

  const openNotificationWithIcon = (type, msg, description) => {
    notification[type]({
      message: msg,
      description: description,
    });
  };

  const handleSendData = async (data) => {
    try {
      setLoading(true);

      if (id === undefined || id === "new") {
        delete data["confirm"];
      }

      console.log(data);
      const response =
        id === undefined || id === "new"
          ? await UsersService.newUsers(keycloak.token, data)
          : await UsersService.update(keycloak.token, id, data);

      if (response.status === 201) {
        openNotificationWithIcon(
          "success",
          response.data.type,
          response.data.messaggio
        );
        type === "admin"
          ? navigate("/admin/accounts")
          : navigate("/team/accounts");
      } else if (response.status === 400) {
        openNotificationWithIcon(
          "warning",
          response.data.type,
          response.data.messaggio
        );
      } else {
        ErrorPage(response.status, navigate);
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const handleChangeGroups = (value) => {
    //  console.log(`selected ${value}`);
  };

  const handleChangeProfiles = (value) => {
    console.log(`selected ${value}`);
  };

  var sel_accounts = [];
  var sel_accounts_dealer = null;
  var sel_profiles = "";
  const getData = async () => {
    var filteredData = {};
    var attributes = [];

    try {
      if (id !== "new" && id !== undefined) {
        filteredData["type"] = type;
        const response = await UsersService.getUserById(
          keycloak.token,
          id,
          filteredData
        );
        if (response.status === 500 || response.status === 0) {
          navigate("/error/500");
        } else {
          if (response.status === 403) {
            navigate("/error/403");
          } else if (response.status === 401) {
            navigate("/error/401");
          }
        }
        setUser(response.data);

        //recupero le proprietà custom dell'user
        attributes = response.data.attributes;
        let lk_accounts = [];
        //popolo la lookup degli accounts
        response.data.entityaccounts.map((account) => {
          lk_accounts.push(
            <Option value={account.id} key={account.id}>
              {account.companyname.trim()}
            </Option>
          );
          return true;
        });
        setGroups_children(lk_accounts);

        lk_accounts.forEach((account) => {
          if (attributes.accounts !== undefined) {
            attributes.accounts.forEach((key) => {
              if (key === account.key) {
                sel_accounts.push(account.props.value);
              }
            });
          }
        });

        let lk_accounts_dealer = [];
        // Filtra gli oggetti dove dealer è true
        const dealerAziende = response.data.entityaccounts.filter(
          (azienda) => azienda.dealer === true
        );
        dealerAziende.map((account) => {
          lk_accounts_dealer.push(
            <Option value={account.id} key={account.id}>
              {account.companyname.trim()}
            </Option>
          );
          return true;
        });
        setGroups_children_dealer(lk_accounts_dealer);
        lk_accounts_dealer.forEach((account) => {
          if (attributes.dealerAccount !== undefined) {
            attributes.dealerAccount.forEach((key) => {
              if (key === account.key) {
                sel_accounts_dealer = account.props.value;
              }
            });
          }
        });
        //pololo la lookup dei profili
        let lk_profiles = [];
        response.data.entitygroups.forEach((group) => {
          lk_profiles.push(
            <Option value={group.id} key={group.id}>
              {group.name.trim()}
            </Option>
          );
        });
        setProfiles_children(lk_profiles);
        response.data.groupsbyuser.map((group) => {
          sel_profiles = group.id;
          return true;
        });
        setEnabled(response.data.enabled);

        setDealerappartenenzadisabled(
          // eslint-disable-next-line eqeqeq
          getAgreement(response.data.attributes.dealer) == 1 ? true : false
        );
        /*setto i campidel form*/
        form.setFieldsValue({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          username: response.data.username,
          profiles: sel_profiles,
          groups: sel_accounts,
          enabled: response.data.enabled,
          dealerAccount: sel_accounts_dealer,
          dealer: getAgreement(response.data.attributes.dealer),
          agreement_commerciale: getAgreement(
            response.data.attributes.agreementcommerciale
          ),
          agreement_condizionigenerali: getAgreement(
            response.data.attributes.agreementcondizionigenerali
          ),
          agreement_marketing: getAgreement(
            response.data.attributes.agreementmarketing
          ),
          agreement_profilazione: getAgreement(
            response.data.attributes.agreementprofilazione
          ),
        });
        ErrorPage(response.status, navigate);
      } else {
        /*carico la lookup degli accounts*/
        const lk_accounts = [];
        const response_accounts = await UsersService.accountsByUserId(
          keycloak.token,
          type
        );
        console.log(response_accounts.data);
        response_accounts.data.map((account) => {
          lk_accounts.push(
            <Option value={account.id} key={account.id}>
              {account.companyname.trim()}
            </Option>
          );
          return true;
        });
        setGroups_children(lk_accounts);
        ErrorPage(response_accounts, navigate);
        /*carico le lk dei profili*/
        var lk_profiles_ = [];
        const response_profiles = await UsersService.profilesByUserId(
          keycloak.token,
          type
        );
        response_profiles.data.map((profile) => {
          lk_profiles_.push(
            <Option value={profile.id} key={profile.id}>
              {profile.name.trim()}
            </Option>
          );
          return true;
        });
        setProfiles_children(lk_profiles_);
        ErrorPage(response_profiles, navigate);
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleBack = () => {
    type === "admin" ? navigate("/admin/accounts") : navigate("/team/accounts");
  };

  const getAgreement = (agreement) => {
    if (agreement === undefined) return false;
    if (agreement[0] === "true") return true;
    else return false;
  };

  const handleChangeDealer = (checked) => {
    setDealerappartenenzadisabled(checked ? true : false);
    if (checked) {
      form.setFieldsValue({ dealerAccount: undefined });
    }
  };

  return (
    <Page loading={loading} inner>
      <>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          Account
        </Divider>
        <Form
          style={{ border: "0px solid" }}
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="user"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            label={t("firstName")}
            name="firstName"
            rules={[
              {
                required: true,
                message: t("ckFirstName"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("lastName")}
            name="lastName"
            // style={{ justifyContent: 'center' }}
            rules={[
              {
                required: true,
                message: t("ckLastName"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Username"
            hidden={id != null && id !== "new" ? false : true}
            name="username"
          >
            <Input disabled={true} placeholder="Username*" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                pattern: "^([-]?[1-9][0-9]*|0)$",
                message: t("patternEmail"),
              },
              {
                required: true,
                message: t("ckEmail"),
              },
            ]}
          >
            <Input placeholder="Email*" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            hidden={id != null && id !== "new" ? true : false}
            rules={[
              {
                required: id != null ? false : true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Password*" />
          </Form.Item>

          <Form.Item
            label={t("confirmPw")}
            name="confirm"
            hidden={id != null && id !== "new" ? true : false}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: id != null ? false : true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password*" />
          </Form.Item>

          <Form.Item
            hidden={type === "admin" ? false : true}
            valuePropName="checked"
            name="enabled"
            label={t("userEnabled")}
          >
            <Switch onChange={onChange} />
          </Form.Item>
          <Form.Item
            hidden={type === "admin" ? false : true}
            valuePropName="checked"
            name="dealer"
            label="Dealer"
          >
            <Switch onChange={handleChangeDealer} />
          </Form.Item>

          {type ==="admin" ? <Form.Item label={t("dealerappartenenza")} name="dealerAccount">
            <Select
              // disabled={dealerappartenenzadisabled ? true : false}
              autoComplete
              showSearch
              style={{
                width: "100%",
              }}
              placeholder={t("dealerappartenenza")}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase().trim())
              }
              optionFilterProp="children"
              defaultValue={sel_accounts_dealer}
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .includes(input.toLowerCase().trim())
              }
              allowClear
            >
              {groups_children_dealer}
            </Select>
          </Form.Item> : ""}
          <Form.Item
            label={t("group")}
            name="groups"
            rules={[
              {
                required: true,
                message: "Inserisci il gruppo",
              },
            ]}
          >
            <Select
              mode="multiple"
              autoComplete="none"
              showSearch
              style={{
                width: "100%",
              }}
              onChange={handleChangeGroups}
              placeholder="Gruppo*"
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase().trim())
              }
              optionFilterProp="children"
              defaultValue={sel_accounts}
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .includes(input.toLowerCase().trim())
              }
              allowClear
            >
              {groups_children}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("profile")}
            name="profiles"
            rules={[
              {
                required: true,
                message: "Inserisci il profilo",
              },
            ]}
          >
            <Select
              autoComplete="none"
              showSearch
              style={{
                width: "100%",
              }}
              onChange={handleChangeProfiles}
              placeholder="Profilo*"
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              optionFilterProp="children"
              defaultValue={sel_profiles}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {profiles_children}
            </Select>
          </Form.Item>
          <Form.Item
            name="agreement_commerciale"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("Devi accettare il consenso")),
              },
            ]}
          >
            {id === "new" || id === undefined ? (
              <Checkbox>
                Consenso al trattamento dei dati con finalità commerciale*
              </Checkbox>
            ) : (
              <Checkbox disabled>
                Consenso al trattamento dei dati con finalità commerciale*
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item
            name="agreement_condizionigenerali"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("Devi accettare il consenso")),
              },
            ]}
          >
            {id === "new" || id === undefined ? (
              <Checkbox>
                {" "}
                Consenso esplicito clausole condizioni generali*
              </Checkbox>
            ) : (
              <Checkbox disabled>
                {" "}
                Consenso esplicito clausole condizioni generali*
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item
            style={{ marginTop: "-5px" }}
            name="agreement_marketing"
            valuePropName="checked"
          >
            <Checkbox>
              Consenso al trattamento dei dati con finalità marketing
            </Checkbox>
          </Form.Item>

          <Form.Item
            style={{ marginTop: "-5px" }}
            name="agreement_profilazione"
            valuePropName="checked"
          >
            <Checkbox>
              Consenso al trattamento dei dati con finalità di profilazione
            </Checkbox>
          </Form.Item>
          <Form.Item
            {...tailFormItemLayout}
            name="btnsalva"
            style={{ border: "0px solid" }}
          >
            <Button type="primary" htmlType="submit">
              {t("save")}
            </Button>
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={handleBack}
            >
              {t("cancel")}
            </Button>
          </Form.Item>
        </Form>
      </>
    </Page>
  );
};

export default User;
