import { useTranslate } from "../../../Translate";
import { lkPricingConsumo, lkPricingAttivazione,lkTypeBalance } from "../../App/UtilsApp";
import { BiTransfer } from 'react-icons/bi';
import { useNavigate} from "react-router-dom";


export const ColumnsListTransferExport = (applk,type) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const arrPricingAttivazione = lkPricingAttivazione(t);
  const arrPricingConsumo= lkPricingConsumo(t);
  const arrType= lkTypeBalance(t);
 
  let columns = [
    {
      title: t("group"),
      dataIndex: "companyName",
      key: "companyName"
    },
    {
      title: t("app"),
      dataIndex: "appId",
      key: "appId",
      render: (value, row) => {
        
        return applk.length > 0 && applk.filter(element => element.appId === value).length >0 ? applk.filter(element => element.appId === value)[0].appName : ""
      }
    },
    {
      title: t("ordernumber"),
      dataIndex: "orderId",
      key: "orderId"
    },
    {
      title: t("causal"),
      dataIndex: "causale",
      key: "causale"
    },
    {
      title: t("creationdate"),
      dataIndex: "dataInserimento",
      key: "dataInserimento",
      render: (text, record) => (
        <>
          {record.dataInserimento !== null && record.dataInserimento != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.dataInserimento))
            : ""}
        </>
      ),
    },
    {
      title: t("amountcharged"),
      dataIndex: "importoSottratto",
      key: "importoSottratto",
      render: (text,record) => (
        <>
        {record.importoSottratto != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.importoSottratto)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("amountcredited"),
      dataIndex: "importoAggiunto",
      key: "importoAggiunto",
      render: (text,record) => (
        <>
        {record.importoAggiunto != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.importoAggiunto)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("pricingAttivazione"),
      dataIndex: "pricingAttivazione",
      key: "pricingAttivazione",
      // render: (text, record) => <,> {arrPricingAttivazione[record.pricingAttivazione]}</>,
      render: (value, row) =>{return  value !==null ? arrPricingAttivazione[value] : ""},

    },
    {
      title: t("pricingConsumo"),
      dataIndex: "pricingConsumo",
      key: "pricingConsumo",
      render: (value, row) =>{return  value !==null ? arrPricingConsumo[value] : ""},
    },
    {
      title: t("periodfrom"),
      dataIndex: "periodoDataDa",
      key: "periodoDataDa",
      render: (text, record) => (
        <>
          {record.periodoDataDa !== null && record.periodoDataDa != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.periodoDataDa))
            : ""}
        </>
      ),
    },
    {
      title: t("periodto"),
      dataIndex: "periodoDataA",
      key: "periodoDataA",
      render: (text, record) => (
        <>
          {record.periodoDataA !== null && record.periodoDataA != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.periodoDataA))
            : ""}
        </>
      ),
    },
    {
      title: t("openingbalance"),
      dataIndex: "saldoIniziale",
      key: "saldoIniziale",
      render: (text,record) => (
        <>
        {record.saldoIniziale != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.saldoIniziale)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("finalbalance"),
      dataIndex: "saldoFinale",
      key: "saldoFinale",
      render: (text,record) => (
        <>
        {record.saldoFinale != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.saldoFinale)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("operationtype"),
      dataIndex: "tipoOperazione",
      key: "tipoOperazione",
      render: (value, row) =>{return  value !==null ? arrType[value] : ""},
    },
    

  ];
  return columns;
}  


export const ColumnsListTransfer = (applk,type) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const navigate = useNavigate();
  const arrPricingAttivazione = lkPricingAttivazione(t);
  const arrPricingConsumo= lkPricingConsumo(t);
  const arrType= lkTypeBalance(t);
  const handleStorno = (record, e) => {
    
          navigate("/admin/consumption/"+record.id)
       
  }
  let columns = [
    {
      title: t("group"),
      dataIndex: "companyName",
      key: "companyName",
      width: "3%",
      align: "center",
    },
    {
      title: t("app"),
      dataIndex: "appId",
      key: "appId",
      width: "2%",
      render: (text, record) => <> {applk.length > 0 && applk.filter(element => element.appId === record.appId).length >0 ? applk.filter(element => element.appId === record.appId)[0].appName : ""}</>,
    },
    {
      title: t("ordernumber"),
      dataIndex: "orderId",
      key: "orderId",
      width: "2%",
    },
    {
      title: t("causal"),
      dataIndex: "causale",
      key: "causale",
      width: "5%",
      align: "center",
    },
    {
      title: t("creationdate"),
      dataIndex: "dataInserimento",
      key: "dataInserimento",
      width: "3%",
      align: "center",
      render: (text, record) => (
        <>
          {record.dataInserimento !== null && record.dataInserimento != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.dataInserimento))
            : ""}
        </>
      ),
    },
    {
      title: t("amountcharged"),
      dataIndex: "importoSottratto",
      key: "importoSottratto",
      width: "5%",
      render: (text,record) => (
        <>
        {record.importoSottratto != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.importoSottratto)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("amountcredited"),
      dataIndex: "importoAggiunto",
      key: "importoAggiunto",
      width: "5%",
      render: (text,record) => (
        <>
        {record.importoAggiunto != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.importoAggiunto)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("pricingAttivazione"),
      dataIndex: "pricingAttivazione",
      key: "pricingAttivazione",
      width: "5%",
      render: (text, record) => <> {arrPricingAttivazione[record.pricingAttivazione]}</>,

    },
    {
      title: t("pricingConsumo"),
      dataIndex: "pricingConsumo",
      key: "pricingConsumo",
      width: "5%",
      render: (text, record) => <> {arrPricingConsumo[record.pricingConsumo]}</>,
    },
    {
      title: t("periodfrom"),
      dataIndex: "periodoDataDa",
      key: "periodoDataDa",
      width: "5%",
      render: (text, record) => (
        <>
          {record.periodoDataDa !== null && record.periodoDataDa != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.periodoDataDa))
            : ""}
        </>
      ),
    },
    {
      title: t("periodto"),
      dataIndex: "periodoDataA",
      key: "periodoDataA",
      width: "5%",
      render: (text, record) => (
        <>
          {record.periodoDataA !== null && record.periodoDataA != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.periodoDataA))
            : ""}
        </>
      ),
    },
    {
      title: t("openingbalance"),
      dataIndex: "saldoIniziale",
      key: "saldoIniziale",
      width: "5%",
      render: (text,record) => (
        <>
        {record.saldoIniziale != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.saldoIniziale)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("finalbalance"),
      dataIndex: "saldoFinale",
      key: "saldoFinale",
      width: "5%",
      render: (text,record) => (
        <>
        {record.saldoFinale != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.saldoFinale)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("operationtype"),
      dataIndex: "tipoOperazione",
      key: "tipoOperazione",
      width: "5%",
      render: (text, record) => <> {arrType[record.tipoOperazione]}</>,
    },
    ...(type === "admin" ?
      [
        {
          title: t("operation"),
          dataIndex: 'operation',
          key: 'operation',
          align: 'center',
          visible: false,
          width: '1%',
          render: (text, record) => {
              return (<>
                {record.tipoOperazione === 1 || record.tipoOperazione === 2 ? <BiTransfer color="orange" onClick={e => handleStorno(record, e)} style={{cursor:'pointer'}} title="Storno" fontSize={18}/>: ""}
              </>)
            },
        }
      ]
      :
      [])
   
  ];
  // columns= [];
  return columns;
};




export const ColumnsListConsumption = (applk) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const arrPricingAttivazione = lkPricingAttivazione(t);
  const arrPricingConsumo= lkPricingConsumo(t);
  const arrType= lkTypeBalance(t); 

  let columns = [
    {
      title: t("group"),
      dataIndex: "companyName",
      key: "companyName",
      width: "3%",
      align: "center",
    },
    {
      title: t("app"),
      dataIndex: "appId",
      key: "appId",
      width: "2%",
      render: (text, record) => <> {applk.length > 0 && applk.filter(element => element.appId === record.appId).length >0 ? applk.filter(element => element.appId === record.appId)[0].appName : ""}</>,
    },
    {
      title: t("causal"),
      dataIndex: "causale",
      key: "causale",
      width: "5%",
      align: "center",
    },
    {
      title: t("creationdate"),
      dataIndex: "dataInserimento",
      key: "dataInserimento",
      width: "3%",
      align: "center",
      render: (text, record) => (
        <>
          {record.dataInserimento !== null && record.dataInserimento != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.dataInserimento))
            : ""}
        </>
      ),
    },
    {
      title: t("amountcharged"),
      dataIndex: "importoSottratto",
      key: "importoSottratto",
      width: "5%",
      render: (text,record) => (
        <>
        {record.importoSottratto != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.importoSottratto)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("pricingAttivazione"),
      dataIndex: "pricingAttivazione",
      key: "pricingAttivazione",
      width: "5%",
      render: (text, record) => <> {arrPricingAttivazione[record.pricingAttivazione]}</>,

    },
    {
      title: t("pricingConsumo"),
      dataIndex: "pricingConsumo",
      key: "pricingConsumo",
      width: "5%",
      render: (text, record) => <> {arrPricingConsumo[record.pricingConsumo]}</>,
    },
    {
      title: t("periodfrom"),
      dataIndex: "periodoDataDa",
      key: "periodoDataDa",
      width: "5%",
      render: (text, record) => (
        <>
          {record.periodoDataDa !== null && record.periodoDataDa != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.periodoDataDa))
            : ""}
        </>
      ),
    },
    {
      title: t("periodto"),
      dataIndex: "periodoDataA",
      key: "periodoDataA",
      width: "5%",
      render: (text, record) => (
        <>
          {record.periodoDataA !== null && record.periodoDataA != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.periodoDataA))
            : ""}
        </>
      ),
    },
    {
      title: t("openingbalance"),
      dataIndex: "saldoIniziale",
      key: "saldoIniziale",
      width: "5%",
      render: (text,record) => (
        <>
        {record.saldoIniziale != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.saldoIniziale)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("finalbalance"),
      dataIndex: "saldoFinale",
      key: "saldoFinale",
      width: "5%",
      render: (text,record) => (
        <>
        {record.saldoFinale != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.saldoFinale)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("operationtype"),
      dataIndex: "tipoOperazione",
      key: "tipoOperazione",
      width: "5%",
      render: (text, record) => <> {arrType[record.tipoOperazione]}</>,
    },
  ];
  // columns= [];
  return columns;
};


export const ColumnsListAccredit= () => {
  const i18n = useTranslate();
  const { t } = i18n;
  const arrType= lkTypeBalance(t);
  
  let columns = [
    {
      title: t("group"),
      dataIndex: "companyName",
      key: "companyName",
      width: "3%",
      align: "center",
    },
    {
      title: t("ordernumber"),
      dataIndex: "orderId",
      key: "orderId",
      width: "1%",
    },
    {
      title: t("causal"),
      dataIndex: "causale",
      key: "causale",
      width: "5%",
      align: "center",
    },
    {
      title: t("creationdate"),
      dataIndex: "dataInserimento",
      key: "dataInserimento",
      width: "3%",
      align: "center",
      render: (text, record) => (
        <>
          {record.dataInserimento !== null && record.dataInserimento != ""
            ? new Intl.DateTimeFormat("it-IT", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(record.dataInserimento))
            : ""}
        </>
      ),
    },
    {
      title: t("amountcredited"),
      dataIndex: "importoAggiunto",
      key: "importoAggiunto",
      width: "5%",
      render: (text,record) => (
        <>
        {record.importoAggiunto != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.importoAggiunto)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("openingbalance"),
      dataIndex: "saldoIniziale",
      key: "saldoIniziale",
      width: "5%",
      render: (text,record) => (
        <>
        {record.saldoIniziale != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.saldoIniziale)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("finalbalance"),
      dataIndex: "saldoFinale",
      key: "saldoFinale",
      width: "5%",
      render: (text,record) => (
        <>
        {record.saldoFinale != null
          ? new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.saldoFinale)
          : new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(0)}
                 </>
      ),
    },
    {
      title: t("operationtype"),
      dataIndex: "tipoOperazione",
      key: "tipoOperazione",
      width: "5%",
      render: (text, record) => <> {arrType[record.tipoOperazione]}</>,
    },
  ];
  // columns= [];
  return columns;
};




