/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../Components/Page/Page";
import { useTranslate } from "../../Translate";
import { NumericInput, ErrorPage, handleLogError,openNotificationWithIcon } from "../../Helpers/Helpers";
import { Button, message, Steps, Select, Form, Modal, Divider } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { AccountsService } from "../../Service/AccountsService";
import { CreditsService } from "../../Service/CreditsService";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xl: {
      span: 8,
    },

    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xl: {
      span: 8,
    },
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 10,
      offset: 10,
    },
  },
};

const Bonus = (props) => {
  const { type } = props;
  const i18n = useTranslate();
  const { t } = i18n;
  const { keycloak } = useKeycloak();
  const { path } = useParams();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const types = path.substring(0, 1);
  const id = path.substring(1);

  const handleFinish = (data) => {
    setBonus(data);
  };

  const setBonus = async (data) => {
    setLoading(true);
    try {
      const response = await CreditsService.setBonus(
        keycloak.token,
        data
      );
      ErrorPage(response, navigate);
      console.log(response)
      if (response.status == 200) {
        openNotificationWithIcon('success', "Success", "Bonus inserito con successo!");
        navigate("/admin/credits");
      }  else {
        openNotificationWithIcon('error', "Error", "Errore nella creazione del bonus!");
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      /*carico la lookup degli accounts*/
      const lk_accounts = [];
      const response_accounts = await AccountsService.getLookup();
      response_accounts.data.map((account) => {
        lk_accounts.push(
          <Option value={account[0]} key={account[0]}>
            {account[1].trim()}
          </Option>
        );
        return true;
      });
      setGroups(lk_accounts);
      ErrorPage(response_accounts, navigate);
      form.setFieldsValue({
        accountId: parseInt(id),
      });
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  const handleBack = () => {
    navigate("/admin/credits");
  };
  return (
    <Page loading={loading} inner>
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ color: "#e61e1e" }}
      >
        {t("newbonus")}
      </Divider>
      <Form
        {...formItemLayout}
        form={form}
        layout="vertical"
        name="form-bonus"
        onFinish={handleFinish}
        scrollToFirstError
      >
        <Form.Item label={t("group")} name="accountId">
          <Select
            style={{ width: "100%" }}
            optionFilterProp="children"
            disabled={true}
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {groups}
          </Select>
        </Form.Item>
        <Form.Item label={t("typepayment")} name="tipoOrdine">
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder={t("selecttypepayment")}
            optionFilterProp="children"
            defaultValue={2}
            disabled={true}
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Option value={2}>BONUS</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t("amount")}
          name="imponibileOrdinato"
          rules={[
            {
              required: true,
              message: t("ckAmount"),
            },
          ]}
        >
          <NumericInput />
        </Form.Item>

        <Form.Item
          label={t("causal")}
          name="causale"
          rules={[
            {
              required: true,
              message: t("ckCausal"),
            },
          ]}
        >
          <TextArea></TextArea>
        </Form.Item>
        <Form.Item></Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={handleBack}
          >
            {t("cancel")}
          </Button>
        </Form.Item>
      </Form>
    </Page>
  );
};

export default Bonus;
