let languages = [
    {
        "id": 1,
        "name": "it",
        "description": "LanguageSwitcher.it"
    },
    {
        "id": 2,
        "name": "en",
        "description": "LanguageSwitcher.en"
    }
]

export default languages;