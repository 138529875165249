import { createSlice } from "@reduxjs/toolkit";
import { api, setHeader } from "../../Helpers/Helpers";

// initial state
export const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  app: [],
  groups: [],
  preview: {},
};

// our slice
const appDetailSlice = createSlice({
  name: "appDetail",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setApp: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.app = payload;
    },

    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    setGroups: (state, { payload }) => {
      //state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.groups = payload;
    },
    setPreview: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.preview = payload;
    },

    setOK: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code =payload.status;
      state.preview = {};
    },

    cleanPreview: (state) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.preview = {};
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },
    setOkFavorite:(state,{ payload }) =>{
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = payload.status;
    }
  },
});

// export the actions
export const {
  setLoading,
  setApp,
  setError,
  setGroups,
  setPreview,
  cleanPreview,
  cleanError,
  setOK,
  setOkFavorite
} = appDetailSlice.actions;

// export the default reducer
export default appDetailSlice.reducer;

export const fetchAppDetail =
  (token, userid = "", id) =>
  async (dispatch) => {
    let path = token ? "/tsapps/api/app/" + id : "/tsapps/api/public/app/" + id;
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setApp(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

export const favoriteApp = (token,userid,appid,flag) => async (dispatch) =>{
  //dispatch(setLoading());
  dispatch(cleanError());
  let path = flag ? "/tsapps/api/app/unsetpreferita/"+appid : "/tsapps/api/app/setpreferita/"+appid;
  try {
    const response = await api.post(
      path,[],
      setHeader(token, userid)
    );
    dispatch(setOkFavorite(response));
  } catch (err) {
    dispatch(setError(err.response));
  }

};  
export const fetchGroups = () => async (dispatch) => {
  //dispatch(setLoading());
  dispatch(cleanError());
  try {
    const response = await api.get(
      "/tsbadmin/api/public/accounts-lk",
      setHeader(null, null)
    );
    dispatch(setGroups(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const buyPreview =
  (token, userid = "", appId, groupId) =>
  async (dispatch) => {
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const path = "tsapps/api/app/buypreview/" + appId + "/" + groupId;
      const response = await api.post(path, [], setHeader(token, userid));
      dispatch(setPreview(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };


  export const contactusApp =
  (token,data) =>
  async (dispatch) => {
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const path = "/tsapps/api/app/requestactivation";
      const response = await api.post(path,data, setHeader(token, null));
      dispatch(setOK(response));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };  

export const buyApp =
  (token, userid = "", appId, groupId) =>
  async (dispatch) => {
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const path = "tsapps/api/app/buy/" + appId + "/" + groupId;
      const response = await api.post(path, [], setHeader(token, userid));
      var v = setOK(response);
      dispatch(v);
    } catch (err) {
      var h = setError(err.response);
      dispatch(h);
    }
  };

export const clearPreview = () => async (dispatch) => {
  dispatch(cleanPreview());
};
