import { Button, Result } from 'antd';
import {Link} from "react-router-dom"
import React from 'react';

const RegistrationSuccefully = () => {
    return (
        <Result
        status="success"
        title="Successfully Registration!"
        subTitle="La tua registrazione è in fase di validazione, riceverai una email di conferma non appena l'account sarà attivato!"
        extra={
          <Link to="/">
                <Button type="primary">Back to Home</Button>
            </Link>
        }
    />
      )
}

export default RegistrationSuccefully