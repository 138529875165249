import React, { useState, useEffect } from "react";
import { BsFiletypePdf } from "react-icons/bs";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { Link } from "react-router-dom";
import Page from "../../Components/Page/Page";
import {
  Col,
  Row,
  Divider
} from "antd";


const CookiePolicy = () => {
  return (
    <Page inner>
      <>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          COOKIE POLICY
        </Divider>
        <Row>
          <Col>
           <a href="/file/TOSHIBAHUB-Cookie Policy.pdf" download="cookiepolicy.pdf" title="Download file"><BsFiletypePdf style={{fontSize:'25px'}}/> File cookie policy</a>
          </Col>
        </Row>
      </>
    </Page>
  )
}

export default CookiePolicy