import React, { useEffect, useState } from "react";
import { UsersService } from "../../Service/UsersService";
import { RegistrationService } from "../../Service/RegistrationService";
import { ParameterService } from "../../Service/ParameterService";
import Page from "../../Components/Page/Page";
import { Divider, Row, Col, Form, Input, Select, Button, Result } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslate } from "../../Translate";
import { useKeycloak } from "@react-keycloak/web";
import {
  handleLogError,
  ErrorPage,
  openNotificationWithIcon,
} from "../../Helpers/Helpers";
const { Option } = Select;

const Invitation = () => {
  const { keycloak } = useKeycloak();
  const i18n = useTranslate();
  const { t } = i18n;
  const [bonus, setBonus] = useState([]);
  const formRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [groups_children, setGroups_children] = useState([]);
  const navigate = useNavigate();
  const onFinish = (values) => {
    handleSendData(values);
  };

  const getBonus = async () => {
    setLoading(true);
    try {
      const response = await ParameterService.getBonus(keycloak.token);
      setBonus(response.data);
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  const handleSendData = async (data) => {
    try {
      setLoading(true);
      const response = await RegistrationService.invitation(
        keycloak.token,
        keycloak?.tokenParsed?.sub?.toString(),
        data
      );
      //   setDataRegistration(response.data);
      if (response.status === 201) {
        navigate("/invitation/success");
      } else if (response.status === 200) {
        openNotificationWithIcon(
          "warning",
          response.data.type ? response.data.type : "Warning",
          response.data.messaggio
            ? response.data.messaggio
            : response.data.error
        );
      } else if (response.status === 400) {
        openNotificationWithIcon(
          "error",
          response.data.type ? response.data.type : "Error",
          response.data.messaggio
            ? response.data.messaggio
            : response.data.error
        );
      } else {
        ErrorPage(response.status, navigate);
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  var sel_accounts = [];
  const getData = async () => {
    try {
      /*carico la lookup degli accounts*/
      const lk_accounts = [];
      const response_accounts = await UsersService.accountsByUserId(
        keycloak.token,
        "team"
      );
      console.log(response_accounts.data);
      response_accounts.data.map((account) => {
        lk_accounts.push(
          <Option value={account.id} key={account.id}>
            {account.companyname.trim()}
          </Option>
        );
        return true;
      });
      setGroups_children(lk_accounts);
      ErrorPage(response_accounts, navigate);
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    getBonus();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleReset = (event) => {
    const fields = formRef.current.getFieldsValue();
    for (let item in fields) {
      if ({}.hasOwnProperty.call(fields, item)) {
        if (fields[item] instanceof Array) {
          fields[item] = [];
        } else {
          fields[item] = undefined;
        }
      }
    }
    formRef.current.setFieldsValue(fields);
  };
  return (
    <Page loading={loading} inner>
      <>
        {parseFloat(bonus.valore) > 0 ? (
          <Form ref={formRef} name="control-ref-invitation" onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={24}>
                {" "}
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  style={{ color: "#e61e1e", fontSize: "19px" }}
                >
                  Nuovo Invito
                </Divider>
              </Col>
              <Col span={16}>
                <p style={{ fontSize: "18px" }}>
                  Invita utenti di altre aziende/studi professionali ad
                  iscriversi a Toshiba HUB. Inserisci l'eMail dell'utente che
                  vuoi invitare.
                </p>
              </Col>
            </Row>
            <Row gutter={18} style={{ marginTop: "20px" }}>
              <Col span={8}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: t("patternEmail"),
                    },
                    {
                      required: true,
                      message: t("ckEmail"),
                    },
                  ]}
                >
                  <Input placeholder="Email*" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18} style={{ marginTop: "20px" }}>
              <Col span={16}>
                <p style={{ fontSize: "18px" }}>
                  L'utente riceverà un'eMail di invito all'iscrizione con
                  l'ndicazione della provenienza: il tuo nome e cognome. Se
                  l'utente si iscriverà a Toshiba HUB, riceverai un credito
                  direttamente sul tuo portafoglio/Wallet del gruppo
                  che puoi selezionare di seguito.
                </p>
              </Col>
            </Row>
            <Row gutter={18} style={{ marginTop: "50px" }}>
              <Col span={8}>
                <Form.Item
                  name="groups"
                  rules={[
                    {
                      required: true,
                      message: "Inserisci il gruppo",
                    },
                  ]}
                >
                  <Select
                    autoComplete="none"
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder="Gruppo*"
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase().trim())
                    }
                    optionFilterProp="children"
                    defaultValue={sel_accounts}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase().trim())
                    }
                    allowClear
                  >
                    {groups_children}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "50px" }}>
              <Col
                span={16}
                style={{
                  textAlign: "center",
                }}
              >
                <Button type="primary" htmlType="submit">
                  {t("send")}
                </Button>
                <Button
                  style={{
                    margin: "0px 8px",
                  }}
                  onClick={handleReset}
                >
                  {t("cancel")}
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Result
            status="warning"
            title="Invito"
            subTitle="Nessun bonus è attualmente disponible."
            extra={
              <Link to="/">
                <Button type="primary">Back to Home</Button>
              </Link>
            }
          />
        )}
      </>
    </Page>
  );
};
export default Invitation;
