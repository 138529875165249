/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React from "react";
import { Button, Row, Col, Form, Input, Select } from "antd";
import { useTranslate } from "../../../Translate";
import { useNavigate } from "react-router-dom";



const ColProps = {
  xs: 24,
  sm: 12,
  style: {
    marginBottom: 16,
  },
};

const TwoColProps = {
  ...ColProps,
  xl: 96,
};

const Filter = ({handleUpload}) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const formRef = React.createRef();
  const onClickUpload = () =>{
    handleUpload();
  }
  let type= ""
  return (
    <Form ref={formRef} name="document-ref" >
    <Row gutter={24}>
        
        <Col
            {...TwoColProps}
            xl={{ span: 24 }}
            md={{ span: 16 }}
            sm={{ span: 8 }}
        >
            <Row gutter={24} type="flex" align="middle" justify="end" style={{ border: '0px solid' }}>
                <div>
                    <Button
                        type="primary" htmlType="submit"
                        style={{ marginRight: '16px' }}
                        onClick={onClickUpload}
                    >
                        Upload
                    </Button>
                   
                </div>
            </Row>
        </Col>
    </Row>
</Form>
  );
};

export default Filter;
