const dev = {
  url: {
    KEYCLOAK_BASE_URL: "https://toshibahub-dev.zenshare.it/kc/auth",
    API_BASE_URL: 'https://api-toshiba-dev.zenshare.it',
    CLIENT_URL: 'https://toshibahub-dev.zenshare.it/',

  },
  zen:{KEY: 'gRSYRYRnVAFnvEMx3t8RJmNZkcLMqVTA'},
  keycloak: {
    REALM: "ToshibaHub",
    CLIENTID: "toshiba-web-app",
    ROLE_ADMIN: "admin"
  }
}

const prod = {
  url: {
    // KEYCLOAK_BASE_URL: "https://toshibahub.zenshare.it/kc/auth",
    // API_BASE_URL: 'https://toshibahub-api.zenshare.it',
    // CLIENT_URL: 'https://toshibahub.zenshare.it/',
    KEYCLOAK_BASE_URL: "https://www.toshibahub.it/kc/auth",
    API_BASE_URL: 'https://api.toshibahub.it',
    CLIENT_URL: 'https://www.toshibahub.it/',
  },
  zen:{KEY: 'gRSYRYRnVAFnvEMx3t8RJmNZkcLMqVTA'},
  keycloak: {
    REALM: "ToshibaHub",
    CLIENTID: "toshiba-web-app",
    ROLE_ADMIN: "admin"
  }
}

const local = {
  url: {
    KEYCLOAK_BASE_URL: "https://toshibahub-dev.zenshare.it/kc/auth",
    API_BASE_URL: 'http://localhost:9090',
    CLIENT_URL: 'http://localhost:3000',
  },
  zen:{KEY: 'nPXj2zE7bHCvUFttvi5HXcuMA0dpf3P0'},
  keycloak: {
    REALM: "ToshibaHub",
    CLIENTID: "toshiba-web-app",
    ROLE_ADMIN: "admin"
  }
}

//export const config = process.env.NODE_ENV === 'development' ?  dev ? process.env.NODE_ENV === 'local': local : prod
//a == 1 ? 'kid' : a == 2 ? 'boy' : 'girl';
//export const config = process.env.NODE_ENV === 'development' ?  dev : prod

export const config = process.env.REACT_APP_ENV === 'local' ?  local : process.env.REACT_APP_ENV ==='development' ? dev : prod