/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { AccountsService } from "../../Service/AccountsService";
import { useKeycloak } from "@react-keycloak/web";
import {
  Divider,
  Button,
  InputNumber,
  Form,
  Input,
  Select,
  Switch,
  notification,
  Row,
  Col,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import Page from "../../Components/Page/Page";
import {
  handleLogError,
  ErrorPage,
  openNotificationWithIcon,
} from "../../Helpers/Helpers";
import { useTranslate } from "../../Translate";
import countries from "../../utils/countries";
import districts from "../../utils/districts";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};

const Accounts = (props) => {
  //gestione della lingua
  const i18n = useTranslate();
  const { t } = i18n;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [dealerappartenenzadisabled, setDealerappartenenzadisabled] =
    useState(false);
  const [account, setAccount] = useState([]);
  const [selected, setSelected] = useState();
  const [countries_children, setCountries_children] = useState([]);
  const [districts_children, setDistricts_children] = useState([]);
  const [countries_children_bill, setCountries_children_bill] = useState([]);
  const [districts_children_bill, setDistricts_children_bill] = useState([]);
  const [groups_children, setGroups_children] = useState([]);
  const [form] = Form.useForm();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const { type } = props;
  const userid = keycloak?.tokenParsed?.sub?.toString();
  const getDataCountries = () => {
    var response = [];
    countries.forEach((country) => {
      response.push(
        <Option key={country.id} value={country.id}>
          {country.name}
        </Option>
      );
    });

    setCountries_children(response);
    setCountries_children_bill(response);
  };

  const getDataDistricts = (countryid) => {
    var response = [];
    districts.forEach((district) => {
      if (
        district.country_id == countryid &&
        (district.type == "province" || district.type == "metropolitan city")
      ) {
        response.push(
          <Option key={district.id} value={district.id}>
            {district.name}
          </Option>
        );
      }
    });
    setDistricts_children(response);
  };

  const getDataDistrictsBill = (countryid) => {
    var response = [];
    districts.forEach((district) => {
      if (
        district.country_id == countryid &&
        (district.type == "province" || district.type == "metropolitan city")
      ) {
        response.push(
          <Option value={district.id} key={district.id}>
            {district.name}
          </Option>
        );
      }
    });
    setDistricts_children_bill(response);
  };

  var sel_accounts = [];
  const getDealer = async () => {
    setLoading(true);
    try {
      var filteredDataSend = {};
      filteredDataSend["type"] = type;
      filteredDataSend["pagination"] = {
        current: 1,
        pageSize: 1000000,
      };
      filteredDataSend["fieldSearch"] = { dealer: true };
      const response = await AccountsService.getAccountsDealer(
        keycloak.token,
        filteredDataSend
      );
      let lk_accounts = [];
      //popolo la lookup degli accounts
      response.data.content.map((account) => {
        lk_accounts.push(
          <Option value={account.id} key={account.id}>
            {account.companyname.trim()}
          </Option>
        );
        return true;
      });

      setGroups_children(lk_accounts);
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    try {
      var filteredDataSend = {};
      filteredDataSend["type"] = type;
      const response = await AccountsService.getAccountById(
        keycloak.token,
        id,
        filteredDataSend
      );
      if (response.status === 500 || response.status === 0) {
        navigate("/error/500");
      } else {
        if (response.status === 403) {
          navigate("/error/403");
        } else if (response.status === 401) {
          navigate("/error/401");
        }
      }
      setAccount(response.data);
      if (response.data.countryid != 0 && response.data.countryid != "") {
        getDataDistricts(response.data.countryid);
      }
      if (
        response.data.countryid_billing != 0 &&
        response.data.countryid_billing != ""
      ) {
        getDataDistrictsBill(response.data.countryid_billing);
      }
      sel_accounts = response.data.dealerid;
      setDealerappartenenzadisabled(response.data.dealer == 1 ? true : false);
      /*setto i campidel form*/
      form.setFieldsValue({
        companyname: response.data.companyname,
        address: response.data.address,
        vatnumber: response.data.vatnumber,
        fiscalcode: response.data.fiscalcode,
        city: response.data.city,
        phone: response.data.phone,
        postalcode: response.data.postalcode,
        countryid: response.data.countryid != 0 ? response.data.countryid : "",
        districtid:
          response.data.districtid != 0 ? response.data.districtid : "",
        countryid_billing:
          response.data.countryid_billing != 0
            ? response.data.countryid_billing
            : "",
        districtid_billing:
          response.data.districtid_billing != 0
            ? response.data.districtid_billing
            : "",
        companyname_billing: response.data.companyname_billing,
        address_billing: response.data.address_billing,
        vatnumber_billing: response.data.vatnumber_billing,
        fiscalcode_billing: response.data.fiscalcode_billing,
        city_billing: response.data.city_billing,
        postalcode_billing: response.data.postalcode_billing,
        pec: response.data.pec,
        sdi: response.data.sdi,
        importoMinimo: response.data.importoMinimo,
        splitpayment: response.data.splitpayment == 1 ? true : false,
        dealer: response.data.dealer == 1 ? true : false,
        dealerid: sel_accounts,
      });
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    //carico le lookup delle countries
    getDataCountries();
    //recupero i dealer
    getDealer();
    //caso udpdate account
    if (id) {
      getData();
    }
  }, []);
  const onFinish = (values) => {
    id == undefined ? handleSendSave(values) : handleSendUpdate(values);
  };

  const handleBack = () => {
    type === "admin" ? navigate("/admin/groups") : navigate("/team/groups");
  };

  const handleSendUpdate = async (data) => {
    try {
      setLoading(true);
      data["id"] = id;
      data["modifiedby"] = userid;
      data["splitpayment"] = data.splitpayment ? 1 : 0;
      var filteredDataSend = {};
      filteredDataSend["type"] = type;
      const response = await AccountsService.update(
        keycloak.token,
        data,
        filteredDataSend
      );
      if (response.status == 201) {
        openNotificationWithIcon(
          "success",
          response.data.type,
          response.data.messaggio
        );
        type === "admin" ? navigate("/admin/groups") : navigate("/team/groups");
      } else if (response.status == 400) {
        openNotificationWithIcon(
          "warning",
          response.data.type,
          response.data.messaggio
        );
      } else {
        ErrorPage(response.status, navigate);
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleSendSave = async (data) => {
    try {
      setLoading(true);
      var filteredDataSend = {};
      filteredDataSend["type"] = type;
      data["createdby"] = userid;
      data["splitpayment"] = data.splitpayment ? 1 : 0;
      const response = await AccountsService.save(
        keycloak.token,
        data,
        filteredDataSend
      );
      if (response.status == 201) {
        openNotificationWithIcon(
          "success",
          response.data.type,
          response.data.messaggio
        );
        type === "admin" ? navigate("/admin/groups") : navigate("/team/groups");
      } else if (response.status == 400) {
        openNotificationWithIcon(
          "warning",
          response.data.type,
          response.data.messaggio
        );
      } else {
        ErrorPage(response.status, navigate);
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleChangeCountries = (value) => {
    console.log(`selected ${value}`);
    getDataDistricts(value);
  };
  const handleChangeDealer = (checked) => {
    setDealerappartenenzadisabled(checked ? true : false);
    if (checked) {
      form.setFieldsValue({ dealerid: undefined });
    }
  };

  const handleChangeCountries_bill = (value) => {
    console.log(`selected ${value}`);
    getDataDistrictsBill(value);
  };

  return (
    <Page loading={loading} inner>
      <>
        <Form
          style={{ border: "0px solid" }}
          form={form}
          layout="vertical"
          name="group"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            importoMinimo: 0,
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ color: "#e61e1e" }}
              >
                {t("group")}
              </Divider>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("companyname")}
                name="companyname"
                rules={[
                  {
                    required: true,
                    message: t("ckCompanyname"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("vatnumber")}
                name="vatnumber"
                rules={[
                  {
                    required: false,
                    message: t("ckVatnumber"),
                  },
                  { len: 11, message: "Vatnumber must be  11 characters." },
                  { max: 11, message: "Vatnumber must be max 11 characters." },
                  // { len}
                ]}
              >
                <Input maxLength={11} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("fiscalcode")}
                name="fiscalcode"
                rules={[
                  {
                    required: true,
                    message: t("ckFiscalcode"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("phone")}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t("ckPhone"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("address")}
                name="address"
                rules={[
                  {
                    required: true,
                    message: t("ckAddress"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("postalcode")}
                name="postalcode"
                rules={[
                  {
                    required: true,
                    message: t("ckPostalcode"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("city")}
                name="city"
                rules={[
                  {
                    required: true,
                    message: t("ckCity"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("country")} name="countryid">
                <Select
                  autoComplete="none"
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChangeCountries}
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countries_children}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={t("district")} name="districtid">
                <Select
                  autoComplete="none"
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Provincia*"
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {districts_children}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ color: "#e61e1e" }}
              >
                {t("billing")}
              </Divider>
            </Col>
            <Col span={8}>
              <Form.Item label={t("companyname")} name="companyname_billing">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("vatnumber")} name="vatnumber_billing">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("fiscalcode")} name="fiscalcode_billing">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("address")} name="address_billing">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("postalcode")} name="postalcode_billing">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("city")} name="city_billing">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("country")} name="countryid_billing">
                <Select
                  autoComplete="none"
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChangeCountries_bill}
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countries_children_bill}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={t("district")} name="districtid_billing">
                <Select
                  autoComplete="none"
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {districts_children_bill}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Pec"
                name="pec"
                rules={[
                  {
                    type: "email",
                    pattern: "^([-]?[1-9][0-9]*|0)$",
                    message: t("patternEmail"),
                  },
                  {
                    required: false,
                    message: t("ckPec"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("codesdi")}
                name="sdi"
                rules={[
                  {
                    required: false,
                    message: t("ckCodeSDI"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                valuePropName="checked"
                name="splitpayment"
                label={t("splitpayment")}
              >
                <Switch />
              </Form.Item>
            </Col>

            {type === "admin" ? (
              <>
                <Col span={24}>
                  <Divider
                    orientation="left"
                    orientationMargin="0"
                    style={{ color: "#e61e1e" }}
                  >
                    Dealer
                  </Divider>
                </Col>
                <Col span={8}>
                  <Form.Item
                    valuePropName="checked"
                    name="dealer"
                    label="Dealer"
                  >
                    <Switch onChange={handleChangeDealer} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t("dealerappartenenza")} name="dealerid">
                    <Select
                      disabled={dealerappartenenzadisabled ? true : false}
                      autoComplete
                      showSearch
                      onChange={(value) => setSelected(null)}
                      value={selected}
                      style={{
                        width: "100%",
                      }}
                      placeholder={t("dealerappartenenza")}
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase().trim())
                      }
                      optionFilterProp="children"
                      defaultValue={sel_accounts}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase().trim())
                      }
                      allowClear
                    >
                      {groups_children}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            ) : (
              ""
            )}
            <Col
              span={24}
              style={
                type === "admin" ? { display: "block" } : { display: "none" }
              }
            >
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ color: "#e61e1e" }}
              >
                {t("other")}
              </Divider>
            </Col>
            <Col
              span={8}
              style={
                type === "admin" ? { display: "block" } : { display: "none" }
              }
            >
              <Form.Item label={t("minimumamount")} name="importoMinimo">
                <InputNumber
                  prefix="&euro;"
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: "right",
              }}
            >
              <Button type="primary" htmlType="submit">
                {t("save")}
              </Button>
              <Button
                onClick={handleBack}
                style={{
                  margin: "0 8px",
                }}
              >
                {t("cancel")}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    </Page>
  );
};

export default Accounts;
