/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../Components/Page/Page";
import { useTranslate } from "../../Translate";
import { NumericInput, ErrorPage, handleLogError,openNotificationWithIcon } from "../../Helpers/Helpers";
import { Button, message, Steps, Select, Form, Modal, Divider } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { AccountsService } from "../../Service/AccountsService";
import { CreditsService } from "../../Service/CreditsService";
const{Option}= Select;

const formItemLayout = {
    labelCol: {
      xl: {
        span: 8,
      },
  
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xl: {
        span: 8,
      },
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };
  
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 2,
        offset: 2,
      },
      sm: {
        span: 10,
        offset: 10,
      },
    },
  };
const Storno = (props) => {
    const { type } = props;
    const i18n = useTranslate();
    const { t } = i18n;
    const { keycloak } = useKeycloak();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();

  
    const handleFinish = (data) => {
      data["movementId"]= id;
      storno(data);
    };
  
    const storno = async (data) => {
      setLoading(true);
      try {
        const response = await CreditsService.storno(
          keycloak.token,
          data
        );
        ErrorPage(response, navigate);
        console.log(response)
        if (response.status == 200) {
          openNotificationWithIcon('success', "Success", "Storno inserito con successo!");
          navigate("/admin/consumption");
        }  else {
          openNotificationWithIcon('error', "Error", "Errore nella creazione dello storno!");
        }
      } catch (error) {
        handleLogError(error);
      }
      setLoading(false);
    };
  
   
  
    const handleBack = () => {
      navigate("/admin/consumption");
    };
    return (
      <Page loading={loading} inner>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          {t("newstorno")}
        </Divider>
        <Form
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="form-bonus"
          onFinish={handleFinish}
          scrollToFirstError
        >
             <Form.Item label={t("typeOperation")} name="tipoOperazione"  rules={[
              {
                required: true,
                message: t("ckTypeOperation"),
              },
            ]}>
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder={t("selecttype")}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            <Option value={0}>{t("accredit")}</Option>
            <Option value={1}>{t("charge")}</Option>
          </Select>
        </Form.Item>
          
          <Form.Item
            label={t("amount")}
            name="importo"
            rules={[
              {
                required: true,
                message: t("ckAmount"),
              },
            ]}
          >
            <NumericInput placeholder={t("inputnumber")} />
          </Form.Item>
  
          <Form.Item
            label={t("causal")}
            name="causale"
            rules={[
              {
                required: true,
                message: t("ckCausal"),
              },
            ]}
          >
            <TextArea></TextArea>
          </Form.Item>
          <Form.Item></Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              {t("save")}
            </Button>
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={handleBack}
            >
              {t("cancel")}
            </Button>
          </Form.Item>
        </Form>
      </Page>
    );
}

export default Storno