import React, { useEffect, useState } from 'react'
import Page from '../../Components/Page/Page'
import List from './Components/List';
import { handleLogError, ErrorPage,openNotificationWithIcon } from '../../Helpers/Helpers';
import { useTranslate } from '../../Translate';
import { useParams, useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web'
import {
    Tabs,
    Button,
    Select,
    Form, Row, Col, Input
} from 'antd';
import { ProfilesService } from '../../Service/ProfilesService'

const ColProps = {
    xs: 24,
    sm: 12,
    style: {
        marginBottom: 16,
    },
}


const { TabPane } = Tabs;

const Permissions = () => {
    const { keycloak } = useKeycloak()
    //gestione della lingua
    const i18n = useTranslate();
    const { t } = i18n;
    //recupero l'id del profilo
    const { id } = useParams();
    const navigate = useNavigate();
    const [loadingUser, setLoadingUser] = useState(false)
    const [loadingAdmin, setLoadingAdmin] = useState(false)
    const [permissionsUser, setPermissionsUser] = useState([])
    const [permissionsAdmin, setPermissionsAdmin] = useState([])
    const [roles, setRoles] = useState([])
    /* eslint-disable no-unused-vars */
    const [group, setGroup] = useState([])
    const [groups_children, setGroups_children] = useState([])
    const [form] = Form.useForm();
    const { Option } = Select;
    /**
  * Funzione che ritorna gli elementi e aggiorna lo state _ permissions
  * 
  * @returns {undefined}
  */

    var sel_accounts;
    const getPermission = async (type) => {
 
        const filter = {}
        filter["type"] = type;
        type === "admin" ? setLoadingAdmin(true) : setLoadingUser(true)
        try {
            const response = await ProfilesService.getPermissions(keycloak.token, id, filter)
            ErrorPage(response.status, navigate);
            type === "admin" ?  setPermissionsAdmin(response.data) :  setPermissionsUser(response.data);
            response.data.map((el) =>{
                if(el.active){
                    addIndexRole(el.id);
                    console.log(el.id);
                }
                return true;
            })
           
        } catch (error) {
            handleLogError(error);
        }
        type === "admin" ? setLoadingAdmin(false) : setLoadingUser(false)
    }

    const addIndexRole = (item) =>{
        var items = roles;
        if(items.indexOf(item) === -1) {
            items.push(item);
        }
        setRoles(items);

    }

    const removeIndexRole = (item) =>{
        var items = roles;
        var index = items.indexOf(item);
        //ck se effettivamente esiste
        if (index > -1) {
          items.splice(index, 1);
        }
        setRoles(items);
    }

    const getGroup = async () => {
        //setLoading(true);
        try {
            const responseProfile = await ProfilesService.getProlieById(keycloak.token, id)
            ErrorPage(responseProfile.status, navigate);
            setGroup(responseProfile.data);
            var lk_accounts = [];
            //popolo la lookup degli accounts
            responseProfile.data.entityaccounts.map((account)=>{
                lk_accounts.push(<Option value={account.id} key={account.id}>{account.companyname.trim()}</Option>);
                return true;
            });
            setGroups_children(lk_accounts)

            lk_accounts.forEach((account)=>{
                if(responseProfile.data.accounts !== undefined && responseProfile.data.accounts !==""){
                        if(responseProfile.data.accounts.toLowerCase().trim()  === account.props.children.toLowerCase().trim()) {
                           sel_accounts= parseInt(account.key);
                        }
                }
             
            })
            /*setto i campi del form*/
            form.setFieldsValue({
                name: responseProfile.data.name,
                group: sel_accounts
            });
   
        } catch (error) {
            handleLogError(error);
        }
        //setLoading(false);
    }

    useEffect(() => {
        getGroup()
        getPermission("user")
        getPermission("admin")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeCk = (e) => {
        e.target.checked ? addIndexRole(e.target.value) : removeIndexRole(e.target.value);
    };

    const onChangeTab = (key) => {
        key === 1 ? setLoadingUser(true) : setLoadingAdmin(true)
        key === 1 ? getPermission("user") : getPermission("admin")
    };

    const handleBack = () => {
        navigate('/admin/profiles');
    }

    const onFinish = (values) => {
        values["roles"] = roles;
        handleSendUpdate(values);
    };


    const handleSendUpdate= async (data) => {
        try {
            setLoadingUser(true);
            console.log(data);
            setLoadingAdmin(true);
            const response = await ProfilesService.savePermissions(keycloak.token,id, data)
            
            if(response.status === 200 ||  response.status === 201 ){
                openNotificationWithIcon('success',response.data.type,response.data.messaggio);
            }else if(response.status === 400){
                openNotificationWithIcon('warning',response.data.type,response.data.messaggio);
            }else{
                ErrorPage(response.status,navigate);
            }
        } catch (error) {
            handleLogError(error);
            setLoadingUser(false);
            setLoadingAdmin(false);
        }
        setLoadingUser(false);
        setLoadingAdmin(false);
    }



    return (
        <Page inner>
            <>
                <Form
                    form={form}
                    name="permissions-ref"
                    style={{ border: '0px solid' }}
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col {...ColProps} xl={{ span: 6 }} md={{ span: 10 }} >
                            <Form.Item 
                            name="name" 
                            label={t("profileName")}
                            rules={[
                            {
                                required: true,
                                message: t('ckProfileName'),
                            },
                        ]}
                            >
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col
                            {...ColProps}
                            xl={{ span: 6 }}
                            md={{ span: 12 }}
                            id="aziendaCascader"
                        >
                            <Form.Item name="group" label={t("privateGroup")}>
                                <Select

                                    autoComplete="none"
                                    showSearch
                                    style={{
                                        width: '100%',
                                    }}
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                    optionFilterProp="children"
                                    defaultValue={sel_accounts}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                >
                                     {groups_children}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col {...ColProps} xl={{ span: 24 }} md={{ span: 20 }} >
                            <Tabs defaultActiveKey="1" onChange={onChangeTab}>
                                <TabPane tab={t("permissionsUser")} key="1">
                                    <List loading={loadingUser} permissions={permissionsUser} onChangeCk={onChangeCk} />
                                </TabPane>
                                <TabPane tab={t("permissionsAdmin")} key="2">
                                    <List loading={loadingAdmin} permissions={permissionsAdmin} onChangeCk={onChangeCk} />
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                    <Form.Item></Form.Item>
                    <Row gutter={24} type="flex" align="right" justify="space-between" style={{ border: '0px solid', float: 'right' }}>

                        <div>
                            <Button
                                type="primary" htmlType="submit"
                                style={{ marginRight: '16px' }}
                            >
                                {t("save")}
                            </Button>
                            <Button
                                onClick={handleBack}
                            >
                                {t("cancel")}
                            </Button>
                        </div>

                    </Row>

                </Form>
            </>
        </Page>
    )
}

export default Permissions