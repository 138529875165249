import React, { useState, useEffect } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Page from "../../Components/Page/Page";
import {
  Col,
  Row,
  Typography,
  Image,
  Card,
  Button,
  Divider,
  Radio,
} from "antd";
import { getCookie,setCookie } from "../../Helpers/Helpers";
const { Title, Paragraph } = Typography;


const SettingCookiePolicy = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const onChange = (e) => {
    //console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const handleExit = () => {
        if(value === 1){
          setCookie("cookiepolicy",true, 365) 
        }else{
          setCookie("cookiepolicy",false, 365) 
        }
        navigate("/");
  };

  useEffect(() => {
    var cookie = getCookie("cookiepolicy");
    if (cookie === "false") {
      //document.cookie = 'cookiepolicy=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }, []);

  return (
    <Page inner>
      <>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          Preferenze
        </Divider>
        <Row>
          <Col>
            <Paragraph>
              Selezioni le categorie di cookie che intende accettare. Per
              ottenere maggiori informazioni sui cookie utilizzati, La invitiamo
              a prendere visione della nostra{" "}
              <Link to="/cookiepolicy">Cookie Policy</Link>.<br></br>
              <br></br>
              <b>Cookie strettamente necessari</b>
              <br></br>
              Questi cookie sono necessari per il funzionamento del Sito e non
              possono essere disattivati nei nostri sistemi. Di solito vengono
              impostati solo in risposta alle azioni da Lei effettuate che
              costituiscono una richiesta di servizi, come l’impostazione delle
              preferenze di privacy o l’accesso. È possibile impostare il
              browser per bloccare o avere avvisi riguardo questi cookie, ma di
              conseguenza, alcune parti del sito non funzioneranno. Questi
              cookie non archiviano informazioni personali.
              <br></br>
              <br></br>
              <b>Cookie di funzionalità</b>
              <br></br>
              Questi cookie sono utilizzati per attivare specifiche funzionalità
              del Sito e una serie di criteri selezionati (ad esempio, la
              lingua) al fine di migliorare il servizio reso. Tali cookie sono
              attivi di default ma disattivabili in qualsiasi momento. Se
              disattiva i cookie di funzionalità, il Sito potrebbe risultare non
              consultabile o alcuni servizi o determinate funzioni del Sito
              potrebbero risultare non disponibili o non funzionare
              correttamente. Potrebbe inoltre essere costretto a modificare o ad
              inserire manualmente alcune informazioni o preferenze ogni volta
              che visiterà il Sito.
            </Paragraph>
            <Radio.Group onChange={onChange}>
              <Radio value={1}>Attivati</Radio>
              <Radio value={0}>Disattivati</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <br></br>
        <Row middle="center" justify="center">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "16px" }}
              onClick={handleExit}
            >
              Salva ed Esci
            </Button>
          </Col>
        </Row>
      </>
    </Page>
  );
};

export default SettingCookiePolicy;
