// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table__dmduB .ant-table td {
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/Views/Permissions/Components/List.module.less"],"names":[],"mappings":"AAAA;EAGQ,mBAAA;AADR","sourcesContent":[".table {\n    :global {\n      .ant-table td {\n        white-space: nowrap;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `table__dmduB`
};
export default ___CSS_LOADER_EXPORT___;
