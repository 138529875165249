import React from 'react'
import {Link} from "react-router-dom"
import {
    Result,
    Button
} from "antd"

const Page500 = () => {
  return (
    <Result
    status="500"
    title="500 Error"
    subTitle="Sorry, something went wrong."
    extra={
      <Link to="/">
            <Button type="primary">Back to Home</Button>
        </Link>
    }
/>
  )
}

export default Page500