import React, { useEffect, useState } from "react";
import { Badge, Descriptions, Button, Col, Row, Space, Modal } from "antd";
import Page from "../../Components/Page/Page";
import { useTranslate } from "../../Translate";
import { ShoppingCartOutlined } from "@ant-design/icons";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { buyPreview, clearPreview, buyApp } from "../../redux/reducers/admin-app-reducer";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import {
    openNotificationWithIcon,
} from "../../Helpers/Helpers";
const { confirm } = Modal

const BuyPreviewList = () => {
    const [localPreview, setLocalPreview] = useState({});
    const navigate = useNavigate();
    const pathname = window.location.pathname;

    const arrPath = pathname.split("/");

    const code = arrPath[4].split("@");
    const appid = code[0];
    const groupid = code[1];


    const { keycloak } = useKeycloak();
    const userid = keycloak?.tokenParsed?.sub?.toString();
    //gestione lingua
    const i18n = useTranslate();
    const { t } = i18n;
    // set up dispatch
    const dispatch = useDispatch();
    // fetch data dal nostro store redux
    const { loading, error, preview } = useSelector((state) => state.adminApps)


    useEffect(() => {
        if (JSON.stringify(preview) !== JSON.stringify({})) return;
        dispatch(buyPreview(keycloak.token, appid, groupid));
    }, []);

    //hook to fetch items
    useEffect(() => {
        if (JSON.stringify(preview) !== JSON.stringify({})) {
            setLocalPreview(preview);
            dispatch(clearPreview());
        }

    }, [preview]);


    //hook to fetch items
    useEffect(() => {
        getNotice(error);
    }, [error]);

    const handleBack = () => {
        dispatch(clearPreview())
        navigate('/admin/services');
    }
    const handleBuy = () => {
        confirm({
            title: t("questionbuyapp"),
            onOk() {
                dispatch(buyApp(keycloak.token, appid, groupid))
            },
        })

    }

    const getNotice = (response) => {
        if (response.status) {
            if (response.code == 400 || response.code == 404) {
                openNotificationWithIcon(
                    "warning",
                    "Warning",
                    <>{response.message ? response.message : ""}</>
                );
            } else if (response.code == 401) {
                openNotificationWithIcon(
                    "warning",
                    "Warning",
                    response.message ? response.message : ""
                );
            } else {
                openNotificationWithIcon(
                    "error",
                    "Errore",
                    t("errorapp") + "\n" + response.message ? response.message : ""
                );
            }
        } else {
            if (response.code === 200) {
                navigate("/admin/services/buy-succefully");
            }
        }
    };
    let buyError=""; 
  
    localPreview?.cannotBuyErrors?.map((i,index) => {
        buyError +=i;
        if(index>0) buyError+=buyError+" - "
    });

    return (
        <Page loading={loading} inner>
            <Row gutter={[16, 16]} style={{ border: "0px solid" }}>
                <Col span={24}>
                    <Badge.Ribbon text="Preview" color="red">
                        <Descriptions title={t("purchasepreview")} bordered>
                            <Descriptions.Item label={t("app")}>
                                {localPreview.titolo}
                            </Descriptions.Item>
                            <Descriptions.Item label={t("author")}>
                                {localPreview.autore}
                            </Descriptions.Item>
                            <Descriptions.Item label={t("description")}>
                                {localPreview.descrizione}
                            </Descriptions.Item>
                            <Descriptions.Item label={t("state")} span={3}>
                                {localPreview.canBuy ? (
                                    <Badge
                                        status="processing"
                                        text={t("purchasable")}
                                        color="green"
                                    />
                                ) : (
                                    <Badge
                                        status="processing"
                                        text={t("notpurchasable")}
                                        color="red"
                                    />
                                )}
                                {buyError ? " ["+buyError+"]" :""}
                            </Descriptions.Item>
                            <Descriptions.Item label={t("price")}>
                                {localPreview.prezzo != null
                                    ? new Intl.NumberFormat("it-IT", {
                                        style: "currency",
                                        currency: "EUR",
                                    }).format(localPreview.prezzo)
                                    : new Intl.NumberFormat("it-IT", {
                                        style: "currency",
                                        currency: "EUR",
                                    }).format(0)}
                            </Descriptions.Item>
                            <Descriptions.Item label={t("currentbalance")}>
                                {localPreview.saldoAttuale != null
                                    ? new Intl.NumberFormat("it-IT", {
                                        style: "currency",
                                        currency: "EUR",
                                    }).format(localPreview.saldoAttuale)
                                    : new Intl.NumberFormat("it-IT", {
                                        style: "currency",
                                        currency: "EUR",
                                    }).format(0)}
                            </Descriptions.Item>
                            <Descriptions.Item label={t("finalbalance")}>
                                {localPreview.saldoAcquisto != null
                                    ? new Intl.NumberFormat("it-IT", {
                                        style: "currency",
                                        currency: "EUR",
                                    }).format(localPreview.saldoAcquisto)
                                    : new Intl.NumberFormat("it-IT", {
                                        style: "currency",
                                        currency: "EUR",
                                    }).format(0)}
                            </Descriptions.Item>
                        </Descriptions>
                    </Badge.Ribbon>
                </Col>
                <Col span={24} style={{ border: "0px solid", textAlign: "right" }}>
                    <Space>
                        <Button onClick={handleBack}>{t("cancel")}</Button>
                        {localPreview.canBuy ?
                            <Button type="primary" onClick={handleBuy} icon={<ShoppingCartOutlined />}>
                                {t("confirmpurchase")}
                            </Button> : ""}
                    </Space>
                </Col>
            </Row>
        </Page>
    );
};

export default BuyPreviewList;
