/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react'
import Page from '../../Components/Page/Page'
import { useKeycloak } from '@react-keycloak/web'
import { FaqsService } from '../../Service/FaqsService';
import languages from '../../utils/languages';
import {
  Divider,
  Button,
  Form,
  Input,
  Select,
  notification
} from 'antd';

import { handleLogError, ErrorPage, openNotificationWithIcon } from '../../Helpers/Helpers';
import { useTranslate } from '../../Translate';
import { useNavigate, useParams } from 'react-router-dom';
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },

  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },

  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};

const CatFaq = () => {
  const i18n = useTranslate();
  const { t } = i18n;
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [cat, setCat] = useState([])
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak()
  const [lingua, setLingua] = useState([])

  const getDataLanguages = () => {
    var response = [];
    languages.forEach((language) => {
      response.push(<Option key={language.id} value={language.id}>{t(language.description)}</Option>);
    });
    setLingua(response);
  }
  const handleBack = () => {
    navigate('/admin/faqs/cats/');
  }

  useEffect(() => {
    getDataLanguages()
    getData()
  }, [])



  const onFinish = (values) => {

    id != undefined ? handleSendUpdate(values) : handleSendSave(values);
  };


  const getData = async () => {
    setLoading(true);
    try {
      if (id) {
        const response = await FaqsService.getCatFaqById(id)
        setCat(response.data);
        /*setto i campidel form*/
        form.setFieldsValue({
          titolo: response.data.titolo,
          languageid: response.data.languageid != 0 ? response.data.languageid : ""
        });
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);

  }

  const handleSendUpdate = async (data) => {
    try {
      setLoading(true);
      data["id"] = id;
      const response = await FaqsService.updateCatFaq(keycloak.token, data)
      if (response.status == 200) {
        openNotificationWithIcon('success', "Success", "Categoria salvata con successo!");
        navigate('/admin/faqs/cats/');
      } else if (response.status == 400 || response.status == 404) {
        openNotificationWithIcon('warning', "Warning", "Categoria non esistente!");
      } else {
        openNotificationWithIcon('error', "Errore", "Errore nell'aggiornamento della Categoria!");
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
  }

  const handleSendSave = async (data) => {
    try {
      setLoading(true);
      const response = await FaqsService.saveCatFaq(keycloak.token, data)
      if (response.status == 200) {
        openNotificationWithIcon('success', "Success", "Categoria salvata con successo!");
        navigate('/admin/faqs/cats/');
      } else if (response.status == 400 || response.status == 404) {
        openNotificationWithIcon('warning', "Warning", "Categoria non esistente!");
      } else {
        openNotificationWithIcon('error', "Errore", "Errore nel salvataggio della categoria!");
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
  }
  return (
    <Page loading={loading} inner>
      <>
        <Divider orientation="left" orientationMargin="0" style={{ color: '#e61e1e' }}>{id == null ? t("newcatfaq") : t("editcatfaq")}</Divider>
        <Form
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="profile"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            label={t('title')}
            name="titolo"
            rules={[
              {
                required: true,
                message: t('ckTitle'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("language")}
            name="languageid"
            rules={[
              {
                required: true,
                message: t('ckLanguage'),
              },
            ]}
          >
            <Select
              autoComplete="none"
              showSearch
              style={{
                width: '100%',
              }}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {lingua}
            </Select>
          </Form.Item>
          <Form.Item></Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              {t("save")}
            </Button>
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={handleBack}
            >
              {t("cancel")}
            </Button>
          </Form.Item>

        </Form>
      </>
    </Page>
  )
}

export default CatFaq