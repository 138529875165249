//import './index.css';
import AppLayout from "./Layouts/AppLayout";
import { BrowserRouter as Router, Routes, Route,Link} from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { config } from "./utils/Constants";
import Keycloak from "keycloak-js";
import Loader from "./Components/Loader/Loader";
import News from "./Views/News/News";
import NewsList from "./Views/News/NewsList";
import OneNews from "./Views/News/OneNews";
import CatNewsList from "./Views/News/CatNewsList";
import CatNews from "./Views/News/CatNews";
import Faqs from "./Views/Faq/Faqs";
import Faq from "./Views/Faq/Faq";
import FaqList from "./Views/Faq/FaqList";
import CatFaqList from "./Views/Faq/CatFaqList";
import CatFaq from "./Views/Faq/CatFaq";
import User from "./Views/User/User";
import Settings from "./Views/Setting/Settings";
import PrivateRoute from "./Helpers/PrivateRoute";
import Users from "./Views/User/Users";
import Page400 from "./Views/Pages/Page400";
import Page403 from "./Views/Pages/Page403";
import Page401 from "./Views/Pages/Page401";
import Page404 from "./Views/Pages/Page404";
import Page500 from "./Views/Pages/Page500";
import RegistrationSuccefully from "./Views/Pages/RegistrationSuccefully";
import InvitationSuccefully from "./Views/Pages/InvitationSuccefully"
import Home from "./Views/Home/Home";
import Accounts from "./Views/Account/Accounts";
import Account from "./Views/Account/Account";
import Registration from "./Views/Registration/Registration";
import Profiles from "./Views/Profile/Profiles";
import Profile from "./Views/Profile/Profile";
import Permissions from "./Views/Permissions/Permissions";
import Credits from "./Views/Credit/Credits";
import CreditsHistory from "./Views/Credit/CreditsHistory";

import { TranslateProvider } from "./Translate";
import Detail from "./Views/News/Detail";
import AppList from "./Views/App/AppList";
import Apps from "./Views/App/Apps";
import AppDetail from "./Views/App/AppDetail";
import AppForm from "./Views/App/AppForm";
import { Categories } from "./Views/App/Categories";
import Category from "./Views/App/Category";
import Consumption from "./Views/Consumi/Consumption";
import BuyPreview from "./Views/App/BuyPreview";
import BuySuccefully from "./Views/App/BuySuccefully";
import Bonus from "./Views/Credit/Bonus";
import Move from "./Views/Credit/Move";
import Storno from "./Views/Consumi/Storno";
import Addebito from "./Views/Credit/Addebito";
import BuyPreviewList from "./Views/App/BuyPreviewList";
//import { Document } from 'postcss';
import Documents from "./Views/Document/Documents";
import Document from "./Views/Document/Document";
import Redirect from "./utils/Redirect";
import PrivacyPolicy from "./Views/Pages/PrivacyPolicy";
import GeneralConditions from "./Views/Pages/GeneralConditions";
import CookiePolicy from "./Views/Pages/CookiePolicy";
import SettingCookiePolicy from "./Views/Pages/SettingCookiePolicy";
import CookieConsent from "react-cookie-consent";
import Invitation from "./Views/Invitation/Invitation"; 
import BonusParameter from "./Views/Bonus/BonusParameter";
import  CreditOrder from "./Views/Credit/Order";
import Shipments from "./Views/NvGroup/Shipments";
import Shipping from "./Views/NvGroup/Shipping";

function App() {
  const keycloak = new Keycloak({
    url: `${config.url.KEYCLOAK_BASE_URL}`,
    realm: `${config.keycloak.REALM}`,
    clientId: `${config.keycloak.CLIENTID}`,
  });

  const initOptions = { pkceMethod: "S256" };

  const handleOnEvent = async (event, error) => {
    //console.log(process.env.NODE_ENV)
    if (event === "onAuthSuccess") {
      if (keycloak.authenticated) {
        //console.log(keycloak.token);
      }
    } else {
      //console.log("not auth");
    }
  };

  const loadingComponent = (
    // <div className="spinStyle">
    //   <Spin size="large" tip="Toshiba Hub is loading..." spinning={true}></Spin>
    // </div>
    <Loader spinning />
  );
  const url = `${config.url.KEYCLOAK_BASE_URL}/`;
  console.log(process.env.REACT_APP_ENV);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={loadingComponent}
      onEvent={(event, error) => handleOnEvent(event, error)}
    >
      <Router>
        <TranslateProvider>
          <AppLayout> 
            <CookieConsent
              location="bottom"
              buttonText="ACCETTA TUTTO"
              cookieName="cookiepolicy"
              setDeclineCookie="false"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "white",background:'#6fa71e', fontSize: "13px" }}
              declineButtonStyle={{background:'white', color:"#888888"}}
              declineButtonText="CONFIGURA"
              enableDeclineButton
              onDecline={() => {
                window.location.href = "/settingcookiepolicy";
              }}
            >
               Il presente sito web utilizza cookie tecnici necessari alla navigazione e funzionali all'erogazione del servizio. I cookie vengono utilizzati altresì per fornirLe un'esperienza di navigazione sempre migliore.
               Può esprimere il Suo consenso cliccando su "ACCETTA TUTTO".
               Per impostare le Sue preferenze e gestire le Sue opzioni, clicchi su "CONFIGURA"; potrà modificarle in qualsiasi momento. 
               Per maggiori informazioni sui cookie, La invitiamo a prendere visione della nostra <Link to='/cookiepolicy'>Cookie Policy</Link>.

            </CookieConsent>
            <Routes>
              <Route path="/auth/*" element={<Redirect url={url} />} />
              <Route path="/kc/*" element={<Redirect url={url} />} />
              <Route path="/" element={<Home />} />
              <Route path="/news" element={<News />} />
              <Route path="/news/detail/:id" element={<Detail />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/credits"
                element={
                  <PrivateRoute>
                    <Credits />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/credits"
                element={
                  <PrivateRoute>
                    <Credits type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/credits/ordershistory/:groupid"
                element={
                  <PrivateRoute>
                    <CreditsHistory />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/credits/ordershistory/:groupid"
                element={
                  <PrivateRoute>
                    <CreditsHistory type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/credits/ordershistory/"
                element={
                  <PrivateRoute>
                    <CreditsHistory type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/credits/bonus/:path"
                element={
                  <PrivateRoute>
                    <Bonus type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/credits/move/:path"
                element={
                  <PrivateRoute>
                    <Move type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/credits/charge/:path"
                element={
                  <PrivateRoute>
                    <Addebito type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/credits/order/:path"
                element={
                  <PrivateRoute>
                    <CreditOrder />
                  </PrivateRoute>
                }
              />
              <Route
                path="/credits/move/:path"
                element={
                  <PrivateRoute>
                    <Move type="team" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/credits/order/:path"
                element={
                  <PrivateRoute>
                    <CreditOrder type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/consumption"
                element={
                  <PrivateRoute>
                    <Consumption />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/consumption"
                element={
                  <PrivateRoute>
                    <Consumption type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/consumption/:id"
                element={
                  <PrivateRoute>
                    <Storno type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/team/accounts"
                element={
                  <PrivateRoute>
                    <Users type="team" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/team/accounts"
                element={
                  <PrivateRoute>
                    <Users type="team" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/team/accounts/:id"
                element={
                  <PrivateRoute>
                    <User type="team" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/accounts"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <Users type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/accounts/:id"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <User type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/accounts/new"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <User type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/profiles"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <Profiles type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/profiles/new"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <Profile type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/profiles/:id"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <Permissions type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/groups"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <Accounts type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/groups/:id"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <Account type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/groups/new"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <Account type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/faqs"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <FaqList type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/faqs/new"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <Faq type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/faqs/:id"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <Faq type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/faqs/cats/"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <CatFaqList type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/faqs/cats/new"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <CatFaq type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/faqs/cats/:id"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <CatFaq type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/news"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <NewsList type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/news/new"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <OneNews type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/news/:id"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <OneNews type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/news/cats/"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <CatNewsList type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/news/cats/new"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <CatNews type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/news/cats/:id"
                element={
                  <PrivateRoute onlyAdmin="true">
                    <CatNews type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/team/groups"
                element={
                  <PrivateRoute>
                    <Accounts type="team" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/team/groups/:id"
                element={
                  <PrivateRoute>
                    <Account type="team" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/team/groups/new"
                element={
                  <PrivateRoute>
                    <Account type="team" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services"
                element={
                  <PrivateRoute>
                    <AppList buy={false} type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services/buy"
                element={
                  <PrivateRoute>
                    <AppList buy={true} type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services/:id"
                element={
                  <PrivateRoute>
                    <AppForm type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services/new"
                element={
                  <PrivateRoute>
                    <AppForm type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services/categories"
                element={
                  <PrivateRoute>
                    <Categories type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services/categories/:id"
                element={
                  <PrivateRoute>
                    <Category type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services/categories/new"
                element={
                  <PrivateRoute>
                    <Category type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services/preview/:id"
                element={
                  <PrivateRoute>
                    <BuyPreviewList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/store/preview/:id"
                element={
                  <PrivateRoute>
                    <BuyPreview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/store/buy-succefully"
                element={
                  <PrivateRoute>
                    <BuySuccefully />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services/buy-succefully"
                element={
                  <PrivateRoute>
                    <BuySuccefully type="admin" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services/:id/documents"
                element={
                  <PrivateRoute>
                    <Documents type="admin" />
                  </PrivateRoute>
                }
              />

              <Route
                path="/myservices/:id/shipments"
                element={
                  <PrivateRoute>
                    <Shipments />
                  </PrivateRoute>
                }
              />
               <Route
                path="/myservices/:id/shipping/:bozzaid"
                element={
                  <PrivateRoute>
                    <Shipping  />
                  </PrivateRoute>
                }
              />
               <Route
                path="/myservices/:id/shipping/"
                element={
                  <PrivateRoute>
                    <Shipping  />
                  </PrivateRoute>
                }
              />
              <Route
                path="/myservices/:id/documents"
                element={
                  <PrivateRoute>
                    <Documents />
                  </PrivateRoute>
                }
              />
               <Route
                path="/myservices/:id/cs/documents"
                element={
                  <PrivateRoute>
                    <Documents docstype="cs"/>
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/services/:id/document/"
                element={
                  <PrivateRoute>
                    <Document type="admin" />
                  </PrivateRoute>
                }
              />
               <Route
                path="/myservices/:id/cs/document/"
                element={
                  <PrivateRoute>
                    <Document docstype="cs" />
                  </PrivateRoute>
                }
              />
              <Route path="/store" element={<Apps vista="view_store" />} />
              <Route path="/store/:id" element={<AppDetail />} />
              <Route
                path="/myservices"
                element={<Apps vista="view_myapps" />}
              />
              <Route
                path="/favorites"
                element={<Apps vista="view_favorites" />}
              />
              <Route path="/*" element={<Page404 />} />
              <Route path="/error/500" element={<Page500 />} />
              <Route path="/error/400" element={<Page400 />} />
              <Route path="/error/403" element={<Page403 />} />
              <Route path="/error/401" element={<Page401 />} />
              <Route path="/error/404" element={<Page404 />} />
              <Route path="/invitation" element={<PrivateRoute><Invitation /></PrivateRoute>} />
              <Route
                path="/invitation/success"
                element={<InvitationSuccefully />}
              />
              <Route path="/registration" element={<Registration />} />
              <Route path="/registration/:userid/:groupid" element={<Registration />} />
              <Route
                path="/registration/success"
                element={<RegistrationSuccefully />}
              />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route
                path="/generalconditions"
                element={<GeneralConditions />}
              />
              CookiePolicy
              <Route path="/cookiepolicy" element={<CookiePolicy />} />
              <Route
                path="/settingcookiepolicy"
                element={<SettingCookiePolicy />}
              />
               <Route
                path="/admin/bonus"
                element={
                  <PrivateRoute>
                    <BonusParameter />
                  </PrivateRoute>
                }
              />
            </Routes>
          </AppLayout>
        </TranslateProvider>
      </Router>
    </ReactKeycloakProvider>
  );
}

export default App;
