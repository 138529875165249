/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React from "react";
import { Table, Modal } from "antd";
import { t } from "@lingui/macro";
import DropOption from "../../../Components/DropOption/DropOption";
import styles from "./List.module.less";
import { Link } from "react-router-dom";

const { confirm } = Modal;

const List = (props) => {
  const {
    type,
    accounts,
    pagination,
    handleTableChange,
    loading,
    onEditItem,
    onDeleteItem,
    dealers
  } = props;
  console.log(dealers);
  const pathDetails = type === "admin" ? "/admin/groups/" : "/team/groups/";
  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      onEditItem(record.id);
    } else if (e.key === "2") {
      confirm({
        title: t`Tutti i servizi verranno definitivamente disattivati. L'operazione è irreversibile. Procedere?`,
        onOk() {
          onDeleteItem(record.id);
        },
      });
    }
  };
  const columns = [
    {
      title: "Ragione sociale",
      dataIndex: "companyname",
      key: "companyname",
      render: (text, record) => (
        <>
          <Link to={`${pathDetails}${record.id}`}>{record.companyname}</Link>
        </>
      ),
    },
    // {
    //   title: 'Account padre',
    //   dataIndex: 'parent',
    //   key: 'parent',
    //   render: (text, record) => (
    //     <> {record.parent !==null ? record.parent.companyname : ''}</>
    //   ),
    // },
    {
      title: "Partita iva",
      dataIndex: "vatnumber",
      key: "vatnumber",
      align: "center",
    },
    {
      title: "Codice Fiscale",
      dataIndex: "fiscalcode",
      key: "fiscalcode",
      align: "center",
    },
    {
      title: "Città",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Dealer",
      dataIndex: "dealer",
      key: "dealer",
      align: "center",
      width: 90,
      render: (text, record) => <> {record.dealer ? "Sì" : "No"}</>,
    },
    {
      title: "Dealer di appartenenza",
      dataIndex: "dealerid",
      key: "dealerid",
      render: (text, record) => <> { dealers.find(oggetto => oggetto.id === record.dealerid)?.companyname ?? null}</>,
    },
    {
      title: "Attivo",
      dataIndex: "inactive",
      key: "inactive",
      align: "center",
      width: 90,
      render: (text, record) => <> {record.inactive === 0 ? "Sì" : "No"}</>,
    },
    // {
    //   title: 'Data creazione',
    //   dataIndex: 'creationdate',
    //   key: 'creationedate',
    //   render:(text,record) =>(

    //     <>{new Intl.DateTimeFormat('it-IT', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(record.creationdate)}</>
    //   )
    // },
    {
      title: "Operazioni",
      key: "operation",
      fixed: "right",
      width: "9%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={
              record.inactive
                ? [{ key: "1", name: t`Modifica` }]
                : [
                    { key: "1", name: t`Modifica` },
                    { key: "2", name: t`Disattiva`},
                  ]
            }
          />
        );
      },
    },
  ];

  return (
    <Table
      dataSource={accounts}
      pagination={pagination}
      className={styles.table}
      bordered
      scroll={{ x: 1200 }}
      columns={columns}
      loading={loading}
      rowKey={(record) => record.id}
      onChange={handleTableChange}
    />
  );
};

export default List;
