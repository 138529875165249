
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react'
import { Table,Modal} from 'antd'
import { t } from "@lingui/macro"
import DropOption from '../../../Components/DropOption/DropOption'
import styles from './List.module.less'
import { Link } from "react-router-dom";


const { confirm } = Modal

const List = (props) => {
  const {documents,pagination,handleTableChange,loading,columns} = props;

  return (
    <Table
        dataSource={documents}
        pagination={pagination}
        className={styles.table}
        bordered
        scroll={{ x: 1200 }}
        columns={columns}
        loading={loading} 
        rowKey={record => record.id}
        onChange={handleTableChange}
      />
  )
}

export default List