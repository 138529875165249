import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import Page403 from '../Views/Pages/Page403'
import { isAdmin } from './Helpers';


/*
Questo codice controlla se un utente che tenta di accedere a un percorso protetto è autenticato.
Visualizza il percorso protetto quando un utente è autenticato oppure non visualizza nulla se l'utente non è autenticato
Keycloak fornisce l'accesso ad alcune proprietà aggiuntive per proteggere la tua applicazione,
 come la proprietà authenticated, che utilizzeremo per verificare se un utente è autenticato

*/
const PrivateRoute = ({ children,onlyAdmin }) => {

  const { keycloak } = useKeycloak();
 
  const isLoggedIn = keycloak.authenticated;

  if(onlyAdmin){
    const result = keycloak.authenticated && isAdmin(keycloak) ? true : false
    if(!result) return <Page403/>;
  }
 // return isLoggedIn ? children : keycloak.login();
 return isLoggedIn ? children : <Page403/>;
 };

export default PrivateRoute