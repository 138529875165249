import axios from 'axios'
import { config } from '../utils/Constants'


export const RegistrationService = {
    registration,
    invitation
}
// -- Axios

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response;
}, function (error) {
    console.log(error) 
    return error.response;
});

// // -- Helper functions
function bearerAuth(token) {
    return `Bearer ${token}`
}

function invitation(token,userid,data) {
    var email = data['email'];
    var groupid = data['groups'];
    var filter ="?email="+email+"&groupid="+groupid;
    return instance.get('/tsbadmin/api/invitation'+filter, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token),
            "user-id": userid,
        }
    })
}

function registration(data) {
    return instance.post('/tsbadmin/api/public/registration', data, {
        headers: {
            'Content-type': 'application/json'
        }
    })
  }