/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React from 'react'
import { Table,Modal} from 'antd'
import DropOption from '../../../Components/DropOption/DropOption'
import styles from './List.module.less'
import { Link } from "react-router-dom";
import { useTranslate } from '../../../Translate';
import languages from '../../../utils/languages';
import Parser from 'html-react-parser';
const { confirm } = Modal

const List = (props) => {
    const i18n = useTranslate();
    const { t } = i18n;
    const {faqs,pagination,handleTableChange,loading,onEditItem,onDeleteItem} = props; 
    const pathDetails ="/admin/faqs/"
    const columns = [
        {
          title: t("question"),
          dataIndex: 'domanda',
          key: 'domanda',
          render: (text, record) => (
            <><Link to={`${pathDetails}${record.id}`}>{record.domanda}</Link></>     
        ),
        },
        {
          title: t("reply"),
          dataIndex: 'risposta',
          key: 'risposta',
          render: (text, record) => <> {Parser(record.risposta)}</>,
        },
        {
          title: t("category"),
          dataIndex: 'catfaq',
          key: 'catfaq',
          render: (text, record) => <> {record.catFaq != null ? record.catFaq.titolo : ""}</>,
        },
        {
          title: t("language"),
          dataIndex: 'languageid',
          key: 'languageid',
          width: '7%',
          render: (text, record) => <> {languages.filter( element => element.id ==record.languageid)[0].name}</>,
        },
        {
          title: t("operation"),
          key: 'operation',
          fixed: 'right',
          width: '9%',
          render: (text, record) => {
            return (
              <DropOption
                onMenuClick={e => handleMenuClick(record, e)}
                menuOptions={[
                  { key: '1', name: t("edit")},
                  { key: '2', name: t("delete")},
                ]}
              />
            )
          },
        },
      ]
      
      const handleMenuClick = (record, e) => {
        if (e.key === '1') {
          onEditItem(record.id)
        } else if (e.key === '2') {
          confirm({
            title: t`Procedere con l'operazione di cancellazione del record?`,
            onOk() {
              onDeleteItem(record.id)
            },
          })
        }
      }
    


    return (
        <Table
            dataSource={faqs}
            pagination={pagination}
            className={styles.table}
            bordered
            scroll={{ x: 1200 }}
            columns={columns}
            loading={loading} 
            rowKey={record => record.id}
            onChange={handleTableChange}
          />
      )
}

export default List