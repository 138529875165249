/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useEffect, useState } from 'react'
import { List ,Divider, Button,Modal} from 'antd';
import Page from '../../Components/Page/Page';
import { useTranslate } from '../../Translate';
import { FaqsService } from '../../Service/FaqsService';
import { handleLogError, ErrorPage, openNotificationWithIcon } from '../../Helpers/Helpers';
import { useNavigate, Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web'
import languages from '../../utils/languages';
const { confirm } = Modal

const CatNewsList = () => {
  const i18n = useTranslate();
  const { t } = i18n;
  const [loading, setLoading] = useState(false)
  const [cats, setCats] = useState([])
  const navigate = useNavigate();
  const { keycloak } = useKeycloak()
  const getData = async () => {
    setLoading(true);
    try {
        var cats_title = [];
        const response = await FaqsService.getCatNews()
        response.data.map((cat)=>{
          cats_title.push({
            "id": cat.id,
            "title":cat.titolo,
            "language": t("language")+": "+languages.filter( element => element.id ==cat.languageid)[0].name
          })
        })
        setCats(cats_title);
    } catch (error) {
      handleLogError(error); 
    }
    setLoading(false);

  }

  useEffect(() => {
    getData() 
  }, [])

  const handleBack = () => {
    navigate('/admin/news');
  }

  const handleNew = () => {
    navigate('/admin/news/cats/new');
  }

  const handleDelete = (id) => {
    confirm({
      title: t("questiondeleterecord"),
      onOk() {
        onDeleteItem(id)
      },
    })
  
  }

   /**
   * Funzione che rimuove la categoria faq
   * @param {Number} id
   * @returns {undefined}
   */
  const onDeleteItem = async(id) => {
    try {
      setLoading(true);
      const response = await FaqsService.deleteCatNews(keycloak.token,id);
      if (response.status == 200) {
        let newCats = cats.filter((el) => el.id !== id);
        setCats(newCats);
        openNotificationWithIcon('success', "Success","Categoria cancellata con successo!");
      } else if (response.status == 400 || response.status == 404) {
        openNotificationWithIcon('warning', "Warning", "Categoria non trovata!");
      } else{
        openNotificationWithIcon('error', "Error", "Errore nella cancellazione della Categoria!");
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  }


  return (
    <Page loading={loading} inner>
      <>
      <div>
        <Button type='gost' style={{float:'right'}} onClick={handleNew} >{t("new")}</Button>
        <Button type='primary' style={{float:'right',marginRight: '16px'}} onClick={handleBack} >{t("back")}</Button>
      </div>  
        <Divider orientation="left" orientationMargin="0" style={{ color: '#e61e1e' }}>{t("categories")}</Divider>
        <List
          itemLayout="horizontal"
          dataSource={cats}
          renderItem={(item) => (
            <List.Item
              actions={
                [<Link to={'/admin/news/cats/'+item.id}>{t("edit")}</Link>, <a key="list-delete" onClick={()=>handleDelete(item.id)}>{t("delete")}</a>]
                }
            >
              <List.Item.Meta
                title={item.title}
                // description={languages.filter( element => element.id ==item.languageid)[0].name}
                description={item.language}
              />
            </List.Item>
          )}
        />
      </>
    </Page>
  )
}

export default CatNewsList