import React from 'react'
import { Table} from 'antd'
import styles from './List.module.less'
import {SiMicrosoftexcel} from 'react-icons/si';
const List = (props) => {
    const {columns,data,loading,btnexport,handleClickExport} = props;
    return (
        <>
        {btnexport ? <SiMicrosoftexcel title='Export' onClick={handleClickExport} style={{fontSize:'22px',cursor:'pointer'}}/> :"" }
        <Table
            dataSource={data}
            className={styles.table}
            bordered
            scroll={{ x: 1200 }}
            columns={columns}
            loading={loading}
            rowKey={record => record.id}
        />
        </>
    )
}

export default List