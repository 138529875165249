import React from 'react'
import { Button, Row, Col, Form, Input, Select } from 'antd'
import {
    useNavigate,
  } from 'react-router-dom'
  
const { Search } = Input

const ColProps = {
    xs: 24,
    sm: 12,
    style: {
        marginBottom: 16,
    },
}

const TwoColProps = {
    ...ColProps,
    xl: 96,
}

const Filter = ({ accounts,onFilterChange }) => {
    const navigate = useNavigate();
    const formRef = React.createRef()
    /*eventuale normalizzazione dei dati tipo la data*/
    const handleFields = fields => {
        return fields
      }
    const handleSubmit = () => {
        const values = formRef.current.getFieldsValue()
        const fields = handleFields(values)
        onFilterChange(fields);
     }
    
    const handleNew = () => {
        navigate('/admin/profiles/new');
    } 

    /*funzione per il reset del form*/
    const handleReset = () => {
        //ottengo tutti i campi del form con i rispettivi valori
        const fields = formRef.current.getFieldsValue()
        for (let item in fields) {
            if ({}.hasOwnProperty.call(fields, item)) {
              if (fields[item] instanceof Array) {
                fields[item] = []
              } else {
                fields[item] = undefined
              }
            }
          }
        formRef.current.setFieldsValue(fields)  
        console.log(fields);
     }
    let initialCreateTime = []

    const onChange = (value) => {
        console.log(`selected ${value}`);
      };
      
      const onSearch = (value) => {
        console.log('search:', value);
      };
    return (
        <Form ref={formRef} name="control-ref" initialValues={{ createTime: initialCreateTime }}>
            <Row gutter={24}>
                <Col {...ColProps} xl={{ span: 6 }} md={{ span: 10 }} >
                    <Form.Item name="search"  style={{display:'none'}}> 
                        <Search
                            placeholder={`Ricerca`}
                            onSearch={handleSubmit}
                        />
                    </Form.Item>
                </Col>
                <Col
                    {...ColProps}
                    xl={{ span: 6 }}
                    md={{ span: 12 }}
                    id="aziendaCascader"
                >
                    <Form.Item name="accounts"  style={{display:'none'}} >
                        <Select
                            style={{width: '100%'}}
                            showSearch
                            placeholder="Seleziona Azienda"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            options={accounts}
                        />
                    </Form.Item>
                </Col>

                <Col
                    {...TwoColProps}
                    xl={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                >
                    <Row gutter={24} type="flex" align="middle" justify="space-between" style={{border:'0px solid'}}>
                        <div>
                            <Button
                                type="primary" htmlType="submit"
                                style={{ marginRight: '16px',display:'none' }}
                                onClick={handleSubmit}
                                
                            >
                                Cerca
                            </Button>
                            <Button onClick={handleReset} style={{display:'none'}}>
                                Reset
                            </Button>
                        </div>
                        <Button type="ghost" onClick={handleNew}>
                            Nuovo
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default Filter