import { createSlice,createAction } from "@reduxjs/toolkit";
import { api, setHeader } from "../../Helpers/Helpers";

export const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  apps: [],
  appsBuy:[],
  categories: [],
  allcategories:[],
  groups: [],
  app: {},
  category: {},
  preview: {},
};


const adminAppsSlice = createSlice({
  name: "adminapps",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setApps: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.apps = payload;
    },

    setAppsBuy: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.appsBuy = payload;
    },

    deleteData: (state, action) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.apps.content = state.apps.content.filter((el) => el.id !== action.payload);
    },
    deleteDataCategory:(state,action) =>{
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.categories = state.categories.filter((el) => el.id !== action.payload);
    },
    setApp: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.app = payload;
    },
    setPreview: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.preview = payload;
    },
    cleanPreview: (state) => {
      //state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.preview = {};
    },
    setCategory: (state, { payload }) => {
     // state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.category = payload;
    },
    saveData: (state, action) => {
      state.loading = false;
      // state.apps.push(action.payload);
      // state.app = action.payload;
      state.error.status = false;
      state.error.message = "";
      state.error.code = 200;
    },
    saveDataCategory: (state) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = 200;
    },
    setCategoriesApps: (state, { payload }) => {
      //state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.categories = payload;
    },

    setCategoriesAppsAll: (state, { payload }) => {
      //state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.allcategories = payload;
    },
    setGroups: (state, { payload }) => {
     // state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.groups = payload;
    },
    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },

    cleanCategory: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.category = {};
    },
    cleanApp:(state) =>{
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.app = {};
    },
    setOK: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code =payload.status;
      state.preview = {};
    },

   
  },
});

// export the actions
export const {
  setLoading,
  setApps,
  saveData,
  setError,
  cleanError,
  setApp,
  setCategoriesApps,
  setGroups,
  setCategory,
  saveDataCategory,
  cleanCategory,deleteData,
  cleanApp,
  setAppsBuy,
  setCategoriesAppsAll,
  setPreview,
  cleanPreview,
  deleteDataCategory,
  setOK
} = adminAppsSlice.actions;

// export the default reducer
export default adminAppsSlice.reducer;

export const fetchApps =
  (token, userid = "", filter = "",buy=false) =>
  async (dispatch) => {
    dispatch(clearPreview());
    dispatch(setLoading());
    dispatch(cleanError());
    let path =""
    filter = filter ==="" ? "?showImmaginiDettaglio=false" :  filter +"&showImmaginiDettaglio=false";

    buy ? path = "/tsapps/api/admin/applist/owned/all"+filter: path = "/tsapps/api/admin/applist" + filter;
    try {
      const response = await api.get(path, setHeader(token, userid));
      buy ?dispatch(setAppsBuy(response.data)) : dispatch(setApps(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

  /*QUESTA ELIMINAZIONE LOGICA DALLO STORE DELL APP*/ 
  export const deleteApp = (token, userid, id) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(cleanError());
    let path = "/tsapps/api/admin/app/"+id;
    try {
      await api.delete(path, setHeader(token, userid));
      dispatch(deleteData(id));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };  

export const saveApp = (token, userid, data) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  let path = "/tsapps/api/admin/app";
  try {
    const response = await api.post(path, data, setHeader(token, userid));
    dispatch(saveData(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const updateApp = (token, userid, data) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  let path = "/tsapps/api/admin/app";
  try {
    const response = await api.put(path, data, setHeader(token, userid));
    dispatch(saveData(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const getApp = (token, userid, appid) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  let path = "/tsapps/api/admin/app/" + appid + "?lang=all";
  try {
    const response = await api.get(path, setHeader(token, userid));
    dispatch(setApp(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const getCategory = (token, userid, categoryid) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  let path = "tsapps/api/public/categoriaapp/" + categoryid + "?lang=all";
  try {
    const response = await api.get(path, setHeader(token, userid));
    dispatch(setCategory(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const fetchCategoriesApps =
  (lang = "it") =>
  async (dispatch) => {
    try {
      const response = await api.get(
        "/tsapps/api/public/categorieapp?lang=" + lang,
        setHeader(null, null)
      );
      dispatch(setCategoriesApps(response.data));
    } catch (err) {
      dispatch(setError());
    }
  };


  export const fetchCategoriesAppsAll =
  () =>
  async (dispatch) => {
    dispatch(clearPreview());
    try {
      const response = await api.get(
        "/tsapps/api/public/categorieapp?lang=all",
        setHeader(null, null)
      );
      dispatch(setCategoriesAppsAll(response.data));
    } catch (err) {
      dispatch(setError());
    }
  };


export const fetchGroups = () => async (dispatch) => {
  try {
    const response = await api.get(
      "/tsbadmin/api/public/accounts-lk",
      setHeader(null, null)
    );
    dispatch(setGroups(response.data));
  } catch (err) {
    dispatch(setError());
  }
};

export const saveCategory = (token, userid, data) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  let path = "tsapps/api/admin/categoriaapp";
  try {
    const response = await api.post(path, data, setHeader(token, userid));
    dispatch(saveDataCategory(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const updateCategory = (token, userid, data) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  let path = "tsapps/api/admin/categoriaapp";
  try {
    const response = await api.put(path, data, setHeader(token, userid));
    dispatch(saveDataCategory(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};


 export const deleteCategory = (token,id) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(clearPreview());
  dispatch(cleanError());
  let path = "/tsapps/api/admin/categoriaapp/"+id;
  try {
    await api.delete(path, setHeader(token,null));
    dispatch(deleteDataCategory(id));
  } catch (err) {
    dispatch(setError(err.response));
  }
};  

export const clearCategory = () => async (dispatch) => {
  dispatch(cleanCategory());
};

export const buyPreview =
  (token, appId, groupId) =>
  async (dispatch) => {
    dispatch(clearPreview());
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const path = "tsapps/api/admin/app/buypreview/" + appId + "/" + groupId;
      const response = await api.post(path, [], setHeader(token, null));
      dispatch(setPreview(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

  export const clearPreview = () => async (dispatch) => {
    dispatch(cleanPreview());
  };
  

  export const buyApp =
  (token, appId, groupId) =>
  async (dispatch) => {
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const path = "tsapps/api/admin/app/buy/" + appId + "/" + groupId;
      const response = await api.post(path, [], setHeader(token, null));
      var v = setOK(response);
      dispatch(v);
    } catch (err) {
      var h = setError(err.response);
      dispatch(h);
    }
  };


