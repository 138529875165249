/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useEffect, useState }  from 'react'
import List from './Components/List';
import Page from '../../Components/Page/Page';
import Filter from './Components/Filter';
import { handleLogError, ErrorPage,openNotificationWithIcon } from '../../Helpers/Helpers';
import { useKeycloak } from '@react-keycloak/web'
import {
  useNavigate,
} from 'react-router-dom';

import { useTranslate } from '../../Translate';
import { FaqsService } from '../../Service/FaqsService';
import languages from '../../utils/languages'; 

const FaqList = (props) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const { type } = props;
  const [faqs, setFaqs] = useState([])
  const [cats, setCats] = useState([])
  const { keycloak } = useKeycloak()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filteredData, setfilteredData] = useState([]);
  const navigate = useNavigate();

  const [languageslk, setLanguageslk] = useState([])

  const getDataLanguages = () => {
    const options = [];
    setLanguageslk(options);
    languages.forEach((language) => {
      const value = language.id
      options.push({
        label: language.name,
        value
      });
    })
  }

  const onFilterChange = (filter) => {
    setfilteredData(filter)
  }

  const getData = async () => {
    try {
      
      if (filteredData.length === 0){
        const response = await FaqsService.getFaqs();
        setFaqs(response.data);
        ErrorPage(response.status, navigate);
        const responseCats= await FaqsService.getCatFaqs(null)
        const options = [];
        setCats(options);
        ErrorPage(responseCats.status, navigate);
        responseCats.data.map((cat) => {
          const value = cat.id
          options.push({
            label: cat.titolo,
            value
          });
          return cat;
        })
      }else{
        const response = await FaqsService.search(filteredData);
        setFaqs(response.data);
        ErrorPage(response.status, navigate);
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  }
  

  useEffect(() => {
    setLoading(true)
    getDataLanguages()
    getData()
  }, [filteredData])


  const onEditItem = (id) => {
    console.log(id)
    navigate('/admin/faqs/'+id);
  }

  /**
   * Funzione che rimuove le faqs
   * @param {Number} id
   * @returns {undefined}
   */
   const onDeleteItem = async (id) => {
    try {
      setLoading(true);
      const response = await FaqsService.deleteFaq(keycloak.token,id);
      if (response.status == 200) {
        let newFaqs = faqs.filter((el) => el.id !== id);
        setFaqs(newFaqs);
        openNotificationWithIcon('success', "Success","Faq cancellata con successo!");
      } else if (response.status == 400 || response.status == 404) {
        openNotificationWithIcon('warning', "Warning", "Faq non trovata!");
      } else{
        openNotificationWithIcon('error', "Error", "Errore nella cancellazione della Faq!");
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  }

  return (
    <Page inner>
      <Filter onFilterChange={onFilterChange} type={type} cats={cats} languageslk={languageslk} />
      <List  faqs={faqs} loading={loading} onEditItem={onEditItem} onDeleteItem={onDeleteItem} />
    </Page>
  )
}

export default FaqList