/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import axios from 'axios'
import { config } from '../utils/Constants'


export const ProfilesService = {
    getProfiles,
    getAccounts,
    save,
    deleteGroup,
    getPermissions,
    getProlieById,
    savePermissions
    
}
// -- Axios

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response;
}, function (error) {
    console.log(error) 
    return error.response;
});

// -- Helper functions
function bearerAuth(token) {
    return `Bearer ${token}`
}

function getProfiles(token) {
    return instance.get('/tsbadmin/api/groups/', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}

function getAccounts(token) {
    return instance.get('/tsbadmin/api/groups/lkaccounts', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}


function save(token, data) {
    return instance.post('/tsbadmin/api/groups/', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
    
}

function deleteGroup(token, id) {
    return instance.delete('/tsbadmin/api/groups/' + id, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
}


function getProlieById(token, id) {
    return instance.get('/tsbadmin/api/groups/details/' + id, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    }) 
}

/* SEZIONE DEDICATA AI PERMESSI*/

function getPermissions(token, id, filter) {
    return instance.get('tsbadmin/api/roles/role-by-group/' + id, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        },
        params: filter
    })
}

function savePermissions(token, id,data) {
    return instance.post('/tsbadmin/api/roles/role-by-group/'+id, data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
    
}
