/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import List from "./Components/List";
import Page from "../../Components/Page/Page";
import { useTranslate } from "../../Translate";
import { Result, Modal, Divider } from "antd";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllDocs,
  fetchDoc,
  cleanDocument,
  cleanDocument_download,
  deleteDocument,
} from "../../redux/reducers/document-reducer";
import { useParams } from "react-router-dom";
import { fetchAppDetail } from "../../redux/reducers/store-detail-reducer";
import Filter from "./Components/Filter";
import { AiOutlineDownload, AiOutlineDelete } from "react-icons/ai";
import moment from 'moment';

const { confirm } = Modal;

const Documents = (props) => {
  const { type,docstype } = props;
  const i18n = useTranslate();
  var { id } = useParams();
  var appId= null;
  if(docstype !==undefined){
    const arrayIds = id.split('@');
    // eslint-disable-next-line no-const-assign
    const idBuy=parseInt(arrayIds[1]);
    appId= parseInt(arrayIds[0]);
    id = idBuy;
  }
  const { keycloak } = useKeycloak();
  const userid = keycloak?.tokenParsed?.sub?.toString();
  const { t } = i18n;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  var stati = {
    0: "ACQUISITO",
    1: "CONSEGNATO",
    2: "NON CONSEGNABILE",
    3: "NON CONSEGNATO E CANCELLATO",
    4: "CONSEGNATO E CANCELLATO",
    5: "ELEBORATO",
    6: "ELABORATO E CANCELLATO"
  };
  // fetch data from our store
  const { loading, error, documents, document_download } = useSelector(
    (state) => state.documents
  );
  const { app } = useSelector((state) => state.appDetail);
  const columns = [
    {
      title: t("name"),
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: t("description"),
      dataIndex: "descrizione",
      key: "descrizione",
    },
    {
      title: t("creationdate"),
      dataIndex: "data_creazione",
      key: "data_creazione",
      render: (text, record) => (
        <>
          {moment(record.data_creazione, 'YYYY-MM-DD HH:mm:ss').locale('it').format('DD/MM/YYYY HH:mm:ss')}
        </>
      ),
    },
    {
      title: t("operation"),
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: "9%",
      render: (text, record) => {
        return (
          <div>
            <AiOutlineDownload
              title={t("download")}
              onClick={(e) => handleDownload(record, e)}
              style={{
                fontSize: "22px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
            {type==="admin" || docstype =="cs" ?<AiOutlineDelete
              title={t("delete")}
              onClick={(e) => handleDelete(record, e)}
              style={{ fontSize: "22px", cursor: "pointer" }}
            /> : "" }
          </div>
          //   <DropOption
          //     onMenuClick={(e) => handleMenuClick(record, e)}
          //     menuOptions={
          //       type === "admin"
          //         ? [
          //             { key: "2", name: t("download") },
          //             { key: "3", name: t("delete") },
          //           ]
          //         : [{ key: "2", name: t("download") }]
          //     }
          //   />
        );
      },
    },
  ];


  const columnsCS = [
    {
      title: t("name"),
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: t("description"),
      dataIndex: "descrizione",
      key: "descrizione",
    },
    {
      title: t("state"),
      dataIndex: "stato",
      key: "stato",
      align: 'center',
      render: (text, record) => (
        <>
          <span style={{color:'#e61e1e',fontWeight:'bold'}}>{ stati[record.stato]}</span>
        </>
      ),
    },
    {
      title: t("creationdate"),
      dataIndex: "data_creazione",
      key: "data_creazione",
      render: (text, record) => (
        <>
          {moment(record.data_creazione, 'YYYY-MM-DD HH:mm:ss').locale('it').format('DD/MM/YYYY HH:mm:ss')}
        </>
      ),
    },
    {
      title: t("operation"),
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: "9%",
      render: (text, record) => {
        return (
          <div>
            <AiOutlineDownload
              title={t("download")}
              onClick={(e) => handleDownload(record, e)}
              style={{
                fontSize: "22px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
            {type==="admin" || docstype =="cs" ?<AiOutlineDelete
              title={t("delete")}
              onClick={(e) => handleDelete(record, e)}
              style={{ fontSize: "22px", cursor: "pointer" }}
            /> : "" }
          </div>
        );
      },
    },
  ];


  //   const handleMenuClick = (record, e) => {
  //     if (e.key === "1") {
  //     } else if (e.key === "2") {
  //     } else if (e.key === "3") {
  //     }
  //   };

  const handleDownload = (record, e) => {
    dispatch(fetchDoc(keycloak.token, userid, record.id, type,docstype));
  };

  const downloadFile = (base64, filename) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = `data:application/octet-stream;base64,${base64}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = (record, e) => {
    confirm({
      title: t("questiondeletefile"),
      onOk() {
        dispatch(deleteDocument(keycloak.token, userid, record.id,docstype));
      },
    });
  };

  const handleUpload = () => {
    if(docstype !=undefined){
      navigate("/myservices/"+ appId +"@"+id+"/cs/document/");
    }else{
      navigate("/admin/services/" + id + "/document/");
    }
  };

  //hook to fetch single app
  useEffect(() => {
    if(docstype == undefined){
      dispatch(fetchAppDetail(keycloak.token, userid, id));
    }else{
      dispatch(fetchAppDetail(keycloak.token, userid, appId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllDocs(keycloak.token, userid, id, type,docstype));
    dispatch(cleanDocument());
  }, [dispatch]);

  useEffect(() => {
    if (JSON.stringify(document_download) !== JSON.stringify({})) {
      const base64 = document_download.file_data; // stringa in base64
      const decodedData = window.atob(base64);// decode in base64 (ottengo una nuova stringa in base64)
      /*estrazione del file */
      let w1 = "data:";
      let w2 = ";";
      const start = decodedData.indexOf(w1) + w1.length;
      const end = decodedData.indexOf(w2, start);
      const type = decodedData.substring(start, end);
      let w = ",";
      const end2 = decodedData.indexOf(w) + w.length;
      const deleteString = decodedData.substring(end2, 0);
      const arr = decodedData.split(deleteString);
      downloadFile(arr[1], document_download.nome);
      dispatch(cleanDocument_download());
    }
  }, [document_download]);

  return (
    <Page inner>
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ color: "#e61e1e" }}
      >
        {app.titolo}
      </Divider>
      {type ==="admin" || docstype ==="cs" ? <Filter handleUpload={handleUpload} /> :""}
      {!error.status ? (
        <List loading={loading} columns={docstype === undefined ? columns : columnsCS} documents={documents} />
      ) : (
        <Result status="warning" title="Items not available at this time." />
      )}
    </Page>
  );
};

export default Documents;
