import React, { useState,useEffect } from 'react'
import Page from '../../Components/Page/Page'
import { useKeycloak } from '@react-keycloak/web'
import {ProfilesService} from '../../Service/ProfilesService' 
import {
    Divider,
    Button,
    Form,
    Input,
    Select
} from 'antd';

import { handleLogError, ErrorPage,openNotificationWithIcon } from '../../Helpers/Helpers';

import { useTranslate } from '../../Translate';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },

    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },

    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 2,
            offset: 2,
        },
        sm: {
            span: 8,
            offset: 8,
        },
    },
};


const Profile = () => {
    //gestione della lingua
    const i18n = useTranslate();
    const { t } = i18n;
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [groups_children, setGroups_children] = useState([])
    const navigate = useNavigate();
    const { keycloak } = useKeycloak()


    const onFinish = (values) => {
        handleSendData(values);
    };

    var sel_accounts = "";

    const handleSendData = async (data) => {
        try {
            setLoading(true);
            const response = await ProfilesService.save(keycloak.token,data) 
            if(response.status === 201){
                openNotificationWithIcon('success',response.data.type,response.data.messaggio);
                navigate('/admin/profiles');
            }else if(response.status === 400){
                openNotificationWithIcon('warning',response.data.type,response.data.messaggio);
            }else{
                ErrorPage(response.status,navigate);
            }
        } catch (error) {
            handleLogError(error);
            setLoading(false);
        }
        setLoading(false);
    }

    const handleBack = () => {
        navigate('/admin/profiles');
    }

    const getAccounts = async () => {
        try {
            const response = await ProfilesService.getAccounts(keycloak.token)
            ErrorPage(response.status, navigate);
             //popolo la lookup degli accounts
            const lk_accounts = []; 
            response.data.content.map((account)=>{
                lk_accounts.push(<Option value={account.id} key={account.id}>{account.companyname.trim()}</Option>);
                return true;
            });
            setGroups_children(lk_accounts)
        } catch (error) {
            handleLogError(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true)
        getAccounts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <Page loading={loading} inner>
            <>
                <Divider orientation="left" orientationMargin="0" style={{ color: '#e61e1e' }}>{t("newProfile")}</Divider>
                <Form
                    {...formItemLayout}
                    form={form}
                    layout="vertical"
                    name="profile"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Form.Item
                        label={t('profileName')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('ckProfileName'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t('group')}
                        name="group"
                    >
                        <Select
                            autoComplete="none"
                            showSearch
                            style={{
                                width: '100%',
                            }}
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase().trim())
                            }
                            optionFilterProp="children"
                            defaultValue={sel_accounts}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase().trim())}
                            allowClear
                        >
                            {groups_children}
                        </Select>
                    </Form.Item>
                    <Form.Item></Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            {t("save")}
                        </Button>
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={handleBack}
                        >
                            {t("cancel")}
                        </Button>
                    </Form.Item>

                </Form>
            </>
        </Page>
    )
}

export default Profile