import React, { useEffect, useState }  from 'react'
import List from './Components/List';
import Page from '../../Components/Page/Page';
import Filter from './Components/Filter';
import { handleLogError, ErrorPage,openNotificationWithIcon } from '../../Helpers/Helpers';
import { useKeycloak } from '@react-keycloak/web'
import {
  useNavigate,
} from 'react-router-dom';
import languages from '../../utils/languages';
import { FaqsService } from '../../Service/FaqsService';

const NewsList = (props) => {
    const { type } = props;
    const [news, setNews] = useState([])
    const [cats, setCats] = useState([])
    const { keycloak } = useKeycloak()
    const [loading, setLoading] = useState(false)
    const [filteredData, setfilteredData] = useState([]);
    const [languageslk, setLanguageslk] = useState([])

    const getDataLanguages = () => {
      const options = [];
      setLanguageslk(options);
      languages.forEach((language) => {
        const value = language.id
        options.push({
          label: language.name,
          value
        });
      })
    }
    const navigate = useNavigate();

    const onFilterChange = (filter) => {
      setfilteredData(filter)
    }
  
    const getData = async () => {
      try {
        
        if (filteredData.length === 0){
          const response = await FaqsService.getAllNews(keycloak.token);
          setNews(response.data);
          ErrorPage(response.status, navigate);
          const responseCats= await FaqsService.getCatNews()
          const options = [];
          setCats(options);
          ErrorPage(responseCats.status, navigate);
          responseCats.data.map((cat) => {
            const value = cat.id
            options.push({
              label: cat.titolo,
              value
            });
            return true;
          })
        }else{
          const response = await FaqsService.searchNews(keycloak.token,filteredData);
          setNews(response.data);
          ErrorPage(response.status, navigate);
        }
      } catch (error) {
        handleLogError(error);
      }
      setLoading(false);
    }
    
  
    useEffect(() => {
      setLoading(true)
      getDataLanguages()
      getData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData])
  
  
    const onEditItem = (id) => {
      navigate('/admin/news/'+id);
    }
  
    /**
     * Funzione che rimuove le news
     * @param {Number} id
     * @returns {undefined}
     */
     const onDeleteItem = async (id) => {
      try {
        setLoading(true);
        const response = await FaqsService.deleteNews(keycloak.token,id);
        if (response.status === 200) {
          let nNews = news.filter((el) => el.id !== id);
          setNews(nNews);
          openNotificationWithIcon('success', "Success","News cancellata con successo!");
        } else if (response.status === 400 || response.status === 404) {
          openNotificationWithIcon('warning', "Warning", "News non trovata!");
        } else{
          openNotificationWithIcon('error', "Error", "Errore nella cancellazione della News!");
        }
      } catch (error) {
        handleLogError(error);
      }
      setLoading(false);
    }
  
    return (
      <Page inner>
        <Filter onFilterChange={onFilterChange} type={type} cats={cats} languageslk={languageslk} />
        <List  news={news} loading={loading} onEditItem={onEditItem} onDeleteItem={onDeleteItem} />
      </Page>
    )
}

export default NewsList