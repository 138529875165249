/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import List from "./Components/List";
import Page from "../../Components/Page/Page";
import { useTranslate } from "../../Translate";
import AntdList from "antd/lib/list";  // Cambia il nome a tuo piacimento
import { Result, Modal, Divider, Spin } from "antd";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShipments,
  fetchStatus,
  confirmShipping,
  cleanError,
} from "../../redux/reducers/shipping-reducer";
import { useParams } from "react-router-dom";
import { AiOutlineSync, AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import moment from "moment";
import Filter from "./Components/Filter";

const { confirm } = Modal;

const Shipments = () => {
  const { keycloak } = useKeycloak();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userid = keycloak?.tokenParsed?.sub?.toString();
  const i18n = useTranslate();
  const { t } = i18n;

  var { id } = useParams();
  console.log(id);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //   fetch data from our store
  const { loading, error, shipments, confirmData, statusShipping } =
    useSelector((state) => state.shipments);

  const columns = [
    {
      title: t("ordernumber"),
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: t("tipoServizio"),
      dataIndex: "tipoServizio",
      key: "tipoServizio",
    },
    {
      title: t("statoFunnel"),
      dataIndex: "statoFunnel",
      key: "statoFunnel",
    },
    {
      title: t("descrizione"),
      dataIndex: "nomeFile",
      key: "nomeFile",
    },
    {
      title: t("importo"),
      dataIndex: "importoTotale",
      key: "importoTotale",
      render: (text, record) => (
        <>
          {record.importoTotale.toLocaleString("it-IT", {
            style: "currency",
            currency: "EUR",
          })}
        </>
      ),
    },

    {
      title: t("dataultimamodifica"),
      dataIndex: "dataUltimoAggiornamento",
      key: "dataUltimoAggiornamento",
      render: (text, record) => (
        <>
          {moment(record.dataUltimoAggiornamento, "YYYY-MM-DD HH:mm:ss")
            .locale("it")
            .format("DD/MM/YYYY HH:mm:ss")}
        </>
      ),
    },
    {
      title: t("operation"),
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: "9%",
      render: (text, record) => {
        return (
          <div>
            {record.statoFunnel != "CONFERMATO" &&
            record.statoFunnel != "CANCELLATO" && record.statoFunnel != "BOZZA"  ? (
              <>
                <AiOutlineCheck
                  title={t("confermaspedizione")}
                  onClick={(e) => handleConfirmShipping(record, true, e)}
                  style={{
                    fontSize: "18px",
                    marginRight: "10px",
                    cursor: "pointer",
                    color: "green",
                  }}
                />
                <AiOutlineClose
                  title={t("cancellaspedizione")}
                  onClick={(e) => handleConfirmShipping(record, false, e)}
                  style={{
                    fontSize: "18px",
                    marginRight: "10px",
                    cursor: "pointer",
                    color: "red",
                  }}
                />
              </>
            ) : (
              ""
            )}
            {record.statoFunnel =="BOZZA" ?  < AiOutlineEdit
              title={t("completaspedizione")}
              onClick={(e) => handleEdit(record, e)}
              style={{
                fontSize: "18px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />:""}
             {record.statoFunnel =="CONFERMATO" ? 
            <AiOutlineSync
              title={t("verificaspedizione")}
              onClick={(e) => handleCkStato(record, e)}
              style={{
                fontSize: "18px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />:""
          }
   
          </div>
        );
      },
    },
  ];

  const handleCkStato = (record, e) => {
    statusCk(record.id);
    showModal();
  };
  const handleEdit= (record, e) => {
    navigate("/myservices/" + id + "/shipping/"+record.id);
  };

  const handleConfirmShipping = (record, ck, e) => {
    confirm({
      title: ck
        ? t`La spedizione verrà confermata. Procedere?`
        : t`La spedizione verrà cancellata. Procedere?`,
      onOk() {
        var data = {};
        data.ordineId = record.id;
        data.status = ck ? "confirm" : "delete";
        conferma(data);
      },
    });
  };

  const conferma = (data) => {
    dispatch(confirmShipping(keycloak.token, userid, data));
  };

  const statusCk = (id) => {
    dispatch(fetchStatus(keycloak.token, userid, id));
  };

  useEffect(() => {
    if (Object.keys(confirmData).length > 0) {
      dispatch(cleanError());
      dispatch(fetchShipments(keycloak.token, userid, id));
    }
  }, [confirmData]);

  useEffect(() => {
    dispatch(fetchShipments(keycloak.token, userid, id));
  }, [dispatch]);

  const handleNew = () => {
    navigate("/myservices/" + id + "/shipping/");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  console.log(statusShipping);

  return (
    <Page inner>
      <Divider
        orientation="left"
        orientationMargin="0"
        style={{ color: "#e61e1e" }}
      >
        {t("shipments")}
      </Divider>
      <Filter handleNew={handleNew} />
      {!error.status ? (
        <List loading={loading} columns={columns} shipments={shipments} />
      ) : (
        <Result status="warning" title="Items not available at this time." />
      )}
      <Modal
        title={t("verificastatospedizione")}
        open={isModalOpen}
        onCancel={handleCancel}
        width={500}
        footer={null} // Imposta il footer a null per rimuovere i pulsanti OK e Annulla
      >
        <>

      {Object.keys(statusShipping).length > 0 ? (
           <Spin spinning={loading}>
        <AntdList
          itemLayout="horizontal"
          dataSource={statusShipping.data}
          style={{ maxHeight: "400px", overflowY: "auto" }} // Imposta l'altezza massima desiderata
          renderItem={(item, index) => (
            <AntdList.Item        >
              <AntdList.Item.Meta
                title={
                   <a  href="#">
                    {item.descrizione }
                   </a>
                }
                description={
                  `${item.numeroServizio ?? ''} ${item.data ?? ''} ${item.data ?? ''}`
                }
              />
            </AntdList.Item>
          )}
        />
        </Spin>
      ) : (
        <Result status="warning" title="Items not available at this time." />
      )}
    </>
      </Modal>
    </Page>
  );
};

export default Shipments;
