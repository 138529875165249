import React, { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import {
  useNavigate,
} from 'react-router-dom'
import { ProfilesService } from '../../Service/ProfilesService'
import { handleLogError, ErrorPage, openNotificationWithIcon } from '../../Helpers/Helpers';
import List from './Components/List';
import Filter from './Components/Filter';
import Page from '../../Components/Page/Page';

const Profiles = () => {
  const accounts = [];
  const [profiles, setProfiles] = useState([])
  const [loading, setLoading] = useState(false)
  const { keycloak } = useKeycloak()
  const navigate = useNavigate();

  /**
   * Funzione che rimuove gli elementi e aggiorna lo state _ profiles
   * @param {Number} id
   * @returns {undefined}
   */
  const onDeleteItem = async (id) => {
    try {
      setLoading(true);
      const response = await ProfilesService.deleteGroup(keycloak.token, id)
      if (response.status === 200) {
        let newProfiles = profiles.filter((el) => el.id !== id);
        setProfiles(newProfiles);
        openNotificationWithIcon('success', response.data.type, response.data.messaggio);
      } else if (response.status === 400) {
        openNotificationWithIcon('warning', response.data.type, response.data.messaggio);
      } else {
        ErrorPage(response.status, navigate);
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  }

  const onSelectItem = (id) => {
     navigate('/admin/profiles/'+id);
  }

  const onFilterChange = (filteredData) => {

    console.log(filteredData)
  }


   /**
   * Funzione che ritorna gli elementi e aggiorna lo state _ profiles
   * 
   * @returns {undefined}
   */

  const getProfiles = async () => {
    try {
      const response = await ProfilesService.getProfiles(keycloak.token)
      setProfiles(response.data);
      ErrorPage(response.status, navigate);
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true)
    getProfiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page inner>
      <>
        <Filter onFilterChange={onFilterChange} accounts={accounts} />
        <List profiles={profiles} loading={loading} onDeleteItem={onDeleteItem} onSelectItem = {onSelectItem}/>
      </>
    </Page>


  )
}

export default Profiles