import { createSlice } from "@reduxjs/toolkit";
import { api, setHeader } from "../../Helpers/Helpers";

// initial state
export const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  documents: [],
  document:{},
  document_download:{}
};

// our slice
const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setDocuments: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.documents = payload;
    },
    setDocument: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.document = payload;
    },
    setDocument_download: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.document_download = payload;
    },


    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
    },
    cleanDocument:(state) =>{
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.document = {};
    },
    cleanDocument_download:(state) =>{
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.document_download = {};
    },
    deleteData: (state, action) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.documents = state.documents.filter((el) => el.id !== action.payload);
    }
  },
});

// export the actions
export const {
  setLoading,
  setDocuments,
  setError,
  setDocument,
  cleanError,
  deleteData,
  cleanDocument,
  setDocument_download,
  cleanDocument_download,
} = documentsSlice.actions;

// export the default reducer
export default documentsSlice.reducer;

export const fetchAllDocs =
  (token, userid = "", id,type,docstype) =>
  async (dispatch) => {
    let path="";
    console.log(docstype);
    if(docstype !==undefined){
      path = "/tsapps/api/appowned/" + id + "/mfp/files";
    }else{
      path = type==="admin" ? "/tsapps/api/admin/app/"+id+"/files" : "/tsapps/api/app/"+id+"/files";
    }
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setDocuments(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

  export const fetchDoc =
  (token, userid = "", id,type,docstype) =>
  async (dispatch) => {
    let path =""
    if(docstype !==undefined){
      path = "/tsapps/api/admin/mfp/file/"+id;
    }else{
      path = type==="admin" ? "/tsapps/api/admin/file/"+id : "/tsapps/api/file/"+id;
    }

    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setDocument_download(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };


  export const uploadFile =
  (token, userid = "", data,docstype) =>
  async (dispatch) => {
    let path="";
    if(docstype !==undefined){
      path = "/tsapps/api/mfp/file";
    }else{
      path = "/tsapps/api/admin/file";
    }
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const response = await api.post(path,data, setHeader(token, userid));
      dispatch(setDocument(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };


  export const deleteDocument = (token, userid, id,docstype) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(cleanError());
    let path="";
    if(docstype !==undefined){
      path = "/tsapps/api/admin/mfp/file/"+id;
    }else{
      path = "/tsapps/api/admin/file/"+id;
    }
    try {
      await api.delete(path, setHeader(token, userid));
      dispatch(deleteData(id));
    } catch (err) {
      dispatch(setError(err.response));
    }
  }; 


  
 

