import { configureStore } from "@reduxjs/toolkit"
import appsReducer from "./reducers/store-reducer";
import appDetailReducer from "./reducers/store-detail-reducer";
import adminAppsReducer from "./reducers/admin-app-reducer";
import consumptionReducer from "./reducers/consumption-reducer";
import docuementsReducer from "./reducers/document-reducer";
import shipmentsReducer from "./reducers/shipping-reducer";
import contactsReducer from "./reducers/contact-reducer";

export const store = configureStore({
  reducer: {
    apps: appsReducer,
    appDetail: appDetailReducer,
    adminApps: adminAppsReducer,
    consumption: consumptionReducer,
    documents: docuementsReducer,
    shipments: shipmentsReducer,
    contacts: contactsReducer
  },
});