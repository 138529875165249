/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState } from "react";
import { Layout, Menu, Input,Tooltip,Form } from "antd";
import { Link } from "react-router-dom";
import config from "../../utils/Config";
import { useKeycloak } from "@react-keycloak/web";
import { isAdmin } from "../../Helpers/Helpers";
import styles from "./Sider.module.less";
//import ScrollBar from '../ScrollBar'
import "react-perfect-scrollbar/dist/css/styles.css";
import ScrollBar from "../ScrollBar/index";
import {
  getItemFromLocalStorage,
  setLocalStorageItem,
} from "../../Helpers/Helpers";
import { SearchOutlined,CloseCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}

const Sidebar = ({ routes, collapsed }) => {
  const formRef = React.createRef();
  const navigate = useNavigate();
  const rootSubmenuKeys = [
    "admin_groups_index",
    "admin_accounts_index",
    "listapp_index",
  ];
  const indexMenu = getItemFromLocalStorage("indexmenu");
  const indexMenuOpen = getItemFromLocalStorage("indexmenuOpen");
  const [selectedKeys, SetSelectedKeys] = useState(
    [indexMenu] || ["store_index"]
  );
  const [openKeys, setOpenKeys] = useState([indexMenuOpen] || "");
  const { keycloak } = useKeycloak();
  const createLinks = (routes) => {
    const items = [];
    routes.map((route) => {
      /*Richiesto ruolo admin per visualizzare la voce di menu*/
      if (route.isadmin) {
        if (!getAdmin()) {
          return false;
        }
      }
      if (route.private) {
        if (!isAuthenticated()) {
          return false;
        }
      }
      if (route.isSidemenu) {
        if (route.children) {
          let c = createLinks(route.children);
          if(!route.hide)
            route.path
              ? items.push(
                  getItem(
                    <Link to={route.path}> {route.name}</Link>,
                    route.key,
                    <route.icon />,
                    c
                  )
                )
              : items.push(getItem(route.name, route.key, <route.icon />, c));
        } else {
          //style={(route.isadmin) ? getAdminMenuStyle() : '{}'}
          if(!route.hide)
            route.path
              ? items.push(
                  getItem(
                    <Link to={route.path}> {route.name}</Link>,
                    route.key,
                    <route.icon />
                  )
                )
              : items.push(getItem(route.name, route.key, <route.icon />));
        }
      }
      return route;
    });
    return items;
  };

  const getAdmin = () => {
    var result = keycloak.authenticated && isAdmin(keycloak) ? true : false;
    //console.log("::::".result)
    return result;
  };

  const isAuthenticated = () => {
    var result = keycloak.authenticated ? true : false;
    return result;
  };

  const onClickMenu = (e) => {
    setLocalStorageItem("indexmenu", e.key);
    SetSelectedKeys(e.key);
    if (e.keyPath.length > 1) {
      setLocalStorageItem("indexmenuOpen", e.keyPath[1]);
      setOpenKeys(e.keyPath[1]);
    }
    //console.log('click ', e);
  };
  const handleClear = () => {
    const fields = formRef.current.getFieldsValue();
    for (let item in fields) {
      if ({}.hasOwnProperty.call(fields, item)) {
        if (fields[item] instanceof Array) {
          fields[item] = [];
        } else {
          fields[item] = undefined;
        }
      }
    }
    formRef.current.setFieldsValue(fields);
    navigate({
      pathname: "/store",
      search: "?fieldSearch=",
    });
  }
  const onChange = (e, value) => {
    if (e.key === "Enter") {
      e.preventDefault();
      //alert(e.target.value);
      // if (e.target.value !== "" && e.target.value !== undefined) {
        if (e.target.value !== undefined) {  
        navigate({
          pathname: "/store",
          search: "?fieldSearch=" + e.target.value,
        });
      }
    }
  };

  return (
    // <div className={styles.menuContainer}>

    <Sider
      style={{
        //overflow: "auto",
       // height: "100%",
        // position: 'fixed',
        left: 0,
      }}
      breakpoint="sm"
      collapsedWidth="0"
      collapsible
      collapsed={collapsed}
      trigger={null}
      theme="light"
      className={styles.sider}
    >
      <ScrollBar
        options={{
          // Disabled horizontal scrolling, https://github.com/utatti/perfect-scrollbar#options
          suppressScrollX: true,
        }}
      >
        <div className={styles.brand}>
          <div className={styles.logo}>
            <Link to="/">
              <img alt="logo" title="Home" src={config.logoPath} />{" "}
            </Link>
          </div>
        </div>
        <div className={styles.search}>
        <Form ref={formRef} name="control-ref">
        <Form.Item name="search">
          <Input
            placeholder="Cerca nello store"
            onKeyDown={onChange}
            prefix={<SearchOutlined />}
            suffix={
                <Tooltip title="svuota ricerca">
                  <CloseCircleFilled onClick={handleClear} />
                </Tooltip>
              }
          />
          </Form.Item>
          </Form>
        </div>
        <div className={styles.menuContainer}>
          <Menu
            onClick={onClickMenu}
            defaultOpenKeys={openKeys}
            defaultSelectedKeys={selectedKeys}
            selectedKeys={selectedKeys}
            // defaultOpenKeys={['sub1']}
            //openKeys={openKeys}
            //onOpenChange={onOpenChange}
            style={{ border: "0px solid" }}
            mode="inline"
            theme="light"
            className="layout-app-meu-sidebar"
            items={createLinks(routes)}
          ></Menu>
        </div>
      </ScrollBar>
    </Sider>

    // </div>
  );
};

export default Sidebar;
