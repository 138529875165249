/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useState, useEffect } from 'react'
import Page from '../../Components/Page/Page'
import { useKeycloak } from '@react-keycloak/web'
import { FaqsService } from '../../Service/FaqsService';
import { AccountsService } from '../../Service/AccountsService';
import { UploadOutlined} from '@ant-design/icons';
import languages from '../../utils/languages';
import ReactQuill from 'react-quill';
import {
  Divider,
  Button,
  Form,
  Input,
  Select,
  message, Upload,
  DatePicker,
  Switch
} from 'antd';
import moment from 'moment';
import { handleLogError, openNotificationWithIcon } from '../../Helpers/Helpers';
import { useTranslate } from '../../Translate';
import { useNavigate, useParams } from 'react-router-dom';
const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },

  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },

  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};


const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });



const OneNews = (props) => {
  const [fileList, setFileList] = useState([])
  const [state, setState] = useState({
    loading: false
  });
  var sel_cats = "";
  //gestione della lingua
  const i18n = useTranslate();
  const { t } = i18n;
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  /* eslint-disable no-unused-vars */
  const [news, setNews] = useState([])
  const [cats, setCats] = useState([])
  const [groups, setGroups] = useState([])
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak()
  const [lingua, setLingua] = useState([])

  const getDataLanguages = () => {
    var response = [];
    languages.forEach((language) => {
      response.push(<Option key={language.id} value={language.id}>{t(language.description)}</Option>);
    });
    setLingua(response);
  }

  var sel_accounts = [];
  const handleBack = () => {
    navigate('/admin/news');
  }

  useEffect(() => {
    getDataLanguages()
    getData()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uploadButton = (
    <Button icon={<UploadOutlined />}> {t("uploadimage")}</Button>
  );


  const onFinish = (values) => {
   // console.log(values);
   // return;

    id !== undefined ? handleSendUpdate(values) : handleSendSave(values);
  };


  const getData = async () => {
    setLoading(true);
    try {
      /*carico la lookup della categoria della news*/
      const lk_cats = [];
      const response_cats = await FaqsService.getCatNews()
      response_cats.data.map((cat) => {
        lk_cats.push(<Option value={cat.id} key={cat.id}>{cat.titolo.trim()}</Option>);
        return cat;
      });
      setCats(lk_cats)

      /*carico la lookup degli accounts*/
      const lk_accounts = [];
      const response_accounts = await AccountsService.getLookup()
      response_accounts.data.map((account) => {
        lk_accounts.push(<Option value={account[0]} key={account[0]}>{account[1].trim()}</Option>);
      });
      setGroups(lk_accounts)

      if (id) {
        const response = await FaqsService.getNewsById(keycloak.token, id)
        setNews(response.data);
        if (response.data.logo != null) {
          const fileListRead = [
            {
              uid: '-1',
              name: response.data.filelogo,
              status: 'done',
             // url: response.data.logo,
            }];
          setFileList(fileListRead);
          setState({
            filebase64: response.data.logo,
            filename: response.data.filelogo,
            loading: false
          })
        }
      

        lk_accounts.forEach((account) => {
          if (response.data.accounts != null) {
            response.data.accounts.forEach((key) => {
              if (key == account.key) {
                sel_accounts.push(account.props.value);
              }
            })
          }
          return true;
        })
        /*setto i campidel form*/
        form.setFieldsValue({
          titolo: response.data.titolo,
          riassunto: response.data.riassunto,
          testo: response.data.testo,
          catNews: response.data.catNews != null ? response.data.catNews.id : "",
          accounts: sel_accounts,
          languageid: response.data.languageid != 0 ? response.data.languageid : "",
          pubblica: response.data.pubblica == 1 ? true : false,
          abilitata: response.data.abilitata == 1 ? true : false,
          dataattivazione: response.data.dataattivazione !== "" ? moment(response.data.dataattivazione) : ""
        });
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);

  }

  const handleSendUpdate = async (data) => {
    try {
      setLoading(true);
      data["id"] = id;
      data["catNews"] = {
        id: data["catNews"]
      }

      data["pubblica"] = data.pubblica ? 1 : 0;
      data["abilitata"] = data.abilitata ? 1 : 0;
      data["logo"] = state.filebase64;
      data["filelogo"] = state.filename;
      const response = await FaqsService.updateNews(keycloak.token, data)
      if (response.status == 200) {
        openNotificationWithIcon('success', "Success", "News salvata con successo!");
        navigate('/admin/news')
      } else if (response.status == 400 || response.status == 404) {
        openNotificationWithIcon('warning', "Warning", "News non esistente!");
      } else {
        openNotificationWithIcon('error', "Errore", "Errore nell'aggiornamento della news!");
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
  }

  const handleSendSave = async (data) => {
    try {
      setLoading(true);
      data["catNews"] = {
        id: data["catNews"]
      }
      data["pubblica"] = data.pubblica ? 1 : 0;
      data["abilitata"] = data.abilitata ? 1 : 0;
      data["logo"] = state.filebase64;
      data["filelogo"] = state.filename;
      const response = await FaqsService.saveNews(keycloak.token, data)
      if (response.status == 200) {
        openNotificationWithIcon('success', "Success", "News salvata con successo!");
        navigate('/admin/news')
      } else if (response.status == 400 || response.status == 404) {
        openNotificationWithIcon('warning', "Warning", "News non esistente!");
      } else {
        openNotificationWithIcon('error', "Errore", "Errore nel salvataggio della news!");
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
  }

  const handleChange = async (info) => {
    console.log(info);
    let filebase64 = "";
    let filename = "";
    let fileList = [...info.fileList];
    setFileList(fileList);
    if (info.file.status != "removed") {
      filebase64 = await getBase64(fileList[0].originFileObj);
      filename = fileList[0].name;
      setState({
        filebase64,
        filename,
        loading: false
      })
    }
  };


  const handleBefore = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return false
  }

  const dateFormat = 'DD/MM/YYYY';

  return (
    <Page loading={loading} inner>
      <>
        <Divider orientation="left" orientationMargin="0" style={{ color: '#e61e1e' }}>{id == null ? t("newnews") : t("editnews")}</Divider>
        <Form
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="news"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            label={t('title')}
            name="titolo"
            rules={[
              {
                required: true,
                message: t('ckTitle'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('summary')+" ("+t("moreinfosummary")+")"}
            name="riassunto"
            rules={[
              {
                required: true,
                message: t('ckSummary'),
              },
            ]}
          >
          <TextArea rows={4} /> 
          </Form.Item>
          <Form.Item
            label={t('text')}
            name="testo"
            rules={[
              {
                required: true,
                message: t('cktext'),
              },
            ]}
          >
            {/* <TextArea rows={4} /> */}
            <ReactQuill theme="snow"  />
          </Form.Item>
          <Form.Item
            label={t("language")}
            name="languageid"
            rules={[
              {
                required: true,
                message: t('ckLanguage'),
              },
            ]}
          >
            <Select
              autoComplete="none"
              showSearch
              style={{
                width: '100%',
              }}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {lingua}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('category')}
            name="catNews"
            rules={[
              {
                required: true,
                message: t('ckCategory'),
              },
            ]}
          >
            <Select
              autoComplete="none"
              showSearch
              style={{
                width: '100%',
              }}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase().trim())
              }
              optionFilterProp="children"
              defaultValue={sel_cats}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase().trim())}
              allowClear
            >
              {cats}
            </Select>
          </Form.Item>
          

          <Form.Item
            valuePropName="checked"
            name="abilitata"
            label={t("active")}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name="pubblica"
            label={t("public")+" ("+t("moreinfopublic")+")"}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t('group')}
            name="accounts"
          >
            <Select
              mode="multiple"
              autoComplete="none"
              showSearch
              style={{
                width: '100%',
              }}
              placeholder={t('group')}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase().trim())
              }
              optionFilterProp="children"
              defaultValue={sel_accounts}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase().trim())}
              allowClear
            >
              {groups}
            </Select>
          </Form.Item>
          <Form.Item
            name="dataattivazione"
            label={t('activationdate')}
            rules={[
              {
                required: true,
                message: t('ckActivationdate'),
              },
            ]}>
            <DatePicker format={dateFormat} />
          </Form.Item>
          <Form.Item
            name="upload"
            label={t("logo")}
          >
            <Upload onChange={handleChange} beforeUpload={handleBefore} fileList={fileList}>
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item></Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              {t("save")}
            </Button>
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={handleBack}
            >
              {t("cancel")}
            </Button>
          </Form.Item>

        </Form>
      </>
    </Page>
  )
}

export default OneNews