import { createSlice } from "@reduxjs/toolkit";
import { api, setHeader } from "../../Helpers/Helpers";

// initial state
export const initialState = {
  loading: false,
  error: {
    status: false,
    message: "",
    code: "",
  },
  shipments: [],
  shipping: {},
  responseSave:{},
  confirmData:{},
  statusShipping: []
};

// our slice
const shipmentsSlice = createSlice({
  name: "shipments",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setShipments: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.shipments = payload;
    },
    setStatusShipping:(state,{payload}) =>{
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.statusShipping = payload;

    },
    setShipping: (state, { payload }) => {
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.shipping = payload;
    },
    setError: (state, action) => {
      const dataMessage = action.payload?.data.message;
      state.loading = false;
      state.error.status = true;
      state.error.message = dataMessage
        ? dataMessage
        : action.payload?.statusText;
      state.error.code = action.payload?.status;
    },
    cleanError: (state) => {
      state.error.status = false;
      state.error.message = "";
      state.error.code = "";
      state.confirmData = {};
      state.responseSave = {};
    },
    saveData: (state, action) => {
      state.responseSave= action.payload;
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = 200;
    },
    confirmData: (state, action) => {
      state.confirmData= action.payload;
      state.loading = false;
      state.error.status = false;
      state.error.message = "";
      state.error.code = 200;
    },
  },
});

// export the actions
export const {
  setLoading,
  setShipments,
  setShipping,
  cleanError,
  setError,
  saveData, 
  confirmData,
  setStatusShipping
} = shipmentsSlice.actions;

// export the default reducer
export default shipmentsSlice.reducer;

export const fetchShipments =
  (token, userid = "", id) =>
  async (dispatch) => {
    let path = "";
    path = "/tsapps/api/postalizzazione/ordini?appOwnedId=" + id;
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setShipments(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

export const fetchShipping =
  (token, userid = "", id) =>
  async (dispatch) => {
    let path = "";
    path = "/tsapps/api/postalizzazione/ordini/" + id;
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setShipping(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };

export const saveShipping = (token, userid, data) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  let path = "/tsapps/api/postalizzazione/ordini";
  try {
    const response = await api.post(path, data, setHeader(token, userid));
    dispatch(saveData(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const confirmShipping = (token, userid, data) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  let path = "/tsapps/api/postalizzazione/ordini/conferma";
  try {
    const response = await api.put(path, data, setHeader(token, userid));
    dispatch(confirmData(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const updateShipping = (token, userid, data) => async (dispatch) => {
  dispatch(setLoading());
  dispatch(cleanError());
  var draftId = data["id"];
  delete data["id"];
  let path = `/tsapps/api/postalizzazione/ordini/draft/${draftId}/finalizza`;
  try {
    const response = await api.put(path, data, setHeader(token, userid));
    dispatch(saveData(response.data));
  } catch (err) {
    dispatch(setError(err.response));
  }
};

export const fetchStatus =
  (token, userid = "", id) =>
  async (dispatch) => {
    let path = "";
    path = "/tsapps/api/postalizzazione/ordini/"+id+"/verifica";
    dispatch(setLoading());
    dispatch(cleanError());
    try {
      const response = await api.get(path, setHeader(token, userid));
      dispatch(setStatusShipping(response.data));
    } catch (err) {
      dispatch(setError(err.response));
    }
  };
