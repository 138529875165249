import React from "react";
import { Button, Row, Col, Form } from "antd";
import { useTranslate } from "../../../Translate";

const ColProps = {
  xs: 24,
  sm: 12,
  style: {
    marginBottom: 16,
  },
};

const TwoColProps = {
  ...ColProps,
  xl: 96,
};

const Filter = ({ handleNew }) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const formRef = React.createRef();
  const onClickNew = () => {
    handleNew();
  };
  return (
    <Form ref={formRef} name="document-ref">
      <Row gutter={24}>
        <Col
          {...TwoColProps}
          xl={{ span: 24 }}
          md={{ span: 16 }}
          sm={{ span: 8 }}
        >
          <Row
            gutter={24}
            type="flex"
            align="middle"
            justify="end"
            style={{ border: "0px solid" }}
          >
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "16px" }}
                onClick={onClickNew}
              >
                {t("newshipping")}
              </Button>
            </div>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
