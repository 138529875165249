
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React,{ useState, useEffect }  from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/less";
import "swiper/less/pagination";
import "swiper/less/navigation";



// import required modules
import { Pagination, Navigation } from "swiper";

import { useKeycloak } from '@react-keycloak/web'
import {
    Card,
    Col,
    Row,
    Typography,
    List,
    Carousel 
  } from 'antd';
  import Page from '../../Components/Page/Page'
import CustomCard from './Components/CustomCard';
import { FaqsService } from '../../Service/FaqsService';
import { handleLogError, ErrorPage,openNotificationWithIcon } from '../../Helpers/Helpers';
import {
  useNavigate,
} from 'react-router-dom';
import { useTranslate } from '../../Translate';
import languages from '../../utils/languages';
import { setLanguages } from '../../Translate/Translate';
  const { Title, Text, Link, Paragraph } = Typography;
  const { Meta } = Card;


  
const News = () => {
  const i18n = useTranslate();
  const { t,getCurrentLanguage } = i18n;
  
//     <Row gutter={[16, 16]}>
//     <Col span={24}>
//         <Card bordered>
//             <Title level={3}>News</Title>
//             <Text>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Text>
//         </Card>
//     </Col>
// </Row>
//   )
const [news, setNews] = useState([])
const [loading, setLoading] = useState(false)
const navigate = useNavigate();
const { keycloak } = useKeycloak()


const getData = async () => {
  try {
      let languageid = languages.filter(element => element.name ==getCurrentLanguage())[0].id;
      var token = keycloak.token !=undefined ? keycloak.token : null;
      var accounts = keycloak.tokenParsed !=undefined ? keycloak.tokenParsed.accounts.toString() : null;
      var userid = keycloak.tokenParsed !=undefined ? keycloak.tokenParsed.sub.toString() : null;
      let data = {};
      data["languageid"]= languageid;
      const response = await FaqsService.getNews(token,accounts , userid,data);
      setNews(response.data);
      ErrorPage(response.status, navigate); 
  } catch (error) {
    handleLogError(error);
  }
  setLoading(false);
}

useEffect(() => {
  setLoading(true)
  getData()
}, [])

const contentStyle = {
  height: '560px',
  color: '#fff',
  lineHeight: '560px',
  textAlign: 'center',
  background: '#364d79',
};

// useEffect(() => {
//   // Funzione per aggiornare le dimensioni quando la finestra viene ridimensionata
//   const handleResize = () => {
//     console.log(window.innerWidth);
//   };

//   // Aggiungere un event listener per il ridimensionamento della finestra
//   window.addEventListener('resize', handleResize);

//   // Pulizia dell'event listener quando il componente viene smontato
//   return () => {
//     window.removeEventListener('resize', handleResize);
//   };
// }, []);

const onChange = (currentSlide) => {
  console.log(currentSlide);
};
     /*
    xs: schermi extra piccoli (<= 576px)
    sm: schermi piccoli (>= 576px)
    md: schermi di medie dimensioni (>= 768px)
    lg: schermi larghi (>= 992px)
    xl: schermi extra larghi (>= 1200px)
    xxl: schermi molto larghi (>= 1600px)
    la proprietà gutter che imposta lo spazio tra le colonne  le proprietà justify e align che definiscono rispettivamente l'allineamento orizzontale e verticale dei componenti nella griglia.
    */ 

    const breakpoints = {
      376: { // xs
        slidesPerView: 1,
        spaceBetween: 10,
        slidesPerGroup: 1,
      },
      576: { // xs
        slidesPerView: 1,
        spaceBetween: 10,
        slidesPerGroup: 1,
      },
      768: { // sm
        slidesPerView: 1,
        spaceBetween: 20,
        slidesPerGroup: 1,
      },
      1100: { // md
        slidesPerView: 2,
        spaceBetween: 20,
        slidesPerGroup: 2,
      },
      1490:{
        slidesPerView: 3,
        spaceBetween: 40,
        slidesPerGroup: 3,
      }
     
      // Aggiungi altri breakpoint se necessario
    };
   

  
return (
<Page loading={loading} inner>
  <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#e61e1e",
          "--swiper-pagination-color": "#e61e1e",
        }}
        breakpoints={breakpoints}
        slidesPerView={3}
        spaceBetween={30}
        slidesPerGroup={3}
        loop={false}
        effect= "fade"
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
      {      news.map((item)=>{
        return(
          <SwiperSlide>  <CustomCard news={item}/></SwiperSlide>
        )
      })
     } 
       
      </Swiper>
    </>
</Page>

);
}

export default News