/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react'
import { Button, Row, Col, DatePicker, Form, Input, Select } from 'antd'
import { useTranslate } from '../../../Translate';
import {useNavigate } from 'react-router-dom';
const { Search } = Input


const ColProps = {
    xs: 24,
    sm: 12,
    style: {
        marginBottom: 16,
    },
}

const TwoColProps = {
    ...ColProps,
    xl: 96,
}

const Filter = (props) => {
    const navigate = useNavigate();
    const i18n = useTranslate();
    const { t } = i18n;
    const { cats,onFilterChange,type,languageslk } = props;
    //const { onAdd, filter } = props
    //const { username } = filter  
    const formRef = React.createRef()
    /*eventuale normalizzazione dei dati tipo la data*/
    const handleFields = fields => {
        return fields
    }
    const handleSubmit = () => {
        const values = formRef.current.getFieldsValue()
        const fields = handleFields(values)
        console.log(fields)
        onFilterChange(fields);
    }
    const handleReset = () => {
        //ottengo tutti i campi del form con i rispettivi valori
        const fields = formRef.current.getFieldsValue()
        for (let item in fields) {
            if ({}.hasOwnProperty.call(fields, item)) {
                if (fields[item] instanceof Array) {
                    fields[item] = []
                } else {
                    fields[item] = undefined
                }
            }
        }
        formRef.current.setFieldsValue(fields)
    }

    const handleNew = () => {
        navigate('/admin/faqs/new');
    }

    const handleCategories = () => {
        navigate('/admin/faqs/cats/');
    }


    return (
        <Form ref={formRef} name="control-ref">
            <Row gutter={24}>
                <Col {...ColProps} xl={{ span: 4 }} md={{ span: 8 }} >
                    <Form.Item name="stringa">
                        <Search
                            placeholder={`Ricerca`}
                            onSearch={handleSubmit}
                        />
                    </Form.Item>
                </Col>
                <Col
                    {...ColProps}
                    xl={{ span: 4 }}
                    md={{ span: 8 }}
                    id="catfaq"
                >
                    <Form.Item name="catfaq">
                        <Select
                            style={{ width: '100%' }}
                            options={cats}
                            optionFilterProp="children"
                            placeholder={`Per favore seleziona una categoria`}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        />
                    </Form.Item>
                </Col>
                {type == "admin" ?
                <Col
                    {...ColProps}
                    xl={{ span: 4 }}
                    md={{ span: 6 }}
                >
                  
                    <Form.Item name="languageid">
                        <Select
                            style={{ width: '100%' }}
                            options={languageslk}
                            optionFilterProp="children"
                            placeholder={`Per favore seleziona una lingua`}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        />
                    </Form.Item> 
                </Col>:""}

                <Col
                    {...TwoColProps}
                    xl={{ span: 12 }}
                    md={{ span: 18 }}
                    sm={{ span: 18 }}
                > 
                    <Row gutter={24} type="flex" align="middle" justify="space-between" style={{border:'0px solid'}}>
                        <div>
                            <Button
                                type="primary" htmlType="submit"
                                style={{ marginRight: '16px' }}
                                onClick={handleSubmit}
                            >
                                Cerca
                            </Button>
                            <Button onClick={handleReset}>
                                Reset
                            </Button>
                        </div>
                        {type == "admin" ?
                            <div><Button type="ghost" onClick={handleCategories} style={{ marginRight: '16px' }}>
                            {t("categories")}
                            </Button> 
                            <Button onClick={handleNew} type="ghost">
                            {t("new")}
                            </Button></div> :''
                        }

                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default Filter