import React from 'react'
import Parser from 'html-react-parser';
import {
    Collapse,
    Tag
  } from 'antd';
  const { Panel } = Collapse;

  
   
const CollapseCustom = (props) => {
    const {faqs} = props;

    const onChange = (key) => {
        console.log(key);
    };
    return (
        <Collapse /*defaultActiveKey={[3]}*/ onChange={onChange}>
            {
                faqs.map((faq)=>{
            return(
                    <Panel header={faq.domanda} key={faq.id}>
                        <p>{Parser(faq.risposta)}</p>
                        <Tag color="red">{faq.catFaq.titolo}</Tag>
                    </Panel>
               )
             
        })
            }
        </Collapse>
    )
}

export default CollapseCustom