/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable array-callback-return*/
import React, { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { AccountsService } from '../../Service/AccountsService';
import { CreditsService } from '../../Service/CreditsService';
import Page from '../../Components/Page/Page';
import { handleLogError, ErrorPage } from '../../Helpers/Helpers';
import { useTranslate } from '../../Translate';
import { Button, message, Steps,Select,Form,Modal } from 'antd';
import Step1 from './Components/Step1';
import Step2 from './Components/Step2';
import Step3 from './Components/Step3';
import styles from './Step.module.less'
const { Step } = Steps;
const { Option } = Select;
const { confirm } = Modal

const Order = (props) => {
  const {type} = props; 
  const i18n = useTranslate();
  const { t } = i18n;
  const { keycloak } = useKeycloak()
  const { path } = useParams();
  const [groups, setGroups] = useState([])
  const [statusOrder, setStatusOrder] = useState(2)
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState([])
  const [groupid, setGroupid] = useState(null)
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const types = path.substring(0,1);
  const id = path.substring(1);


  const getData = async () => {
    setLoading(true);
    try {
     
      /*carico la lookup degli accounts*/
      const lk_accounts = [];
      const response_accounts = await AccountsService.getLookup()
      response_accounts.data.map((account) => {
        lk_accounts.push(<Option value={account[0]} key={account[0]}>{account[1].trim()}</Option>);
        return true;
      });
      setGroups(lk_accounts)
      ErrorPage(response_accounts, navigate);
      if(types =='a'){
        setGroupid(id);
      }else{
        //in questo caso stiamo recuperando l'ordine
        const response_order = await CreditsService.getOrder(keycloak.token,keycloak.tokenParsed.sub.toString(),id,type)
        var ordertmp = response_order.data;
        setOrder(ordertmp);
        console.log(order)
        ErrorPage(response_order, navigate); 
      }

     
    } catch (error) { 
      handleLogError(error);
    }
    setLoading(false);

  }

  const handleFinish = (values) => {
    console.log("values: ", values);
    setStep2(values);
  };
  const setStep2 = async (data) => {
    setLoading(true);
    try {
      let response = null;
      data["operatoreId"]= keycloak.tokenParsed.sub.toString();
      if(types == "o"){//in questo caso ho l'id dell'ordine direttamente
        data["id"] = id
        response = await CreditsService.updateOrder(keycloak.token,keycloak.tokenParsed.sub.toString(),data,type)
      }else{
        response = await CreditsService.newOrder(keycloak.token,keycloak.tokenParsed.sub.toString(),data,type)
      }
      setOrder(response.data);
      ErrorPage(response.status, navigate);
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
    setCurrent(current + 1);
  }

  const setStatus = async (status,page) =>{
    setLoading(true);
    try {
      let response = null;
      response = await CreditsService.updateStato(keycloak.token,keycloak.tokenParsed.sub.toString(),order.id,status,type)
      setStatusOrder(status)
      setOrder(response.data);
    } catch (error) {
      handleLogError(error)
    }
    setLoading(false)
    setCurrent(page);
  }

  const pay = async() => {
    setLoading(true);
    try {
      let response = null;
      response = await CreditsService.pay(keycloak.token,keycloak.tokenParsed.sub.toString(),order.id,type)
      ErrorPage(response.status, navigate);
      if(response.data !=null){
        window.location.href = response.data.data ;
      }
    } catch (error) {
      handleLogError(error)
    }
    setLoading(false)

  }


  useEffect(() => {
    getData()
  }, [])

  const [current, setCurrent] = useState(0);

  const next = () => {
    if(current == 0){
      form.submit();
    }else if(current == 1){
      setStatus(1,current + 1)
    }else{
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if(current == 1){
      setStatus(2,current - 1)
    }else{
      setCurrent(current - 1);
    }
  };

  const handleDone = () => {
   
    if(order.tipoOrdine == 0 && type !="admin"){
      message.success(t("processingcomplete"));
      pay();
    }else if(order.tipoOrdine == 1 && type !="admin"){
      message.success(t("processingcomplete"));
      navigate('/credits/ordershistory/'+order.accountId)
    } else if(order.tipoOrdine == 0 && type =="admin"){
      message.success(t("processingcomplete"));
      navigate('/admin/credits/ordershistory/'+order.accountId)
    } else if(order.tipoOrdine == 1 && type =="admin"){
      confirm({
        title: t("proceedoperation"),
        onOk() {
          paid();
          message.success(t("processingcomplete"));
        },
      })
    }
    
  }

  const paid = async() => {
    setLoading(true);
    try {
      let response = null;
      response = await CreditsService.paid(keycloak.token,keycloak.tokenParsed.sub.toString(),order.id)
      ErrorPage(response.status, navigate);
      if(response.data !=null){
        navigate('/admin/credits/ordershistory/'+order.accountId)
      }
      console.log(response);
    } catch (error) {
      handleLogError(error)
    }
    setLoading(false)

  }


  const steps = [
    {
      title: 'First',
      content: <Step1 type= {type} groups= {groups} groupid= {groupid}  form={form} onFinish={handleFinish} order={order} />,
    },
    {
      title: 'Second',
      content: <Step2 order={order}/>,
    },
    {
      title: 'Last',
      content: <Step3 order={order}/>,
    },
  ];

  const getBtn= () => {
    if(order.tipoOrdine == 0 && type !="admin"){
       return t("pay");
    }else if(order.tipoOrdine == 1 && type !="admin"){
      return t("done");
    }else if(order.tipoOrdine == 0 && type =="admin"){
      return t("done");
    }else if(order.tipoOrdine == 1 && type =="admin"){
      return t("confirmpayment")
    }
  }

  return (
    <Page loading={loading} inner>
      <>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className={styles.stepscontent}>{steps[current].content}</div>
      <div className={styles.stepsaction}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            {t("confirm")}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => handleDone()}>
        
          {getBtn()}
          </Button>
        )}
      
        {/* {current == 1 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            {t("cancel")}
          </Button>
        )} */}
      </div>
    </>
    </Page>
  )
}

export default Order




