import axios from "axios";
import { config } from "../utils/Constants";

export const CreditsService = {
  getCredits,
  getOrderHistory,
  newOrder,
  updateStato,
  pay,
  getOrder,
  updateOrder,
  deleteOrder,
  search,
  paid,
  setBonus,
  move,
  storno,
  addebito,
  getAppsByGroup,
  updateExpiration,
};

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return error.response;
  }
);
// -- Helper functions
function bearerAuth(token) {
  return `Bearer ${token}`;
}
//ritorna l'elenco dei saldi in base all'accounts passati
function getCredits(token, accounts, userid, type) {
  if (type === "admin") {
    return instance.get(
      "/tspayments/api/admin/balance/saldo/all?accounts=" + accounts,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: bearerAuth(token),
        },
      }
    );
  } else {
    return instance.get("/tspayments/api/balance/saldo?accounts=" + accounts, {
      headers: {
        "Content-type": "application/json",
        "user-id": userid,
        Authorization: bearerAuth(token),
      },
    });
  }
}

//Ritorna l'elenco degli ordini di un dato account/group
function getOrderHistory(token, account, userid, type) {
  if (type === "admin") {
    let filter = "accountId=" + account;
    if (account === undefined) filter = "";
    return instance.get("/tspayments/api/admin/orders/all?" + filter, {
      headers: {
        "Content-type": "application/json",
        Authorization: bearerAuth(token),
      },
    });
  } else {
    return instance.get("/tspayments/api/orders/all?accountId=" + account, {
      headers: {
        "Content-type": "application/json",
        Authorization: bearerAuth(token),
        "user-id": userid,
      },
    });
  }
}

//ritorna ordine da ID
function getOrder(token, userid, orderId, type) {
  let path =
    type === "admin"
      ? "/tspayments/api/admin/orders/"
      : "/tspayments/api/orders/";
  return instance.get(path + orderId, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
      "user-id": userid,
    },
  });
}

//creazione di un nuovo ordine
function newOrder(token, userid, data, type) {
  let path =
    type === "admin"
      ? "/tspayments/api/admin/orders/"
      : "/tspayments/api/orders/";

  return instance.post(path, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
      "user-id": userid,
    },
  });
}

//update scadenza
function updateExpiration(token, userid, data) {
  let path = "/tsapps/api/admin/app/change-expire-date";

  return instance.post(path, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
      "user-id": userid,
    },
  });
}

//update di un  ordine
function updateOrder(token, userid, data, type) {
  let path =
    type === "admin"
      ? "/tspayments/api/admin/orders/"
      : "/tspayments/api/orders/";
  return instance.put(path, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
      "user-id": userid,
    },
  });
}

//paga attraverso paypal
function pay(token, userid, orderId, type) {
  let path =
    type === "admin"
      ? "/tspayments/api/admin/orders/"
      : "/tspayments/api/orders/";
  return instance.post(path + orderId + "/paypal/pay", [], {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
      "user-id": userid,
    },
  });
}

//setta ordine a pagato
function paid(token, userid, orderId) {
  return instance.put(
    "/tspayments/api/admin/orders/" + orderId + "/pagato",
    [],
    {
      headers: {
        "Content-type": "application/json",
        Authorization: bearerAuth(token),
        "user-id": userid,
      },
    }
  );
}

//aggiorna stato ordine
//I possibili stati dell'ordine sono i seguenti:
//    0: PAGATO
//    1: IN ATTESA DI PAGAMENTO
//    2: BOZZA
//    3: ANNULLATO
function updateStato(token, userid, orderId, statoId, type) {
  let path =
    type === "admin"
      ? "/tspayments/api/admin/orders/"
      : "/tspayments/api/orders/";
  return instance.put(path + orderId + "/changeStatus/" + statoId, [], {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
      "user-id": userid,
    },
  });
}

//delete order
function deleteOrder(token, userid, orderId, type) {
  let path =
    type === "admin"
      ? "/tspayments/api/admin/orders/"
      : "/tspayments/api/orders/";
  return instance.delete(path + orderId, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
      "user-id": userid,
    },
  });
}

//ricerca ordini
function search(token, userid, data, type) {
  if (type === "admin") {
    return instance.get("/tspayments/api/admin/orders/all", {
      headers: {
        "Content-type": "application/json",
        Authorization: bearerAuth(token),
      },
      params: data,
    });
  } else {
    return instance.get("/tspayments/api/orders/all/search", {
      headers: {
        "Content-type": "application/json",
        Authorization: bearerAuth(token),
        "user-id": userid,
      },
      params: data,
    });
  }
}

//bonus

function setBonus(token, data) {
  return instance.post("tspayments/api/admin/orders/bonus", data, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
    },
  });
}

//sposta il credito

function move(token, data, type) {
  let path =
    type === "admin"
      ? "tspayments/api/admin/balance/transfercredits"
      : "tspayments/api/balance/transfercredits";
  return instance.post(path, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
    },
  });
}

//storno

function storno(token, data) {
  return instance.post("tspayments/api/admin/balance/storno", data, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
    },
  });
}

//addebito (consumo fine mese)

function addebito(token, data) {
  return instance.post("tspayments/api/admin/balance", data, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
    },
  });
}

//ritorna ordine da ID
function getAppsByGroup(token, groupid) {
  return instance.get("tsapps/api/admin/applist/owned/all?gruppo=" + groupid, {
    headers: {
      "Content-type": "application/json",
      Authorization: bearerAuth(token),
    },
  });
}
