import axios from 'axios'
import { config } from '../utils/Constants'


export const AccountsService = {
    getAccounts,
    getAccountsDealer,
    getAccountById,
    update,
    save,
    disabled,
    search,
    getLookup
}
// -- Axios

const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
    return response;
}, function (error) {
    console.log(error) 
    return error.response;
});

// -- Helper functions
function bearerAuth(token) {
    return `Bearer ${token}`
}


function getLookup(type) {
    return instance.get('/tsbadmin/api/public/accounts-lk?type='+type, {
        headers: {
            'Content-type': 'application/json',
        }
    })
}

function getAccounts(token,filter) {
    return instance.get('/tsbadmin/api/accounts/', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }, params:filter
    })
}

function getAccountsDealer(token,filter) {
    return instance.get('/tsbadmin/api/accounts/dealer', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }, params:filter
    })
}

function search(token,filter) {
    return instance.get('/tsbadmin/api/accounts/search/', {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }, params:filter
    })
}

function getAccountById(token,id,filter) {
    return instance.get('/tsbadmin/api/accounts/'+id, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }, params:filter
    })
}

function update(token,data,filter) {
    return instance.put('/tsbadmin/api/accounts/', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }, params:filter
    })
      
  }

  function save(token,data,filter) {
    return instance.post('/tsbadmin/api/accounts/', data, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }, params:filter
    })
  }

  function disabled(token,id) {
    return instance.delete('/tsbadmin/api/accounts/'+id, {
        headers: {
            'Content-type': 'application/json',
            'Authorization': bearerAuth(token)
        }
    })
  }