/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import {
    Card,
    Col,
    Row,
    Typography
} from 'antd';
import { useTranslate } from '../../Translate';
import Page from '../../Components/Page/Page';
const { Title, Text, Link, Paragraph } = Typography;

const Detail = () => {
    const i18n = useTranslate();
    const { t } = i18n;
    const [loading, setLoading] = useState(false)
    const [news, setNews] = useState([]);
    return (
        <Page loading={loading} inner>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Title level={3}>{news.titolo}</Title>
                    <Text>{news.testo}</Text>
                </Col>
            </Row>
        </Page>
    )
}

export default Detail