/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import {
  useNavigate
} from 'react-router-dom';
import { Modal,Select } from 'antd'
import List from './Components/List';
import Filter from './Components/Filter';
import Page from '../../Components/Page/Page';
import { handleLogError,  openNotificationWithIcon,ErrorPage } from '../../Helpers/Helpers';
import { useTranslate } from '../../Translate';
import { MdDelete } from 'react-icons/md';
import { BsPaypal } from 'react-icons/bs';
import {FiCheckCircle} from 'react-icons/fi'
import { FaEdit } from 'react-icons/fa';
import {  useParams } from "react-router-dom";
import { CreditsService } from '../../Service/CreditsService';
import {AccountsService} from '../../Service/AccountsService';
import moment from 'moment';
import { Excel } from "antd-table-saveas-excel";
const { confirm } = Modal
const { Option } = Select;

const CreditsHistory = (props) => {
  const {type} = props;
  const i18n = useTranslate();
  const { t } = i18n;
  const { groupid } = useParams();
  const id = groupid;
  //const id = location.state.accountid;
  const [history, setHistory] = useState([])
  const { keycloak } = useKeycloak()
  const [loading, setLoading] = useState(false)
  const [filteredData, setfilteredData] = useState([]);
  const [reload, setReload] = useState(false);
  const [groups, setGroups] = useState([])
  const status = [];
  status[0] = t("paid");
  status[1] = t("waitingforpayment");
  status[2] = t("draft");
  status[3] = t("canceled");

  const types = [];
  types[0] = t("PAYPAL");
  types[1] = t("Bonifico");
  types[2] = t("Bonus");


  const columnsExport = [
    {
      title: t("ordernumber"),
      dataIndex: 'id',
      key: 'id',
    } ,
    {
      title: t("orderdate"),
      dataIndex: 'dataInserimento',
      key: 'dataInserimento',
      render: (text, record) => (

        <>{new Intl.DateTimeFormat('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(record.dataInserimento))}</>
      )
    },
    {
      title: t("ordertype"),
      dataIndex: 'tipoOrdine',
      key: 'tipoOrdine',
      render: (text, record) => (

        <>{types[record.tipoOrdine] ? types[record.tipoOrdine] : ""}</>
      )
    },
    {
      title: t("group"),
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: t("refilledamount"),
      dataIndex: 'importoAccreditato',
      key: 'importoAccreditato',
      render: (text, record) => (
        <>{record.importoAccreditato != null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(record.importoAccreditato) : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0)}</>
      ),
    },
    {
      title: t("amountordered"),
      dataIndex: 'imponibileOrdinato',
      key: 'imponibileOrdinato',
      render: (text, record) => (
        <>{record.imponibileOrdinato != null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(record.imponibileOrdinato) : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0)}</>
      ),
    },
    {
      title: t("totalpayment"),
      dataIndex: 'totaleOrdinato',
      key: 'totaleOrdinato',
      render: (text, record) => (
        <>{record.totaleOrdinato != null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(record.totaleOrdinato) : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0)}</>
      ),
    },
   
    {
      title: t("paymentdate"),
      dataIndex: 'dataPagamento',
      key: 'dataPagamento',
      render: (text, record) => (

        <>{(record.dataPagamento !== null && record.dataPagamento !== "") ? new Intl.DateTimeFormat('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(record.dataPagamento)) : ""}</>
      )
    },
    {
      title: t("orderstatus"),
      dataIndex: 'pagato',
      key: 'pagato',
      render: (text, record) => (

        <>{status[record.statoOrdine] ? status[record.statoOrdine] : ""}</>
      )
    },
    
  ]

  const columns = [
    {
      title: t("ordernumber"),
      dataIndex: 'id',
      key: 'id',
      width: '7%',
    },
    {
      title: t("orderdate"),
      dataIndex: 'dataInserimento',
      key: 'dataInserimento',
      width: '9%',
      render: (text, record) => (

        <>{new Intl.DateTimeFormat('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(record.dataInserimento))}</>
      )
    },
    {
      title: t("ordertype"),
      dataIndex: 'tipoOrdine',
      key: 'tipoOrdine',
      width: '7%',
      render: (text, record) => (

        <>{types[record.tipoOrdine] ? types[record.tipoOrdine] : ""}</>
      )
    },
    {
      title: t("group"),
      dataIndex: 'companyName',
      key: 'companyName',
      width: '9%',
    },
    {
      title: t("refilledamount"),
      dataIndex: 'importoAccreditato',
      key: 'importoAccreditato',
      width: '9%',
      render: (text, record) => (
        <>{record.importoAccreditato != null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(record.importoAccreditato) : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0)}</>
      ),
    },
    {
      title: t("amountordered"),
      dataIndex: 'imponibileOrdinato',
      key: 'imponibileOrdinato',
      width: '9%',
      render: (text, record) => (
        <>{record.imponibileOrdinato != null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(record.imponibileOrdinato) : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0)}</>
      ),
    },
    {
      title: t("totalpayment"),
      dataIndex: 'totaleOrdinato',
      key: 'totaleOrdinato',
      width: '9%',
      render: (text, record) => (
        <>{record.totaleOrdinato != null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(record.totaleOrdinato) : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0)}</>
      ),
    },
    // {
    //   title: t("billingnumber"),
    //   dataIndex: 'riferimentoFattura',
    //   key: 'riferimentoFattura',
    //   width: '9%',
    // },
    // {
    //   title: t("billingdate"),
    //   dataIndex: 'dataFattura',
    //   key: 'dataFattura',
    //   width: '9%',
    //   render: (text, record) => (

    //     <>{record.dataFattura != null ? new Intl.DateTimeFormat('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(record.dataFattura)) : ""}</>
    //   )
    // },
    {
      title: t("paymentdate"),
      dataIndex: 'dataPagamento',
      key: 'dataPagamento',
      width: '9%',
      render: (text, record) => (

        <>{(record.dataPagamento !== null && record.dataPagamento != "") ? new Intl.DateTimeFormat('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(record.dataPagamento)) : ""}</>
      )
    },
    {
      title: t("orderstatus"),
      dataIndex: 'pagato',
      key: 'pagato',
      width: '9%',
      render: (text, record) => (

        <>{status[record.statoOrdine] ? status[record.statoOrdine] : ""}</>
      )
    },

    {
      title: t("operation"),
      dataIndex: 'operation',
      key: 'operation',
      align: 'center',
      width: '9%',
      render: (text, record) => {
        return (
          <>
          {type ==="admin" && record.statoOrdine== 1 && record.tipoOrdine === 1 ? <FiCheckCircle onClick={() => handleConfirmOrder(record.id)} style={{ color:'green', fontSize: '22px', marginRight: '10px', cursor: 'pointer', }} /> :""}
            {record.statoOrdine == 2  ? <MdDelete onClick={() => handleDeleteOrder(record.id)} style={{ fontSize: '22px', marginRight: '10px', cursor: 'pointer', }} /> : ""}
            {record.statoOrdine == 2  ? <FaEdit onClick={() => handleEditOrder(record.id)} style={{ fontSize: '22px', marginRight: '10px', cursor: 'pointer' }} /> : ""}
            {(record.statoOrdine == 1 && record.tipoOrdine == 0) ? <BsPaypal onClick={() => handlePayOrder(record.id)} style={{ fontSize: '16px', color: '#2790C3', cursor: 'pointer', }} /> : ""}

          </>
        )
      },
    }
  ];


  const handlePayOrder = (id) => {
    pay(id)
  }

  const pay = async (id) => {
    setLoading(true);
    try {
      let response = null;
      response = await CreditsService.pay(keycloak.token, keycloak.tokenParsed.sub.toString(), id,type)
      if (response.data != null) {
        console.log(response.data)
        window.location.href = response.data.data;
      }
    } catch (error) {
      handleLogError(error)
    }
    setLoading(false)

  }

  const handleEditOrder = (id) => {
    type == "admin"  ?  navigate('/admin/credits/order/o' + id) : navigate('/credits/order/o' + id)
  }

  const handleDeleteOrder = (id) => {
    confirm({
      title: t("questiondeleterecord"),
      onOk() {
        onDeleteItem(id)
      },
    })
  }

  const handleConfirmOrder = (id) => {
    confirm({
      title: t("questiontransfer"),
      onOk() {
        onConfirmItem(id)
      },
    })
  }

 /**
   * Funzione per la conferma di un ordine
   * @param {Number} id
   * @returns {undefined}
   */
  const onConfirmItem = async (id) => {

    setLoading(true);
    try {
      let response = null;
      response = await CreditsService.paid(keycloak.token,keycloak.tokenParsed.sub.toString(),id)
      if (response.status == 200) {
        //let newHistory = history.filter((el) => el.id !== id);
       // setHistory(newHistory);
       setReload(true);
        openNotificationWithIcon('success', "Success", "Operazione completata con successo!");
      } else if (response.status == 400 || response.status == 404) {
        openNotificationWithIcon('warning', "Warning", "Ordine non trovato!");
      } else {
        openNotificationWithIcon('error', "Error", "Errore nella conferma del pagamento!");
      }
    } catch (error) {
      handleLogError(error)
    }
    setLoading(false)

  }


  /**
   * Funzione che rimuove l'ordine
   * @param {Number} id
   * @returns {undefined}
   */
  const onDeleteItem = async (id) => {
    setLoading(true)
    try {
      const response = await CreditsService.deleteOrder(keycloak.token, keycloak.tokenParsed.sub.toString(), id,type)
      if (response.status == 200) {
        let newHistory = history.filter((el) => el.id !== id);
        setHistory(newHistory);
        openNotificationWithIcon('success', "Success", "Ordine cancellato con successo!");
      } else if (response.status == 400 || response.status == 404) {
        openNotificationWithIcon('warning', "Warning", "Ordine non trovato!");
      } else {
        openNotificationWithIcon('error', "Error", "Errore nella cancellazione dell'ordine!");
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  }


  const getData = async () => {
    var data = {};
    console.log(data)
    try {
       /*carico la lookup degli accounts*/
       const lk_accounts = [];
       const response_accounts = await AccountsService.getLookup()
       response_accounts.data.map((account) => {
    
         if(type == "admin"){
           lk_accounts.push(<Option value={account[0]} key={account[0]}>{account[1].trim()}</Option>);
         }
         else if(keycloak.tokenParsed.accounts.indexOf(account[0]) != -1 && type != "admin"){
           lk_accounts.push(<Option value={account[0]} key={account[0]}>{account[1].trim()}</Option>);
         }
         return account;
       });
       setGroups(lk_accounts)
      if (
          filteredData.search != undefined
          ||
          filteredData.groups != undefined
          || filteredData.status != undefined 
          || (filteredData.dataInserimento != undefined && filteredData.dataInserimento.length != 0) 
          || (filteredData.dataPagamento !=undefined && filteredData.dataPagamento.length != 0)
        ) {
        data["accountId"] = id;  
        if(filteredData.groups != undefined){
          data["accountId"] = filteredData.groups;  
        }  
        if (filteredData.search != undefined)
          data["fieldSearch"] = filteredData.search;
        if (filteredData.status != undefined)
          data["paymentStatus"] = filteredData.status;
        if (filteredData.dataInserimento != undefined && filteredData.dataInserimento.length != 0) {
          let dateVarStart = moment(filteredData.dataInserimento[0]);
          let newDateVarStart = dateVarStart.utc().format("DD-MM-YYYY");
          let dateVarEnd = moment(filteredData.dataInserimento[1]);
          let newDateVarEnd = dateVarEnd.utc().format("DD-MM-YYYY");
          data["orderStartDate"] = newDateVarStart;
          data["orderEndDate"] = newDateVarEnd;
        }
        if (filteredData.dataPagamento != undefined && filteredData.dataPagamento.length != 0 ) {
          let dateVarStartPay = moment(filteredData.dataPagamento[0]);
          let newDateVarStartPay = dateVarStartPay.utc().format("DD-MM-YYYY");
          let dateVarEndPay = moment(filteredData.dataPagamento[1]);
          let newDateVarEndPay = dateVarEndPay.utc().format("DD-MM-YYYY");
          data["paymentStartDate"] = newDateVarStartPay;
          data["paymentEndDate"] = newDateVarEndPay;
        }
      }

      const response = Object.keys(data).length == 0
        ? await CreditsService.getOrderHistory(keycloak.token, id, keycloak.tokenParsed.sub.toString(),type)
        : await CreditsService.search(keycloak.token, keycloak.tokenParsed.sub.toString(), data,type);
      setHistory(response.data.content)
      setLoading(false);
      if (response.status == 500 || response.status == 0) {
        navigate('/error/500');
      } else {
        if (response.status == 403) {
          navigate('/error/403');
        } else if (response.status == 401) {
          navigate('/error/401');
        } else if (response.status == 404) {
          navigate('/error/404');
        }
      }
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true)
    setReload(false)
    getData()
  }, [filteredData,type,groupid,reload])


  const navigate = useNavigate();

  const onFilterChange = (filter) => {
    console.log(filter);
    setfilteredData(filter)
  }

  const handleClickExport = () => {
    const excel = new Excel();
    excel
      .addSheet("orders")
      .addColumns(columnsExport)
      .addDataSource(history, {
        str2Percent: true
      })
      .saveAs("export_orders.xlsx");
  };



  return (
    <Page inner>
      <Filter onFilterChange={onFilterChange} type={type} groups={groups}/>
      <List loading={loading} columns={columns} data={history}  handleClickExport={handleClickExport} exportflag={true} />
    </Page>
  )
}

export default CreditsHistory