/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react'
import { Card, Col, Row} from 'antd';
import { useTranslate } from '../../../Translate';
const { Meta } = Card;

const Step3 = (props) => {
  const {order} = props;
  console.log(order)
  const i18n = useTranslate();

  const { t } = i18n;
  const gridStyle = {
    width: '50%',
    textAlign: 'left'
  };

  const metaStyle = {
    padding: '5px'
  }

  const metaStyleStatus = {
    padding: '5px',
    color: '#E61E1E',
    fontWeight: "bold"
  }

  const status = [];
  status[0]= t("paid");
  status[1]= t("waitingforpayment");
  status[2]= t("draft");
  status[3]= t("canceled");

  const type = [];
  type[0]= t("PAYPAL");
  type[1]= t("Bonifico");
  type[2]= t("Bonus");
  
  return (
    <div style={{padding: '30px',background: '#ececec;'}}>
    <Row gutter={16}>
     <Col span={24}>
       <Card>
       <Card.Grid style={gridStyle}>
            <Meta title={t("companyname")} description={order.companyName} style={metaStyle} />
            <Meta title={t("orderdate")} description={new Intl.DateTimeFormat('it-IT', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(new Date(order.dataInserimento))} style={metaStyle} />
            <Meta title={t("ordertype")} description={type[order.tipoOrdine] ? type[order.tipoOrdine]  : ""}  style={metaStyle} />
            {/* <Meta title={t("orderstatus")} description={status[order.statoOrdine] ? status[order.statoOrdine]  : ""} style={metaStyleStatus}  /> */}
            <Meta  title={t("orderstatus")}  description={<span style={{color:'#E61E1E'}}>{status[order.statoOrdine] ? status[order.statoOrdine]  : "" }</span>} style={metaStyleStatus}  /> 
            <Meta title="Valuta" description="EUR"  style={metaStyle} />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
              <Meta title={t("refilledamount")} description={order.importoAccreditato != null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(order.importoAccreditato) : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0)} style={metaStyle} />
              <Meta title={t("amount")} description={order.imponibileOrdinato != null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(order.imponibileOrdinato) : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0)} style={metaStyle} />
              <Meta title={t("vat")} description={order.iva != null ? order.iva+' %' : "22 %"} style={metaStyle} />
              <Meta title={t("vatamount")} description={order.importoIva != null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(order.importoIva) : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0)}  style={metaStyle}  />
              <Meta title={t("totalpayment")} description={order.totaleOrdinato != null ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(order.totaleOrdinato) : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(0)}  style={metaStyle}  />
              <Meta title={t("details")} description={order.transferDetails != null ? order.transferDetails.data : ''}  style={metaStyle}  />
          </Card.Grid>
       </Card>
     </Col>
   </Row>
   </div>
  )
}

export default Step3