/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import Page from "../../Components/Page/Page";
import { useKeycloak } from "@react-keycloak/web";
import { ParameterService } from "../../Service/ParameterService";
import { Divider, Button, Form, Input, Select, notification } from "antd";

import {
  handleLogError,
  ErrorPage,
  openNotificationWithIcon,
  NumericInput,
} from "../../Helpers/Helpers";
import { useTranslate } from "../../Translate";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 2,
      offset: 2,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};

const BonusParameter = () => {
  const i18n = useTranslate();
  const { t } = i18n;
  const [loading, setLoading] = useState(false);
  const [bonus, setBonus] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    getData();
  }, []);

  const onFinish = (values) => {
    handleSendUpdate(values);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await ParameterService.getBonus(keycloak.token);
      setBonus(response.data);
      /*setto i campidel form*/
      form.setFieldsValue({
        nome: response.data.nome,
        descrizione: response.data.descrizione,
        valore: response.data.valore,
      });
    } catch (error) {
      handleLogError(error);
    }
    setLoading(false);
  };

  const handleSendUpdate = async (data) => {
    try {
      setLoading(true);
      data["id"] = bonus.id;
      const response = await ParameterService.update(keycloak.token, data);
      if (response.status == 200 || response.status==201) {
        openNotificationWithIcon(
          "success",
          "Success",
          "Bonus salvato con successo!"
        );
      } else {
        openNotificationWithIcon(
          "error",
          "Errore",
          "Errore nel salvataggio del bonus!"
        );
      }
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Page loading={loading} inner>
      <>
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ color: "#e61e1e" }}
        >
          {t("bonusregistrazione")}
        </Divider>
        <Form
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="profile"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            label={t("nome")}
            name="nome"
            rules={[
              {
                required: true,
                message: t("cknome"),
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item label={t("descrizione")} name="descrizione">
            <TextArea />
          </Form.Item>
          <Form.Item
            label={t("importo")}
            name="valore"
            rules={[
              {
                required: true,
                message: t("ckImporto"),
              },
            ]}
          >
            <NumericInput />
          </Form.Item>
          <Form.Item></Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              {t("save")}
            </Button>
          </Form.Item>
        </Form>
      </>
    </Page>
  );
};

export default BonusParameter;
